<script setup lang="ts">
import type { CompareMachineryAccessories } from './Page.vue'
import { machineryAccessoryCategories } from '~/server/schemas/machineryAccessory'
import type { MachineryAccessoryCategory } from '~/types'
import { machineryAccessoryCategoryToGerman } from '~/translations'

const props = defineProps<{ payload: CompareMachineryAccessories }>()
const emit = defineEmits<{
  (e: 'keep', payload: string[]): void
  (e: 'discard', payload: string[]): void
  (e: 'cancel'): void
}>()

const { machineryAccessory: queryMachineryAccessory, shopping: queryShopping } = useQuery()

const oldMachineryAttachedMachineryAccessoriesIds = props.payload.oldMachinery.attachedMachineryAccessories.map(({ id }) => id)
const newMachineryAttachedMachineryAccessoriesIds = props.payload.newMachinery.attachedMachineryAccessories.map(({ id }) => id)

const attachedMachineryAccessoriesIds = [...oldMachineryAttachedMachineryAccessoriesIds, ...newMachineryAttachedMachineryAccessoriesIds]

const nonAttachedMachineryAccessoriesKeys = props.payload.checkedMachineryAccessoryKeys.filter(key => !attachedMachineryAccessoriesIds.includes(key))
const selectedMachineryAccessoryCategories = nonAttachedMachineryAccessoriesKeys.filter(key => machineryAccessoryCategories.includes(key as MachineryAccessoryCategory))

const { data: selectedMachineryAcessoriesData } = queryMachineryAccessory.all(ref({ id: { in: nonAttachedMachineryAccessoriesKeys } }))
const selectedMachineryAcessories = computed(() => selectedMachineryAcessoriesData.value?.data ?? [])
const { data: availabilityInformationForSelectedAccessories } = queryShopping.checkSpecificMachineryAccessoryCombinations(props.payload.newMachinery.id, ref(nonAttachedMachineryAccessoriesKeys))
</script>

<template>
  <div v-if="selectedMachineryAcessories">
    Sie sind dabei das Gerät zu verändern. Bitte beachten Sie Folgendes:
    <ul class="mb-2 pl-2 list-inside list-disc">
      <li>
        Das ursprüngliche Gerät {{ payload.oldMachinery.id }} ist permanent verknüpft mit:
        <ul class="pl-4 list-inside list-disc">
          <li v-for="accessory of payload.oldMachinery.attachedMachineryAccessories" :key="accessory.id">
            {{ accessory.id }} ({{ machineryAccessoryCategoryToGerman[accessory.category as MachineryAccessoryCategory] }})
          </li>
        </ul>
      </li>

      <li>
        Das neue Gerät {{ payload.newMachinery.id }} ist permanent verknüpft mit:
        <ul class="pl-4 list-inside list-disc">
          <li v-for="accessory of payload.newMachinery.attachedMachineryAccessories" :key="accessory.id">
            {{ accessory.id }} ({{ machineryAccessoryCategoryToGerman[accessory.category as MachineryAccessoryCategory] }})
          </li>
        </ul>
      </li>
    </ul>

    <p class="mb-2">
      Permanente Verknüpfungen werden nicht aufgehoben.
    </p>

    Kompatibilität der ursprünglichen Anbaugeräte mit dem neuen Gerät:
    <ul class="mb-2 pl-2 list-inside list-disc">
      <li v-for="accessory of selectedMachineryAcessories" :key="accessory.id">
        {{ accessory.id }} ({{ availabilityInformationForSelectedAccessories?.[accessory.id] ? 'kompatibel' : 'inkompatibel' }})
      </li>
      <li v-for="(selectedCategory, index) of selectedMachineryAccessoryCategories" :key="index">
        {{ machineryAccessoryCategoryToGerman[selectedCategory as MachineryAccessoryCategory] }}: {{ payload.newMachinery.type.compatibleMachineryAccessories.some(({ category }) => category === selectedCategory) ? 'kompatibel' : 'inkompatibel' }}
      </li>
    </ul>

    Sollen alle ursprünglichen ausgewählten Anbaugeräte erhalten bleiben?
    <div class="mt-4 flex gap-x-4">
      <n-button
        type="primary"
        @click="emit('keep', [...newMachineryAttachedMachineryAccessoriesIds, ...nonAttachedMachineryAccessoriesKeys])"
      >
        Ja
      </n-button>
      <n-button
        type="primary"
        @click="emit('discard', newMachineryAttachedMachineryAccessoriesIds)"
      >
        Nein
      </n-button>
      <n-button @click="emit('cancel')">
        Vorgang abbrechen
      </n-button>
    </div>
  </div>
</template>
