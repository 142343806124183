<script setup lang="ts">
const { t: $t } = useI18n()

import { CustomerLanguage, CustomerType } from '~/prisma/enums'
import { paymentConditions, taxRates } from '~/server/schemas'
import type { ApiCustomerGetById, UpdateOrCreateCustomer } from '~/types'

const props = defineProps<{
  mode: 'create' | 'update'
  showComments: boolean
  customer?: ApiCustomerGetById | null
  canUpdateCustomerUser: boolean
  inviteErrors: Record<string, string>
  isSaving: boolean
  payload: UpdateOrCreateCustomer['data']
}>()
const emit = defineEmits<{
  (e: 'save', payload: UpdateOrCreateCustomer): void
  (e: 'resetInviteErrors'): void
}>()

const customerTypeOptions = useTranslatedSelectOptions('customer.type', Object.values(CustomerType))

const taxRateOptions = taxRates.options.map(rate => ({
  label: `${(Number(rate) * 100).toFixed(0)}%`,
  value: rate,
}))

const countryOptions = getCountryOptions()

const paymentConditionOptions = paymentConditions.options.map(condition => ({
  label: $t(`customer.paymentCondition.${condition}`),
  value: condition,
}))

const languageOptions = useTranslatedSelectOptions('customer.language', Object.values(CustomerLanguage))

const contactPersons = ref<UpdateOrCreateCustomer['data']['contactPersons']>(props.payload.contactPersons)
// Reset invite errors when contact persons updated
watch(contactPersons, () => {
  emit('resetInviteErrors')
})

const formData = ref<UpdateOrCreateCustomer['data']['customer']>(props.payload.customer)
watchEffect(() => {
  if (formData.value && formData.value?.type !== CustomerType.Dealer) {
    formData.value.isPartnerLiftCustomer = false
    formData.value.isSystemLiftCustomer = false
  }
})

const alreadyInvitedContactPersons = computed((): Record<string, boolean> => {
  if (!props.customer) {
    return {}
  }
  return props.customer.contactPersons.reduce((acc, contactPerson) => {
    acc[contactPerson.id] = contactPerson.customerUser !== null
    return acc
  }, {} as Record<string, boolean>)
})
</script>

<template>
  <FormKit
    v-model="formData"
    type="form"
    :actions="false"
    :disabled="isSaving"
    @submit="emit('save', { data: { customer: $event, contactPersons: contactPersons ?? [] }, mode })"
  >
    <CommentList v-if="customer && showComments" :id="customer.id" type="Customer" class="mb-5" />
    <TheDevOnlyNiceJson :form-value="formData" />
    <div class="flex space-x-2">
      <FormKit
        name="name"
        type="text"
        validation="required"
        :label="$t('customer.company.name.label')"
        :placeholder="$t('customer.field.nameThree.placeholder')"
      />
      <FormKit
        name="nameTwo"
        type="text"
        :label="`${$t('common.field.name.name')} 2`"
        :placeholder="$t('customer.field.nameTwo.placeholder')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="nameThree"
        type="text"
        :label="`${$t('common.field.name.name')} 3`"
        :placeholder="$t('customer.field.nameThree.placeholder')"
      />
      <FormKit
        name="type"
        type="select"
        :options="customerTypeOptions"
        validation="required"
        :label="$t('common.field.type.name')"
        :placeholder="$t('common.pleaseSelect')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKitAddressPopup
        name="address"
        :initial-value="formData.location"
        :label="$t('customer.field.companyAddress.name')"
        :placeholder="$t('customer.field.companyAddress.placeholder')"
        disable-expand
      />
      <FormKitAddressPopup
        name="invoiceFullAddress"
        :initial-value="formData.invoiceAddress"
        :label="$t('customer.field.invoiceAddress.name')"
        :placeholder="$t('customer.field.invoiceAddress.placeholder')"
        disable-expand
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="email"
        type="email"
        validation="email"
        :label="$t('common.field.email.name')"
        :placeholder="$t('common.field.email.placeholder')"
      />
      <FormKit
        name="invoiceEmail"
        type="email"
        :label="$t('customer.field.invoiceEmail.name')"
        :placeholder="$t('common.field.email.placeholder')"
        validation="required"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="telephone"
        type="tel"
        :label="$t('common.field.telephone.name')"
        :placeholder="$t('common.field.telephone.placeholder')"
      />
      <FormKit
        name="telefax"
        type="tel"
        :label="$t('customer.field.telefax.name')"
        :placeholder="$t('customer.field.telefax.placeholder')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="iban"
        type="text"
        :label="$t('customer.field.iban.name')"
        :placeholder="$t('customer.field.iban.placeholder')"
      />
      <FormKit
        name="taxId"
        type="text"
        :label="$t('customer.field.taxId.name')"
        :placeholder="$t('customer.field.taxId.placeholder')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="vatID"
        type="text"
        :label="$t('customer.field.vatID.name')"
        :placeholder="$t('customer.field.vatID.placeholder')"
      />
      <FormKit
        name="taxRate"
        type="select"
        :options="taxRateOptions"
        :label="$t('customer.field.taxRate.name')"
      />
      <FormKit
        name="paymentCondition"
        type="select"
        :options="paymentConditionOptions"
        validation="required"
        :label="$t('common.field.paymentCondition.name')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="language"
        type="select"
        :options="languageOptions"
        validation="required"
        :label="$t('common.field.language.name')"
        :placeholder="$t('common.pleaseSelect')"
      />
      <FormKitMultiSelect
        name="country"
        :label="$t('customer.field.country.name')"
        :multi-select-options="countryOptions"
        :is-multi-select-allowed="false"
        :placeholder="$t('customer.field.country.placeholder')"
      />
    </div>

    <h2 class="Heading">
      {{ $t('customer.additionals') }}
    </h2>
    <div class="flex space-x-2">
      <FormKit
        name="isExternalStorageCustomer"
        type="checkbox"
        :label="$t('customer.field.externalStorageCustomer.name')"
      />
      <FormKit
        name="isExternalStorageSupplier"
        type="checkbox"
        :label="$t('customer.field.externalStorageSupplier.name')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="isPartnerLiftCustomer"
        type="checkbox"
        :disabled="formData.isSystemLiftCustomer || formData.type !== CustomerType.Dealer"
        :label="$t('customer.field.isPartnerLiftCustomer.name')"
      />
      <FormKit
        name="isSystemLiftCustomer"
        type="checkbox"
        :disabled="formData.isPartnerLiftCustomer || formData.type !== CustomerType.Dealer"
        :label="$t('customer.field.isSystemLiftCustomer.name')"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="isExcludedFromMarketingCommunication"
        type="checkbox"
        :label="$t('customer.field.isExcludedFromMarketingCommunication.name')"
      />
    </div>

    <CustomerContactPersonInput
      v-model="contactPersons"
      :already-invited-contact-persons
      :can-update-customer-user
      :invite-errors
    />

    <FormKit type="submit">
      {{ $t('button.save') }} <n-spin v-if="isSaving" size="small" class="ml-2" />
    </FormKit>
  </FormKit>
</template>

<style scoped>
.Heading {
  @apply text-xl font-bold py-4
}
</style>
