<script lang="ts" setup>
const { t: $t } = useI18n()

import type { ApiCustomerModuleOfferGetById } from '~/layers/customer-module/types'

const props = defineProps<{
  context: {
    value: string[]
    node: { input: (value: string[]) => unknown }
    options: ApiCustomerModuleOfferGetById['positions']
    title?: string
  }
}>()

// Machinery positions with attached accessories are grouped together and placed first
const orderedOptions = computed(() => {
  const machineriesWithAttachedAccessories: ApiCustomerModuleOfferGetById['positions'] = []
  for (const position of props.context.options) {
    if (!position.machinery?.attachedMachineryAccessories) {
      continue
    }

    const attachedAccessories = props.context.options.filter(p => p.machineryAccessory?.attachedMachineryId === position.machinery?.id)
    machineriesWithAttachedAccessories.push(position, ...attachedAccessories)
  }

  const machineryWithAttachedAccessoriesIds = machineriesWithAttachedAccessories.map(p => p.id)
  const otherOptions = props.context.options.filter(p => !machineryWithAttachedAccessoriesIds.includes(p.id))
  return [...machineriesWithAttachedAccessories, ...otherOptions]
})
const optionsToSelect = computed(() => props.context.options.filter(({ terminatedAt, isDelivered }) => !!isDelivered && !terminatedAt))

const isScanPopupOpen = ref(false)

function handleClick(position: ApiCustomerModuleOfferGetById['positions'][number]) {
  // Get attached machinery accessory IDs of a machinery position
  let attachedAccessoryIds: string[] | undefined
  if (position.machinery?.attachedMachineryAccessories) {
    attachedAccessoryIds = props.context.options.filter(p => p.machineryAccessory?.attachedMachineryId === position.machinery?.id).map(p => p.id)
  }

  const id = position.id
  const checkedItems = new Set(props.context.value)

  // Uncheck position
  if (checkedItems.has(id)) {
    checkedItems.delete(id)
    if (attachedAccessoryIds) {
      attachedAccessoryIds.forEach(id => checkedItems.delete(id))
    }
    return props.context.node.input(Array.from(checkedItems))
  }

  // Check position
  checkedItems.add(id)
  if (attachedAccessoryIds) {
    attachedAccessoryIds.forEach(id => checkedItems.add(id))
  }
  return props.context.node.input(Array.from(checkedItems))
}

function handleScan(machineryid: string) {
  const position = optionsToSelect.value.findLast((p) => {
    if (p.machinery?.id === machineryid || p.machineryAccessory?.id === machineryid || p.id === machineryid) {
      return true
    }
    return false
  })

  if (position) {
    handleClick(position)
  }
}

function toggleSelect() {
  if (props.context.value.length === optionsToSelect.value.length) {
    return props.context.node.input([])
  }
  props.context.node.input(optionsToSelect.value.map(({ id }) => id))
}

function isClickable(position: ApiCustomerModuleOfferGetById['positions'][number]) {
  return position.isDelivered && !position.terminatedAt && !position.machineryAccessory?.attachedMachineryId
}

function getUnclickableMessage(position: ApiCustomerModuleOfferGetById['positions'][number]) {
  if (!position.isDelivered || position.terminatedAt) {
    return $t('customerModule.positionSelector.tooltip.notTerminable')
  }

  if (position.machineryAccessory?.attachedMachineryId) {
    return $t('customerModule.positionSelector.tooltip.permanentlyAttachedToMachinery')
  }
}
</script>

<template>
  <CustomerModuleEmptyPlaceholder
    v-if="context.options.length === 0"
    title="Keine Geräte"
    cta="In diesem Auftrag sind keine aktiven Geräte mehr."
  />
  <div v-else>
    <div class="flex items-center justify-between">
      <span class="text-lg font-bold">
        {{ context.title }}
      </span>
      <div class="flex items-center gap-1 mr-1">
        <n-button :loading="isScanPopupOpen" @click="isScanPopupOpen = true">
          <template #icon>
            <Icon name="material-symbols:barcode-scanner-rounded" />
          </template>
          {{ $t('customerModule.offer.position.scan') }}
        </n-button>
        <n-button class="size-9" @click="toggleSelect">
          <template #icon>
            <Icon
              v-if="context.value.length === optionsToSelect.length"
              name="material-symbols:select-check-box-rounded"
              class="text-hos-blue"
              size="25"
            />
            <Icon v-else name="material-symbols:check-box-outline-blank" size="25" />
          </template>
        </n-button>
      </div>
    </div>

    <n-divider class="!my-3" />

    <div class="space-y-3">
      <n-tooltip
        v-for="position in orderedOptions"
        :key="position.id"
        :disabled="isClickable(position)"
        trigger="hover"
        placement="bottom"
      >
        <template #trigger>
          <CustomerModuleOfferPositionCard
            :position="position"
            class="cursor-pointer hover:bg-hos-blue-light/15"
            :class="{
              'bg-hos-blue-light/15 ring-2': context.value.includes(position.id),
              'opacity-50 hover:bg-transparent !cursor-not-allowed': !isClickable(position),
            }"
            @click="isClickable(position) && handleClick(position)"
          >
            <template #extra>
              <div>
                <div v-if="position.isDelivered && !position.terminatedAt" class="absolute bottom-2 sm:top-2 right-2 text-hos-blue">
                  <Icon v-if="context.value.includes(position.id)" name="material-symbols:select-check-box-rounded" size="25" />
                  <Icon v-else name="material-symbols:check-box-outline-blank" size="25" />
                </div>
              </div>
            </template>
          </CustomerModuleOfferPositionCard>
        </template>
        {{ getUnclickableMessage(position) }}
      </n-tooltip>
    </div>

    <CustomerModuleScanPopup
      v-model="isScanPopupOpen"
      :ids="optionsToSelect.map(position => position?.machinery?.id ?? position?.machineryAccessory?.id ?? position.id)"
      @select="handleScan"
    />
  </div>
</template>
