<script lang="ts" setup>
import type { ApiCommentGetAllReaction } from '~/types'
import type { CommentReactionType } from '~/prisma/enums'

const props = defineProps<{
  isReadOnly: boolean
  reactionsByType: Partial<Record<CommentReactionType, ApiCommentGetAllReaction[]>>
  userSelectedReaction?: ApiCommentGetAllReaction
}>()
defineEmits<{
  (e: 'handleReactionClick', payload: CommentReactionType): void
}>()

const reactionsByTypeEntries = computed(() => Object.entries(props.reactionsByType) as [CommentReactionType, ApiCommentGetAllReaction[]][])
</script>

<template>
  <div class="flex gap-1 flex-wrap items-center">
    <n-tag
      v-for="[type, reactions] in reactionsByTypeEntries"
      :key="type"
      class="border-2"
      :checked="userSelectedReaction?.type === type"
      :checkable="!props.isReadOnly"
      round
      :color="{ borderColor: '#808080' }"
      @update:checked="$emit('handleReactionClick', type)"
    >
      <n-popover trigger="hover" placement="top-start">
        <template #trigger>
          <div class="flex items-center justify-center gap-1 py-2">
            <span>{{ commentReactionConfig[type] }}</span>
            <span>{{ reactions.length }}</span>
          </div>
        </template>
        <p>
          {{ reactions.map(({ createdBy }) => createdBy.name ?? createdBy.email).join(', ') }}
        </p>
      </n-popover>
    </n-tag>
  </div>
</template>
