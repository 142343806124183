import currency from 'currency.js'
import options from '../../options'
import type { PDFDocumentType } from '../../type'
import { extractInnerEntities, formatPercentage, maxWidth, reset } from '../../helpers'
import type { OfferPDFData } from '../../getOffer'
import header from '../header'
import signatures from './signatures'
import type { OfferType } from '~/types'

interface Offer {
  type: string
  status: string
  customer: { taxRate: string | null }
  otherInvoiceRecipient: { taxRate: string | null } | null
  positions: PositionToCalculatePrice[]
  rentalDays?: { discountRate: number, date: Date }[]
}

/* ------ DYNAMIC DATA ------ */
export function calculateTotals(offer: Offer, shouldGetExtendedRentalDays = true, showSingleUnitPrice?: boolean) {
  const { positions, rentalDays } = offer
  const invoiceRecipient = offer.otherInvoiceRecipient ?? offer.customer
  const { machineryRelations, machineryCategoryRelations, accessoryRelations, itemSetRelations, extraPositionRelations, insurancePositionRelations, automatedPositionsRelations } = extractInnerEntities(positions)

  const isForServiceProject = offer.type === 'service-project'
  const machineryPrices = machineryRelations.map(machineryRelation => calculateTotalPositionPrice(machineryRelation, rentalDays, offer.type as OfferType, shouldGetExtendedRentalDays, showSingleUnitPrice))
  const machineryCategoryPrices = machineryCategoryRelations.map(machineryCategoryRelation => calculateTotalPositionPrice(machineryCategoryRelation, rentalDays, offer.type as OfferType, shouldGetExtendedRentalDays, showSingleUnitPrice))
  const accessoryPrices = accessoryRelations.map(accessoryRelation => calculateTotalPositionPrice(accessoryRelation, rentalDays, offer.type as OfferType, shouldGetExtendedRentalDays, showSingleUnitPrice))
  const itemSetPrices = itemSetRelations.map(itemSetRelation => calculateTotalPositionPrice(itemSetRelation, rentalDays, offer.type as OfferType, shouldGetExtendedRentalDays, showSingleUnitPrice))
  const furtherPositionPrices = extraPositionRelations.map((extraPositionRelation) => {
    const showSingleUnitPriceForThisPosition = showSingleUnitPrice && !(['extraPosition', 'manualPosition'].includes(extraPositionRelation.type) && isForServiceProject)
    return calculateTotalPositionPrice(extraPositionRelation, undefined, offer.type as OfferType, shouldGetExtendedRentalDays, showSingleUnitPriceForThisPosition)
  })
  const insurancePositionPrices = insurancePositionRelations.map(insurancePositionRelation => calculateTotalPositionPrice(insurancePositionRelation, rentalDays, offer.type as OfferType, shouldGetExtendedRentalDays, showSingleUnitPrice))
  const automatedPositionsPrices = automatedPositionsRelations.map(automatedPositionsRelation => calculateTotalPositionPrice(automatedPositionsRelation, undefined, offer.type as OfferType, shouldGetExtendedRentalDays, showSingleUnitPrice))

  const netto = [...machineryPrices, ...machineryCategoryPrices, ...accessoryPrices, ...itemSetPrices, ...furtherPositionPrices, ...insurancePositionPrices, ...automatedPositionsPrices].reduce((total, current) => total.add(current), currency(0))
  const vat = netto.multiply(Number(invoiceRecipient.taxRate))
  const brutto = netto.add(vat)

  return { netto, brutto, vat }
}

function getData(rentalOrSale: OfferPDFData, i18n: I18n, showSingleUnitPrice?: boolean) {
  const { type, customer, otherInvoiceRecipient, positions, rentalDays, status } = rentalOrSale
  const invoiceRecipient = otherInvoiceRecipient ?? customer
  const { netto: totalNetto, brutto: totalBrutto, vat } = calculateTotals({ type, positions, status, customer, otherInvoiceRecipient, rentalDays }, false, showSingleUnitPrice)

  return {
    paymentLines: [
      { label: i18n.t('pdf.offer.total.netAmount'), middle: '€', value: formatNumberToString(totalNetto.value, i18n.locale) },
      { label: i18n.t('pdf.offer.total.vat', { rate: formatPercentage(invoiceRecipient.taxRate, i18n.locale) }), middle: '%', value: formatNumberToString(vat.value, i18n.locale) },
      { label: i18n.t('pdf.offer.total.grossAmount'), middle: '€', value: formatNumberToString(totalBrutto.value, i18n.locale) },
    ],
    paymentCondition: i18n.t(`customer.paymentCondition.${rentalOrSale.paymentCondition}`),
  }
}
/* -------------------------- */

/* ------ VARIABLE OPTIONS ------ */
const indent = 30
const preValueGap = 20
const textFont = options.fontFamily.base
const textBruttoBetragFont = options.fontFamily.bold
/* ------------------------------ */

export default function (doc: PDFDocumentType<OfferPDFData>, i18n: I18n, extraPaddingBelow = 0, showSingleUnitPrice?: boolean) {
  const { paymentLines, paymentCondition } = getData(doc.data, i18n, showSingleUnitPrice)

  /* ------ INLINE OPTIONS ------ */
  reset(doc)
    .font(textFont)
  /* ---------------------------- */

  /* --------- RENDER CODE ------ */
  const labelWidth = Math.max(maxWidth(doc, [paymentLines[0].label, paymentLines[2].label]), doc.widthOfString(paymentLines[1].label) + indent)
  const valueWidth = maxWidth(doc, paymentLines.map(({ value }) => value))
  const middleWidth = maxWidth(doc, paymentLines.map(({ middle }) => middle))
  const leftX = doc.page.width - options.margin.right - labelWidth - doc.widthOfString(' ') - middleWidth - preValueGap - valueWidth
  const midX = leftX + labelWidth + doc.widthOfString(' ')
  const rightX = midX + middleWidth + preValueGap + valueWidth
  const shouldShowSignature = ['order', 'invoiced'].includes(doc.data.status)

  if (doc.y + extraPaddingBelow + doc.currentLineHeight(true) * paymentLines.length >= doc.footerStartY) {
    doc.addPage()
    header(doc)
  } else {
    doc.y = doc.footerStartY - doc.currentLineHeight(true) - extraPaddingBelow
  }

  doc
    .text(paymentLines[0].label, leftX, doc.y, { lineBreak: false })
    .text(paymentLines[0].middle, midX, doc.y, { lineBreak: false })
    .text(paymentLines[0].value, rightX - doc.widthOfString(paymentLines[0].value))

  doc
    .text(paymentLines[1].label, leftX + indent, doc.y, { lineBreak: false })
    .text(paymentLines[1].middle, midX, doc.y, { lineBreak: false })
    .text(paymentLines[1].value, rightX - doc.widthOfString(paymentLines[1].value))

  doc
    .font(textBruttoBetragFont)
    .text(paymentLines[2].label, leftX, doc.y, { lineBreak: false })
    .text(paymentLines[2].middle, midX, doc.y, { lineBreak: false })
    .text(paymentLines[2].value, rightX - doc.widthOfString(paymentLines[2].value))
    .font(textFont)

  doc
    .moveUp()
    .text(paymentCondition, options.margin.left)

  if (shouldShowSignature) {
    doc.addPage()
    header(doc)

    if (doc.data.type === 'rental') {
      doc
        .moveDown(1)
        .fontSize(8)
        .text(i18n.t('pdf.offer.total.signatureConfirmation'))
        .moveDown(5)
    } else {
      doc.moveDown(5.5)
    }
    signatures(doc, i18n)
  }

  return reset(doc)
  /* ---------------------------- */
}
