import type { QueryClient } from '@tanstack/vue-query'

const entityToQueryKey: Record<FileFolderEntity, string[][]> = {
  offer: [['offer'], [CUSTOMER_MODULE_QUERY_KEY, 'offers']],
  customer: [['customer']],
}

export default (entity: FileFolderEntity, queryClient: QueryClient) => {
  return entityToQueryKey[entity].map(queryKey => queryClient.invalidateQueries({ queryKey }))
}
