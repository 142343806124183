<script setup lang="ts">
import currency from 'currency.js'
import type { CreateInvoiceCustomOrCreditNotePosition, CreateInvoiceCustomOrCreditNotePositionType } from '~/types'
import { positionUnits } from '~/server/schemas'
import { unitsToGerman } from '~/translations'

defineProps<{ modelValue: CreateInvoiceCustomOrCreditNotePosition[], type: 'creditPosition' | 'invoice', allowNegativeValue?: boolean }>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: CreateInvoiceCustomOrCreditNotePosition[]): void
  (e: 'delete', idx: number): void
  (e: 'createCustomPosition', type: CreateInvoiceCustomOrCreditNotePositionType): void
}>()

const positionUnitOptions = positionUnits.options.map(type => ({
  label: unitsToGerman[type],
  value: type,
}))

const calculateTotalCustomPositionPrice = (pricePerUnit: number, quantity: number) => quantity === 0 ? currency(0) : currency(pricePerUnit ?? 0).multiply(quantity)
</script>

<template>
  <div>
    <div class="flex flex-col md:flex-row mb-4 md:items-center md:justify-between gap-2">
      <span>Zusätztliche individuelle {{ type === 'creditPosition' ? 'Rechnungskorrektur-Positionen' : 'Rechnungspositionen' }} erstellen:</span>
      <div class="flex flex-col md:flex-row gap-2">
        <n-button class="grow" @click="emit('createCustomPosition', type === 'creditPosition' ? 'creditNote' : 'invoice')">
          <template #icon>
            <Icon name="material-symbols:add-comment" />
          </template>
          Individuelle Position hinzufügen
        </n-button>
        <n-button class="grow" @click="emit('createCustomPosition', 'comment')">
          <template #icon>
            <Icon name="material-symbols:add-comment" />
          </template>
          Individueller Kommentar hinzufügen
        </n-button>
      </div>
    </div>

    <n-divider />
    <div class="h-[200px] overflow-y-auto px-1">
      <FormKit
        v-if="modelValue.length > 0"
        type="list"
        :model-value="modelValue"
      >
        <FormKit v-for="(value, index) of modelValue" :key="index" type="group">
          <div class="flex items-center justify-between w-full">
            <div v-if="value.type === 'comment'" class="w-10/12">
              <FormKit
                v-model="value.title"
                outer-class="CommentInput"
                type="text"
                label="Kommentar"
                validation="required"
              />
            </div>
            <div v-else class="flex items-center justify-between gap-5 w-10/12">
              <FormKit
                v-model="value.title"
                type="text"
                label="Bezeichnung"
                validation="required"
              />
              <FormKit
                v-model="value.quantity"
                label="Menge"
                type="number"
                number
                step="0.01"
                :validation="`required${allowNegativeValue ? '' : '|min:0'}`"
              />
              <FormKitMultiSelect
                v-model="value.unit"
                :multi-select-options="positionUnitOptions"
                :is-multi-select-allowed="false"
                label="Einheit"
                validation="required"
                placeholder="Bitte auswählen"
              />
              <FormKit
                v-model="value.pricePerUnit"
                type="number"
                number
                step="0.01"
                label="E-Preis"
                :validation="`required${allowNegativeValue ? '' : '|min:0'}`"
              />
              <div class="w-[500px] font-semibold text-base">
                <p>G-Preis:</p>
                {{ formatNumberToString(calculateTotalCustomPositionPrice(value.pricePerUnit, value.quantity).value) }} €
              </div>
            </div>
            <div class="flex justify-end items-center gap-x-4">
              <n-button
                @click="emit('delete', index)"
              >
                <template #icon>
                  <Icon name="material-symbols:delete-outline-rounded" />
                </template>
              </n-button>
            </div>
          </div>
        </FormKit>
      </FormKit>
      <div v-else class="h-full bg-gray-50 flex items-center justify-center">
        <div class="text-center">
          <p class="text-lg">
            Noch keine Positionen hinzugefügt.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.n-dynamic-input-item__action) {
  @apply self-center !important
}

.CommentInput :deep(.formkit-inner) {
  @apply !max-w-full;
}

:deep(textarea.formkit-input) {
  @apply min-h-[2.5rem] h-10 p-2;
}
</style>
