<script setup lang="ts">
import type { DataTableRowKey } from 'naive-ui'
import { machineryAccessoryCategorySchema } from '~/server/schemas'

const props = withDefaults(defineProps<{ alreadyCheckedRowKeys: DataTableRowKey[], selectType?: 'multiple' | 'single' }>(), {
  alreadyCheckedRowKeys: () => [],
  selectType: 'multiple',
})
const emit = defineEmits<{ (e: 'makeCompatible', machineryAccessoryIds: string[]): void }>()

const { machineryAccessorySelect } = useTableColumnConfigs()
const tableConfig = machineryAccessorySelect({ selectType: props.selectType, isSetSelectable: true })

const { fulltextSearchValue, where: fulltextSearchWhere } = useFilterData(tableConfig.value.fulltextSearch)
const { serverSidePaginationTableProps, serverSidePagination, watchForPageCount, watchForReset } = useTablePagination({ pageCount: 1, pageSize: 10 })

const selectedMachineryAccessoryCategories = ref<string[]>([])
const machineryAccessoryCategorySelectOptions = computed(() => useTranslatedSelectOptions('machineryAccessory.category', machineryAccessoryCategorySchema.options))

function resetFilters() {
  selectedMachineryAccessoryCategories.value = []
}

const where = computed(() => ({
  ...fulltextSearchWhere.value,
  category: selectedMachineryAccessoryCategories.value.length > 0 ? { in: selectedMachineryAccessoryCategories.value } : undefined,
}))

const { machineryAccessory } = useQuery()
const { data, error, isLoading } = machineryAccessory.all(where, undefined, undefined, serverSidePagination)
const accessories = computed(() => data.value?.data ?? [])

const checkedRowKeys = ref<DataTableRowKey[]>(props.alreadyCheckedRowKeys)

watchForPageCount(computed(() => data.value?.totalCount ?? 0))
watchForReset(fulltextSearchWhere)
</script>

<template>
  <TheDataCard title="Kompatible Anbaugeräte" :error="error">
    <TableFilters v-model="fulltextSearchValue" :placeholder="tableConfig.fulltextSearch.placeholder">
      <n-collapse class="mb-2">
        <n-collapse-item>
          <template #header>
            <span class="text-lg">
              {{ $t('machinery.compatibleMachineryAccessories.table.filters') }}
            </span>
          </template>

          <div class="flex flex-col gap-x-2 gap-y-5 bg-gray-200 p-3 rounded-sm">
            <div class="grid grid-cols-3 gap-2">
              <div>
                <p class="font-semibold">
                  {{ $t('machineryAccessory.category.title') }}
                </p>
                <n-select
                  v-model:value="selectedMachineryAccessoryCategories"
                  :options="machineryAccessoryCategorySelectOptions"
                  multiple
                  filterable
                  clearable
                />
              </div>

              <div class="flex items-end">
                <n-button class="!bg-white w-full md:w-auto" @click="resetFilters">
                  {{ $t('common.resetAll') }}
                </n-button>
              </div>
            </div>
          </div>
        </n-collapse-item>
      </n-collapse>

      <TableView
        v-if="accessories"
        v-bind="serverSidePaginationTableProps"
        :data="accessories"
        :columns="tableConfig.columns"
        :is-loading="isLoading"
        :row-key="row => row.id"
        :checked-row-keys="checkedRowKeys"
        :row-props="tableConfig.rowProps"
        @update:checked-row-keys="keys => checkedRowKeys = keys"
      />
    </TableFilters>
    <n-button type="primary" class="mt-4" @click="emit('makeCompatible', checkedRowKeys as string[])">
      <template #icon>
        <Icon name="material-symbols:add-link" />
      </template>
      Maschine mit {{ checkedRowKeys.length }} ausgewählten Anbaugeräten kompatibel machen
    </n-button>
  </TheDataCard>
</template>
