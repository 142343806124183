<script setup lang="ts">
const { t: $t } = useI18n()

import type { CommentType } from '~/prisma/enums'

const props = withDefaults(defineProps<{
  id: string
  type: CommentType
  isReadOnly?: boolean
  isClosable?: boolean
  hideListTitle?: boolean
  listTitleText?: string
  refreshQueries?: CommentType[]
  canDelete?: boolean
  isVisibilityAdjustable?: boolean
}>(), {
  isReadOnly: false,
  isClosable: true,
  hideListTitle: false,
  listTitleText: 'Kommentare',
  refreshQueries: (): CommentType[] => [],
  canDelete: true,
  isVisibilityAdjustable: false,
})

const emit = defineEmits<{ (e: 'afterOpenCreatePopup'): void }>()

const { comment: queryComment } = useQuery()
const { data: comments, isLoading, isSuccess } = queryComment.allByTypeAndEntityId(computed(() => ({ type: props.type, entityId: props.id })))

const popupConfirmDeleteComment = ref<null | string>(null)
const { openCommentCreateOrUpdatePopup } = useGlobalOpeners()

function openCommentCreate(originalCommentId?: string) {
  openCommentCreateOrUpdatePopup.open({
    type: props.type,
    id: props.id,
    isClosable: props.isClosable,
    refreshQueries: props.refreshQueries,
    isVisibilityAdjustable: props.isVisibilityAdjustable,
    originalCommentId,
    mode: 'create',
  })
  emit('afterOpenCreatePopup')
}
function openCommentUpdate(commentId: string) {
  openCommentCreateOrUpdatePopup.open({
    type: props.type,
    id: commentId,
    isClosable: true,
    refreshQueries: props.refreshQueries,
    isVisibilityAdjustable: props.isVisibilityAdjustable,
    mode: 'update',
    commentId,
  })
}
const commentTitle = computed(() => {
  const base = props.listTitleText
  if (comments.value !== undefined) {
    return `${base} (${comments.value.length})`
  }
  return base
})

/** Mutations */
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const create = useMutation({
  mutationFn: $trpc.comment.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('comment.notification.create.error') }),
  onSuccess: async () => {
    await Promise.all(getQueryKeysToInvalidate(true))
    notification.success({ title: $t('comment.notification.create.success'), duration: 4500 })
  },
})
const deleteOne = useMutation({
  mutationFn: $trpc.comment.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('comment.notification.delete.error') }),
  onSuccess: async () => {
    await Promise.all(getQueryKeysToInvalidate(true))
    popupConfirmDeleteComment.value = null
    notification.success({ title: $t('comment.notification.delete.success'), duration: 4500 })
  },
})
const commentReactionUpsert = useMutation({
  mutationFn: $trpc.commentReaction.upsert.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('commentReaction.update.error') }),
  onSuccess: () => Promise.all(getQueryKeysToInvalidate()),
})
const commentReactionDelete = useMutation({
  mutationFn: $trpc.commentReaction.deleteByCommentId.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('commentReaction.update.error') }),
  onSuccess: () => Promise.all(getQueryKeysToInvalidate()),
})

function getQueryKeysToInvalidate(shouldInvalidateRefreshQueries = false) {
  const invalidateQueriesPromises = [
    queryClient.invalidateQueries({ queryKey: ['comment'] }),
    queryClient.invalidateQueries({ queryKey: [commentConfigs[props.type].entity] }),
  ]
  if (shouldInvalidateRefreshQueries) {
    props.refreshQueries.forEach((query) => {
      invalidateQueriesPromises.push(queryClient.invalidateQueries({ queryKey: [commentConfigs[query].entity] }))
    })
  }
  return invalidateQueriesPromises
}
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div v-if="!isReadOnly" class="w-full text-right">
      <n-button @click="openCommentCreate()">
        Neuen Kommentar erstellen
      </n-button>
    </div>

    <p v-if="!hideListTitle" class="font-semibold mb-1">
      {{ commentTitle }}
    </p>
    <div v-if="isLoading">
      Kommentare werden geladen...
    </div>
    <div v-else-if="isSuccess" class="flex flex-col gap-y-4">
      <div v-if="!comments || comments.length === 0">
        Es gibt noch keine Kommentare.
      </div>
      <CommentItem
        v-for="comment of comments"
        v-else
        :key="comment.id"
        :comment="comment"
        :entity-id="id"
        :is-read-only
        :is-delete-disabled="!canDelete"
        :is-visibility-adjustable
        @create="create.mutate"
        @update="openCommentUpdate"
        @delete="popupConfirmDeleteComment = $event"
        @reaction-upsert="(commentId, type) => commentReactionUpsert.mutate({ commentId, type })"
        @reaction-delete="commentReactionDelete.mutate({ commentId: $event })"
      />
    </div>
    <div v-else>
      Kommentare konnten nicht geladen werden
    </div>
  </div>

  <TheConfirmPopup v-if="popupConfirmDeleteComment" @confirm="deleteOne.mutate({ id: popupConfirmDeleteComment })" @close="popupConfirmDeleteComment = null">
    Der Kommentar und alle zugehörigen Daten werden gelöscht.
  </TheConfirmPopup>
</template>
