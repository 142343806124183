<script lang="ts" setup>
const { t: $t } = useI18n()

import type { ApiCommentGetAll, CreateComment, UpdateOrCreateComment } from '~/types'
import type { ApiCustomerModuleCommentGetAll } from '~/layers/customer-module/types'
import { type CommentReactionType, CommentType, CommentVisibility } from '~/prisma/enums'

const props = defineProps<{
  comment: ApiCommentGetAll | ApiCustomerModuleCommentGetAll
  isReadOnly: boolean
  isDeleteDisabled: boolean
  isVisibilityAdjustable: boolean
  entityId: string
}>()
const emits = defineEmits<{
  (e: 'create', payload: CreateComment['data']): void
  (e: 'update', commentId: string): void
  (e: 'delete', commentId: string): void
  (e: 'reactionUpsert', commentId: string, type: CommentReactionType): void
  (e: 'reactionDelete', commentId: string): void
}>()

const { useremail, isRole } = useAuthorization()
const isAuthorOfComment = computed(() => useremail.value === props.comment.user.email)
const reactionsByType = computed(() => Object.groupBy(props.comment.reactions, reaction => reaction.type))
const userSelectedReaction = computed(() => props.comment.reactions.find(reaction => reaction.createdBy.email === useremail.value))
const isCustomerUser = computed(() => isRole('customer-user', false))
const commentTitle = computed(() => {
  if (props.comment.deletedAt) {
    return undefined
  }

  const title = `${props.comment.user.name} - ${useDateAsString(props.comment.createdAt)}`
  if (props.comment.user.role === 'customer-user' && !isCustomerUser.value) {
    return `[${$t('comment.customer.label')}] ${title}`
  }
  return title
})

const showReplyForm = ref(false)
const replyFormPayload = computed(() => {
  const commentType = props.comment.type
  const commentEntityId = commentConfigs[commentType].commentTableRelationFieldName
  const originalCommentMention = `<p><span data-type="mention" data-id="${props.comment.user.email}" data-label="${props.comment.user.name}"></span></p>`

  return {
    mode: 'create' as const,
    data: {
      // NOTE: Auto-mention of original/parent commenter is disabled when:
      // - current user is a customer user
      // - original/parent commenter is a customer user
      text: isCustomerUser.value || props.comment.user.role === 'customer-user' ? '' : originalCommentMention,
      type: commentType,
      visibility: isCustomerUser.value || commentType === CommentType.ServiceProjectReporting ? CommentVisibility.Public : CommentVisibility.Private,
      originalCommentId: props.comment.id,
      [commentEntityId]: props.entityId,
      files: [],
    },
  }
})

function handleReactionClick(type: CommentReactionType) {
  if (type === userSelectedReaction.value?.type) {
    emits('reactionDelete', props.comment.id)
  } else {
    emits('reactionUpsert', props.comment.id, type)
  }
}

function createComment(payload: UpdateOrCreateComment) {
  // We only send `mode === create` so it can't be `update`
  // But we add this check for type safety
  if (payload.mode === 'create') {
    emits('create', payload.data)
  }
  showReplyForm.value = false
}
</script>

<template>
  <n-card v-if="comment.deletedAt">
    {{ $t('comment.deleted.label') }}
    <n-divider v-if="comment.totalRepliesCount > 0" />
    <CommentReplyContainer
      :comment
      :entity-id
      :is-read-only
      :is-delete-disabled
      :is-visibility-adjustable
      @create="emits('create', $event)"
      @update="emits('update', $event)"
      @delete="emits('delete', $event)"
      @reaction-upsert="(id, type) => emits('reactionUpsert', id, type)"
      @reaction-delete="emits('reactionDelete', $event)"
    />
  </n-card>
  <n-card
    v-else
    header-class="!p-4"
    content-class="!p-4"
    size="small"
    :segmented="{
      content: true,
      footer: 'soft',
    }"
  >
    <template #header>
      <h3 class="text-base font-medium">
        {{ commentTitle }}
      </h3>
    </template>
    <template #header-extra>
      <div class="flex justify-end space-x-2">
        <n-button
          v-if="isAuthorOfComment && !isReadOnly"
          type="primary"
          secondary
          size="small"
          @click="emits('update', comment.id)"
        >
          <Icon name="material-symbols:edit-square-outline-rounded" />
        </n-button>
        <n-button
          v-if="(isAuthorOfComment || isRole(['admin', 'special-role-benni'])) && !isDeleteDisabled"
          type="error"
          secondary
          size="small"
          @click="emits('delete', comment.id)"
        >
          <Icon name="material-symbols:delete-outline-rounded" />
        </n-button>
      </div>
    </template>

    <div class="flex flex-col">
      <!-- HTML is sanitized before being rendered  -->
      <CommentTextRender v-if="comment.text" :text="comment.text" />
      <FileView
        v-if="comment.files.length"
        :files="comment.files"
        collapsed-by-default
      />

      <div class="flex mt-4 justify-between items-center">
        <div class="flex items-center gap-1">
          <CommentReactionPopover
            v-if="!isReadOnly"
            :user-selected-reaction
            @handle-reaction-click="handleReactionClick"
          />
          <CommentReactionList
            :is-read-only
            :reactions-by-type
            :user-selected-reaction
            @handle-reaction-click="handleReactionClick"
          />
        </div>
        <n-button
          v-if="comment.originalCommentId === null && !isReadOnly"
          secondary
          size="small"
          @click="showReplyForm = !showReplyForm"
        >
          <template #icon>
            <Icon name="material-symbols:reply-rounded" />
          </template>
          {{ showReplyForm ? $t('comment.reply.cancel.label') : $t('comment.reply.label') }}
        </n-button>
      </div>
      <Transition name="CommentReply" mode="out-in">
        <CommentCreateOrEditForm
          v-if="showReplyForm"
          :is-visibility-adjustable
          editor-height="10vh"
          :payload="replyFormPayload"
          @save="createComment"
          @close="showReplyForm = false"
        />
      </Transition>

      <n-divider v-if="comment.totalRepliesCount > 0" />
      <CommentReplyContainer
        :comment
        :entity-id
        :is-read-only
        :is-delete-disabled
        :is-visibility-adjustable
        @create="emits('create', $event)"
        @update="emits('update', $event)"
        @delete="emits('delete', $event)"
        @reaction-upsert="(id, type) => emits('reactionUpsert', id, type)"
        @reaction-delete="emits('reactionDelete', $event)"
      />
    </div>
  </n-card>
</template>

<style scoped>
:deep(.n-collapse .n-collapse-item .n-collapse-item) {
  @apply !ml-0;
}

/* Comment reply transition */
.CommentReply-enter-active, .CommentReply-leave-active {
  transition: max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
  overflow: hidden;
}
.CommentReply-enter-from, .CommentReply-leave-to {
  max-height: 0;
  opacity: 0;
}
.CommentReply-enter-to, .CommentReply-leave-from {
  max-height: 500px;
  opacity: 1;
}
</style>
