import { isBefore } from 'date-fns'
import currency from 'currency.js'
import type { OfferType } from '~/types'
import type { OfferPositionType } from '~/prisma/enums'

const DAILY_DISCOUNT_APPLIED_POSITION_TYPES: Readonly<OfferPositionType[]> = [
  'machineryAccessory',
  'machineryAccessoryCategory',
  'machinery',
  'itemSet',
  'machineryCategory',
]

export interface PositionToCalculatePrice {
  title: string
  type: OfferPositionType
  pricePerUnit: number | null
  discountRate: number | null
  quantity: number
  terminatedDate?: Date | null
}

export default function calculateTotalPositionPrice(position: PositionToCalculatePrice | null | undefined, rentalDays?: { discountRate: number, date: Date }[], offerType?: OfferType, shouldGetExtendedRentalDays = true, showSingleUnitPrice?: boolean) {
  if (!position || position.quantity === 0) {
    return currency(0)
  }

  const pricePerUnit = currency(position.pricePerUnit ?? 0)
  let quantity = position.quantity
  let rentalDaysForPosition = rentalDays

  if (showSingleUnitPrice) {
    quantity = 1
  } else if (rentalDaysForPosition && shouldGetExtendedRentalDays && !isOfferPositionQuantityUpdatable(position.type, offerType)) {
    const extendedRentalDays = getExtendedOfferRentalDays(rentalDaysForPosition, position.terminatedDate)
    rentalDaysForPosition = rentalDaysForPosition
      .concat(extendedRentalDays)
      .filter(({ date }) => position.terminatedDate && (isBefore(date, position.terminatedDate) || getAbsoluteDateDifferenceInHours(date, position.terminatedDate) <= 12))

    quantity = rentalDaysForPosition.length
  }

  const discountRate = currency(1).subtract(position.discountRate ?? 0)
  const discountedPricePerUnit = pricePerUnit.multiply(discountRate)

  let totalDiscountPriceForRentalDays
  if (rentalDaysForPosition && DAILY_DISCOUNT_APPLIED_POSITION_TYPES.includes(position.type) && !showSingleUnitPrice) {
    totalDiscountPriceForRentalDays = rentalDaysForPosition.reduce((total, { discountRate }) =>
      total.add(discountedPricePerUnit.multiply(discountRate ?? 0)), currency(0))
  } else if (rentalDaysForPosition && position.type === 'insurance' && !showSingleUnitPrice) {
    totalDiscountPriceForRentalDays = rentalDaysForPosition.reduce((total, { discountRate }) =>
      total.add(discountedPricePerUnit.multiply(discountRate === 1 ? 1 : 0)), currency(0))
  }

  const totalPositionPrice = discountedPricePerUnit.multiply(quantity).subtract(totalDiscountPriceForRentalDays ?? 0)

  // Custom positions can be negative
  const isCustomPosition = position.type === 'invoice' || position.type === 'creditNote'

  if (!isCustomPosition && totalPositionPrice.value < 0) {
    pdfLogger.error({
      'msg': 'The total price cannot be a negative amount',
      'position.title': position.title,
      'position.type': position.type,
      'position.pricePerUnit': position.pricePerUnit,
      'position.discountRate': position.discountRate,
      'position.quantity': position.quantity,
      'totalPositionPrice.value': totalPositionPrice.value,
    })
    throw new Error('The total price cannot be a negative amount.')
  }

  return totalPositionPrice
}
