<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'

defineProps<{ payload: ApiMachineryGetById }>()
const emit = defineEmits<{ (e: 'save', data: ApiMachineryGetById): void }>()

const { useremail } = useAuthorization()

function submitUVVForm(data: ApiMachineryGetById) {
  data.lastUVVDateUpdatedAt = new Date()
  data.lastUVVDateUpdatedByEmail = useremail.value

  emit('save', data)
}
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="submitUVVForm"
  >
    <FormKitDate
      name="lastUVVDate"
      label="Letztes UVV-Datum"
    />
    <FormKitFileDropzone name="documentUVVFiles" label="Dokumente" />
  </FormKit>
</template>
