<script setup lang="ts">
const props = defineProps<{ machineryAccessoryId?: string, fromItemSet: boolean, isExpanded?: boolean }>()

const selectedDuration = props.fromItemSet ? useRouteQueryAsNumberRange('machineryAccessoryForItemSetInvoicingDateRange') : useRouteQueryAsNumberRange('machineryAccessoryInvoicingDateRange')

const chartContainerRef = ref<HTMLElement | null>(null)
const { isIntersecting } = useIsIntersecting(chartContainerRef)

const { reporting } = useQuery()
const { data: machineryAccessoryRevenueChart, isFetching, suspense, refetch, isPending } = reporting.getChartRevenueMachineryAccessory(selectedDuration, props.fromItemSet, props.machineryAccessoryId, isIntersecting)

const loading = computed(() => isFetching.value || isPending.value)

onServerPrefetch(async () => {
  if (isIntersecting.value) {
    await suspense()
  }
})
</script>

<template>
  <ReportingCard name="machineryAccessoryRevenue" header="Lagertool Umsatz">
    <template v-if="isExpanded" #header-extra>
      <n-button :disabled=" loading" @click="() => refetch()">
        <template #icon>
          <Icon name="material-symbols:refresh" />
        </template>
      </n-button>
    </template>

    <template #controls>
      <TheDurationPicker v-model="selectedDuration" class="mb-2" />
      <p class="mb-2">
        Der hier dargestellte Umsatz wird aus offenen und bezahlten Rechnungen berechnet, nachträglich erstellte Rechnungskorrekturen werden nicht berücksichtigt. Zur Berechnung werden ausschließlich die Lagertool-Positionen berücksichtigt. Die Berechnung basiert auf den jeweils von dem Lagertool abgerechneten Tagen.
      </p>
      <p class="mb-2 font-semibold">
        Gesamt-Lagertool-Umsatz im Zeitraum: {{ useDisplayNumberAsCurrency(machineryAccessoryRevenueChart?.additionalData?.totalRevenue ?? 0) }}
      </p>
      <p>Umsatz pro Tag:</p>
    </template>

    <div ref="chartContainerRef" class="min-h-[400px]">
      <n-skeleton v-if="loading" class="rounded" width="100%" height="400px" :sharp="false" />
      <ReportingChart v-else-if="machineryAccessoryRevenueChart" v-bind="machineryAccessoryRevenueChart" />
    </div>
  </ReportingCard>
</template>
