import countriesInGerman from 'i18n-iso-countries/langs/de.json'
import countriesInEnglish from 'i18n-iso-countries/langs/en.json'

export default () => {
  const { currentLocaleCode } = useI18nLocale()
  return computed(() => {
    const languageBasedCountries = currentLocaleCode.value === 'en' ? countriesInEnglish.countries : countriesInGerman.countries
    return Object.entries(languageBasedCountries).map((country) => {
      if (typeof country[1] === 'string') {
        return {
          label: country[1],
          value: country[0],
        }
      } else {
      // if there are multiple names for a country, we use the first one
        return {
          label: country[1][0],
          value: country[0],
        }
      }
    })
  })
}
