<script setup lang="ts">
import currency from 'currency.js'
import { useRouteQuery } from '@vueuse/router'
import { defu } from 'defu'
import type { ApiInvoiceGetAllForOverview, InvoiceStatus, InvoiceView } from '~/types'
import { invoiceStatusCategorySelectOptions, invoiceTypeCategorySelectOptions, offerTypeCategorySelectOptions } from '~/translations'

import { calculateTotals as calculateInvoiceTotals } from '~/server/pdf-generation/sections/invoice/total'

const props = withDefaults(
  defineProps<{
    title?: string
    additionalFilters?: Record<string, unknown>
    showColorCoding?: boolean
    showSumOfInvoices?: boolean
    showInvoiceFilters?: boolean
    view?: InvoiceView
  }>(),
  {
    title: 'Rechnungs-Übersicht',
    additionalFilters: () => ({}),
    showInvoiceFilters: true,
    view: undefined,
  },
)

const { urlWithLocale } = useUrlWithLocale()

const viewToStatus: Partial<Record<InvoiceView, InvoiceStatus[] | undefined>> = {
  payment: ['created', 'pending', 'partiallyPaid'],
  archive: ['paid', 'cancelled', 'notInvoiceable'],
}

const { invoice: { columns, fulltextSearch, rowProps, rowClassName } } = useTableColumnConfigs()
const { fulltextSearchValue, where } = useFilterData(fulltextSearch)

const { invoice } = useQuery()

// filters
const filteredInvoiceStatusCategorySelectOptions = computed(() => {
  if (!props.view) {
    return invoiceStatusCategorySelectOptions
  }

  return invoiceStatusCategorySelectOptions.filter(option => viewToStatus[props.view ?? 'preparation']?.includes(option.value as InvoiceStatus))
})

const selectedInvoiceStatusCategories = useRouteQuery<string[] | null>('invoiceStatus', null)
const selectedInvoiceTypeCategories = useRouteQuery<string[] | null>('invoiceType', null)
const selectedOfferTypes = useRouteQuery<string[] | null>('offerType', null)

const selectedCreatedAt = useRouteQueryAsNumberRange('createdAt')
const selectedSentAt = useRouteQueryAsNumberRange('pendingAt')
const selectedPaidAt = useRouteQueryAsNumberRange('paidAt')
const selectedCancelledAt = useRouteQueryAsNumberRange('cancelledAt')

function resetFilters() {
  selectedInvoiceStatusCategories.value = null
  selectedInvoiceTypeCategories.value = null
  selectedOfferTypes.value = null
  selectedCreatedAt.value = null
  selectedSentAt.value = null
  selectedPaidAt.value = null
  selectedCancelledAt.value = null
}

const invoiceWhere = computed(() => defu({
  ...where.value,
  status: { in: selectedInvoiceStatusCategories.value ? [selectedInvoiceStatusCategories.value] : (props.view ? viewToStatus[props.view] : undefined) },
  type: selectedInvoiceTypeCategories.value?.length ? selectedInvoiceTypeCategories.value : { not: { in: ['cancellation', 'creditNoteCancellation'] } },
  offer: {
    type: selectedOfferTypes.value?.length ? selectedOfferTypes.value : undefined,
  },
  createdAt: selectedCreatedAt.value ? createPrismaDateRangeFilterSerialized(selectedCreatedAt.value) : undefined,
  paidAt: selectedPaidAt.value ? createPrismaDateRangeFilterSerialized(selectedPaidAt.value) : undefined,
  pendingAt: (props.view === 'payment' && selectedSentAt.value) ? createPrismaDateRangeFilterSerialized(selectedSentAt.value) : undefined,
  cancelledAt: (props.view === 'archive' && selectedCancelledAt.value) ? createPrismaDateRangeFilterSerialized(selectedCancelledAt.value) : undefined,
}, props.additionalFilters))

const { openInvoiceCommentPopup, openInvoiceConfirmPaymentPopup, openInvoicingPopup, openCreateCreditNotePopup, openInvoiceEmailRecipientPopup } = useGlobalOpeners()
const { serverSidePaginationTableProps, serverSidePagination, watchForPageCount, watchForReset } = useTablePagination({ pageCount: 1 })
const { data: invoicesData, error: errorInvoices, isFetching: isFetchingInvoices, isLoading: isLoadingInvoices } = invoice.allForOverview(invoiceWhere, serverSidePagination)

const invoices = computed(() => invoicesData.value?.invoices)

watchForPageCount(computed(() => invoicesData.value?.totalCount ?? 0))
watchForReset(invoiceWhere)

const INVOICE_ACTION_BUTTONS: Readonly<Record<InvoiceStatus, Action[]>> = {
  created: ['cancel', 'invoiced', 'mark-as-sent', 'mark-as-paid', 'comment'],
  pending: ['cancel', 'invoiced', 'mark-as-paid', 'comment'],
  partiallyPaid: ['cancel', 'invoiced', 'mark-as-paid', 'comment'],
  paid: ['cancel', 'comment'],
  cancelled: ['cancelledInvoicePdf', 'comment'],
  notInvoiceable: ['comment', 'delete'],
}

function buttonsToShow(invoice: Exclude<typeof invoices.value, undefined>[number]): Action[] {
  const actions = INVOICE_ACTION_BUTTONS[invoice.status as InvoiceStatus].concat() ?? []

  const invoiceWithoutCreditNote: InvoiceStatus[] = ['cancelled', 'notInvoiceable']
  if (invoice.type !== 'creditNote' && !invoiceWithoutCreditNote.includes(invoice.status as InvoiceStatus)) {
    actions.push('creditNote')
  }

  return actions
}

function frontButtonsToShow(invoice: Exclude<typeof invoices.value, undefined>[number]): Action[] {
  const actions: Action[] = ['pdf']
  if (invoice.offer.customer.invoiceEmail && invoice.status !== 'cancelled') {
    return [...actions, 'mail']
  }
  return actions
}

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const updateStatus = useMutation({
  mutationFn: $trpc.invoice.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Rechnungs-Status konnte nicht erfolgreich aktualisiert werden' }),
  onSuccess: async (invoice) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['offer', invoice.offerId] }),
      queryClient.invalidateQueries({ queryKey: ['offer', 'invoice', 'unique', invoice.offerId] }),
      queryClient.invalidateQueries({ queryKey: ['invoice', 'offerPosition'] }),
    ])

    notification.success({ title: 'Der Rechnungs-Status wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const deleteOne = useMutation({
  mutationFn: $trpc.invoice.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Rechnung konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['invoice'] })

    popupConfirmDeleteInvoice.value = null
    notification.success({ title: 'Der Rechnung wurde gelöscht', duration: 4500 })
  },
})

const sumsOfInvoices = computed(() => {
  if (!invoices.value || !props.showSumOfInvoices) {
    const zero = formatNumberToString(0)
    return {
      brutto: zero,
      netto: zero,
    }
  }

  let brutto = currency(0)
  let netto = currency(0)

  for (const invoice of invoices.value) {
    // Filter
    if (['cancelled', 'notInvoiceable'].includes(invoice.status)) {
      continue
    }

    const invoiceTotals = calculateInvoiceTotals(invoice)
    brutto = brutto.add(invoiceTotals.brutto)
    netto = netto.add(invoiceTotals.netto)
  }

  return {
    brutto: formatNumberToString(brutto.value),
    netto: formatNumberToString(netto.value),
  }
})

function openInvoicePDF(invoice: ApiInvoiceGetAllForOverview) {
  const customerLocale = getLocale(invoice.offer.customer.language)
  window.open(urlWithLocale(`/api/pdf/invoice/details/${invoice.cuid}`, customerLocale), '_blank')
}

function openCancelledInvoicePDF(invoice: ApiInvoiceGetAllForOverview) {
  if (!invoice.cancellationInvoice?.id) {
    defaultLogger.error({
      'msg': 'No cancellation invoice found',
      'invoice.id': invoice.id,
    })
    return
  }
  const customerLocale = getLocale(invoice.offer.customer.language)
  window.open(urlWithLocale(`/api/pdf/invoice/details/${invoice.cancellationInvoice?.cuid}`, customerLocale), '_blank')
}

const { payload: cancelInvoicePayload, open: openPopupConfirm, close: closePopupConfirm } = usePopup<{ invoiceIdToCancel: string }>()

function cancelInvoice() {
  updateStatus.mutate({ id: cancelInvoicePayload.value!.invoiceIdToCancel, status: 'cancelled' })
  closePopupConfirm()
}

const popupConfirmDeleteInvoice = ref<null | ApiInvoiceGetAllForOverview>(null)
</script>

<template>
  <div class="space-y-4">
    <TheConfirmPopup v-if="popupConfirmDeleteInvoice" @confirm="deleteOne.mutate(popupConfirmDeleteInvoice)" @close="popupConfirmDeleteInvoice = null">
      Der Rechnung wird gelöscht.
    </TheConfirmPopup>
    <ThePopup v-if="cancelInvoicePayload" title="Wollen Sie die Rechnung wirklich stornieren?" :show="Boolean(cancelInvoicePayload)" @close="closePopupConfirm()">
      <div class="flex gap-3">
        <NButton type="primary" @click="cancelInvoice()">
          Ja, stornieren
        </NButton>
        <NButton @click="closePopupConfirm()">
          Abbrechen
        </NButton>
      </div>
    </ThePopup>
    <TheDataCard :title="title" :error="errorInvoices" :is-loading="isLoadingInvoices">
      <TableFilters v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
        <template v-if="showSumOfInvoices">
          <div class="w-full mt-2 px-2 text-gray-500 italic text-right">
            Gesamtbetrag der Rechnungen (Brutto): {{ sumsOfInvoices.brutto }} EUR
          </div>
          <div class="w-full mt-2 px-2 text-gray-500 italic text-right">
            Gesamtbetrag der Rechnungen (Netto): {{ sumsOfInvoices.netto }} EUR
          </div>
        </template>

        <template #search>
          <n-collapse v-if="showInvoiceFilters">
            <n-collapse-item>
              <template #header>
                <span class="text-lg">
                  Rechnungs-spezifische Filter ausklappen
                </span>
              </template>
              <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                  <div>
                    <p class="font-semibold">
                      Erstellt am
                    </p>
                    <n-date-picker
                      v-model:value="selectedCreatedAt"
                      type="daterange"
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="props.view === 'payment'">
                    <p class="font-semibold">
                      Versendet
                    </p>
                    <n-date-picker
                      v-model:value="selectedSentAt"
                      type="daterange"
                      filterable
                      clearable
                    />
                  </div>
                  <div>
                    <p class="font-semibold">
                      Bezahlt am
                    </p>
                    <n-date-picker
                      v-model:value="selectedPaidAt"
                      type="daterange"
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="props.view === 'archive'">
                    <p class="font-semibold">
                      Storniert am
                    </p>
                    <n-date-picker
                      v-model:value="selectedCancelledAt"
                      type="daterange"
                      filterable
                      clearable
                    />
                  </div>
                  <div>
                    <p class="font-semibold">
                      Status
                    </p>
                    <n-select
                      v-model:value="selectedInvoiceStatusCategories"
                      :options="filteredInvoiceStatusCategorySelectOptions"
                      filterable
                      clearable
                    />
                  </div>
                  <div>
                    <p class="font-semibold">
                      Rechnungstyp
                    </p>
                    <n-select
                      v-model:value="selectedInvoiceTypeCategories"
                      :options="invoiceTypeCategorySelectOptions"
                      filterable
                      clearable
                    />
                  </div>
                  <div>
                    <p class="font-semibold">
                      Angebotstyp
                    </p>
                    <n-select
                      v-model:value="selectedOfferTypes"
                      :options="offerTypeCategorySelectOptions"
                      filterable
                      clearable
                    />
                  </div>
                  <div class="flex items-end">
                    <n-button class="!bg-white w-full md:w-auto" @click="resetFilters">
                      Alle zurücksetzen
                    </n-button>
                  </div>
                </div>
              </div>
            </n-collapse-item>
          </n-collapse>
        </template>
        <TableView
          v-bind="{ rowClassName, ...serverSidePaginationTableProps, showColorCoding }"
          :data="invoices"
          :columns="columns"
          :is-loading="isFetchingInvoices"
          :action-buttons="buttonsToShow"
          :striped="!showColorCoding"
          :row-props="rowProps"
          :front-action-buttons="frontButtonsToShow"
          @invoiced="(row) => openInvoicingPopup.open({ offerId: row.offer.id })"
          @comment="(row) => openInvoiceCommentPopup.open({ id: row.id, invoiceId: row.invoiceId })"
          @mark-as-paid="(row) => openInvoiceConfirmPaymentPopup.open({ id: row.id })"
          @mark-as-sent="(row) => updateStatus.mutate({ id: row.id, status: 'pending' })"
          @cancel="(row) => openPopupConfirm({ invoiceIdToCancel: row.id })"
          @credit-note="(row: ApiInvoiceGetAllForOverview) => openCreateCreditNotePopup.open({ id: row.id })"
          @pdf="openInvoicePDF"
          @mail="(row) => openInvoiceEmailRecipientPopup.open({ id: row.id })"
          @cancelled-invoice-pdf="openCancelledInvoicePDF"
          @delete="invoice => popupConfirmDeleteInvoice = invoice"
        />
      </TableFilters>
    </TheDataCard>
  </div>
</template>

<style scoped>
  :deep(.PaidInvoice td) {
  @apply bg-green-100 !important;
  }
</style>
