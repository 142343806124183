<script setup lang="ts">
import { startOfDay, sub } from 'date-fns'
import type { ApiOfferGetById, OfferType, TerminateRentalPositions } from '~/types'

const props = defineProps<{
  payload: TerminateRentalPositions
  positionsOptions?: ApiOfferGetById['positions']
  offerType: OfferType
  isPending: boolean
}>()
const emit = defineEmits<{ (e: 'save', payload: TerminateRentalPositions): void }>()

const { cloned } = useMightyCloned(props.payload)

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit
    v-model="cloned"
    type="form"
    :plugins="[formkitPlugins]"
    :actions="false"
    @submit="emit('save', cloned)"
  >
    <div class="flex gap-2 flex-wrap">
      <CustomerModuleInputsPositionSelector
        id="positionIds"
        :title="$t('customerModule.offer.terminate.positionIds.label')"
        :options="positionsOptions"
        validation="required|min:1"
      />
      <div class="WidthHalfMinusGap">
        <FormKitDate
          id="terminatedDate"
          :transform-date="startOfDay"
          label="Datum der Abmeldung angeben"
          :validation="`required|date_after:${useDateAsString(sub(cloned.obligationStartsAt, { days: 1 }), 'yyyy-MM-dd')}`"
          :validation-messages="{ date_after: `Die Abmeldung kann nicht vor dem Startdatum(${useDateAsString(cloned.obligationStartsAt, 'dd.MM.yy')}) erfolgen.` }"
        />
      </div>
      <div class="flex gap-2">
        <FormKit
          id="claimingPartnerName"
          type="text"
          label="Name"
          validation="required"
        />
        <FormKit
          id="claimingPartnerEmail"
          type="email"
          label="Email"
          validation="email"
        />
        <FormKit
          id="claimingPartnerTelephone"
          type="tel"
          label="Telefon"
          validation="required"
        />
      </div>
      <div class="WidthHalfMinusGap">
        <FormKit
          id="deliveryLocation"
          type="text"
          label="Standort der Geräte"
          :validation="cloned.doesCustomerDoTask ? undefined : 'required'"
          :disabled="!props.payload.doesCustomerDoTask || cloned.doesCustomerDoTask"
        />
      </div>
      <div class="WidthHalfMinusGap">
        <FormKit
          id="differentPickupLocation"
          type="text"
          label="Abweichende Abholadresse"
          :disabled="cloned.doesCustomerDoTask"
        />
      </div>
      <div class="WidthHalfMinusGap">
        <FormKitDate
          id="pickupAt"
          :label="$t('offer.terminate.pickupAt.label')"
          :validation="cloned.doesFitterDoTask ? 'required' : ''"
        />
      </div>
      <div class="WidthHalfMinusGap flex items-center">
        <FormKit
          v-if="offerType === 'service-project'"
          id="doesFitterDoTask"
          outer-class="!mb-0 ml-2"
          wrapper-class="!mb-0"
          type="checkbox"
          label="Durchgeführt von Monteur"
        />
      </div>
      <div class="w-full">
        <CommentList :id="payload.offerId" type="Termination" class="mb-5 w-full" hide-list-title />
      </div>
      <FormKit
        v-if="offerType !== 'service-project'"
        id="doesCustomerDoTask"
        type="checkbox"
        label="Kunde bringt die Maschine und Anbaugeräte selbst zurück"
      />
    </div>
    <n-button
      type="primary"
      prefix-icon="check"
      size="large"
      :loading="isPending"
      :disabled="isPending"
      attr-type="submit"
    >
      Den Auftrag abmelden
    </n-button>
  </FormKit>
</template>

<style scoped>
.Heading {
  @apply text-lg font-bold py-4
}
.WidthHalfMinusGap {
  @apply w-full lg:w-[calc(50%-0.5rem)]
}
</style>
