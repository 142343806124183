<script setup lang="ts">
defineProps<{ label: string }>()
const minValue = defineModel<number | undefined | null>('min', { required: true })
const maxValue = defineModel<number | undefined | null>('max', { required: true })
</script>

<template>
  <div>
    <p class="font-semibold">
      {{ label }}
    </p>
    <div class="flex items-center">
      <n-input-number
        v-model:value="minValue"
        :min="0"
        placeholder="2000"
      >
        <template #prefix>
          {{ $t('general.min') }}
        </template>
      </n-input-number>
      <n-input-number
        v-model:value="maxValue"
        :min="0"
        placeholder="4000"
      >
        <template #prefix>
          {{ $t('general.max') }}
        </template>
      </n-input-number>
    </div>
  </div>
</template>
