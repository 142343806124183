<script setup lang="ts">
import type { UpdateOrCreateStorageLoadCarrier } from '~/types'

const props = defineProps<{
  payload: UpdateOrCreateStorageLoadCarrier
  customers: { id: string, name: string }[]
  selectedCustomerId?: string
}>()

const emit = defineEmits<{ (e: 'submit', payload: UpdateOrCreateStorageLoadCarrier): void }>()

const customerOptions = computed(() => props.customers.map(customer => ({
  label: customer.name,
  value: customer.id,
})))

const value = ref(props.payload.data)
value.value.customerId = props.selectedCustomerId ?? ''
</script>

<template>
  <FormKit
    :value="value"
    type="form"
    :submit-label="`${payload.mode === 'create' ? 'Neuen Ladungsträger anlegen' : 'Ladungsträger speichern'}`"
    @submit="(value: typeof payload.data) => emit('submit', { mode: payload.mode, data: value })"
  >
    <TheDevOnlyNiceJson :value="value" />
    <div class="grid grid-cols-2 gap-x-2">
      <FormKitMultiSelect
        name="customerId"
        :is-multi-select-allowed="false"
        :multi-select-options="customerOptions"
        label="Kunde"
        validation="required"
        outer-class="max-w-md"
        :disabled="!!selectedCustomerId"
      />
      <div />
      <FormKit
        name="name"
        label="Bezeichnung"
        type="text"
        validation="required"
      />
      <FormKit
        name="lengthInMm"
        label="Länge (mm)"
        type="number"
        min="0"
        step="1"
        placeholder="320"
        number="integer"
        validation="required"
      />
      <FormKit
        name="widthInMm"
        label="Breite (mm)"
        type="number"
        min="0"
        step="1"
        placeholder="540"
        number="integer"
        validation="required"
      />
      <FormKit
        name="heightInMm"
        label="Höhe (mm)"
        type="number"
        min="0"
        step="1"
        placeholder="540"
        number="integer"
        validation="required"
      />
      <FormKit
        name="loadingMeter"
        label="Lademeter"
        type="number"
        min="0"
        step="0.01"
        placeholder="0.8"
        number="float"
        validation="required"
      />
      <FormKit
        name="maximumLiftingWeight"
        label="Max. Traglast (kg)"
        type="number"
        min="0"
        step="1"
        placeholder="1000"
        number="integer"
        validation="required"
      />
      <FormKit
        name="weight"
        label="Gewicht (kg)"
        type="number"
        min="0"
        step="0.001"
        placeholder="800"
        number="float"
        validation="required"
      />
    </div>
    <FormKitFileDropzone
      name="photos"
      label="Fotos"
    />
    <FormKitFileDropzone
      name="documents"
      label="Dokumente"
    />
  </FormKit>
</template>
