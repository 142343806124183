import { offerPositionsToShipSchema } from '../server/schemas'
import type { OfferPositionType } from '~/prisma/enums'

export default (position: { type: OfferPositionType, isAttachedAccessoryHidden?: boolean }) => {
  if (position.type === 'machineryAccessory') {
    return !position.isAttachedAccessoryHidden
  }

  return offerPositionsToShipSchema.safeParse(position.type).success
}
