<script lang="ts" setup>
import type { ApiCommentGetAllReaction } from '~/types'
import type { CommentReactionType } from '~/prisma/enums'

defineProps<{
  userSelectedReaction?: ApiCommentGetAllReaction
}>()
const emit = defineEmits<{
  (e: 'handleReactionClick', payload: CommentReactionType): void
}>()

const show = ref(false)

function updateReaction(type: CommentReactionType) {
  emit('handleReactionClick', type)
  show.value = false
}
</script>

<template>
  <n-popover v-model:show="show" trigger="click">
    <template #trigger>
      <n-button circle quaternary>
        <template #icon>
          <Icon name="ant-design:smile-outlined" />
        </template>
      </n-button>
    </template>
    <div>
      <n-tag
        v-for="[type, icon] in Object.entries(commentReactionConfig)"
        :key="type"
        :checked="userSelectedReaction?.type === type"
        checkable
        @update:checked="updateReaction(type as CommentReactionType)"
      >
        <span>{{ icon }}</span>
      </n-tag>
    </div>
  </n-popover>
</template>
