<script setup lang="ts">
const { t: $t } = useI18n()

import type { DataTableColumns, DataTableRowKey } from 'naive-ui'
import type { ApiMachineryAccessoryGetAllForOverview } from '~/types'

const props = defineProps<{
  machineryAccessoryId: string
  currentCompatibleAccessoryIds: string[]
}>()
const emit = defineEmits<{ (e: 'close'): void }>()

const { machineryAccessory, machineryAccessoryBundle } = useQuery()

const { data: bundle, isFetching: isFetchingBundle } = machineryAccessoryBundle.byMachineryAccessoryId(props.machineryAccessoryId)
const bundledAccessoryIds = computed(() => bundle.value?.machineryAccessories.map(machineryAccessory => machineryAccessory.id).filter(id => id !== props.machineryAccessoryId) ?? [])

const { machineryAccessory: { columns: machineryAccessoryColumns, fulltextSearch: machineryAccessoryFulltextSearch } } = useTableColumnConfigs()

const columns: DataTableColumns<ApiMachineryAccessoryGetAllForOverview> = [
  {
    type: 'selection',
    disabled: ({ id }) => bundledAccessoryIds.value?.includes(id),
  },
  ...machineryAccessoryColumns,
]

const { fulltextSearchValue, where: fulltextSearchWhere } = useFilterData(machineryAccessoryFulltextSearch)

const accessoryWhere = computed(() => ({
  ...fulltextSearchWhere.value,
}))

const { data: allAccessoriesData, error, isFetching: isFetchingAccessories } = machineryAccessory.allForOverview(accessoryWhere)

const checkedRowKeys = ref<DataTableRowKey[]>(props.currentCompatibleAccessoryIds as DataTableRowKey[])

const showOnlySelectedItems = ref(false)
const filteredAccessoriesData = computed(() => (
  showOnlySelectedItems.value
    ? allAccessoriesData.value?.accessories.filter(accessory => checkedRowKeys.value.includes(accessory.id))
    : allAccessoriesData.value?.accessories
))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const updateCompatibility = useMutation({
  mutationFn: $trpc.machineryAccessory.compatibility.updateCompatibility.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('machineryAccessory.notification.updateCompatibility.error') }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['accessoryIds', 'compatibility'] })
    emit('close')
    notification.success({ title: $t('machineryAccessory.notification.updateCompatibility.success'), duration: 4500 })
  },
})
</script>

<template>
  <ThePopup show width="1000px" @close="emit('close')">
    <n-alert v-if="!isFetchingBundle && bundledAccessoryIds.length > 0" type="info" class="mb-2">
      <p>Aktuelle Lagertool(s) in Bündel:</p>
      <p v-for="accessoryId in bundledAccessoryIds" :key="accessoryId">
        - {{ accessoryId }}
      </p>
      <p>Diese Lagertool(s) sind nicht zu entfernen. Bitte passen Sie das entsprechende Bündel zuerst an.</p>
    </n-alert>

    <TheDataCard title="Kompatibilität definieren" :error="error">
      <label class="flex justify-end gap-2 mb-2">
        <span class="font-semibold">
          Nur kompatible Lagertools anzeigen
        </span>
        <n-switch v-model:value="showOnlySelectedItems" />
      </label>
      <TableFilters v-model="fulltextSearchValue" :placeholder="machineryAccessoryFulltextSearch.placeholder">
        <TableView
          :data="filteredAccessoriesData"
          :columns="columns"
          :is-loading="isFetchingAccessories"
          :row-key="row => row.id"
          :checked-row-keys="checkedRowKeys"
          @update:checked-row-keys="keys => checkedRowKeys = keys"
        />
      </TableFilters>
      <n-button
        type="primary"
        @click="() => updateCompatibility.mutate({
          id: props.machineryAccessoryId,
          compatibleAccessoryIds: checkedRowKeys as string[],
        })"
      >
        {{ $t('button.save') }}
      </n-button>
    </TheDataCard>
  </ThePopup>
</template>
