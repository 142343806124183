// This file is for sharing schemas with `machinery` and `machineryAccessory` schemas
import { z } from 'zod'
import { idSchema } from './common'
import { zObject } from './zObject'

export const machineryFemsOptions = ['FEM 1', 'FEM 2', 'FEM 3', 'FEM 4', 'Terminal West', 'Welle', 'Rollengeführt'] as const
export const machineryFemsWithFiveOptions = [...machineryFemsOptions, 'FEM 5'].sort() as [string, ...string[]]
export const machineryFemsWithFiveAndNoneOptions = [...machineryFemsWithFiveOptions, 'Keine'].sort() as [string, ...string[]]

export const machineryFems = z.enum(machineryFemsOptions)
export const machineryFemsAndNone = z.enum([...machineryFemsOptions, 'Keine'])
export const machineryFemsAndFive = z.enum(machineryFemsWithFiveOptions)
export const machineryFemsWithFiveAndNone = z.enum(machineryFemsWithFiveAndNoneOptions)

export const machineryFemsWithFiveAndNoneAndAdditional = z.enum([...machineryFemsWithFiveAndNoneOptions, 'Manitou', 'Magni', 'Merlo', 'Sonstiges'])

export const machineryColors = ['Weiß', 'Schwarz', 'Grau', 'Beige'] as const
export const machineryTireColors = z.enum(machineryColors)
export const nonMarkingTireColors: z.infer<typeof machineryTireColors>[] = ['Weiß', 'Grau', 'Beige']

export const specialPermissionsSchema = zObject({
  id: idSchema,
  title: z.string(),
  date: z.coerce.date(),
}).array()
