import { OfferPositionType } from '~/prisma/enums'
import type { OfferType } from '~/types'

const EXCLUDED_SERVICE_PROJECT_TYPES: OfferPositionType[] = [
  OfferPositionType.insurance,
  OfferPositionType.itemSet,
  OfferPositionType.itemSetAccessory,
]

const EXCLUDED_OFFER_TYPES: OfferPositionType[] = [
  OfferPositionType.machinery,
  OfferPositionType.machineryAccessory,
  OfferPositionType.machineryCategory,
  OfferPositionType.machineryAccessoryCategory,
  OfferPositionType.insurance,
  OfferPositionType.itemSet,
  OfferPositionType.itemSetAccessory,
]

export default (positionType: OfferPositionType, offerType: OfferType | undefined) => {
  if (offerType === 'service-project') {
    return !EXCLUDED_SERVICE_PROJECT_TYPES.includes(positionType)
  }

  return !EXCLUDED_OFFER_TYPES.includes(positionType)
}
