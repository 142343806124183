<script lang="ts" setup>
const { t: $t } = useI18n()

import type { CreateCustomerData, PaymentConditions, TaxRates, UpdateCustomer, UpdateOrCreateCustomer } from '~/types'
import { FileFolderType } from '~/prisma/enums'

export interface CreatedCustomer {
  customerId: string
}

const { isRole, canAccessPage } = useAuthorization()
const { openCustomerPopup: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

const routerNameToSaveCreatedId = computed(() => popupData.value?.mode === 'create' ? popupData.value.routerNameToSaveCreatedId : undefined)
// TODO: Can we import this from global openers?
const routerToSaveCreatedId = routerNameToSaveCreatedId.value
  ? useRouteQueryAsObject<CreatedCustomer>(routerNameToSaveCreatedId.value)
  : undefined
function closePopup(createdId?: string) {
  if (createdId && routerToSaveCreatedId) {
    routerToSaveCreatedId.value = {
      ...routerToSaveCreatedId.value,
      customerId: createdId,
    }
  }
  closePopup_()
}

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const id = computed(() => {
  if (!popupData.value) {
    return
  }

  if (popupData.value.mode === 'update') {
    return popupData.value.id
  }
})

const inviteErrors = ref<Record<string, string>>({})
const isSaving = computed(() => update.isPending.value || create.isPending.value)

// Filters
const machinerySoldAtRange: Ref<[number, number] | null> = ref(null)
const machineryAccessorySoldAtRange: Ref<[number, number] | null> = ref(null)

function resetFilters() {
  machinerySoldAtRange.value = null
  machineryAccessorySoldAtRange.value = null
}

const {
  machineryColumns: getMachineryTableColumns,
  machineryWithoutColumns: {
    fulltextSearch: foreignMachineryFulltextSearch,
    rowProps: foreignMachineryRowProps,
  },
  soldMachinery: {
    fulltextSearch: soldMachineryFulltextSearch,
    rowProps: soldMachineryRowProps,
    columns: soldMachineryTableColumns,
  },
  soldMachineryAccessory: {
    fulltextSearch: machineryAccessoryFulltextSearch,
    rowProps: machineryAccessoryRowProps,
    columns: machineryAccessoryTableColumns,
  },
} = useTableColumnConfigs()
const foreignMachineryTableColumns = getMachineryTableColumns()

const { fulltextSearchValue: soldMachineryFulltextSearchValue, where: soldMachineryFulltextWhere } = useFilterData(soldMachineryFulltextSearch)
const { fulltextSearchValue: machineryAccessoryFulltextSearchValue, where: machineryAccessoryFulltextWhere } = useFilterData(machineryAccessoryFulltextSearch)
const { fulltextSearchValue: foreignMachineryFulltextSearchValue, where: foreignMachineryFulltextWhere } = useFilterData(foreignMachineryFulltextSearch)

const soldMachineryWhere = computed(() => ({
  ...soldMachineryFulltextWhere.value,
  soldAt: machinerySoldAtRange.value ? createPrismaDateRangeFilterSerialized(machinerySoldAtRange.value, { transformStartToStartOfDay: false }) : undefined,
}))

const machineryAccessoryWhere = computed(() => ({
  ...machineryAccessoryFulltextWhere.value,
  soldAt: machineryAccessorySoldAtRange.value ? createPrismaDateRangeFilterSerialized(machineryAccessorySoldAtRange.value, { transformStartToStartOfDay: false }) : undefined,
}))

const foreignMachineryWhere = computed(() => ({
  ...foreignMachineryFulltextWhere.value,
  isForeignMachinery: true,
  foreignMachineryCustomerId: id.value,
}))

const foreignMachineryEnabled = computed(() => !!id.value && canAccessPage('/overview/foreign-machinery'))

const { customer: customerQueries, machinery: machineryQueries, machineryAccessory: machineryAccessoryQueries } = useQuery()
const { data: customer, isFetching: isFetchingCustomer } = customerQueries.byId(id)
const { data: soldMachinery, isLoading: isLoadingSoldMachinery } = machineryQueries.soldMachineryByCustomerId(id, soldMachineryWhere)
const { data: foreignMachineryData, isLoading: isLoadingForeignMachinery } = machineryQueries.allForOverview(foreignMachineryWhere, undefined, undefined, undefined, undefined, foreignMachineryEnabled.value)
const { data: soldMachineryAccessory, isLoading: isLoadingSoldMachineryAccessory } = machineryAccessoryQueries.soldMachineryAccessoriesByCustomerId(id, machineryAccessoryWhere)

const foreignMachinery = computed(() => foreignMachineryData.value?.machinery ?? [])
const mode = computed(() => popupData.value?.mode)

const update = useMutation({
  mutationFn: $trpc.customer.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('customer.notification.update.error') }),
  onSuccess: async (result) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['customer'] }),
      queryClient.invalidateQueries({ queryKey: ['users'] }),
      queryClient.invalidateQueries({ queryKey: ['rentalPricing', 'getPriceOverviewData'] }),
    ])
    if (result.inviteErrors.length > 0) {
      inviteErrors.value = result.inviteErrors.reduce((acc, { id, message }) => {
        acc[id] = message
        return acc
      }, {} as Record<string, string>)
    } else {
      closePopup()
    }
    notification.success({ title: $t('customer.notification.update.success'), duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.customer.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('customer.notification.create.error') }),
  onSuccess: async (result) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['customer'] }),
      queryClient.invalidateQueries({ queryKey: ['users'] }),
    ])
    if (result.inviteErrors.length > 0) {
      inviteErrors.value = result.inviteErrors.reduce((acc, { id, message }) => {
        acc[id] = message
        return acc
      }, {} as Record<string, string>)
    } else {
      if (popupData.value?.mode === 'create' && popupData.value.routerNameToSaveCreatedId) {
        closePopup(result.customer.id)
      } else {
        closePopup()
      }
    }
    notification.success({ title: $t('customer.notification.create.success'), duration: 4500 })
  },
})

function saveCreateOrEditForm(payload: UpdateOrCreateCustomer) {
  if (payload.mode === 'create') {
    return create.mutateAsync(payload.data)
  } else {
    return update.mutateAsync(payload.data)
  }
}

const canSeeInvoices = computed(() => isRole(['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'bookkeeping']))
const canUpdateCustomerUser = computed(() => {
  const shouldReturnCreatedId = popupData.value?.mode === 'create' && popupData.value.routerNameToSaveCreatedId
  return !shouldReturnCreatedId && isRole('admin')
})

const customerPayload = computed(() => {
  if (mode.value === 'create') {
    return {
      customer: {
        type: 'Dealer',
        name: '',
        taxRate: '0.19',
        paymentCondition: 'instantNet',
        language: 'DE',
        country: 'DE',
        address: undefined,
        invoiceFullAddress: undefined,
        isSystemLiftCustomer: false,
        isPartnerLiftCustomer: false,
        isExcludedFromMarketingCommunication: false,
      },
      contactPersons: [],
    } satisfies CreateCustomerData
  }

  if (isFetchingCustomer.value || !customer.value) {
    return
  }

  return {
    customer: {
      ...customer.value,
      address: customer.value.address ?? undefined,
      invoiceFullAddress: customer.value.invoiceFullAddress ?? undefined,
      taxRate: customer.value.taxRate as TaxRates,
      paymentCondition: customer.value.paymentCondition as PaymentConditions,
    },
    contactPersons: customer.value.contactPersons.map((contactPerson) => {
      return {
        ...contactPerson,
        hasHOSAccess: contactPerson.customerUser !== null,
      }
    }),
  } satisfies UpdateCustomer['data']
})
</script>

<template>
  <ThePopup v-if="mode && (mode !== 'update' || customer)" :show="true" title="Kunden-Anlage" width="1050px" @close="closePopup">
    <n-collapse v-if="customer && !isRole('freelancer-sales', false)" class="mb-4">
      <n-collapse-item :title="$t('machinery.overview.calendarAndOfferOverviewCollapsable.title')">
        <p class="font-semibold text-xs text-gray-500 mb-2">
          Der Kalender zeigt alle Geräte die jemals an den gewählten Kunden vermieter oder verkauft wurden.
        </p>
        <CalendarPage :additional-offer-filters="{ customerId: customer.id }" show-calendar-for="all" :allow-offer-creation-mode="false" :show-machinery-filters="false" :should-only-include-items-with-offers="true" />
        <OfferPage class="my-2" view="all" :additional-filters="{ customerId: customer.id }" :initial-page-size="2" />
      </n-collapse-item>
    </n-collapse>
    <n-collapse v-if="customer && canSeeInvoices" class="mb-4">
      <n-collapse-item :title="`Rechnungen ${customer.name}`">
        <div class="w-full flex flex-col gap-y-2">
          <InvoicePage :additional-filters="{ offer: { customer: { id: customer.id } }, type: { not: { in: ['cancellation', 'creditNoteCancellation'] } } }" :show-color-coding="true" />
        </div>
      </n-collapse-item>
    </n-collapse>
    <n-collapse class="mb-4">
      <n-collapse-item title="An Kunde verkaufte Maschinen">
        <TableFilters v-model="soldMachineryFulltextSearchValue" :placeholder="soldMachineryFulltextSearch.placeholder">
          <template #search>
            <n-collapse>
              <n-collapse-item>
                <template #header>
                  <span class="text-lg">
                    Geräte-spezifische Filter ausklappen
                  </span>
                </template>
                <div class="flex flex-col bg-gray-200 gap-y-2 p-3 rounded-sm">
                  <div>
                    <div>
                      <p class="font-semibold">
                        {{ $t('machinery.field.soldAt.name') }}
                      </p>
                      <n-date-picker
                        v-model:value="machinerySoldAtRange"
                        type="daterange"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                  <div>
                    <n-button class="!bg-white" @click="resetFilters">
                      Alle zurücksetzen
                    </n-button>
                  </div>
                </div>
              </n-collapse-item>
            </n-collapse>
          </template>
          <TableView
            :data="soldMachinery"
            :columns="soldMachineryTableColumns"
            :is-loading="isLoadingSoldMachinery"
            :row-props="soldMachineryRowProps"
          />
        </TableFilters>
      </n-collapse-item>
    </n-collapse>
    <n-collapse class="mb-4">
      <n-collapse-item title="An Kunde verkaufte Lagertools">
        <TableFilters v-model="machineryAccessoryFulltextSearchValue" :placeholder="machineryAccessoryFulltextSearch.placeholder">
          <template #search>
            <n-collapse>
              <n-collapse-item>
                <template #header>
                  <span class="text-lg">
                    Lagertool-spezifische Filter ausklappen
                  </span>
                </template>
                <div class="flex flex-col bg-gray-200 gap-y-2 p-3 rounded-sm">
                  <div>
                    <div>
                      <p class="font-semibold">
                        {{ $t('machinery.field.soldAt.name') }}
                      </p>
                      <n-date-picker
                        v-model:value="machineryAccessorySoldAtRange"
                        type="daterange"
                        filterable
                        clearable
                      />
                    </div>
                  </div>
                  <div>
                    <n-button class="!bg-white" @click="resetFilters">
                      Alle zurücksetzen
                    </n-button>
                  </div>
                </div>
              </n-collapse-item>
            </n-collapse>
          </template>
          <TableView
            :data="soldMachineryAccessory"
            :columns="machineryAccessoryTableColumns"
            :is-loading="isLoadingSoldMachineryAccessory"
            :row-props="machineryAccessoryRowProps"
          />
        </TableFilters>
      </n-collapse-item>
    </n-collapse>
    <n-collapse v-if="canAccessPage('/overview/foreign-machinery')" class="mb-4">
      <n-collapse-item title="Fremdgeräte">
        <TableFilters v-model="foreignMachineryFulltextSearchValue" :placeholder="foreignMachineryFulltextSearch.placeholder">
          <TableView
            :data="foreignMachinery"
            :columns="foreignMachineryTableColumns"
            :is-loading="isLoadingForeignMachinery"
            :row-props="foreignMachineryRowProps"
          />
        </TableFilters>
      </n-collapse-item>
    </n-collapse>
    <n-collapse v-if="customer" class="mb-4">
      <n-collapse-item :title="$t('fileFolder.type.CustomerDocument')">
        <FileFolderContainer
          :entity-id="customer.id"
          :folder-type="FileFolderType.Customer"
        />
      </n-collapse-item>
    </n-collapse>
    <CustomerCreateOrEditForm
      v-if="customerPayload"
      :mode
      :customer
      :payload="customerPayload"
      :can-update-customer-user
      :invite-errors
      :show-comments="!isRole('freelancer-sales', false)"
      :is-saving
      @reset-invite-errors="inviteErrors = {}"
      @save="saveCreateOrEditForm"
    />
  </ThePopup>
</template>
