import xss from 'xss'

export default (html: string) => xss(html, {
  whiteList: {
    span: ['style', 'class'],
    p: [],
    strong: [],
    em: [],
    s: [],
    u: [],
    blockquote: [],
    hr: [],
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    br: [],
    ul: [],
    li: [],
  },
  css: {
    whiteList: {
      color: true,
    },
  },
})
