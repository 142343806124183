<script setup lang="ts">
const { t: $t } = useI18n()

import { defu } from 'defu'
import { receptionMachineryRelationsPrefilledData } from '~/server/schemas'
import type { MachineryAccessoryCategoryFilter, MachineryDrives, MachineryFem, MachineryRubrics, MachineryStatus, UpdateMachineryAccessory, UpdateOrCreateReceptionMachinery, UpdateReceptionMachinery } from '~/types'
import { accessoryFilterConditionsFieldsToGerman } from '~/translations'

const { openReceptionProtocol: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

const shouldCheckCompatibleAccessoryFields = computed(() => {
  if (popupData.value?.mode !== 'create' || popupData.value.category !== 'forklift') {
    return false
  }
  return true
})

const { receptionMachinery: queryReceptionMachinery, machineryType: queryMachineryType, machineryDrive: queryMachineryDrives, machineryAccessory: queryMachineryAccessory } = useQuery()

const receptionMachineryQueryMachineryId = computed(() => {
  if (popupData.value?.mode === 'update') {
    return popupData.value.machineryId
  }
  return undefined
})

const createdAccessoryId = computed(() => {
  if (popupData.value?.mode === 'create') {
    return popupData.value.accessoryId
  }
  return undefined
})

const typeId = computed(() => {
  if (popupData.value?.mode === 'create') {
    return popupData.value.typeId
  }
  return undefined
})

const receptionMachinesWhere = computed(() => {
  if (!typeId.value) {
    return undefined
  }
  return { machinery: { typeId: typeId.value } }
})

const { data: receptionMachinery, isLoading: isLoadingReceptionMachinery } = queryReceptionMachinery.byMachineryIdOrThrow(receptionMachineryQueryMachineryId)
const { data: receptionMachineries, isLoading: isLoadingReceptionMachineries } = queryReceptionMachinery.allByType(receptionMachinesWhere, ref(1))
const { data: specialEquipments } = queryReceptionMachinery.specialEquipments()
const { data: tireTypes } = queryReceptionMachinery.tireTypes()
const { data: machineryTypes } = queryMachineryType.all()
const { data: machineryDrivesOption } = queryMachineryDrives.all()
const { data: accessory } = queryMachineryAccessory.byId(createdAccessoryId)

const { key: localStorageKey, customKeyInfix, clearFormStorage } = useLocalStorageKeyForFormKit()

function closePopup(createdReceptionId?: string) {
  if (!createdReceptionId && createdAccessoryId.value) {
    deleteAccessory.mutate({ id: createdAccessoryId.value })
  }
  clearFormStorage()
  notCompatibleAccessoryFields.value = []

  closePopup_()
}

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const update = useMutation({
  mutationFn: $trpc.receptionMachinery.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('receptionMachinery.notification.reception.error') }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['receptionMachines'] }),
      queryClient.invalidateQueries({ queryKey: ['receptionMachineryHistory'] }),
    ])
    closePopup()
    notification.success({ title: $t('receptionMachinery.notification.reception.success'), duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: async (receptionMachineryToCreate: UpdateOrCreateReceptionMachinery['data']) => {
    // If there is an accessory created and the accessory is not compatible with the machine,
    // we update the accessory
    if (accessory.value && notCompatibleAccessoryFields.value.length > 0) {
      await $trpc.machineryAccessory.update.mutate({
        accessory: {
          ...accessory.value,
          category: 'fork',
          fem: receptionMachineryToCreate.receptionMachinery.fem as MachineryFem,
          liftingWeight: notCompatibleAccessoryFields.value.includes('liftingWeight')
            ? receptionMachineryToCreate.receptionMachinery.liftingWeight as number
            : accessory.value!.liftingWeight as number,
        },
        machineryTypes: [],
      } as UpdateMachineryAccessory['data'])
    }
    return $trpc.receptionMachinery.create.mutate(receptionMachineryToCreate)
  },
  onError: makeTrpcErrorToast(notification, { description: $t('receptionMachinery.notification.reception.error') }),
  onSuccess: async (receptionMachinery) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['receptionMachines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
    ])
    closePopup(receptionMachinery.id)
    notification.success({ title: $t('receptionMachinery.notification.reception.success'), duration: 4500 })
  },
})

const deleteAccessory = useMutation({
  mutationFn: $trpc.machineryAccessory.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Anbaugerät konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['accessories'] })
    notification.success({ title: 'Das Anbaugerät wurde gelöscht', duration: 4500 })
  },
})

function save(payload: UpdateOrCreateReceptionMachinery) {
  if (payload.mode === 'update') {
    update.mutate(payload.data)
  } else {
    if (createdAccessoryId.value) {
      payload.data.accessoryId = createdAccessoryId.value
    }

    create.mutate(payload.data)
  }
}

const {
  machineryFieldsByMachineCategory,
  machineryPropulsionFieldsByMachineCategory,
} = useFormOptionalConditions()

const payload = ref<UpdateOrCreateReceptionMachinery | undefined>()

watch(
  () => ({ popupDataValue: popupData.value ?? null, receptionMachineriesValue: receptionMachineries.value ?? null, isLoadingReceptionMachineriesValue: isLoadingReceptionMachineries.value, isLoadingReceptionMachineryValue: isLoadingReceptionMachinery.value, receptionMachineryValue: receptionMachinery.value ?? null }),
  ({ popupDataValue, receptionMachineriesValue, isLoadingReceptionMachineriesValue, isLoadingReceptionMachineryValue, receptionMachineryValue }) => {
    // Don't override existing `payload` if already set
    if (payload.value) {
      return
    }

    if (!popupDataValue) {
      return undefined
    }

    customKeyInfix.value = popupDataValue.machineryId

    if (popupDataValue.mode === 'create') {
      if (!receptionMachineriesValue || isLoadingReceptionMachineriesValue) {
        return undefined
      }

      const defaultReceptionMachinery = receptionMachineriesValue[0]

      const { drive, category } = popupDataValue

      const defaultData = defaultReceptionMachinery
        ? receptionMachineryRelationsPrefilledData.parse({
          receptionMachinery: defaultReceptionMachinery as UpdateOrCreateReceptionMachinery['data']['receptionMachinery'],
          machinerySpecialEquipments: defaultReceptionMachinery.receptionMachinerySpecialEquipmentRelation?.receptionMachinerySpecialEquipments.map(({ id }) => id) ?? [],
          machineryTire: defaultReceptionMachinery.tire as UpdateOrCreateReceptionMachinery['data']['machineryTire'],
          machineryTireTypes: defaultReceptionMachinery.tire?.receptionMachineryTireTireTypeRelation?.receptionMachineryTireTypes.map(({ id }) => id) ?? [],
          machineryBattery: defaultReceptionMachinery.battery as UpdateOrCreateReceptionMachinery['data']['machineryBattery'],
          machineryMotor: defaultReceptionMachinery.motor as UpdateOrCreateReceptionMachinery['data']['machineryMotor'],
        })
        : {}

      const data = defu({
        drive,
        machineryRubric: popupDataValue.machineryRubric,
        receptionMachinery: {
          machineryId: popupDataValue.machineryId,
          typeId: popupDataValue.typeId,
          category,
          isDefective: false,
        },
        machinerySpecialEquipments: [],
        machineryStatus: 'reception',
        machineryTire: {},
        machineryTireTypes: [],
        machineryBattery: {},
        machineryMotor: {},
      }, defaultData) as UpdateOrCreateReceptionMachinery['data']

      // Remove unrelated fields depending on the machinery drive
      if (category === 'forklift') {
        for (const [key] of Object.entries(machineryFieldsByMachineCategory)) {
          if (!useShowFieldByFilters(machineryFieldsByMachineCategory, key, category, drive)) {
          // @ts-expect-error We overwrite unnecessary fields on purpose here. TODO: Make this an explicit zod-discriminate-union-model
            data.receptionMachinery[key as keyof UpdateOrCreateReceptionMachinery['data']['receptionMachinery']] = undefined
          }
        }

        for (const [key] of Object.entries(machineryPropulsionFieldsByMachineCategory)) {
          if (!useShowFieldByFilters(machineryPropulsionFieldsByMachineCategory, key, category, drive)) {
            data.machineryMotor[key as keyof UpdateOrCreateReceptionMachinery['data']['machineryMotor']] = undefined
          }
        }
      }

      payload.value = {
        mode: 'create',
        data,
      } as UpdateOrCreateReceptionMachinery
    }

    if (popupDataValue.mode === 'update') {
      if (!receptionMachineryValue || isLoadingReceptionMachineryValue) {
        return undefined
      }

      payload.value = {
        mode: 'update',
        data: {
          isModification: popupDataValue.isModification,
          receptionMachinery: receptionMachineryValue as UpdateOrCreateReceptionMachinery['data']['receptionMachinery'],
          drive: receptionMachineryValue.machinery?.drive?.name as MachineryDrives ?? 'electric',
          machineryRubric: receptionMachineryValue.machinery?.machineryRubric as MachineryRubrics ?? 'electricForklift',
          machinerySpecialEquipments: receptionMachineryValue.receptionMachinerySpecialEquipmentRelation?.receptionMachinerySpecialEquipments.map(({ id }) => id) ?? [],
          machineryStatus: receptionMachineryValue.machinery?.status as MachineryStatus,
          machineryTire: receptionMachineryValue.tire as UpdateOrCreateReceptionMachinery['data']['machineryTire'],
          machineryTireTypes: receptionMachineryValue.tire?.receptionMachineryTireTireTypeRelation?.receptionMachineryTireTypes.map(({ id }) => id) ?? [],
          machineryBattery: receptionMachineryValue.battery as UpdateOrCreateReceptionMachinery['data']['machineryBattery'],
          machineryMotor: receptionMachineryValue.motor as UpdateOrCreateReceptionMachinery['data']['machineryMotor'],
        },
      } as UpdateReceptionMachinery
    }
  },
  {
    immediate: true,
  },
)

const popupConfirmConnectingIncompatibleForks = ref<null | UpdateOrCreateReceptionMachinery>(null)

const notCompatibleAccessoryFields = ref<Array<keyof MachineryAccessoryCategoryFilter>>([])
function checkCreatedAccessoryCompatibilityAndSave(payload: UpdateOrCreateReceptionMachinery) {
  if (!shouldCheckCompatibleAccessoryFields.value) {
    save(payload)
    return
  }

  const { fem, liftingWeight } = payload.data.receptionMachinery
  const notCompatibleFields: Array<keyof MachineryAccessoryCategoryFilter> = []
  if (accessory.value) {
    if (fem && fem !== accessory.value.fem) {
      notCompatibleFields.push('fem')
    }

    if (accessory.value.liftingWeight === undefined || accessory.value.liftingWeight === null || (liftingWeight && liftingWeight > accessory.value.liftingWeight)) {
      notCompatibleFields.push('liftingWeight')
    }
  }
  notCompatibleAccessoryFields.value = notCompatibleFields
  if (notCompatibleFields.length === 0) {
    save(payload)
    return
  }

  popupConfirmConnectingIncompatibleForks.value = payload
}
</script>

<template>
  <div>
    <TheConfirmPopup
      v-if="popupConfirmConnectingIncompatibleForks"
      action-button-label="Fortfahren"
      @confirm="save(popupConfirmConnectingIncompatibleForks)"
      @close="popupConfirmConnectingIncompatibleForks = null"
    >
      Die verbundenen Gabeln sind nicht mit den Maschinen-Werten kompatibel.<br>
      Wenn Sie fortfahren, werden die Felder "{{ notCompatibleAccessoryFields.map(field => accessoryFilterConditionsFieldsToGerman[field as keyof MachineryAccessoryCategoryFilter]).join(', ') }}" von {{ createdAccessoryId }} aktualisiert.
    </TheConfirmPopup>
    <ThePopup v-if="popupData && payload" :show="Boolean(payload)" :title="`Aufnahmeprotokoll ${payload.data.receptionMachinery.machineryId}`" @close="closePopup">
      <MachineryCreationReceptionProtocolForm
        :payload="payload"
        :special-equipments="specialEquipments ?? []"
        :tire-types="tireTypes ?? []"
        :machinery-types="machineryTypes ?? []"
        :machinery-drives="machineryDrivesOption ?? []"
        :local-storage-key="localStorageKey"
        :created-accessory-id="createdAccessoryId"
        @save="checkCreatedAccessoryCompatibilityAndSave"
      />
    </ThePopup>
  </div>
</template>
