<script lang="ts" setup>
const props = defineProps<{ ids: string[] }>()
const emits = defineEmits<{ (e: 'select', id: string): void }>()
const show = defineModel<boolean>({ default: false })

const errorText = ref<string | undefined>(undefined)

const { $i18n } = useNuxtApp()
function decodeQrCode(decoded: string) {
  const machineryId = decoded.split('/').at(-1) ?? decoded
  if (props.ids.includes(machineryId)) {
    emits('select', machineryId)
    closePopup()
  } else {
    errorText.value = $i18n.t('customerModule.offer.position.scan.error.notIncluded', { id: machineryId })
  }
}

function closePopup() {
  errorText.value = undefined
  show.value = false
}
</script>

<template>
  <ThePopup :show="show" :title="$t('customerModule.offer.position.scan')" width="450px" @close="closePopup">
    <TheQRCodeScanner @decode-qr-code="data => decodeQrCode(data)" />
    <n-alert v-if="errorText" type="error" class="mt-2">
      {{ errorText }}
    </n-alert>
  </ThePopup>
</template>
