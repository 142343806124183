import { useInfiniteQuery, useQuery } from '@tanstack/vue-query'
import { startOfToday } from 'date-fns'
import type { CustomerModuleOfferInput } from '~/server/trpc/routers/customerModule/offer'
import type { GroupedPhotoGroup, OfferType, Pagination } from '~/types'
import type { CustomerModuleCommentTypes, CustomerModuleDefectEntity } from '~/layers/customer-module/types'
import type { CustomerModuleMachineryFilter, CustomerModuleMachineryTransformedFilter } from '~/layers/customer-module/schemas/machinery'

const KEY = 'customerModule'
export const CUSTOMER_MODULE_QUERY_KEY = KEY

type _Where = Record<string, unknown>
type Where = Ref<_Where>

export default () => {
  const { $trpc } = useNuxtApp()
  const { accessCodeSession } = useCustomerModuleAccessCodeSession()
  const { useremail } = useAuthorization()

  const isInternalUser = computed(() => useremail.value !== 'N/A' && !accessCodeSession.value)

  return {
    accountQuery: {
      getSelf: (enabled?: MaybeRef<boolean>) => useQuery({
        queryFn: () => $trpc.customerModule.account.getOwnAccountData.query(),
        queryKey: [KEY, 'account', 'self'],
        enabled: enabled ? unref(enabled) && isInternalUser : isInternalUser,
      }),
      getStatus: () => useQuery({
        queryFn: () => $trpc.customerModule.account.getAccountStatus.query(),
        queryKey: [KEY, 'account', 'self', 'status'],
        enabled: isInternalUser,
      }),
    },

    offerQuery: {
      getOffers: (input: MaybeRef<CustomerModuleOfferInput>) => useQuery({
        queryFn: () => $trpc.customerModule.offer.getOffers.query({ ...unref(input) }),
        queryKey: [KEY, 'offers', input],
      }),
      getOfferById: (id?: MaybeRef<string | undefined>) => useQuery({
        queryFn: () => {
          const unrefId = unref(id)
          if (!unrefId) {
            return null
          }
          return $trpc.customerModule.offer.getOfferById.query({ id: unrefId })
        },
        queryKey: [KEY, 'offers', id],
      }),
      getOfferByIdAndType: (id?: MaybeRef<string | undefined>, type?: MaybeRef<OfferType | undefined>) => useQuery({
        queryFn: () => {
          const unrefId = unref(id)
          const unrefType = unref(type)
          if (!unrefId || !unrefType) {
            return null
          }
          return $trpc.customerModule.offer.getOfferById.query({ id: unrefId, where: { type: unrefType } })
        },
        queryKey: [KEY, 'offers', id, type],
      }),
      getInfiniteOffers: (input: MaybeRef<CustomerModuleOfferInput>) => useInfiniteQuery({
        queryFn: ({ pageParam }) => $trpc.customerModule.offer.getOffers.query({ ...unref(input), pagination: { skip: pageParam, take: 10 } }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, _, lastPageParam) => {
          if (lastPage.length === 0) {
            return undefined
          }
          return lastPageParam + 10
        },
        queryKey: [KEY, 'offers', input],
      }),
      getOfferCountPerView: (type: OfferType) => useQuery({
        queryFn: () => $trpc.customerModule.offer.getOfferCountsPerView.query({ type }),
        queryKey: [KEY, 'offers', 'getOfferCountsPerView', type],
        enabled: () => !!type,
      }),
      getOfferObligationEndsAtById: (id?: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          const unrefId = unref(id)
          if (!unrefId) {
            return null
          }
          return $trpc.customerModule.offer.getOfferObligationEndsAtById.query({ offerId: unrefId })
        },
        queryKey: [KEY, 'offers', 'getOfferObligationEndsAtById', id],
      }),
      getCanOfferUpdatedToOrder: (id?: Ref<string | undefined>, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefId = unref(id)
          if (!unrefId) {
            return null
          }
          return $trpc.customerModule.offer.getCanOfferUpdatedToOrder.query({ id: unrefId, startOfDay: startOfToday() })
        },
        queryKey: [KEY, 'offers', 'getCanOfferUpdatedToOrder', id],
        enabled,
      }),
    },

    offerPublicQuery: {
      getOfferByAccessCodeSession: (session: Ref<string | undefined>) => useQuery({
        queryFn() {
          if (!session.value) {
            return null
          }

          return $trpc.customerModule.offer.publicGetOfferByAccessCode.query(session.value)
        },
        queryKey: [KEY, 'offers', 'getOfferByAccessCodeSession', session],
        retry: 1,
      }),
      getOfferPositionsById: (offerId: Ref<string | undefined>, where?: Where, accessCodeSession?: Ref<string | undefined>, enabled?: Ref<boolean>) => useQuery({
        queryFn() {
          if (!offerId.value) {
            return null
          }
          return $trpc.customerModule.offer.publicGetOfferPositions.query({
            id: offerId.value,
            where: unref(where),
            accessCodeSession: unref(accessCodeSession),
          })
        },
        queryKey: [KEY, 'offers', 'getOfferPositionsById', offerId, where, accessCodeSession],
        enabled,
      }),
    },

    invoiceQuery: {
      getInvoicesByOfferId: (offerId: MaybeRef<string>, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => {
          const id = unref(offerId)
          if (!id) {
            return null
          }
          const where = { offerId: id }
          return $trpc.customerModule.invoice.getInvoices.query({ where, pagination: pagination?.value })
        },
        queryKey: [KEY, 'invoices', 'getInvoicesByOfferId', offerId, pagination],
      }),
    },

    commentQuery: {
      getAll: (filters: MaybeRef<{ type: CustomerModuleCommentTypes, entityId?: string }>) => useQuery({
        queryFn: () => {
          const unrefFilters = unref(filters)
          const { type, entityId } = unrefFilters
          if (entityId === undefined) {
            return null
          }
          // We do destructuring for avoid ts error
          return $trpc.customerModule.comment.getAll.query({ type, entityId })
        },
        queryKey: [KEY, 'comments', 'getAll', filters],
        enabled: isInternalUser,
      }),
      getCommentById: (id: MaybeRef<string | undefined>) => useQuery({
        queryFn: () => {
          const unrefId = unref(id)
          if (!unrefId) {
            return null
          }
          return $trpc.customerModule.comment.getById.query({ id: unrefId })
        },
        queryKey: [KEY, 'comments', 'getCommentById', id],
      }),
      getCount: (entityId: MaybeRef<string | undefined>, type: MaybeRef<CustomerModuleCommentTypes>) => useQuery({
        queryFn: () => {
          const id = unref(entityId)
          if (id === undefined) {
            return null
          }
          return $trpc.customerModule.comment.getCount.query({ type: unref(type), entityId: id })
        },
        queryKey: [KEY, 'comments', 'getCount', type, entityId],
      }),
    },

    defectPublicQuery: {
      getAllByEntityId: (entity: MaybeRef<CustomerModuleDefectEntity>, id: MaybeRef<string | undefined>, where: Where, accessCodeSession: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          const unrefId = unref(id)
          if (!unrefId) {
            return null
          }

          return $trpc.customerModule.defect.publicGetAllByEntityId.query({
            entity: unref(entity),
            id: unrefId,
            where: where.value,
            accessCodeSession: accessCodeSession.value,
          })
        },
        queryKey: [KEY, 'defects', 'getAllByEntityId', entity, id, where, accessCodeSession],
      }),
      getById: (defectId: MaybeRef<string | undefined>, accessCodeSession?: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          const id = unref(defectId)
          if (!id) {
            return null
          }

          return $trpc.customerModule.defect.publicGetById.query({ id, accessCodeSession: accessCodeSession?.value })
        },
        queryKey: [KEY, 'defects', 'getById', defectId, accessCodeSession],
      }),
    },

    rentalDayQuery: {
      getAllByOfferId: (offerId: MaybeRef<string | undefined>, where?: Where) => useQuery({
        queryFn: () => {
          const id = unref(offerId)
          if (!id) {
            return null
          }
          return $trpc.customerModule.rentalDay.getAllByOfferId.query({ id, where: where?.value })
        },
        queryKey: [KEY, 'rentalDays', 'getAllByOfferId', offerId, where],
      }),
    },

    rentalPauseQuery: {
      getAllByOfferId: (offerId: MaybeRef<string | undefined>, where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => {
          const id = unref(offerId)
          if (!id) {
            return null
          }
          return $trpc.customerModule.rentalPause.getAllByOfferId.query({ offerId: id, where: where?.value, pagination: pagination?.value })
        },
        queryKey: [KEY, 'rentalPauses', 'getAllByOfferId', offerId, where],
      }),
      getById: (id: MaybeRef<string | undefined>) => useQuery({
        queryFn: () => {
          const unrefId = unref(id)
          if (!unrefId) {
            return null
          }
          return $trpc.customerModule.rentalPause.getById.query({ id: unrefId })
        },
        queryKey: [KEY, 'rentalPauses', 'getById', id],
      }),
    },

    serviceProjectDailyReportQuery: {
      getAll: (offerId: MaybeRef<string | undefined>, where: Where, pagination: MaybeRef<Pagination>) => useQuery({
        queryFn: () => {
          const id = unref(offerId)
          if (!id) {
            return null
          }

          return $trpc.customerModule.serviceProjectDailyReport.getAll.query({ offerId: id, where: where.value, pagination: unref(pagination) })
        },
        queryKey: [KEY, 'serviceProjectDailyReport', 'getAll', offerId, where, pagination],
      }),
    },

    serviceProjectInterimReportQuery: {
      getAll: (offerId: MaybeRef<string | undefined>, where?: Where) => useQuery({
        queryFn: () => {
          const id = unref(offerId)
          if (!id) {
            return null
          }
          return $trpc.customerModule.serviceProjectInterimReport.getAll.query({ id, where: where?.value })
        },
        queryKey: [KEY, 'serviceProjectInterimReport', 'getAll', offerId, where],
      }),
    },

    serviceProjectReportingQuery: {
      getAllOffersForExternalStakeholder: (userId?: Ref<string>) => useQuery({
        queryFn: () => $trpc.customerModule.serviceProjectReporting.getAllOffersOfExternalStakeholder.query({
          userId: unref(userId),
        }),
        queryKey: [KEY, 'serviceProjectReportingQuery', 'getAllOffersForExternalStakeholder', userId],
      }),
      getOfferForServiceProjectReportingById: (id?: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          const offerId = unref(id)
          if (!offerId) {
            return null
          }
          return $trpc.customerModule.serviceProjectReporting.getOfferForServiceProjectReporting.query({ offerId })
        },
        queryKey: [KEY, 'serviceProjectReportingQuery', 'getOfferForServiceProjectReportingById', id],
      }),
      getExternalStakeholders: (offerId: Ref<string>) => useQuery({
        queryFn: () => $trpc.customerModule.serviceProjectReporting.getExternalStakeholders.query({
          offerId: offerId.value,
        }),
        queryKey: [KEY, 'serviceProjectReportingQuery', 'getExternalStakeholders', offerId],
      }),
    },

    groupedPhotoQuery: {
      getByGroupAndKey: (group: MaybeRef<GroupedPhotoGroup | undefined>, key: MaybeRef<string | undefined>, enabled?: MaybeRef<boolean>) => useQuery({
        queryFn: () => {
          const unrefGroup = unref(group)
          const unrefKey = unref(key)
          if (!unrefGroup || !unrefKey) {
            return null
          }
          return $trpc.customerModule.groupedPhoto.getByGroupAndKey.query({ group: unrefGroup, key: unrefKey })
        },
        queryKey: [KEY, 'groupedPhotos', 'getByGroupAndKey', group, key],
        enabled: isInternalUser.value && enabled,
      }),
    },

    machineryQuery: {
      getMachineryForCategory: (machineryCategoryFilter?: MaybeRef<{ typeId: string, driveId: string, machineryRubric: string } | null>, enabled?: MaybeRef<boolean>) => useQuery({
        queryFn: () => {
          const filters = machineryCategoryFilter ? unref(machineryCategoryFilter) : null
          if (!filters) {
            return null
          }
          return $trpc.customerModule.machinery.getMachineryForCategory.query(filters)
        },
        queryKey: [KEY, 'machinery', 'getMachineryForCategory', machineryCategoryFilter],
        enabled,
      }),
      partnerSales: {
        getAll: (filters?: MaybeRef<CustomerModuleMachineryFilter>, where?: Where, pagination?: MaybeRef<Pagination>, enabled?: MaybeRef<boolean>) => useQuery({
          queryFn: () => $trpc.customerModule.machinery.partnerSales.getAll.query({ where: unref(where), filters: unref(filters), pagination: unref(pagination) }),
          queryKey: [KEY, 'machinery', 'partnerSales', 'getAll', filters, where, pagination],
          enabled,
        }),
        getDetailsById: (id: Ref<string>, enabled: MaybeRef<boolean>) => useQuery({
          queryFn: () => {
            if (!unref(enabled)) {
              return null
            }
            return $trpc.customerModule.machinery.partnerSales.getDetailsById.query({ id: id.value })
          },
          queryKey: [KEY, 'machinery', 'partnerSales', 'getDetailsById', id, enabled],
        }),
      },
      onlineSales: {
        getAll: (filters?: MaybeRef<CustomerModuleMachineryTransformedFilter>, userInput?: MaybeRef<string>, pagination?: MaybeRef<Pagination>) => useQuery({
          queryFn: () => $trpc.customerModule.machinery.onlineSales.getAll.query({ userInput: unref(userInput), filters: unref(filters), pagination: unref(pagination) }),
          queryKey: [KEY, 'machinery', 'onlineSales', 'getAll', filters, userInput, pagination],
        }),
      },
    },

    foreignMachineryQuery: {
      getAll: (filters?: MaybeRef<CustomerModuleMachineryFilter>, where?: Where, pagination?: MaybeRef<Pagination>, enabled?: MaybeRef<boolean>) => useQuery({
        queryFn: () => $trpc.customerModule.foreignMachinery.getAll.query({ where: unref(where), filters: unref(filters), pagination: unref(pagination) }),
        queryKey: [KEY, 'foreignMachinery', 'getAll', filters, where, pagination],
        enabled,
      }),
      canManageDefects: (machineryId: Ref<string>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.customerModule.foreignMachinery.canManageDefects.query({ id: machineryId.value }),
        queryKey: [KEY, 'foreignMachinery', 'canManageDefects', machineryId],
        enabled,
      }),
    },

    fairTicketQuery: {
      getBaumaTicketCode: () => useQuery({
        queryFn: () => $trpc.customerModule.fairTicket.getBaumaTicketCode.query(),
        queryKey: [KEY, 'fairTicketQuery', 'getBaumaTicketCode'],
      }),
    },

  }
}
