import validate from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/pages/runtime/validate.js";
import authorization_45global from "/app/middleware/authorization.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authorization_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sidebase-auth": () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.10.1_@electric-sql+pglite@0.2.13_encoding@0.1.13_magicast@0.3.5_n_a964110d943eed4ef79a7beecd7324f1/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}