<script lang="ts" setup>
import type { DataTableRowKey } from 'naive-ui'
import currency from 'currency.js'
import { isWithinInterval, startOfWeek } from 'date-fns'
import { diff } from 'deep-object-diff'
import { createId } from '@paralleldrive/cuid2'
import { createInvoiceSchema, invoiceTypeToSelect, offerPositionsToShipInvoiceSchema, offerPositionsToShipSchema } from '~/server/schemas'
import type { ApiOfferPositionGetToCreateInvoice, CreateInvoice, CreateInvoiceCustomOrCreditNotePositionType, CreateInvoiceCustomPosition, CreateInvoiceExtraDaysPosition, CreateInvoiceToOfferPositionRelation, CreateProformaInvoice, GeneratedByAutomation, InvoiceType, OfferPositionsToShip, OfferType } from '~/types'
import { invoiceTypeToGerman } from '~/translations'
import type { InvoiceToOfferPositionRelation } from '~/server/trpc/routers/invoice'

type CreateInvoiceExcludeCreditNote = Exclude<CreateInvoice, { type: 'creditNote' }> | CreateProformaInvoice

const { urlWithLocale } = useUrlWithLocale()

const selectionNotAllowedInvoiceType = ['fullpayment', 'proformapayment']

const { openInvoicingPopup: { data: payload, close: closeInvoicePopup } } = useGlobalOpeners()

const formData = ref<null | CreateInvoiceExcludeCreditNote>(null)
const customPositions = ref<CreateInvoiceCustomPosition[]>([])
const extraDaysPositions = ref<CreateInvoiceExtraDaysPosition[]>([])
const shouldNotCreateInvoiceId = ref(false)
const invoicePeriod: Ref<null | [number, number]> = ref(null)
const defaultInvoicePeriod = ref<[number, number]>()

const invoiceFormType = computed(() => formData.value?.type)
const offerId = computed(() => payload.value?.offerId)
const isFullpayment = computed(() => invoiceFormType.value === 'fullpayment')

const isCreatingInvoice = ref(false)

const { invoice: queryInvoice } = useQuery()

const { data: offer, isLoading: isLoadingOffer } = queryInvoice.offerForInvoiceById(offerId)
const { data: invoiceToOfferPositionRelations, isLoading: isLoadingInvoiceToOfferPositionRelations } = queryInvoice.getToCreateInvoice(
  // Call `getToCreateInvoice` only if the offer is loaded so that this request doesn't get bundled with the offer request which will slow down the offer request
  computed(() => offer.value?.id),
  isFullpayment,
)

const { offerForInvoiceTab: { columns }, invoiceToOfferPositions } = useTableColumnConfigs()
const invoiceToOfferPositionsForType = invoiceToOfferPositions(invoiceFormType)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

function resetFormData() {
  const newFormData = offerId.value ? createInitialInvoiceForm(offerId.value) : null
  formData.value = newFormData && newFormData.type !== 'creditNote' ? newFormData : null
  invoicePeriod.value = null
  checkedPositionKeys.value = []
  customPositions.value = []
  extraDaysPositions.value = []
  shouldNotCreateInvoiceId.value = false
  isCreatingInvoice.value = false
}

const create = useMutation({
  mutationFn: $trpc.invoice.create.mutate,
  onError: (e) => {
    isCreatingInvoice.value = false
    makeTrpcErrorToast(notification, { description: 'Die Rechnung konnte nicht erstellt werden' })(e)
  },
  onSuccess: async (invoice) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
      queryClient.invalidateQueries({ queryKey: ['comment'] }),
    ])

    resetFormData()
    openInvoiceNextStepsPopup.open({ invoiceId: invoice.id })

    notification.success({ title: 'Die Rechnung wurde erfolgreich erstellt', duration: 4500 })
  },
})

const discountGenerated = useMutation({
  mutationFn: $trpc.invoice.discountGeneratedPositions.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Position konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])

    notification.success({ title: 'Die Position wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const updateCommentVisibility = useMutation({
  mutationFn: $trpc.invoice.updateCommentVisibility.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Position konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['invoice'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])

    notification.success({ title: 'Die Position wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const createProformaUploadedAt = useMutation({
  mutationFn: $trpc.offer.createProformaUploadedAt.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Proforma-Rechnung konnte nicht generiert werden.' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['offer'] })
    notification.success({ title: 'Die Proforma-Rechnung wurde erfolgreich generiert', duration: 4500 })
  },
})

const { payload: updatePositionPayload, open: openUpdatePositionPopup, close: closeUpdatePositionPopup } = usePopup<InvoiceToOfferPositionRelation>()
const popupConfirmDiscountPosition = ref<null | InvoiceToOfferPositionRelation>(null)
const { payload: positionReturnDetail, open: openPositionReturnDetailPopup, close: closePositionReturnDetailPopup } = usePopup<ApiOfferPositionGetToCreateInvoice>()
const { payload: extendPositionRentalPayload, open: openExtendPositionRentalPopup, close: closeExtendPositionRentalPopup } = usePopup<ApiOfferPositionGetToCreateInvoice>()

const updatePositionPopupTitle = computed(() => {
  if (!updatePositionPayload.value) {
    return
  }

  if (updatePositionPayload.value.type === 'manualPosition') {
    return `Position ${updatePositionPayload.value.title} anpassen`
  }

  if (updatePositionPayload.value.type === 'generatedByAutomation') {
    return 'Menge aktualisieren'
  }

  if (updatePositionPayload.value.invoicedDate) {
    return `Rabatt am ${useDateAsString(updatePositionPayload.value.invoicedDate, 'dd.MM.yyyy')} anpassen`
  }

  return `Preis von ${updatePositionPayload.value.title} anpassen`
})

function handleDiscount(data: InvoiceToOfferPositionRelation, revert?: boolean) {
  discountGenerated.mutate({
    offerId: data.offerId,
    generatedByAutomation: data.generatedByAutomation as GeneratedByAutomation,
    revert,
  })

  popupConfirmDiscountPosition.value = null
}

function handleComment(data: InvoiceToOfferPositionRelation) {
  updateCommentVisibility.mutate({
    id: data.id,
    isHidden: !data.isHidden,
  })
}

const returnedTaskCuidByPositionId = computed(() => {
  const taskIdByPositionId: Record<string, string> = {}

  if (!offer.value) {
    return taskIdByPositionId
  }

  const returnedTasks = offer.value.logisticsTasks.filter(t => t.type === 'inbound' && !t.isCancelled)

  for (const task of returnedTasks) {
    for (const position of task.positionsToShip) {
      taskIdByPositionId[position.id] = task.cuid
    }
  }

  return taskIdByPositionId
})

const updatablePositionTypes: readonly string[] = [...offerPositionsToShipSchema.options, 'manualPosition', 'tankFilling', 'adblueFilling', 'pollution', 'comment']
type InvoicePositionTypeToUpdate = typeof updatablePositionTypes[number]
function getOpenPositionActions(row: InvoiceToOfferPositionRelation, hasExtraDaysPosition: boolean): Record<InvoicePositionTypeToUpdate, Action[]> {
  const invoiceOrDiscount = row.discountRate ? 'invoice' : 'discount'
  const positionsToShipActions: Action[] = []
  const isSpecialOffer = offer.value?.type === 'special'

  if (row.type === 'comment') {
    if (row.isHidden) {
      positionsToShipActions.push('markAsVisible')
    }

    return {
      comment: positionsToShipActions,
    }
  }

  if (!row.firstDayOfWeek && !isSpecialOffer) {
    if (row.invoicedDate) {
      positionsToShipActions.push('updateDiscount')
    } else if (!hasExtraDaysPosition) {
      positionsToShipActions.push('updatePrice')
    }
  }

  return {
    machinery: positionsToShipActions,
    machineryAccessory: positionsToShipActions,
    machineryAccessoryCategory: positionsToShipActions,
    itemSet: positionsToShipActions,
    manualPosition: ['update'],
    tankFilling: ['update', invoiceOrDiscount],
    adblueFilling: ['update', invoiceOrDiscount],
    pollution: [invoiceOrDiscount],
    comment: [],
  }
}

function invoicePositionActionButtons(row: InvoiceToOfferPositionRelation): Action[] {
  const invoicePositionType = row.generatedByAutomation ?? row.type

  if (!updatablePositionTypes.includes(invoicePositionType)) {
    return []
  }

  const hasExtraDaysPosition = extraDaysPositions.value.some(p => p.offerPositionToExtendId === row.id)

  const buttons = row.paymentStatus === 'Open'
    ? getOpenPositionActions(row, hasExtraDaysPosition)[invoicePositionType as InvoicePositionTypeToUpdate]
    : []

  // Add action buttons specific to fullpayment invoices
  if (offerPositionsToShipInvoiceSchema.safeParse(invoicePositionType).success && formData.value?.type === 'fullpayment' && row.type !== 'comment') {
    buttons.push('openCollectedPopup')
    if (!hasExtraDaysPosition && offer.value?.type !== 'service-project') {
      buttons.push('extendPositionRentalDays')
    }
  }

  return buttons
}

const isSale = computed(() => {
  if (!offer.value) {
    return false
  }

  return offer.value.type === 'sale'
})

const areMachinesAndAccessoriesReturned = computed(() => {
  if (!offer.value) {
    return false
  }
  return offer.value.positions.every(({ type, isReturned }) => !offerPositionsToShipSchema.options.includes(type as OfferPositionsToShip) || isReturned)
})

const invoiceTypeOptions = computed(() => invoiceTypeToSelect.options.map((type) => {
  let disabled = false
  const isServiceProject = offer.value?.type === 'service-project'
  if (type === 'fullpayment' && !isSale.value) {
    disabled = isServiceProject ? !offer.value?.isCompleted : !areMachinesAndAccessoriesReturned.value
  } else if (type === 'proformapayment') {
    disabled = !isSale.value
  }

  return {
    label: invoiceTypeToGerman[type],
    value: type,
    attrs: { disabled },
  }
}))

const router = useRouter()

function handleOpenOfferPage() {
  if (!offer.value) {
    return
  }

  return openOfferPage({ mode: 'edit', id: offer.value.id, redirectUrl: router.currentRoute.value.fullPath })
}

const { openOfferPage, openInvoiceNextStepsPopup, openInternalCostPopup } = useGlobalOpeners()

watch(offer, (newOffer) => {
  if (!newOffer && !payload.value) {
    resetFormData()
    return
  }

  if (!newOffer) {
    return
  }

  if (newOffer.obligationStartsAt && newOffer.obligationEndsAt && newOffer.type === 'special') {
    defaultInvoicePeriod.value = [newOffer.obligationStartsAt.getTime(), newOffer.obligationEndsAt.getTime()]
  } else {
    defaultInvoicePeriod.value = undefined
  }

  if (formData.value) {
    formData.value.offerId = newOffer.id
  } else {
    const createInvoiceForm = createInitialInvoiceForm(newOffer.id, payload.value?.type)
    if (createInvoiceForm && createInvoiceForm.type !== 'creditNote') {
      formData.value = createInvoiceForm
    }
  }
})

// POSITION TABLE
function getRowKey(row: ApiOfferPositionGetToCreateInvoice) {
  const keyParts = []

  if (row.firstDayOfWeek) {
    keyParts.push(row.firstDayOfWeek.getTime())
  }

  if (row.invoicedDate) {
    const { obligationStartsAt } = row.relatedOffer
    const firstDayOfWeek = startOfWeek(row.invoicedDate)

    keyParts.push(obligationStartsAt <= firstDayOfWeek ? firstDayOfWeek.getTime() : obligationStartsAt.getTime(), row.invoicedDate.getTime())
  }

  return keyParts.join('-') ? `${row.id}-${keyParts.join('-')}` : row.id
}

const checkedPositionKeys = ref<DataTableRowKey[]>([])
const allCheckedPositions = computed(() => checkedPositionKeys.value.map(key => (key as string).split('-')))
const checkedParentPositionIds = computed(() => [...new Set(allCheckedPositions.value.map(key => key[0]))])

// FORM
function getAllNotInvoicedPositions(positions: ApiOfferPositionGetToCreateInvoice[], shouldIncludeAllComments = true) {
  const positionsToCheck = shouldIncludeAllComments ? positions : positions.filter(p => p.type === 'comment' ? checkedParentPositionIds.value.includes(p.id) : true)
  return positionsToCheck
    .filter(({ paymentStatus }) => paymentStatus !== 'Invoiced')
    .flatMap((p) => {
      let positionRelations = [p]

      if (p.children) {
        const notPaidChildren = p.children.filter(child => child.paymentStatus !== 'Invoiced')
        positionRelations = positionRelations.concat(notPaidChildren)
        for (const child of notPaidChildren) {
          if (child.children) {
            const notPaidGrandChildren = child.children.filter(grandChild => grandChild.paymentStatus !== 'Invoiced')
            positionRelations = positionRelations.concat(notPaidGrandChildren)
          }
        }
      }

      return positionRelations
    })
}

// using watchEffect will reset the selected items on tab swtich
watch([invoiceFormType, invoiceToOfferPositionRelations], ([newInvoiceFormType, newPositionRelations], [oldInvoiceFormType, oldPositionRelations]) => {
  if (!newPositionRelations || !newInvoiceFormType) {
    return
  }

  if (oldInvoiceFormType !== 'partialpayment' && offer.value?.type !== 'special') {
    invoicePeriod.value = null
  }

  const isSameInvoiceType = newInvoiceFormType === oldInvoiceFormType

  const diffPositions = Object.values(diff(oldPositionRelations ?? [], newPositionRelations))
  if (diffPositions.length === 0 && isSameInvoiceType) {
    return
  }

  if (!selectionNotAllowedInvoiceType.includes(newInvoiceFormType)) {
    // https://github.com/sidestream-tech/hanselmann-os/issues/2716
    if (!isSameInvoiceType) {
      checkedPositionKeys.value = []
    }
    return
  }

  const shouldHaveSameCommentSelection = isSameInvoiceType && oldPositionRelations
  const allNotInvoicedPositions = getAllNotInvoicedPositions(invoiceToOfferPositionRelationsRows.value ?? [], !shouldHaveSameCommentSelection)

  checkedPositionKeys.value = allNotInvoicedPositions.map(p => getRowKey(p))
})

/** SET INSURANCE POSITIONS QUANTITY */
const machineryInvoiceDaysById = ref<Record<string, number>>({})
const machineryPositions = computed(() => invoiceToOfferPositionRelations.value?.filter(p => p.type === 'machinery') ?? [])
const insurancePositions = computed(() => invoiceToOfferPositionRelations.value?.filter(p => p.type === 'insurance') ?? [])
const insurancePositionsByMachinery = computed(() => insurancePositions.value.reduce((acc, p) => {
  const machineryForInvoice = p.machineryForInvoice
  if (machineryForInvoice) {
    acc[machineryForInvoice] = p
  }
  return acc
}, {} as Record<string, InvoiceToOfferPositionRelation>))
const fullyDiscountedDatesByMachinery = computed(() => {
  return machineryPositions.value.reduce((acc, machinery) => {
    if (machinery?.children) {
      return machinery.children.reduce((acc, child) => {
        acc[machinery.id] = acc[machinery.id] || {}
        child.children?.forEach(c => c.invoicedDate
          ? acc[machinery.id][c.invoicedDate.getTime()] = c.discountRateForDay === 1
          : null)
        return acc
      }, acc)
    }
    return acc
  }, {} as Record<string, Record<number, boolean>>)
})

function resetMachineryInvoiceDays(selectedMachineIds: string[] = []) {
  const positionsToFilter: string[] = []
  // Reset machinery invoice ids if the machinery is not selected
  for (const machineryId of Object.keys(insurancePositionsByMachinery.value)) {
    if (!selectedMachineIds.includes(machineryId) && machineryInvoiceDaysById.value[machineryId]) {
      machineryInvoiceDaysById.value[machineryId] = 0
      positionsToFilter.push(insurancePositionsByMachinery.value[machineryId].id)
    }
  }

  if (positionsToFilter.length > 0) {
    checkedPositionKeys.value = checkedPositionKeys.value.filter(key => !positionsToFilter.includes(key.toString()))
  }
}

function setMachineryInvoiceDays() {
  if (checkedPositionKeys.value.length === 0) {
    resetMachineryInvoiceDays()
    return
  }

  const machineryPositionIds = machineryPositions.value.map(p => p.id)
  const checkedMachineryPositionIds = checkedParentPositionIds.value.filter(id => machineryPositionIds.includes(id))

  for (const machineryPositionId of checkedMachineryPositionIds) {
    /**
     * Update the count of days to invoice. Only count days for checked positions where:
     * `key[0] === machineryPositionId`: check if position is for machinery
     * `key[2]`: check if position is grand child
     * The value of key[2] is invoiced date
     * To check how to create the key see `getRowKey`
     */
    machineryInvoiceDaysById.value[machineryPositionId] = allCheckedPositions.value.filter(
      (key) => {
        return (key[0] === machineryPositionId
          && key[2]
          && !fullyDiscountedDatesByMachinery.value[machineryPositionId]?.[Number(key[2])])
      },
    ).length
    const insuranceId = insurancePositionsByMachinery.value[machineryPositionId]?.id
    if (insuranceId && !checkedPositionKeys.value.includes(insuranceId)) {
      checkedPositionKeys.value = [...checkedPositionKeys.value, insuranceId]
    }
  }

  resetMachineryInvoiceDays(checkedMachineryPositionIds)
}

watch([checkedPositionKeys, fullyDiscountedDatesByMachinery], () => {
  setMachineryInvoiceDays()
})

const invoiceToOfferPositionRelationsRows = computed(() => invoiceToOfferPositionRelations.value?.map((position) => {
  if (!position.children && position.type === 'insurance') {
    const quantity = position.machineryForInvoice ? machineryInvoiceDaysById.value[position.machineryForInvoice] ?? 0 : 0
    return {
      ...position,
      quantity,
    }
  }

  return position
}))

const selectedInvoiceToOfferPositionsAndTotalAmount = computed(() => {
  let totalInvoiceAmount = currency(0)
  const selectedInvoiceToOfferPositions: CreateInvoiceToOfferPositionRelation[] = []

  if (invoiceToOfferPositionRelationsRows.value) {
    for (const id of checkedParentPositionIds.value) {
      const position = invoiceToOfferPositionRelationsRows.value.find(p => p.id === id)

      if (!position) {
        /**
         * This branch is reached due to a race condition: We did not find `position` which we expected to exist inside `invoiceToOfferPositionRelationsRows` which is loaded from the server.
         *
         * What we are doing now is to return a fallback value. This fallback value will be replaced with the actual value as soon as this `computed` here re-runs after the (likely still in progress fetch) completes. Then we should be able to find the `position` and not fall back any longer.
         *
         * The downside of this solution is, that for a short while the `totalAmount` and `selectedPositions` are incorrect. The values are likely only incorrect for a small amount of milli-seconds.
         *
         * We used to throw an error here instead, but this of course interrupted the whole programm flow. We removed it and replaced it with this solution in: https://github.com/sidestream-tech/hanselmann-os/pull/1606
         */
        return {
          totalAmount: 0,
          selectedPositions: [],
        }
      }

      if (position.children) {
        for (const child of position.children) {
          if (child.children) {
            const grandChildrenKeys = allCheckedPositions.value.filter(key => key[0] === id && key.length > 2).map(key => key[2])
            const grandChildren = child.children.filter(grandChild => grandChildrenKeys.includes(grandChild.invoicedDate?.getTime().toString() ?? ''))

            for (const grandChild of grandChildren) {
              totalInvoiceAmount = totalInvoiceAmount.add(calculateInvoiceToOfferPositionPrice(grandChild).value)
              selectedInvoiceToOfferPositions.push({
                ...grandChild,
                offerPositionId: grandChild.id,
              })
            }
          } else {
            totalInvoiceAmount = totalInvoiceAmount.add(calculateInvoiceToOfferPositionPrice(child).value)
            selectedInvoiceToOfferPositions.push({
              ...child,
              offerPositionId: child.id,
            })
          }
        }
      } else {
        totalInvoiceAmount = totalInvoiceAmount.add(calculateInvoiceToOfferPositionPrice(position).value)
        selectedInvoiceToOfferPositions.push({
          ...position,
          offerPositionId: position.id,
        })
      }
    }
  }

  // Add custom positions & extraDays to total amount
  for (const customPosition of customPositions.value) {
    totalInvoiceAmount = totalInvoiceAmount.add(currency(customPosition.pricePerUnit).multiply(customPosition.quantity).value)
  }

  for (const extraDaysPosition of extraDaysPositions.value) {
    totalInvoiceAmount = totalInvoiceAmount.add(calculateInvoiceToOfferPositionPrice(extraDaysPosition).value)
  }

  return {
    totalAmount: totalInvoiceAmount.value,
    selectedPositions: selectedInvoiceToOfferPositions,
  }
})

watch([() => selectedInvoiceToOfferPositionsAndTotalAmount.value.totalAmount, () => formData.value?.totalAmount], ([positionsTotalAmount, totalAmount]) => {
  if (positionsTotalAmount || totalAmount) {
    shouldNotCreateInvoiceId.value = false
  }
})

function createInitialInvoiceForm(offerId: string, type?: InvoiceType | null) {
  const parsedData = createInvoiceSchema.safeParse({
    offerId,
    totalAmount: 0,
    type: type ?? 'downpayment',
    status: 'created',
    includeVAT: true,
    createElectronicInvoice: true,
    invoicedAt: new Date(),
    offerPositions: [],
    customPositions: [],
    downpaymentInvoices: [],
    extraDaysPositions: [],
  })

  return parsedData.success ? parsedData.data : null
}

function createInvoiceToCreate() {
  if (!payload.value || !formData.value || !invoiceToOfferPositionRelations.value || !offer.value) {
    return
  }

  const [start, end] = invoicePeriod.value ?? [undefined, undefined]
  const baseForm = {
    ...formData.value,
    startDate: start ? new Date(start) : undefined,
    endDate: end ? new Date(end) : undefined,
  }

  if (formData.value.type === 'downpayment') {
    return baseForm
  }

  // Subtract downpayment total if there are downpayment invoices to include
  const downpaymentInvoicesToInclude = []
  let downpaymentTotal = currency(0)
  if (['partialpayment', 'fullpayment'].includes(formData.value.type)) {
    const downpaymentInvoices = offer.value.invoices.filter(i => i.type === 'downpayment' && !i.downpaymentDeductedInvoiceId && !i.cancelledAt && i.status === 'paid').sort((a, b) => b.totalAmount - a.totalAmount)

    for (const invoice of downpaymentInvoices) {
    // For now we only include the downpayment that are lower than the total amount of the invoice
      if (downpaymentTotal.add(invoice.totalAmount).value <= selectedInvoiceToOfferPositionsAndTotalAmount.value.totalAmount) {
        downpaymentInvoicesToInclude.push(invoice)
        downpaymentTotal = downpaymentTotal.add(invoice.totalAmount)
      }
    }
  }

  return {
    ...baseForm,
    totalAmount: currency(selectedInvoiceToOfferPositionsAndTotalAmount.value.totalAmount).subtract(downpaymentTotal.value).value,
    offerPositions: selectedInvoiceToOfferPositionsAndTotalAmount.value.selectedPositions,
    downpaymentInvoices: downpaymentInvoicesToInclude.map(({ id }) => ({ id })),
    customPositions: customPositions.value,
    extraDaysPositions: extraDaysPositions.value,
  }
}

async function submitInvoice() {
  isCreatingInvoice.value = true
  const invoiceToCreate = createInvoiceToCreate()

  try {
    if (invoiceToCreate) {
      if (invoiceToCreate.type === 'proformapayment') {
        await Promise.all([
          createProforma(),
          offer.value?.proforma ? undefined : createProformaUploadedAt.mutateAsync(invoiceToCreate),
        ])
      } else {
        await create.mutateAsync({ invoice: invoiceToCreate, shouldCreateInvoiceId: !shouldNotCreateInvoiceId.value })
      }
    }
  } finally {
    isCreatingInvoice.value = false
  }
}

const leftAmountToInvoice = computed(() => {
  if (!invoiceToOfferPositionRelations.value || !offer.value) {
    return formatNumberToString(0)
  }

  let totalAmountToInvoice = currency(0)
  for (const position of invoiceToOfferPositionRelations.value) {
    if (position.paymentStatus !== 'Invoiced') {
      const children = position.children?.filter(child => child.paymentStatus !== 'Invoiced')

      if (children) {
        for (const child of children) {
          const grandChildren = child.children?.filter(child => child.paymentStatus !== 'Invoiced')

          if (grandChildren) {
            for (const grandChild of grandChildren) {
              totalAmountToInvoice = totalAmountToInvoice.add(calculateInvoiceToOfferPositionPrice(grandChild).value)
            }
          } else {
            totalAmountToInvoice = totalAmountToInvoice.add(calculateInvoiceToOfferPositionPrice(child).value)
          }
        }
      } else {
        totalAmountToInvoice = totalAmountToInvoice.add(calculateInvoiceToOfferPositionPrice(position).value)
      }
    }
  }

  const downpaymentAndCreditNoteTotal = offer.value.invoices
    .filter(i => (i.type === 'downpayment' && !i.downpaymentDeductedInvoiceId && !i.cancelledAt) || (i.type === 'creditNote' && !['paid', 'cancelled'].includes(i.status)))
    .reduce((acc, curr) => acc.add(curr.totalAmount), currency(0))

  return formatNumberToString(totalAmountToInvoice.subtract(downpaymentAndCreditNoteTotal.value).value)
})

const totalInternalCosts = computed(() => {
  const summedInternalCost = offer.value?.internalCostPositions.reduce((acc, curr) => acc + curr.price, 0)

  return formatNumberToString(summedInternalCost ?? 0)
})

const startOfCustomPositionIndex = computed(() => {
  if (!offer.value) {
    return 0
  }

  const sortedPositions = offer.value.positions.filter(p => p.groupInOffer === 0).sort(compareOfferPositionsByGroupAndIndex)

  if (sortedPositions[sortedPositions.length - 1]) {
    return sortedPositions[sortedPositions.length - 1].indexInOffer + 1
  }

  return 0
})

function createCustomPosition(type: CreateInvoiceCustomOrCreditNotePositionType) {
  if (!formData.value || type === 'creditNote') {
    return
  }

  const indexInOffer = startOfCustomPositionIndex.value + customPositions.value.length
  const isServiceProject = offer.value?.type === 'service-project'
  const position: CreateInvoiceCustomPosition = {
    id: createId(),
    title: '',
    quantity: 1,
    pricePerUnit: 0,
    unit: 'flatRate',
    discountRate: 0,
    type,
    indexInOffer,
    groupInOffer: 0,
    isHidden: false,
    groupType: 'InvoiceRelated',
    showPositionInOfferPdf: !isServiceProject,
    includePositionToInvoice: !isServiceProject,
  }

  customPositions.value.push(position)
}

const unrenderFormKitItemsToOverWrite = ref(false)
function deletePosition(idx: number) {
  if (!formData.value) {
    return
  }
  const newPositions = customPositions.value.slice()
  newPositions.splice(idx, 1)

  unrenderFormKitItemsToOverWrite.value = true
  nextTick(() => {
    if (!formData.value) {
      return
    }
    customPositions.value = newPositions
    unrenderFormKitItemsToOverWrite.value = false
  })
}

const finalInvoice = computed(() => {
  if (!offer.value) {
    return undefined
  }

  return offer.value.invoices.find(i => i.type === 'fullpayment' && i.status !== 'cancelled')
})

const isInvoiceFilledIn = computed(() => {
  if (invoiceFormType.value === 'downpayment') {
    return formData.value ? formData.value.totalAmount > 0 : false
  }
  return checkedPositionKeys.value.length > 0 || customPositions.value.length > 0 || extraDaysPositions.value.length > 0
})

/** Get customer language */
const customerLocale = computed(() => getLocale(offer.value?.customer.language))

/** Download and show preview PDF */
const previewPdfUrl = computed(() => urlWithLocale('/api/pdf/invoice/preview', customerLocale.value))
const proformaPdfUrl = computed(() => urlWithLocale('/api/pdf/invoice/preview?previewMarkDisabled=true', customerLocale.value))

const { fetch: createPreview, isLoading: isLoadingPreviewPdf } = useOpenDocumentInNewTab(previewPdfUrl, () => createInvoiceToCreate())
const { fetch: createProforma } = useOpenDocumentInNewTab(proformaPdfUrl, () => createInvoiceToCreate())

const actualRentalDuration = computed(() => {
  if (!offer.value?.obligationEndsAt) {
    return undefined
  }

  if (offer.value.obligationActuallyEndedAt) {
    return calculateRentalDuration({ obligationEndsAt: offer.value.obligationActuallyEndedAt, obligationStartsAt: offer.value.obligationStartsAt })
  }

  return calculateRentalDuration({ obligationEndsAt: offer.value.obligationEndsAt, obligationStartsAt: offer.value.obligationStartsAt })
})

const machineryPosition = computed(() => offer.value?.positions.find(p => p.type === 'machinery'))
const machineryId = computed(() => machineryPosition.value?.machineryId)
const machineryAccessoryIds = computed(() => offer.value?.positions.filter(p => ['machineryAccessory', 'machineryAccessoryCategory'].includes(p.type)).map(p => p.machineryAccessoryId) ?? [])

const notReturnablePositions = computed(() => offer.value?.positions.filter(p => p.isNotReturnable).map(p => p.title) ?? [])

// Invoice position extends rental days
function addExtraDaysPosition(payload: CreateInvoiceExtraDaysPosition) {
  const newExtraDaysPositions = [...extraDaysPositions.value, payload].sort(compareOfferPositionsByGroupAndIndex)

  extraDaysPositions.value = newExtraDaysPositions
  closeExtendPositionRentalPopup()
}

function deleteExtraDaysPosition(idx: number) {
  const newExtraDaysPositions = extraDaysPositions.value.slice()
  newExtraDaysPositions.splice(idx, 1)

  extraDaysPositions.value = newExtraDaysPositions
}

const countOfLogisticTasks = computed(() => {
  const countTotal = offer.value?.logisticsTasks.filter(task => !task.isCancelled) ?? []
  return {
    countTotal,
    countByCustomer: countTotal.filter(task => task.doesCustomerDoTask) ?? [],
  }
})

watch(invoicePeriod, (newDuration, oldDuration) => {
  if (!newDuration || (oldDuration && newDuration[0] === oldDuration[0] && newDuration[1] === oldDuration[1])) {
    return
  }

  if (!invoiceToOfferPositionRelationsRows.value) {
    checkedPositionKeys.value = []
    return
  }

  const newSelectedPositionKeys = getAllNotInvoicedPositions(invoiceToOfferPositionRelationsRows.value.filter(doesOfferPositionNeedDelivery))
    .filter((p) => {
      const { invoicedDate } = p

      if (invoicedDate) {
        return isWithinInterval(invoicedDate, { start: newDuration[0], end: newDuration[1] })
      }

      return false
    })
    .map(p => getRowKey(p))

  checkedPositionKeys.value = newSelectedPositionKeys
})

const isMobileDevice = useIsMobileScreen()

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <ThePopup
    v-if="positionReturnDetail && returnedTaskCuidByPositionId"
    :show="Boolean(positionReturnDetail)"
    :title="`Details zur Rücknahme - ${positionReturnDetail.title}`"
    @close="closePositionReturnDetailPopup"
  >
    <LogisticsIssuanceDetailsPage :cuid="returnedTaskCuidByPositionId[positionReturnDetail.id] " :default-opened-positions="[positionReturnDetail.id]" type="collected" />
  </ThePopup>

  <InvoiceCreationExtendPositionRentalPopup
    v-if="extendPositionRentalPayload"
    :is-shown="Boolean(extendPositionRentalPayload)"
    :invoice-offer-position="extendPositionRentalPayload"
    @close="closeExtendPositionRentalPopup"
    @submit="addExtraDaysPosition"
  />

  <ThePopup
    v-if="updatePositionPayload"
    :show="Boolean(updatePositionPayload)"
    :title="updatePositionPopupTitle"
    @close="closeUpdatePositionPopup"
  >
    <InvoicePositionUpdateForm
      :row="updatePositionPayload"
      :insurance-id="insurancePositionsByMachinery[updatePositionPayload.id]?.id"
      :offer-type="offer?.type ? offer.type as OfferType : undefined"
      @close="closeUpdatePositionPopup"
    />
  </ThePopup>>

  <TheConfirmPopup
    v-if="popupConfirmDiscountPosition"
    action-button-label="Abziehen"
    @confirm="handleDiscount(popupConfirmDiscountPosition)"
    @close="popupConfirmDiscountPosition = null"
  >
    Die Position wird vollständig von der Rechnung abgezogen.
  </TheConfirmPopup>

  <ThePopup v-if="payload" width="90%" :show="Boolean(payload)" title="Rechnungen erstellen" @close="closeInvoicePopup">
    <h1 class="Heading">
      Auftrag
    </h1>
    <div class="w-full mt-2 px-2 text-gray-500 italic text-right">
      Noch nicht abgerechneter Betrag: {{ leftAmountToInvoice }} EUR
    </div>
    <TableView
      :data="offer ? [offer] : []"
      :columns="columns"
      :is-loading="isLoadingOffer"
      :action-buttons="() => ['update']"
      :is-paginated="false"
      :show-summary="false"
      @update="handleOpenOfferPage"
    />
    <OfferStatusTimeline v-if="offer" class="pt-5 md:overflow-x-scroll" :offer-id="offer.id" :horizontal="!isMobileDevice" />

    <div class="flex flex-col gap-y-2 my-5">
      <n-alert v-if="offer?.internalCostPositions.length" type="warning">
        Zu diesem Auftrag existieren interne Kosten in Höhe von {{ totalInternalCosts }} EUR.
        <span class="text-blue-500 cursor-pointer hover:underline" @click="openInternalCostPopup.open({ id: offer.id, type: 'offer' })">Hier klicken um Details zu internen Kosten zu sehen</span>.
      </n-alert>
      <n-alert v-else type="info">
        Zu diesem Auftrag sind keine internen Kosten bekannt.
      </n-alert>

      <n-alert v-if="notReturnablePositions.length" type="warning" class="mt-5">
        {{ notReturnablePositions.join(', ') }} {{ notReturnablePositions.length > 1 ? 'sind' : 'ist' }} als nicht rücknahmefähig markiert worden (z.B. wenn nicht zurückgekommen).
      </n-alert>

      <n-alert type="info">
        Für diesen Auftrag wurden {{ countOfLogisticTasks.countTotal.length }} Lieferungen/Abholungen geplant, davon wurden {{ countOfLogisticTasks.countByCustomer.length }} vom Kunden selbst übernommen.
      </n-alert>

      <n-alert v-if="offer" type="info">
        Für diesen Auftrag wurde bereits eine Bezahlung von {{ offer.paidAmountWithoutInvoice.toLocaleString('de-DE') }} EUR hinterlegt.
      </n-alert>

      <div v-if="offer?.type === 'rental' && offer.obligationEndsAt">
        <n-alert type="info">
          <div v-if="!offer.obligationActuallyEndedAt">
            Der Auftrag wurde noch nicht abgemeldet.
            Das ursprünglich vereinbarte Ende ist der {{ useDateAsString(offer.obligationEndsAt, 'dd.MM.yy') }} (Dauer: {{ actualRentalDuration }}  Tage).
          </div>

          <div v-else>
            Der Auftrag wurde zum {{ useDateAsString(offer.obligationActuallyEndedAt, 'dd.MM.yy') }} abgemeldet (Dauer: {{ actualRentalDuration }} Tage).
            Das ursprünglich vereinbarte Ende war der {{ useDateAsString(offer.obligationEndsAt, 'dd.MM.yy') }} (Dauer: {{ calculateRentalDuration({ obligationEndsAt: offer.obligationEndsAt, obligationStartsAt: offer.obligationStartsAt }) }}  Tage).
          </div>

          <div v-for="position in offer.positions" :key="position.id">
            <div v-if="position.type === 'machinery' && !!position.machineryId" class="py-1">
              <InvoiceCreationMachineryPriceInformation :position="position" :customer-id="offer.customerId" />
            </div>
          </div>
        </n-alert>
      </div>

      <n-alert
        v-if="offer?.type === 'service-project' && machineryPosition && machineryPosition.deliveredAt"
        type="info"
      >
        Die Maschine {{ machineryPosition.machineryId }} wurde am {{ useDateAsString(machineryPosition.deliveredAt, 'dd.MM.yy') }} geliefert
        <span v-if="machineryPosition.terminatedDate">und am {{ useDateAsString(machineryPosition.terminatedDate, 'dd.MM.yy') }} abgemeldet</span>
      </n-alert>
    </div>

    <h1 class="Heading mt-5">
      Frühere Rechnungen
    </h1>
    <InvoicePage title="" :is-loading="isLoadingOffer" :additional-filters="{ offerId: payload.offerId, type: { not: { in: ['cancellation', 'creditNoteCancellation'] } } }" :show-color-coding="true" :show-sum-of-invoices="true" />

    <n-collapse class="mt-5">
      <n-collapse-item>
        <template #header>
          <span class="Heading text-text">
            Kommentare
          </span>
        </template>
        <div class="w-full">
          <CommentList :id="payload.offerId" type="Offer" is-read-only class="grow" />
        </div>
      </n-collapse-item>
    </n-collapse>
    <n-collapse class="mt-5">
      <n-collapse-item>
        <template #header>
          <span class="Heading text-text">
            Kalender
          </span>
        </template>
        <CalendarPage
          v-if="machineryId"
          :additional-filters-machinery="{ id: machineryId }"
          show-calendar-for="machinery"
          :allow-offer-creation-mode="false"
          :show-machinery-filters="false"
          :start-time="offer?.obligationStartsAt"
          :days-to-render="actualRentalDuration ? actualRentalDuration + 30 : undefined"
          :show-settings="false"
        />
        <CalendarPage
          v-if="machineryAccessoryIds"
          :additional-filters-machinery-accessory="{ id: { in: machineryAccessoryIds } }"
          show-calendar-for="machineryAccessories"
          :allow-offer-creation-mode="false"
          :show-machinery-accessory-filters="false"
          :start-time="offer?.obligationStartsAt"
          :days-to-render="actualRentalDuration ? actualRentalDuration + 30 : undefined"
          :show-settings="false"
        />
      </n-collapse-item>
    </n-collapse>

    <n-divider />

    <div v-if="finalInvoice">
      Schlussrechnung ist bereits erstellt
    </div>
    <div v-else>
      <h1 class="Heading">
        Rechnungs-Erstellung:
      </h1>
      <p>Wählen Sie den Rechungstyp, den Sie erstellen wollen. Geben Sie dann die restlichen notwendigen Daten an und klicken Sie anschließend auf erstellen.</p>
      <p v-if="!areMachinesAndAccessoriesReturned && !isSale" class="mb-1">
        <span class="font-bold">Achtung:</span> Schlussrechnung kann nicht erstellt werden, da noch nicht alle (Anbau-)Geräte zurückgegeben wurden.
      </p>

      <div v-if="isLoadingOffer" class="w-full h-full flex justify-center items-center my-3">
        <n-spin size="large" />
      </div>

      <FormKit
        v-else-if="formData"
        v-model="formData"
        type="form"
        :plugins="[formkitPlugins]"
        :actions="false"
        :disabled="isCreatingInvoice"
        @submit="submitInvoice"
      >
        <FormKit
          id="type"
          type="select"
          :options="invoiceTypeOptions"
          validation="required"
          label="Rechnungstyp"
          placeholder="Bitte auswählen"
        />

        <div class="w-full flex flex-col md:grid md:grid-cols-2 gap-2 items-start">
          <FormKitDate
            id="invoicedAt"
            label="Rechnungsdatum"
            validation="required"
          />
          <div v-if="offer && ((formData.type === 'partialpayment' && offer.type === 'rental') || offer.type === 'special')" class="-translate-y-3.5 w-full">
            <p class="font-bold mb-2">
              Abrechnungszeitraum
            </p>
            <TheDurationPicker
              v-model="invoicePeriod"
              default-duration-days="monthToDate"
              :default-duration-value="defaultInvoicePeriod"
              class="mb-2"
            />
          </div>
        </div>

        <div v-if="formData.type">
          <FormKit
            v-if="formData.type === 'downpayment'"
            id="totalAmount"
            type="number"
            validation="required"
            label="Anzahlungsbetrag (EUR)"
            placeholder="Bitte auswählen"
            step="0.01"
          />
          <div v-else>
            <div class="flex items-center gap-2">
              <p class="font-bold">
                Positionsdaten
              </p>
              <n-button @click="handleOpenOfferPage">
                <template #icon>
                  <Icon name="material-symbols:settings-outline" />
                </template>
                Bearbeiten
              </n-button>
            </div>
            <span>Ausgewählter Gesamtpreis: {{ selectedInvoiceToOfferPositionsAndTotalAmount.totalAmount }}</span>
            <TableView
              :key="formData.type"
              class="mt-4"
              :data="invoiceToOfferPositionRelationsRows"
              :columns="invoiceToOfferPositionsForType.columns"
              :action-buttons="invoicePositionActionButtons"
              :is-loading="isLoadingInvoiceToOfferPositionRelations"
              :single-line="false"
              :row-key="getRowKey"
              :checked-row-keys="checkedPositionKeys"
              :show-summary="false"
              :is-paginated="false"
              @update:checked-row-keys="keys => checkedPositionKeys = keys"
              @update="openUpdatePositionPopup"
              @update-price="openUpdatePositionPopup"
              @update-discount="openUpdatePositionPopup"
              @mark-as-visible="row => handleComment(row)"
              @discount="row => popupConfirmDiscountPosition = row"
              @invoice="row => handleDiscount(row, true)"
              @open-collected-popup="openPositionReturnDetailPopup"
              @extend-position-rental-days="openExtendPositionRentalPopup"
            />

            <div v-if="extraDaysPositions.length" class="my-3">
              <p class="font-bold">
                Zusätzliche Miettage für Positionen
              </p>
              <InvoiceCreationExtraDaysPositionList
                v-model="extraDaysPositions"
                type="invoice"
                class="mt-6"
                @delete="deleteExtraDaysPosition"
              />
            </div>
            <InvoiceCreationPositionInput
              v-if="!unrenderFormKitItemsToOverWrite"
              v-model="customPositions"
              type="invoice"
              class="mt-6"
              :allow-negative-value="true"
              @delete="deletePosition"
              @create-custom-position="createCustomPosition"
            />
          </div>
        </div>

        <div class="flex flex-col md:flex-row flex-2 md:justify-between mt-2">
          <div class="flex flex-col">
            <FormKit
              id="includeVAT"
              :value="true"
              type="checkbox"
              label="Mehrwertsteuer berücksichtigen"
            />
            <FormKit
              v-model="shouldNotCreateInvoiceId"
              type="checkbox"
              label="Keine Rechnung für den Kunden erstellen"
              :disabled="Boolean(selectedInvoiceToOfferPositionsAndTotalAmount.totalAmount || formData.totalAmount)"
            />
            <FormKit
              id="createElectronicInvoice"
              type="checkbox"
              :label="$t('invoice.creation.createElectronicInvoice.toggle')"
            />
          </div>
          <n-button :style="{ width: '250px' }" :disabled="!(isInvoiceFilledIn || shouldNotCreateInvoiceId)" :loading="isLoadingPreviewPdf" @click="createPreview">
            Vorschau anzeigen
          </n-button>
        </div>
        <TheDevOnlyNiceJson :checked-position-keys="checkedPositionKeys" />

        <n-button
          type="primary"
          prefix-icon="check"
          size="large"
          :loading="isCreatingInvoice"
          :disabled="isCreatingInvoice"
          class="p-6"
          @click="submitInvoice"
        >
          Erstellen
        </n-button>
      </FormKit>
    </div>
  </ThePopup>
</template>

<style scoped>
.Heading {
  @apply text-lg font-bold py-1
}
</style>
