<script setup lang="ts">
import { createInput } from '@formkit/vue'
import TiptapEditor from './CreateInputBase/Tiptap/Editor.vue'

const editor = createInput(TiptapEditor, { props: ['placeholder', 'readonly', 'isMentionEnabled', 'height'] })
</script>

<template>
  <FormKit :type="editor" />
</template>
