<script setup lang="ts">
import type { CreateQuickSale, FilePath } from '~/types'

defineProps<{ payload: CreateQuickSale }>()
const emit = defineEmits<{ (e: 'save', payload: CreateQuickSale & { language: string }): void }>()

const hasCustomTemplate = ref<boolean>(false)
const language = ref<string>('de')

interface PayloadData extends Omit<CreateQuickSale, 'templateFile'> {
  // Since FormKitFileDropzone returns an array of files
  templateFile?: FilePath[]
}

function handleSubmit(data: PayloadData) {
  const payload: CreateQuickSale & { language: string } = {
    ...data,
    templateFile: data.templateFile && data.templateFile.length > 0 ? data.templateFile[0] : undefined,
    language: language.value,
  }
  emit('save', payload)
}
</script>

<template>
  <FormKit :value="payload" type="form" :submit-label="$t('button.create')" @submit="handleSubmit">
    <FormKit
      name="price"
      type="number"
      number="float"
      step="0.01"
      :label="$t('quickSale.creationForm.price.label')"
    />
    <FormKit
      name="description"
      type="text"
      :label="$t('quickSale.creationForm.description.label')"
      validation="required"
    />
    <FormKit
      name="additionalInformation"
      type="textarea"
      :label="$t('quickSale.creationForm.additionalInformation.label')"
    />
    <FormKit
      v-model="hasCustomTemplate"
      type="checkbox"
      :label="$t('quickSale.creationForm.customTemplate.label')"
    />
    <div
      v-if="hasCustomTemplate"
      class="text-sm space-y-2"
    >
      <p class="font-bold">
        {{ $t('quickSale.creationForm.templateNotes.title') }}
      </p>
      <ul class="list-disc list-inside space-y-1">
        <li>{{ $t('quickSale.creationForm.templateNotes.item1') }}</li>
        <li>{{ $t('quickSale.creationForm.templateNotes.item2') }}</li>
        <li>
          {{ $t('quickSale.creationForm.templateNotes.item3') }}
          <ul class="list-disc list-inside ml-4 space-y-1">
            <li>{{ $t('quickSale.creationForm.templateNotes.topMargin') }}</li>
            <li>{{ $t('quickSale.creationForm.templateNotes.bottomMargin') }}</li>
            <li>{{ $t('quickSale.creationForm.templateNotes.leftMargin') }}</li>
            <li>{{ $t('quickSale.creationForm.templateNotes.rightMargin') }}</li>
          </ul>
        </li>
      </ul>
      <FormKitFileDropzone
        name="templateFile"
        validation="required|max:1"
        accept="application/pdf"
        label=""
      />
    </div>
    <FormKit
      name="includeVAT"
      type="checkbox"
      :label="$t('quickSale.creationForm.includeVAT.label')"
    />
    <QuickSalePdfLanguageSelector v-model="language" />
  </FormKit>
</template>
