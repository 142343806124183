import { z } from 'zod'
import { zObject } from '~/server/schemas/zObject'

export const idSchema = z.string().min(1).max(36)
export const idObjectSchema = zObject({
  id: idSchema,
})
export const idObjectDuplicateSchema = zObject({
  id: idSchema,
  customId: idSchema.optional(),
})

export const emailSchema = z.coerce.string().email()
export const emailObjectSchema = zObject({
  email: emailSchema,
})

// Needed for updated file approach, where files are stored in their own table. Introduced in https://github.com/sidestream-tech/hanselmann-os/pull/2359
export const pathInObjectSingleFileSchema = zObject({ path: z.string() })
export const pathInObjectFilesSchema = pathInObjectSingleFileSchema.array().default(() => [])
