import revive_payload_client_3SJMYoa1c4 from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_u9LHGEtvHF from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ukc02U3RLI from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import payload_client_qDsvBgnWdP from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_934nveyOEL from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NAww6NCbmb from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_aowCBejkMO from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_TgWj010bce from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.12.3_@parcel+watcher@2.4.1_@type_e5c9dc9e393d1f529b7fde574f3e6ff2/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_NZGkD0JFlv from "/app/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@4.18.1_vue@3.4.32_typescript@5.6.3_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_w8vTYTHEf1 from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.17_magicast@0.3.5_rollup@4.18.1_webpack@5.92.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import switch_locale_path_ssr_qpduARRPwo from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.18.1__eda70bc5b5fa68b78c621d65ee07c46c/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ZFX3ZxpGeY from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.18.1__eda70bc5b5fa68b78c621d65ee07c46c/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import plugin_9YRvSeSb0N from "/app/node_modules/.pnpm/@nuxt+icon@1.8.2_magicast@0.3.5_rollup@4.18.1_vite@5.3.4_@types+node@22.10.1_terser@5.3_3336f93a65e8f5293e42e3b8be66b669/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import colorMode_zN3pdpuqzc from "/app/node_modules/.pnpm/@bg-dev+nuxt-naiveui@2.0.0-rc.3_magicast@0.3.5_rollup@4.18.1_vue@3.4.32_typescript@5.6.3_/node_modules/@bg-dev/nuxt-naiveui/dist/runtime/plugins/colorMode.js";
import plugin_f6dMrvA91Y from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.10.1_@electric-sql+pglite@0.2.13_encoding@0.1.13_magicast@0.3.5_n_a964110d943eed4ef79a7beecd7324f1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import ccm_client_BPpOUeFogE from "/app/plugins/ccm.client.ts";
import chunk_reload_client_BG2S3BHLQY from "/app/plugins/chunk-reload.client.ts";
import signaturePad_StdQn7RF4b from "/app/plugins/signaturePad.ts";
import trpcClient_M8UiGTyzsx from "/app/plugins/trpcClient.ts";
import vue_query_wrmMkNpEpe from "/app/plugins/vue-query.ts";
export default [
  revive_payload_client_3SJMYoa1c4,
  unhead_u9LHGEtvHF,
  router_Ukc02U3RLI,
  sentry_client_shVUlIjFLk,
  payload_client_qDsvBgnWdP,
  navigation_repaint_client_934nveyOEL,
  check_outdated_build_client_NAww6NCbmb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_aowCBejkMO,
  plugin_TgWj010bce,
  plugin_client_NZGkD0JFlv,
  plugin_w8vTYTHEf1,
  switch_locale_path_ssr_qpduARRPwo,
  i18n_ZFX3ZxpGeY,
  formkitPlugin_pZqjah0RUG,
  plugin_9YRvSeSb0N,
  colorMode_zN3pdpuqzc,
  plugin_f6dMrvA91Y,
  ccm_client_BPpOUeFogE,
  chunk_reload_client_BG2S3BHLQY,
  signaturePad_StdQn7RF4b,
  trpcClient_M8UiGTyzsx,
  vue_query_wrmMkNpEpe
]