<script setup lang="ts">
const { t: $t } = useI18n()

import type { OtherInvoiceRecipientUpsert, PaymentConditions, TaxRates } from '~/types'

const { openOtherInvoiceRecipientCreateOrEditPopup: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

// Save the created id in the router query
const routerNameToSaveCreatedId = computed(() => popupData.value?.mode === 'create' ? popupData.value.routerNameToSaveCreatedId : undefined)
const createdIdValue = routerNameToSaveCreatedId.value
  ? useRouteQuery<string>(routerNameToSaveCreatedId.value)
  : undefined
function closePopup(createdId?: string) {
  if (createdId && createdIdValue) {
    createdIdValue.value = createdId
  }
  closePopup_()
}

const id = computed(() => popupData.value?.mode === 'update' ? popupData.value.id : undefined)
const queryOtherInvoiceRecipient = useQuery().otherInvoiceRecipient
const { data: otherInvoiceRecipient } = queryOtherInvoiceRecipient.byId(id)

const payload = ref<OtherInvoiceRecipientUpsert | undefined>(undefined)
watch(otherInvoiceRecipient, (newValue) => {
  if (payload.value || !popupData.value) {
    return
  }
  if (popupData.value.mode === 'create') {
    payload.value = {
      customerId: popupData.value.customerId,
      name: '',
      nameTwo: null,
      nameThree: null,
      address: '',
      fullAddress: {
        label: '',
      },
      email: '',
      telephone: '',
      telefax: null,
      iban: '',
      taxId: '',
      vatID: '',
      taxRate: '0',
      paymentCondition: 'instantNet' as const,
      language: 'DE',
      country: 'DE',
    }
  } else if (newValue) {
    payload.value = {
      ...newValue,
      fullAddress: newValue.fullAddress ?? {
        label: newValue.address,
      },
      language: newValue.language,
      taxRate: newValue.taxRate as TaxRates,
      paymentCondition: newValue.paymentCondition as PaymentConditions,
    }
  }
}, { immediate: true })

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const upsert = useMutation({
  mutationFn: $trpc.otherInvoiceRecipient.upsert.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('customer.otherInvoiceRecipient.notification.update.error') }),
  onSuccess: async (newInvoiceRecipient) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['otherInvoiceRecipient'] }),
      queryClient.invalidateQueries({ queryKey: ['customer'] }),
    ])

    if (routerNameToSaveCreatedId.value) {
      closePopup(newInvoiceRecipient.id)
    } else {
      closePopup()
    }

    notification.success({ title: $t('customer.otherInvoiceRecipient.notification.update.success'), duration: 4500 })
  },
})
</script>

<template>
  <ThePopup v-if="payload" :show="true" title="Anlage Abweichender Rechnungsempfänger" width="1050px" @close="closePopup">
    <CustomerOtherInvoiceRecipientCreateOrEditForm
      :payload
      @upsert="upsert.mutate"
    />
  </ThePopup>
</template>
