const FALLBACK_FILE_ICON = 'lucide:file'

const EXT_TO_ICON_NAME: Record<string, string> = {
  '.pdf': 'carbon:document-pdf',
  '.rtf': 'carbon:document',
  '.xls': 'carbon:xls',
  '.doc': 'carbon:document',
  '.xml': 'carbon:document',
  '.zip': 'carbon:zip',
  '.jpeg': 'carbon:image',
  '.png': 'carbon:image',
  '.txt': 'carbon:document',
}

export default (ext: string) => {
  return EXT_TO_ICON_NAME[ext] ?? FALLBACK_FILE_ICON
}
