<script setup lang="ts">
import type { RouterOutput } from '~/types'

const props = defineProps<{
  mentionListId: string
  items: RouterOutput['mention']['byQuery']
  command: ({ id, label }: { id: string, label?: string | null }) => void
}>()

const selectedIndex = ref(0)
watch(props.items, () => selectedIndex.value = 0)

const upHandler = () => selectedIndex.value = ((selectedIndex.value + props.items.length) - 1) % props.items.length
const downHandler = () => selectedIndex.value = (selectedIndex.value + 1) % props.items.length
const enterHandler = () => selectItem(selectedIndex.value)

function onKeyDown({ event }: { event: KeyboardEvent }) {
  if (event.key === 'ArrowLeft') {
    upHandler()
    return true
  }
  if (event.key === 'ArrowRight') {
    downHandler()
    return true
  }
  if (event.key === 'Enter') {
    enterHandler()
    return true
  }
  return false
}

function selectItem(index: number) {
  const item = props.items[index]
  if (item) {
    props.command({ id: item.email, label: item.name })
  }
}

defineExpose({ onKeyDown })
</script>

<template>
  <ClientOnly>
    <Teleport :to="`#${mentionListId}`">
      <div class="border-t rounded-b bg-gray-50 p-2">
        <div v-if="items.length" class="flex flex-wrap items-center gap-1.5">
          <button
            v-for="(user, index) in items"
            :key="index"
            class="rounded px-2 py-1"
            :class="{ 'bg-primary-50': index === selectedIndex }"
            @click="selectItem(index)"
          >
            <div v-if="user.name" class="flex flex-col items-start gap-0.5">
              <span>
                {{ user.name }}
              </span>
              <span class="text-xs opacity-80">
                {{ user.email }}
              </span>
            </div>
            <span v-else>
              {{ user.email }}
            </span>
          </button>
        </div>
        <div v-else class="italic opacity-75">
          {{ $t('editor.mention.empty') }}
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>
