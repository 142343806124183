import type { PaginationProps } from 'naive-ui'
import { defu } from 'defu'
import { diff } from 'deep-object-diff'

export default (initialPaginationProps: Pick<PaginationProps, 'pageCount' | 'pageSize' | 'page'>) => {
  const paginationSettings = reactive(
    defu(
      initialPaginationProps,
      {
        pageSize: 20,
        showQuickJumper: true,
        size: 'large',
        showSizePicker: true,
        pageSizes: [2, 5, 10, 20, 50, 100],
        page: 1,
      } satisfies PaginationProps,
    ),
  )

  const serverSidePagination = computed(() => ({
    take: paginationSettings.pageSize,
    skip: (paginationSettings.page - 1) * paginationSettings.pageSize,
  }))

  const watchForPageCount = (itemCount: Ref<number>) => watch(() => [paginationSettings.pageSize, itemCount.value], (newValue) => {
    const [pageSize, itemCount] = newValue
    paginationSettings.pageCount = Math.ceil(itemCount / pageSize)
  }, { immediate: true })

  const watchForReset = (source: Ref<unknown>) => watch(source, (newSource, oldSource) => {
    if (checkDiff(newSource, oldSource)) {
      paginationSettings.page = 1
    }
  })

  const serverSidePaginationTableProps = {
    'isServerSidePaginated': true,
    'controlledPaginationSettings': paginationSettings,
    'onUpdate:page': (page: number) => {
      paginationSettings.page = page
    },
    'onUpdate:page-size': (pageSize: number) => {
      paginationSettings.pageSize = pageSize
    },
  } as const
  return { serverSidePaginationTableProps, serverSidePagination, watchForPageCount, watchForReset }
}

/** Returns `true` if two passed arguments are not deeply equal */
export function checkDiff(lhs: unknown, rhs: unknown): boolean {
  // Simple case
  if (lhs === rhs) {
    return false
  }

  // One of the values is nullish
  // We know that both cannot be null or undefined (otherwise lhs === rhs)
  if (lhs === null || rhs === null || lhs === undefined || rhs === undefined) {
    return true
  }

  // Two objects - very common case (includes arrays)
  if (typeof lhs === 'object' && typeof rhs === 'object') {
    return Object.keys(diff(lhs, rhs)).length > 0
  }

  // Catch-all (strings, numbers, booleans, etc.)
  return lhs !== rhs
}
