<script setup lang="ts">
import type { ApiMachineryAccessoryGetById } from '~/types'

defineProps<{ payload: ApiMachineryAccessoryGetById }>()
const emit = defineEmits<{ (e: 'save', data: ApiMachineryAccessoryGetById): void }>()
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    :submit-label="$t('button.save')"
    @submit="(data: ApiMachineryAccessoryGetById) => emit('save', data)"
  >
    <FormKit
      name="endCustomerSellingPrice"
      type="number"
      number="float"
      step="0.01"
      min="0.01"
      :label="$t('machinery.field.endCustomerSellingPrice.name', { currency: 'EUR' })"
    />
    <FormKit
      name="dealerSellingPrice"
      type="number"
      number="float"
      step="0.01"
      min="0.01"
      :label="$t('machinery.field.dealerSellingPrice.name', { currency: 'EUR' })"
    />
  </FormKit>
</template>
