<script setup lang="ts">
const { t: $t } = useI18n()

import type { DataTableRowKey } from 'naive-ui'
import { useRouteQuery } from '@vueuse/router'
import type { ApiMachineryAccessoryGetAllForOverview, MachineryAccessoryOverviewSelectOptions, MachineryAccessoryStatus } from '~/types'
import { machineryAccessoryCategorySelectOptions, machineryAccessoryTrailerTypeSelectOptions } from '~/translations'
import { machineryFems } from '~/server/schemas'

const props = withDefaults(defineProps<{
  showStatus?: MachineryAccessoryStatus[] | undefined
  actions?: ('showDetails' | 'connect' | 'addCosts')[]
  selectOptions?: MachineryAccessoryOverviewSelectOptions
  additionalFilters?: Record<string, unknown>
  pageSize?: number
  title?: string
  showScanButton?: boolean
  shouldExcludeBundledAccessories?: boolean
}>(), {
  actions: () => ['showDetails'],
  showStatus: undefined,
  selectOptions: undefined,
  additionalFilters: () => ({}),
  pageSize: 20,
  title: 'Lagertool-Übersicht',
  showScanButton: false,
  shouldExcludeBundledAccessories: false,
})
const emit = defineEmits<{ (e: 'updateCheckedRowKeys', payload: DataTableRowKey[]): void }>()

const { machineryAccessory: { columns, rowProps, fulltextSearch }, machineryAccessorySelectForSet } = useTableColumnConfigs()
const { fulltextSearchValue, where } = useFilterData(fulltextSearch)
const { machineryAccessory } = useQuery()

const machineryAccessoryFEMSelectOptions = machineryFems.options.map(v => ({ label: v, value: v }))

// filters
const onlyIncludeSoldMachineAccessories = useRouteQueryAsBoolean('onlyIncludeSoldMachineAccessories')
const onlyShowNotReturnableAccessories = useRouteQueryAsBoolean('onlyShowNotReturnableAccessories')
const onlyShowReleasedForOnlineSale = useRouteQueryAsBoolean('onlyShowReleasedForOnlineSale')

const selectedMachineryAccessoryCategories = useRouteQuery<string[] | null>('accessoryCategories', null)
const selectedMachineryAccessoryTrailerTypes = useRouteQuery<string[] | null>('trailerTypes', null)
const fem = useRouteQuery<string[] | null>('fem', null)
const minLiftingWeight = useRouteQueryAsNumber('minLiftingWeight')
const maxLiftingWeight = useRouteQueryAsNumber('maxLiftingWeight')
const minLengthInMillimeters = useRouteQueryAsNumber('minLengthInMillimeters')
const maxLengthInMillimeters = useRouteQueryAsNumber('maxLengthInMillimeters')
const weightInKilograms = useRouteQueryAsNumber('weightInKilograms')
const distanceInnerEdgeToInnerEdge = useRouteQueryAsNumber('distanceInnerEdgeToInnerEdge')
const distanceOuterEdgeToOuterEdge = useRouteQueryAsNumber('distanceOuterEdgeToOuterEdge')
const cubicMeters = useRouteQueryAsNumber('cubicMeters')
const retractionLugsWidth = useRouteQueryAsNumber('retractionLugsWidth')
const retractionLugsHeight = useRouteQueryAsNumber('retractionLugsHeight')
const retractionLugsDistanceInnerEdgeToInnerEdge = useRouteQueryAsNumber('retractionLugsDistanceInnerEdgeToInnerEdge')
const retractionLugsDistanceOuterEdgeToOuterEdge = useRouteQueryAsNumber('retractionLugsDistanceOuterEdgeToOuterEdge')
const drive = useRouteQuery<string | null>('drive', null)
const volt = useRouteQuery<string | null>('volt', null)
const wheelSize = useRouteQuery<string | null>('wheelSize', null)
const minWidthInMillimeters = useRouteQueryAsNumber('minWidthInMillimeters')
const maxWidthInMillimeters = useRouteQueryAsNumber('maxWidthInMillimeters')
const comment = useRouteQuery<string | null>('comment', null)
const productCode = useRouteQuery<string | null>('productCode', null)
const minHeightInMillimeters = useRouteQueryAsNumber('minHeightInMillimeters')
const maxHeightInMillimeters = useRouteQueryAsNumber('maxHeightInMillimeters')

// Show filters based on selected category
const { machineryAccessoryFieldsByCategory } = useFormOptionalConditions()
const showFilter = computed(() => {
  const categories = new Set(selectedMachineryAccessoryCategories.value ?? [])
  const noCategoriesSelected = categories.size === 0

  function isShown(field: keyof typeof machineryAccessoryFieldsByCategory): boolean {
    return noCategoriesSelected || machineryAccessoryFieldsByCategory[field].some(category => categories.has(category))
  }

  return {
    retractionLugsDistance: isShown('retractionLugsDistanceInnerEdgeToInnerEdge'),
    retractionLugsDimensions: isShown('retractionLugsWidth'),
    distance: isShown('distanceInnerEdgeToInnerEdge'),
    lengthInMillimeters: isShown('lengthInMillimeters'),
    widthInMillimeters: isShown('widthInMillimeters'),
    heightInMillimeters: isShown('heightInMillimeters'),
    weightInKilograms: isShown('weightInKilograms'),
    liftingWeight: isShown('liftingWeight'),
    productCode: isShown('productCode'),
    fem: isShown('fem'),
    drive: isShown('drive'),
    volt: isShown('volt'),
    wheelSize: isShown('wheelSize'),
    cubicMeters: isShown('cubicMeters'),
    trailerType: isShown('trailerType'),
  }
})

// Reset filters
function resetFilters() {
  selectedMachineryAccessoryCategories.value = null
  selectedMachineryAccessoryTrailerTypes.value = null
  fem.value = null
  minLiftingWeight.value = null
  maxLiftingWeight.value = null
  minLengthInMillimeters.value = null
  maxLengthInMillimeters.value = null
  weightInKilograms.value = null
  distanceInnerEdgeToInnerEdge.value = null
  distanceOuterEdgeToOuterEdge.value = null
  cubicMeters.value = null
  retractionLugsWidth.value = null
  retractionLugsHeight.value = null
  retractionLugsDistanceInnerEdgeToInnerEdge.value = null
  retractionLugsDistanceOuterEdgeToOuterEdge.value = null
  drive.value = null
  volt.value = null
  wheelSize.value = null
  minWidthInMillimeters.value = null
  maxWidthInMillimeters.value = null
  comment.value = null
  productCode.value = null
  minHeightInMillimeters.value = null
  maxHeightInMillimeters.value = null
}

const hideAccessoriesWithActiveOrders: Ref<boolean> = ref(false)

const machineryAccesoryWhere = computed(() => {
  const onlySoldMachineAccessoriesWhere = createPrismaSoldAtWhere(onlyIncludeSoldMachineAccessories.value)

  const accessoryWhere = {
    ...where.value,
    ...props.additionalFilters,
    ...onlySoldMachineAccessoriesWhere,
    isForeignAccessory: false,
    liftingWeight: {
      ...generatePrismaGreaterThanOrEqualWhere(minLiftingWeight.value),
      ...generatePrismaLessThanOrEqualWhere(maxLiftingWeight.value),
    },
    lengthInMillimeters: {
      ...generatePrismaGreaterThanOrEqualWhere(minLengthInMillimeters.value),
      ...generatePrismaLessThanOrEqualWhere(maxLengthInMillimeters.value),
    },
    weightInKilograms: generatePrismaGreaterThanOrEqualWhere(weightInKilograms.value),
    distanceInnerEdgeToInnerEdge: generatePrismaGreaterThanOrEqualWhere(distanceInnerEdgeToInnerEdge.value),
    distanceOuterEdgeToOuterEdge: generatePrismaGreaterThanOrEqualWhere(distanceOuterEdgeToOuterEdge.value),
    cubicMeters: generatePrismaGreaterThanOrEqualWhere(cubicMeters.value),
    retractionLugsWidth: generatePrismaGreaterThanOrEqualWhere(retractionLugsWidth.value),
    retractionLugsHeight: generatePrismaGreaterThanOrEqualWhere(retractionLugsHeight.value),
    retractionLugsDistanceInnerEdgeToInnerEdge: generatePrismaGreaterThanOrEqualWhere(retractionLugsDistanceInnerEdgeToInnerEdge.value),
    retractionLugsDistanceOuterEdgeToOuterEdge: generatePrismaGreaterThanOrEqualWhere(retractionLugsDistanceOuterEdgeToOuterEdge.value),
    drive: drive.value ? { contains: drive.value } : undefined,
    volt: volt.value ? volt.value : undefined,
    wheelSize: wheelSize.value ? wheelSize.value : undefined,
    widthInMillimeters: {
      ...generatePrismaGreaterThanOrEqualWhere(minWidthInMillimeters.value),
      ...generatePrismaLessThanOrEqualWhere(maxWidthInMillimeters.value),
    },
    comment: comment.value ? { contains: comment.value, ...postgreSQLCaseInsensitiveSearch() } : undefined,
    productCode: productCode.value ? { contains: productCode.value, ...postgreSQLCaseInsensitiveSearch() } : undefined,
    heightInMillimeters: {
      ...generatePrismaGreaterThanOrEqualWhere(minHeightInMillimeters.value),
      ...generatePrismaLessThanOrEqualWhere(maxHeightInMillimeters.value),
    },
    isPermanentlyNotAvailable: !!onlyShowNotReturnableAccessories.value,
    isReleasedForOnlineSale: onlyShowReleasedForOnlineSale.value ? true : undefined,
    ...(selectedMachineryAccessoryCategories.value?.length ? { category: { in: selectedMachineryAccessoryCategories.value } } : {}),
    ...(selectedMachineryAccessoryTrailerTypes.value?.length ? { trailerType: { in: selectedMachineryAccessoryTrailerTypes.value } } : {}),
    ...(fem.value?.length ? { fem: { in: fem.value } } : {}),
  }

  const statussesToShow = props.showStatus
  if (!statussesToShow) {
    return accessoryWhere
  }

  return {
    ...accessoryWhere,
    status: { in: statussesToShow },
  }
})

const { serverSidePaginationTableProps, serverSidePagination, watchForPageCount, watchForReset } = useTablePagination({ pageCount: 1, pageSize: props.pageSize })
const { data: accessoriesData, error: errorAccessories, isFetching: isFetchingAccessories } = machineryAccessory.allForOverview(machineryAccesoryWhere, undefined, serverSidePagination, hideAccessoriesWithActiveOrders, props.shouldExcludeBundledAccessories)

const accessories = computed(() => accessoriesData.value?.accessories ?? [])
watchForPageCount(computed(() => accessoriesData.value?.totalCount ?? 0))
watchForReset(machineryAccesoryWhere)

const showDetails = (a: ApiMachineryAccessoryGetAllForOverview) => navigateTo(`/machinery-accessory/${a.id}`)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const connectMachineryAccessoryToMachinery = useMutation({
  mutationFn: (data: { machineryAccessoryId: string, machineryId: string | null }) => $trpc.machineryAccessory.connectMachinery.mutate({ id: data.machineryAccessoryId, machineryId: data.machineryId }),
  onError: makeTrpcErrorToast(notification, { description: $t('machineryAccessory.notification.connectMachinery.error') }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
    ])
    closeConnectpopup()
    notification.success({ title: $t('machineryAccessory.notification.connectMachinery.success'), duration: 4500 })
  },
})

const { openInternalCostPopup } = useGlobalOpeners()
const { payload: connectPayload, open: openConnectPopup, close: closeConnectpopup } = usePopup<ApiMachineryAccessoryGetAllForOverview>()
const { payload: scanMachineryPayload, open: openscanMachineryPopup, close: closescanMachinerypopup } = usePopup<{ show: boolean }>()

const isSelectMode = computed(() => props.selectOptions !== undefined)
const currentColumns = computed(() => isSelectMode.value ? machineryAccessorySelectForSet(props.selectOptions).columns : columns)
const currentRowProps = computed(() => isSelectMode.value ? machineryAccessorySelectForSet(props.selectOptions).rowProps : rowProps)
</script>

<template>
  <div class="space-y-4">
    <ThePopup v-if="connectPayload" :show="Boolean(connectPayload)" width="90%" @close="closeConnectpopup">
      <ServiceMachineryConnectionOfMachineryAccessory
        :already-checked-row-keys="connectPayload.attachedMachinery?.id ? [connectPayload.attachedMachinery.id] : []"
        :compatible-machinery-type-ids="connectPayload.compatibleMachineryTypes.map(({ id }) => id)"
        @connect="machineryId => {
          //Without checking connectPayload ts throws an error
          if (connectPayload) {
            connectMachineryAccessoryToMachinery.mutate({ machineryAccessoryId: connectPayload.id, machineryId })
          }
        }"
      />
    </ThePopup>
    <MachineryAccessoryScanMachineryPopup
      v-if="scanMachineryPayload"
      v-model="fulltextSearchValue"
      :show="scanMachineryPayload.show"
      @close="closescanMachinerypopup"
    />

    <TheDataCard :title="title" :error="errorAccessories">
      <TableFilters v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
        <template #search>
          <n-button v-if="showScanButton" @click="openscanMachineryPopup({ show: true })">
            <template #icon>
              <Icon name="material-symbols:qr-code-scanner" />
            </template>
            Gerät Scannen
          </n-button>
          <n-collapse class="sm:block">
            <n-collapse-item>
              <template #header>
                <span class="text-lg">
                  Anbau-Geräte-spezifische Filter ausklappen
                </span>
              </template>
              <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                <div class="flex gap-1 flex-wrap">
                  <n-checkbox v-model:checked="onlyIncludeSoldMachineAccessories" type="checkbox" class="shrink-0">
                    Nur verkaufte Lagertools anzeigen
                  </n-checkbox>
                  <n-checkbox v-model:checked="onlyShowNotReturnableAccessories" type="checkbox" class="shrink-0">
                    Nur die nicht rücknahmefähigen Lagertools anzeigen
                  </n-checkbox>
                  <n-checkbox v-model:checked="onlyShowReleasedForOnlineSale" type="checkbox" class="shrink-0">
                    {{ $t('machineryAccessory.overview.onlyShowReleasedForOnlineSale') }}
                  </n-checkbox>
                  <n-checkbox v-model:checked="hideAccessoriesWithActiveOrders" type="checkbox" class="shrink-0">
                    Lagertool-Geräte mit einem aktiven Auftrag ausblenden
                  </n-checkbox>
                </div>
                <div class="grid grid-cols-1 sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-x-2 sm:gap-y-5">
                  <div>
                    <p class="font-semibold">
                      {{ $t('common.field.category.name') }}
                    </p>
                    <n-select
                      v-model:value="selectedMachineryAccessoryCategories"
                      :options="machineryAccessoryCategorySelectOptions"
                      multiple
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="showFilter.retractionLugsDistance">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.retractionLugsDistanceInnerEdgeToInnerEdge.name') }}
                    </p>
                    <n-input-number
                      v-model:value="retractionLugsDistanceInnerEdgeToInnerEdge"
                      :min="0"
                      :consistent-menu-width="false"
                      placeholder="50"
                      filterable
                      clearable
                    >
                      <template #prefix>
                        min.
                      </template>
                    </n-input-number>
                  </div>
                  <div v-if="showFilter.productCode">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.productCode.name') }}
                    </p>
                    <n-input
                      v-model:value="productCode"
                      :consistent-menu-width="false"
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="showFilter.liftingWeight">
                    <p class="font-semibold">
                      {{ $t('machinery.field.liftingWeight.name') }}
                    </p>
                    <div class="flex items-center">
                      <n-input-number
                        v-model:value="minLiftingWeight"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          min.
                        </template>
                      </n-input-number>
                      <n-input-number
                        v-model:value="maxLiftingWeight"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          max.
                        </template>
                      </n-input-number>
                    </div>
                  </div>
                  <div v-if="showFilter.retractionLugsDistance">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.retractionLugsDistanceOuterEdgeToOuterEdge.name') }}
                    </p>
                    <n-input-number
                      v-model:value="retractionLugsDistanceOuterEdgeToOuterEdge"
                      :min="0"
                      :consistent-menu-width="false"
                      placeholder="50"
                      filterable
                      clearable
                    >
                      <template #prefix>
                        min.
                      </template>
                    </n-input-number>
                  </div>
                  <div>
                    <p class="font-semibold">
                      {{ $t('comment.name', { count: 1 }) }}
                    </p>
                    <n-input
                      v-model:value="comment"
                      :consistent-menu-width="false"
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="showFilter.fem">
                    <p class="font-semibold">
                      {{ $t('receptionMachinery.field.fem.name') }}
                    </p>
                    <n-select
                      v-model:value="fem"
                      :options="machineryAccessoryFEMSelectOptions"
                      multiple
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="showFilter.retractionLugsDimensions">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.retractionLugsWidth.name') }}
                    </p>
                    <n-input-number
                      v-model:value="retractionLugsWidth"
                      :min="0"
                      :consistent-menu-width="false"
                      placeholder="50"
                      filterable
                      clearable
                    >
                      <template #prefix>
                        min.
                      </template>
                    </n-input-number>
                  </div>
                  <div v-if="showFilter.drive">
                    <p class="font-semibold">
                      {{ $t('machinery.field.drive.name') }}
                    </p>
                    <n-input
                      v-model:value="drive"
                      :consistent-menu-width="false"
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="showFilter.lengthInMillimeters">
                    <p class="font-semibold">
                      {{ $t('common.field.length.name') }}
                    </p>
                    <div class="flex items-center">
                      <n-input-number
                        v-model:value="minLengthInMillimeters"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          min.
                        </template>
                      </n-input-number>
                      <n-input-number
                        v-model:value="maxLengthInMillimeters"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          max.
                        </template>
                      </n-input-number>
                    </div>
                  </div>
                  <div v-if="showFilter.retractionLugsDimensions">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.retractionLugsHeight.name') }}
                    </p>
                    <n-input-number
                      v-model:value="retractionLugsHeight"
                      :min="0"
                      :consistent-menu-width="false"
                      placeholder="50"
                      filterable
                      clearable
                    >
                      <template #prefix>
                        min.
                      </template>
                    </n-input-number>
                  </div>
                  <div v-if="showFilter.volt">
                    <p class="font-semibold">
                      {{ $t('receptionMachinery.field.volt.name') }}
                    </p>
                    <n-input
                      v-model:value="volt"
                      :consistent-menu-width="false"
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="showFilter.widthInMillimeters">
                    <p class="font-semibold">
                      {{ $t('common.field.width.name') }}
                    </p>
                    <div class="flex items-center">
                      <n-input-number
                        v-model:value="minWidthInMillimeters"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          min.
                        </template>
                      </n-input-number>
                      <n-input-number
                        v-model:value="maxWidthInMillimeters"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          max.
                        </template>
                      </n-input-number>
                    </div>
                  </div>
                  <div v-if="showFilter.distance">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.distanceOuterEdgeToOuterEdge.name') }}
                    </p>
                    <n-input-number
                      v-model:value="distanceOuterEdgeToOuterEdge"
                      :min="0"
                      :consistent-menu-width="false"
                      placeholder="50"
                      filterable
                      clearable
                    >
                      <template #prefix>
                        min.
                      </template>
                    </n-input-number>
                  </div>
                  <div v-if="showFilter.wheelSize">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.wheelSize.name') }}
                    </p>
                    <n-input
                      v-model:value="wheelSize"
                      :consistent-menu-width="false"
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="showFilter.heightInMillimeters">
                    <p class="font-semibold">
                      {{ $t('common.field.height.name') }}
                    </p>
                    <div class="flex items-center">
                      <n-input-number
                        v-model:value="minHeightInMillimeters"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          min.
                        </template>
                      </n-input-number>
                      <n-input-number
                        v-model:value="maxHeightInMillimeters"
                        :min="0"
                        :consistent-menu-width="false"
                        placeholder="50"
                        filterable
                        clearable
                      >
                        <template #prefix>
                          max.
                        </template>
                      </n-input-number>
                    </div>
                  </div>
                  <div v-if="showFilter.distance">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.distanceInnerEdgeToInnerEdge.name') }}
                    </p>
                    <n-input-number
                      v-model:value="distanceInnerEdgeToInnerEdge"
                      :min="0"
                      :consistent-menu-width="false"
                      placeholder="50"
                      filterable
                      clearable
                    >
                      <template #prefix>
                        min.
                      </template>
                    </n-input-number>
                  </div>
                  <div v-if="showFilter.cubicMeters">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.cubicMeters.name') }}
                    </p>
                    <n-input-number
                      v-model:value="cubicMeters"
                      :min="0"
                      :consistent-menu-width="false"
                      placeholder="50"
                      filterable
                      clearable
                    >
                      <template #prefix>
                        min.
                      </template>
                    </n-input-number>
                  </div>
                  <div v-if="showFilter.weightInKilograms">
                    <p class="font-semibold">
                      {{ $t('common.field.weight.name') }}
                    </p>
                    <n-input-number
                      v-model:value="weightInKilograms"
                      :min="0"
                      :consistent-menu-width="false"
                      placeholder="50"
                      filterable
                      clearable
                    >
                      <template #prefix>
                        min.
                      </template>
                    </n-input-number>
                  </div>
                  <div v-if="showFilter.trailerType">
                    <p class="font-semibold">
                      {{ $t('machineryAccessory.field.trailerType.name') }}
                    </p>
                    <n-select
                      v-model:value="selectedMachineryAccessoryTrailerTypes"
                      :options="machineryAccessoryTrailerTypeSelectOptions"
                      multiple
                      filterable
                      clearable
                    />
                  </div>
                </div>
                <div class="flex items-end mt-4">
                  <n-button class="!bg-white" @click="resetFilters">
                    Alle Filter zurücksetzen
                  </n-button>
                </div>
                <p class="my-2">
                  Nicht alle Anbau-Geräte haben alle hier aufgelisteten Eigenschaften. Wenn Sie nach einer Eigenschaft suchen, die ein Anbau-Gerät nicht hat, wird dieses Anbau-Gerät herausgefiltert.
                </p>
              </div>
            </n-collapse-item>
          </n-collapse>
        </template>

        <TableView
          v-bind="serverSidePaginationTableProps"
          :key="String(isSelectMode)"
          :data="accessories"
          :columns="currentColumns"
          :action-buttons="() => isSelectMode ? [] : actions"
          :is-loading="isFetchingAccessories"
          :row-props="currentRowProps"
          :single-line="!isSelectMode"
          :row-key="row => row.id"
          :show-summary="!isSelectMode"
          :checked-row-keys="selectOptions?.selectedKeys ?? []"
          @show-details="showDetails"
          @connect="openConnectPopup"
          @add-costs="(row: ApiMachineryAccessoryGetAllForOverview) => openInternalCostPopup.open({ type: 'machineryAccessory', id: row.id })"
          @update:checked-row-keys="(keys) => emit('updateCheckedRowKeys', keys)"
        />
      </TableFilters>
    </TheDataCard>
  </div>
</template>
