<script setup lang="ts">
import type { ApiItemSetGetById } from '~/types'

const props = defineProps<{ setId: string, isUpdateDisabled?: boolean }>()

const itemSetId = computed(() => props.setId)
const { itemSet: queryItemSet } = useQuery()
const { data: itemSet, isLoading, error } = queryItemSet.byId(itemSetId)

const defectiveAccessoryIds = computed(() => itemSet.value?.machineryAccessories.filter(({ isDefective }) => isDefective).map(({ id }) => id) ?? [])
const permanentlyNotAvailableAccessoryIds = computed(() => itemSet.value?.machineryAccessories.filter(({ isPermanentlyNotAvailable }) => isPermanentlyNotAvailable).map(({ id }) => id) ?? [])

const priceInformation: OneDataColumn<ApiItemSetGetById>[] = [
  {
    title: 'Preis (EUR)',
    key: 'priceInEuro',
    render: ({ priceInEuro }) => priceInEuro.toLocaleString('de-DE'),
  },
]

const { itemSetColumns } = useOneDataColumnConfigs()
const { openSetCreateOrUpdatePopup } = useGlobalOpeners()
const { canAccessPage, isRole } = useAuthorization()
</script>

<template>
  <div>
    <div v-if="!itemSet && !isLoading">
      <TheDataCard>
        Dieses Set existiert nicht.
      </TheDataCard>
    </div>
    <div v-else class="flex flex-col w-full gap-y-2">
      <div class="space-y-2">
        <n-alert
          v-if="defectiveAccessoryIds.length"
          type="warning"
        >
          Bitte beachten Sie: Dieses Set enthält mindestens ein defektes Lagertool-Gerät <br>
          <nuxt-link
            v-for="id of defectiveAccessoryIds"
            :key="id"
            :to="`/machinery-accessory/${id}`"
            class="underline text-primary"
          >
            {{ id }}
          </nuxt-link>
        </n-alert>
        <n-alert
          v-if="permanentlyNotAvailableAccessoryIds.length"
          type="warning"
        >
          Bitte beachten Sie: Dieses Set enthält mindestens ein dauerhaft nicht verfügbares Lagertool-Gerät <br>
          <nuxt-link
            v-for="id of permanentlyNotAvailableAccessoryIds"
            :key="id"
            :to="`/machinery-accessory/${id}`"
            class="underline text-primary"
          >
            {{ id }}
          </nuxt-link>
        </n-alert>
      </div>

      <div class="flex gap-2 flex-wrap">
        <TheDataCard class="my-2">
          <n-collapse>
            <n-collapse-item>
              <template #header>
                <span class="text-lg">
                  Lagertool-Übersicht
                </span>
              </template>
              <MachineryAccessoryOverviewPage v-if="itemSet" title="" :actions="[]" :additional-filters="{ id: { in: itemSet?.machineryAccessories.map(({ id }) => id) } }" />
            </n-collapse-item>
          </n-collapse>
        </TheDataCard>

        <div class="flex flex-col gap-2 DetailsColumn">
          <TheDataCard
            :is-loading="isLoading"
            :error="error"
          >
            <template #header>
              <div class="CardHeader">
                <h2>Set Informationen</h2>
                <n-button v-if="!isUpdateDisabled && canAccessPage('/set-overview')" @click="openSetCreateOrUpdatePopup.open({ mode: 'update', id: setId })">
                  <template #icon>
                    <Icon name="material-symbols:edit-outline-rounded" />
                  </template>Ändern
                </n-button>
              </div>
            </template>
            <TableOneDataColumn :config="{ data: itemSet, columns: itemSetColumns }" />
            <FileView label="Fotos" :files="itemSet?.photos ?? []" />
          </TheDataCard>
          <TheDataCard
            title="Kommentare"
            :is-loading="isLoading"
            :error="error"
          >
            <CommentList v-if="itemSet" :id="itemSet.id" type="ItemSet" hide-list-title />
          </TheDataCard>
        </div>
        <div class="flex flex-col gap-2 DetailsColumn">
          <TheDataCard
            v-if="isRole(['project-manager', 'department-lead', 'bookkeeping'])"
            :is-loading="isLoading"
            :error="error"
          >
            <template #header>
              <div class="CardHeader">
                <h2>Preis Informationen</h2>
                <n-button v-if="!isUpdateDisabled && isRole('bookkeeping')" @click="openSetCreateOrUpdatePopup.open({ mode: 'update', id: setId })">
                  <template #icon>
                    <Icon name="material-symbols:edit-outline-rounded" />
                  </template>Ändern
                </n-button>
              </div>
            </template>
            <TableOneDataColumn :config="{ data: itemSet, columns: priceInformation }" />
          </TheDataCard>
          <TheDataCard
            title="QR Code"
            :error="error"
            :is-loading="isLoading"
          >
            <QrCodeDownloadSelect :id="setId" type="itemSet" />
          </TheDataCard>
          <TheDataCard
            title="Dokumente"
            :is-loading="isLoading"
            :error="error"
          >
            <FileView :files="itemSet?.documents ?? []" />
          </TheDataCard>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-3
}
.CardHeader h2 {
  @apply text-lg font-semibold
}

.DetailsColumn {
  @apply w-[400px] flex-grow;
}
</style>
