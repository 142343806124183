<script setup lang="ts">
import type { FormKitNode } from '@formkit/core'

defineOptions({ inheritAttrs: false })

withDefaults(defineProps<{
  options?: Array<{ label: string, value: unknown }>
  validation?: string | false
}>(), {
  options: () => [{ label: 'ja', value: true }, { label: 'nein', value: false }],
  validation: 'required',
})

const checkboxes = ref<null | FormKitNode>(null)
function reset() {
  const nodeValue = checkboxes.value
  if (!nodeValue) {
    return
  }
  // @ts-expect-error Unsure why this is undefined, but it is https://formkit.com/essentials/architecture (section: "Using a template ref")
  const actualNode = nodeValue.node
  actualNode.input(null)
}
</script>

<template>
  <div class="relative">
    <!-- Bind any remaining attributes to the FormKit element -->
    <FormKit ref="checkboxes" type="radio" :validation="validation === false ? undefined : validation" :options="options" v-bind="$attrs" />
    <n-space class="absolute top-5 right-2">
      <n-button text @click="reset">
        <template #icon>
          <Icon name="material-symbols:device-reset-rounded" class="custom-icon" />
        </template>
      </n-button>
    </n-space>
  </div>
</template>
