<script setup lang="ts">
const { data: popupData, close } = useGlobalOpeners().openStoragePartialPositionCreateOrEditPopup
const {
  storagePosition: storagePositionQuery,
  partialStoragePosition: partialStoragePositionQuery,
} = useQuery()

// PartialStoragePosition data
const partialStoragePositionId = computed(() => {
  return popupData.value?.mode === 'update' ? popupData.value.id : undefined
})
const { data: partialPosition } = partialStoragePositionQuery.byId(partialStoragePositionId, !!partialStoragePositionId.value)
const processedPartialPosition = computed(() => {
  if (!partialPosition.value) {
    return undefined
  }

  // Nullify `projectAddressId` if it is the standard one
  return {
    ...partialPosition.value,
    projectAddressId: partialPosition.value.projectAddress?.isDefault
      ? null
      : partialPosition.value.projectAddressId,
  }
})

// StoragePosition data
const storagePositionId = computed(() => popupData.value?.storagePositionId)
const { data: position } = storagePositionQuery.byId(storagePositionId)
const totalRetrievedQuantity = computed(() => {
  return position.value?.partialStoragePositions
    .filter(pos => pos.id !== partialStoragePositionId.value)
    .reduce((sum, pos) => sum + pos.quantity, 0) ?? 0
})
const maxRetrievableQuantity = computed(() => {
  if (!position.value) {
    return 0
  }

  return position.value.quantity - totalRetrievedQuantity.value
})

const packagingLotSize = computed(() => position.value?.storageProduct?.isPackagingUnit ? position.value.storageProduct.packagingLotSize : undefined)

// Upsertion
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const upsertMutation = useMutation({
  mutationFn: $trpc.externalStorage.partialStoragePosition.upsert.mutate,
  onError: makeTrpcErrorToast(notification, {
    description: 'Die Auslagerung konnte nicht beantragt werden',
  }),
  async onSuccess() {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['externalStoragePartialStoragePosition'] }),
      queryClient.invalidateQueries({ queryKey: ['externalStoragePosition'] }),
    ])
    close()
    notification.success({
      title: 'Die Auslagerung wurde erfolgreich beantragt',
      duration: 4500,
    })
  },
})

const popupTitle = computed(() => `Lagerposition ${storagePositionId.value?.concat(' ') ?? ''}auslagern`)

const washingEnabled = computed(() => position.value?.project.washingEnabled ?? false)

const { data: carrierData } = partialStoragePositionQuery.carrierNameAndLicensePlateOptions(storagePositionId)
const carrierNameOptions = computed(() => carrierData.value?.carrierNames.map(it => ({ label: it, value: it })) ?? [])
const carrierLicensePlateOptions = computed(() => carrierData.value?.carrierLicensePlates.map(it => ({ label: it, value: it })) ?? [])
const carrierTrailerLicensePlateOptions = computed(() => carrierData.value?.carrierTrailerLicensePlates.map(it => ({ label: it, value: it })) ?? [])
</script>

<template>
  <!-- If `mode === 'update'`, `partialPosition` must be loaded -->
  <ThePopup
    v-if="popupData && position && (popupData.mode !== 'update' || partialPosition)"
    :title="popupTitle"
    show
    @close="close"
  >
    <ExternalStoragePartialStoragePositionCreateOrEditForm
      :payload="processedPartialPosition"
      :storage-position-id="position.id"
      :project-id="position.projectId"
      :max-quantity="maxRetrievableQuantity"
      :washing-enabled
      :packaging-lot-size
      :carrier-name-options
      :carrier-license-plate-options
      :carrier-trailer-license-plate-options
      @save="upsertMutation.mutate"
    />
  </ThePopup>
</template>
