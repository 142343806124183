<script lang="ts" setup>
type ListSize = 'sm' | 'md' | 'lg'
type ValueType = string | number | null

withDefaults(defineProps<{
  label?: string
  value?: ValueType
  horizontal?: boolean
  size?: ListSize
  formatNumber?: boolean
  placeholder?: string
}>(), { size: 'lg', formatNumber: true })

function defaultValueFormatter(value?: ValueType, formatNumber?: boolean) {
  if (typeof value === 'number' && formatNumber) {
    return value.toLocaleString('de-DE')
  }
  return value || undefined
}
</script>

<template>
  <div
    :class="{
      'flex flex-wrap items-center gap-2': horizontal,
      'space-y-0.5': !horizontal,
      'text-base md:text-lg': size === 'lg',
      'text-sm md:text-base': size === 'md',
      'text-xs md:text-sm': size === 'sm',
    }"
  >
    <p class="font-semibold">
      {{ label }}:
    </p>
    <slot v-if="$slots.default" />
    <p v-else class="pt-px">
      {{ defaultValueFormatter(value, formatNumber) || placeholder || $t('customerModule.empty.placeholder') }}
    </p>
  </div>
</template>
