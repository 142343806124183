<script setup lang="ts">
const { t: $t } = useI18n()

import { RentalPriceModifierConditionType, RentalPriceModifierUnit } from '~/prisma/enums'
import type { PriceCalculationResult } from '~/server/trpc/routers/shop/logic/rentalPricingCalculation'

const props = defineProps<{
  pricing: PriceCalculationResult
}>()

interface ItemGroup {
  label: string
  value: string
  items: string[]
}
const itemGroups = computed<ItemGroup[] | undefined>(() => {
  const pricing = props.pricing

  const result: ItemGroup[] = [{
    label: $t('rentalPricing.basePrice.title'),
    value: `${pricing.appliedBasePrice.toLocaleString('de')} ${$t('unit.eur')}`,
    items: [],
  }]

  for (const pricingModifier of pricing.appliedPriceModifiers) {
    const label = $t(`rentalPriceModifier.conditionType.${pricingModifier.conditionType}`)
    const value = [
      pricingModifier.value > 0
        ? $t('rentalPriceModifier.value.surcharge')
        : $t('rentalPriceModifier.value.discount'),
      ' ',
      pricingModifier.value.toString(),
      ' ',
      pricingModifier.unit === RentalPriceModifierUnit.Absolute ? $t('unit.eur') : $t('unit.percent'),
    ].join('')

    const modifierItems: string[] = []

    switch (pricingModifier.conditionType) {
      case RentalPriceModifierConditionType.Days: {
        if (pricingModifier.daysGte !== null) {
          modifierItems.push($t(
            `rentalPriceModifier.days.${pricingModifier.daysLte === null ? 'fromOnly' : 'fromWithTill'}`,
            pricingModifier.daysGte,
          ))
        }
        if (pricingModifier.daysLte !== null) {
          modifierItems.push($t(`rentalPriceModifier.days.till`, pricingModifier.daysLte))
        }
        break
      }

      case RentalPriceModifierConditionType.CustomerType: {
        if (pricingModifier.customerType !== null) {
          modifierItems.push($t(`rentalPriceModifier.customerType.${pricingModifier.customerType}`))
        }
        break
      }
    }

    result.push({
      label,
      value,
      items: modifierItems,
    })
  }

  result.push({
    label: 'Gesamtpreis pro Tag',
    value: `${pricing.pricePerDay.toLocaleString('de')} ${$t('unit.eur')}`,
    items: [],
  })

  return result
})
</script>

<template>
  <div
    v-for="(itemGroup, idx) in itemGroups"
    :key="idx"
    :class="{ 'mt-4': idx > 0 }"
  >
    <p class="flex justify-between gap-12">
      <span>{{ itemGroup.label }}</span>
      <span>{{ itemGroup.value }}</span>
    </p>

    <p v-for="(item, itemIdx) in itemGroup.items" :key="itemIdx">
      {{ item }}
    </p>
  </div>
</template>
