<script setup lang="ts">
const { t: $t } = useI18n()

import type { Prisma } from '@prisma/client'
import { useRouteQuery } from '@vueuse/router'
import type { DataTableColumns } from 'naive-ui'
import type { ApiInputOfferUpdateReleaseToCustomer, ApiOfferGetAll, OfferType, OfferView } from '~/types'
import { offerPositionsToShipSchema, offerUpdateSchema } from '~/server/schemas'

const props = withDefaults(defineProps<{
  type?: OfferType
  view: OfferView
  additionalFilters?: Record<string, unknown>
  initialPageSize?: number
  showOfferFilters?: boolean
  isCardView?: boolean
  allowCreation?: boolean
}>(), {
  additionalFilters: () => ({}),
  initialPageSize: 20,
  type: undefined,
  showOfferFilters: true,
  allowCreation: true,
})
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

// Roles
const { username, useremail, isRole } = useAuthorization()
const isAdmin = computed(() => isRole('admin'))

const {
  offer: { fulltextSearch, rowClassName },
  rentalColumns,
  saleColumns,
  serviceProjectOffer: { fulltextSearch: serviceProjectFulltextSearch },
  specialOffer: { fulltextSearch: specialOfferFulltextSearch, columns: specialOfferColumn },
} = useTableColumnConfigs()

const tableSearch = computed(() => {
  if (props.type === 'special') {
    return specialOfferFulltextSearch
  }
  if (props.type === 'service-project') {
    return serviceProjectFulltextSearch
  }
  return fulltextSearch
})

const { fulltextSearchValue, where } = useFilterData(tableSearch.value)

const cancellationColumns: DataTableColumns<ApiOfferGetAll> = [
  {
    title: $t('offer.field.cancelledBy.name'),
    key: 'cancelledByEmail',
    minWidth: 225,
  },
  {
    title: $t('offer.field.cancelledAt.name'),
    key: 'cancelledAt',
    render: ({ cancelledAt }) => cancelledAt ? useDateAsString(cancelledAt, 'dd.MM.yy') : $t('common.notAvailable'),
    minWidth: 150,
  },
]

const currentColumns = computed(() => {
  let columns

  switch (props.type) {
    case 'special':
      columns = specialOfferColumn
      break
    case 'rental':
      columns = rentalColumns(props.view)
      break
    default:
      columns = saleColumns
  }

  if (props.view === 'cancelled') {
    columns.push(...cancellationColumns)
  }

  return columns
})

const { urlWithLocale } = useUrlWithLocale()

const isSearchingOnlyFavorites = ref(false)
const isSortByRequestDate = ref(false)
const isSortByStartDate = ref(false)
const isSortBySaleProtocolUploadDate = ref(false)
const isSearchingOnlyStorageSpaceOffer = ref(false)
const isSearchingOnlyReleasedOffers = ref(false)

// Filters
const selectedContactForCustomer = useRouteQuery<string | null>('contactForCustomer', null)

const selectedRequestedAt = useRouteQueryAsNumberRange('requestedAt')
const selectedObligationStartAtRange = useRouteQueryAsNumberRange('startAtRange')

function resetFilters() {
  selectedContactForCustomer.value = null
  selectedRequestedAt.value = null
  selectedObligationStartAtRange.value = null
}

const viewSpecificFiltersByType = computed((): Prisma.OfferWhereInput => {
  const type = props.type
  const view = props.view

  if (type && ['service-project', 'special'].includes(type)) {
    switch (view) {
      case 'inquiry':
        return { status: 'inquiry', isCancelled: false, isCompleted: false }
      case 'offer':
        return { status: 'offer', isCancelled: false, isCompleted: false }
      case 'order':
        return { status: 'order', isCancelled: false, isCompleted: false }
      case 'completed':
        return { isCompleted: true }
      case 'cancelled':
        return { isCancelled: true }

      // Not needed for service project, but still need to be defined
      // Explicitly do not use `default:`
      case 'all':
      case 'costs':
      case 'service-order':
      case 'offer-freelancer-sales':
      case 'order-freelancer-sales':
        return {}
    }
  }

  const generateWhereStatusFromOfferType = (value: boolean) => type === 'sale' ? { isDelivered: value } : { isReturned: value }

  switch (view) {
    case 'all':
      return { isCancelled: false }
    case 'offer':
      return { status: 'offer', isCancelled: false }
    case 'costs':
      return { status: 'order', isCancelled: false }
    case 'order':
      return { status: 'order', isCancelled: false, ...(props.type === 'special' ? {} : { positions: { some: { type: { in: offerPositionsToShipSchema.options }, ...generateWhereStatusFromOfferType(false) } } }) }
    case 'completed':
      return {
        status: { in: ['order', 'invoiced'] },
        positions: {
          every: { OR: [
            { type: { in: offerPositionsToShipSchema.options }, ...generateWhereStatusFromOfferType(true) },
            { type: { not: { in: offerPositionsToShipSchema.options } } },
          ] },
        },
      }
    case 'cancelled':
      return { isCancelled: true }
    case 'inquiry':
      return { status: 'inquiry', isCancelled: false }
    case 'draft':
      return { status: 'draft', isCancelled: false }
    case 'service-order':
      return { AND: [{ isMachineryConversionRequired: true }, { machineryConvertedAt: null }, { NOT: { saleProtocolPdf: null } }, { isCancelled: false }] }

    case 'offer-freelancer-sales':
    case 'order-freelancer-sales': {
      const status = view.startsWith('offer') ? 'offer' : 'order'

      return {
        status,
        isCancelled: false,
        OR: [
          {
            createdBy: {
              role: 'freelancer-sales',
              email: isAdmin.value ? undefined : useremail.value,
            },
          },
          // https://github.com/sidestream-tech/hanselmann-os/issues/2200
          {
            sharedWith: {
              some: {
                email: useremail.value,
              },
            },
          },
        ],
      }
    }
  }
})

const { offer: queryOffer } = useQuery()
const offersWhere = computed(() => ({
  ...where.value,
  ...viewSpecificFiltersByType.value,
  isFavorite: isSearchingOnlyFavorites.value ? true : undefined,
  isStorageSpaceOffer: isSearchingOnlyStorageSpaceOffer.value ? true : undefined,
  offerReleasedToCustomerAt: isSearchingOnlyReleasedOffers.value ? { not: null } : undefined,
  type: props.type,
  ...props.additionalFilters,
  contactForCustomer: selectedContactForCustomer.value ? { name: { contains: selectedContactForCustomer.value } } : undefined,
  requestedAt: selectedRequestedAt.value ? createPrismaDateRangeFilterSerialized(selectedRequestedAt.value) : undefined,
  obligationStartsAt: selectedObligationStartAtRange.value ? createPrismaDateRangeFilterSerialized(selectedObligationStartAtRange.value) : undefined,
}))

const offersSorters = computed(() => {
  type Sorter = { requestedAt: 'desc' } | { obligationStartsAt: 'desc' } | { saleProtocolPdfUploadedAt: 'desc' }
  const sorters: Sorter[] = []

  if (isSortByRequestDate.value) {
    sorters.push({ requestedAt: 'desc' })
  }
  if (isSortByStartDate.value) {
    sorters.push({ obligationStartsAt: 'desc' })
  }
  if (isSortBySaleProtocolUploadDate.value) {
    sorters.push({ saleProtocolPdfUploadedAt: 'desc' })
  }

  return sorters
})

const { serverSidePaginationTableProps, serverSidePagination, watchForPageCount, watchForReset } = useTablePagination({ pageCount: 1 })
watchForReset(computed(() => [offersWhere.value, offersSorters.value]))

const { data, error: errorOffers, isLoading: isLoadingOffers } = queryOffer.all(offersWhere, serverSidePagination, offersSorters)
const offers = computed(() => data.value?.offers ?? [])
watchForPageCount(computed(() => data.value?.totalCount ?? 0))

const update = useMutation({
  mutationFn: $trpc.offer.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('offer.notification.update.error') }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines', 'rental', 'sale'] }),
    ])
    closeInvoicedPopup()
    notification.success({ title: $t('offer.notification.update.success'), duration: 4500 })
  },
})

const updateIsCancelled = useMutation({
  mutationFn: $trpc.offer.updateIsCancelled.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('offer.notification.update.error') }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['logistics'] }),
    ])
    notification.success({ title: $t('offer.notification.update.success'), duration: 4500 })

    popupConfirmCancelOffer.value = null
  },
})

const updateIsFavorite = useMutation({
  mutationFn: $trpc.offer.updateIsFavorite.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('offer.notification.update.error') }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines', 'rental', 'sale'] }),
      // this mutation will also update related logistcs isFavorite value
      queryClient.invalidateQueries({ queryKey: ['logistics'] }),
    ])
    notification.success({ title: $t('offer.notification.update.success'), duration: 4500 })
  },
})

const markOfferABAsSent = useMutation({
  mutationFn: $trpc.offer.markOfferABAsSent.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('offer.notification.update.error') }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['logistics'] }),
    ])
    notification.success({ title: $t('offer.notification.update.success'), duration: 4500 })
    closeMarkOfferABAsSentPopup()
  },
})

const deleteOne = useMutation({
  mutationFn: $trpc.offer.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('offer.notification.delete.error') }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines', 'rental', 'sale'] }),
    ])
    notification.success({ title: $t('offer.notification.delete.success'), duration: 4500 })

    popupConfirmDeleteOffer.value = null
  },
})

const baseActionsByView: Record<OfferView, Action[]> = {
  'offer': ['update', 'delete', 'duplicate'],
  'order': ['update', 'cancel', 'pdf'],
  'completed': [],
  'cancelled': [],
  'all': ['update'],
  'costs': ['addCosts'],
  'inquiry': [],
  'draft': [],
  'service-order': ['update', 'confirmConversion'],
  'offer-freelancer-sales': ['update', 'pdf'],
  'order-freelancer-sales': ['update', 'pdf'],
}

function actionButtons(row: ApiOfferGetAll): Action[] {
  const actions: Action[] = baseActionsByView[props.view].slice()
  if (['costs', 'service-order'].includes(props.view)) {
    return [...new Set(actions)]
  }

  if (row.status === 'offer') {
    if (row.isConvertableToOrder) {
      actions.push('acceptOffer' satisfies Action)
    }
    if (row.type === 'rental') {
      actions.push('mail' satisfies Action)
      if (row.offerReleasedToCustomerAt) {
        actions.push('revertReleaseOfferToCustomer' satisfies Action)
      } else {
        actions.push('releaseOfferToCustomer' satisfies Action)
      }
    }
  }

  if (['order-freelancer-sales', 'order'].includes(props.view) && row.type === 'sale') {
    const isAllPositionsIssued = row.positions
      .filter(doesOfferPositionNeedDelivery)
      .every(position => position.isIssued)

    if (!row.isConfirmed && isAllPositionsIssued) {
      actions.push('confirmOrder' satisfies Action)
    }
    if (!row.abSentAt) {
      actions.push('markOfferABAsSent' satisfies Action)
    }
  }

  if (row.status === 'order' || (['offer', 'all'].includes(props.view) && row.isConvertableToOrder)) {
    actions.push('pdf' satisfies Action)
  }

  if (row.status === 'order' && row.type !== 'special') {
    actions.push('mail' satisfies Action)
  }

  if (row.type === 'special' && props.view !== 'cancelled') {
    actions.push('duplicate' satisfies Action)
  }

  if (props.view === 'cancelled') {
    actions.push('comment' satisfies Action)
  }

  // https://github.com/sidestream-tech/hanselmann-os/issues/2200
  if (row.status === 'offer' && row.type === 'sale' && isAdmin.value) {
    actions.push('shareWithFreelancer')
  }

  if (props.view === 'inquiry') {
    actions.push('view' satisfies Action)
  }

  return [...new Set(actions)]
}

const popupConfirmDeleteOffer = ref<null | ApiOfferGetAll>(null)
const popupConfirmCancelOffer = ref<null | ApiOfferGetAll>(null)
const popupConfirmMachineryConversion = ref<null | ApiOfferGetAll>(null)
const { payload: invoicedPayload, open: openInvoicedPopup, close: closeInvoicedPopup } = usePopup<ApiOfferGetAll>()
const { payload: confirmPayload, open: openConfirmPopup, close: closeConfirmPopup } = usePopup<ApiOfferGetAll>()
const { payload: markOfferABAsSentPayload, open: openMarkOfferABAsSentPopup, close: closeMarkOfferABAsSentPopup } = usePopup<ApiOfferGetAll>()
const { payload: commentPayload, open: openCommentPopup, close: closeCommentPopup } = usePopup<ApiOfferGetAll>()

function cancelOffer(cancelPayload: ApiOfferGetAll) {
  openCommentPopup(cancelPayload)
  return updateIsCancelled.mutate({ id: cancelPayload.id, isCancelled: true })
}

function updateFavorite(payload: ApiOfferGetAll) {
  updateIsFavorite.mutate({ id: payload.id, isFavorite: !payload.isFavorite })
}
function updateToInvoiced(payload: ApiOfferGetAll) {
  update.mutate(offerUpdateSchema.parse(payload))
  closeInvoicedPopup()
}

function openPrintPdf(row: ApiOfferGetAll) {
  openOfferPrintPopup.open({
    offerId: row.id,
    offerCuid: row.cuid,
    type: row.type as OfferType,
  })
}

const translatedOfferType = $t(`offer.type.${props.type ?? 'rental'}.nameWithArticle`)
const duplicate = useMutation({
  mutationFn: $trpc.offer.duplicate.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('offer.notification.duplicate.error', { offerType: translatedOfferType }) }),
  onSuccess: async (duplicatedOffer) => {
    if (!duplicatedOffer) {
      return
    }

    await queryClient.invalidateQueries({ queryKey: ['offer'] })

    notification.success({ title: $t('offer.notification.duplicate.success', { offerType: translatedOfferType }), duration: 4500 })
    openOfferPage({ mode: 'edit', id: duplicatedOffer.id, isCustomerEditingEnabled: true })
  },
})

const confirmMachineryConversion = useMutation({
  mutationFn: $trpc.offer.confirmMachineryConversion.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('offer.notification.confirmMachineryConversion.error') }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['offer'] })
    notification.success({ title: $t('offer.notification.confirmMachineryConversion.success'), duration: 4500 })

    popupConfirmMachineryConversion.value = null
  },
})

const releaseOfferToCustomerConfirmPayload = ref<ApiInputOfferUpdateReleaseToCustomer | null>(null)
function openReleaseOfferToCustomerConfirmPopup(id: string | null, release: boolean) {
  if (id === null) {
    notification.error({ title: $t('offer.notification.updateReleaseOfferToCustomer.error'), duration: 4500 })
    return
  }
  releaseOfferToCustomerConfirmPayload.value = { id, release }
}
const releaseOfferToCustomerPopupPayload = ref<string | null>(null)
function sendOfferMail(offer: ApiOfferGetAll) {
  navigateTo(getMailtoForOfferEmail(offer, username.value, urlWithLocale), { external: true })
  if (offer.status === 'offer' && offer.type === 'rental' && !offer.offerReleasedToCustomerAt) {
    releaseOfferToCustomerPopupPayload.value = offer.id
  }
}

const createLabel = computed(() => {
  if (props.type === 'special') {
    return 'Neuer Spezialauftrag'
  }

  if (props.type === 'service-project' && props.view === 'inquiry') {
    return 'Neue Anfrage'
  }

  if (props.view === 'offer') {
    return 'Neues Angebot'
  }

  if (props.view === 'order') {
    return 'Neuer Auftrag'
  }

  return null
})

const route = useRoute()
const { openOfferPage, openInquiryTaskPage, openOfferPrintPopup, openInternalCostPopup } = useGlobalOpeners()

function viewDetailsPage(id: string, isCancelled: boolean) {
  if (isCancelled || !id) {
    return
  }

  openOfferPage({
    mode: 'edit',
    id,
    redirectUrl: ['service-order', 'offer-freelancer-sales', 'completed'].includes(props.view) ? route.path : undefined,
  })
}

const shareWithFreelancersOfferId = ref<string | undefined>()

const overviewTitle = $t(`offer.overview.${props.view}`)
</script>

<template>
  <div class="space-y-4">
    <TheConfirmPopup
      v-if="popupConfirmMachineryConversion"
      action-button-label="Bestätigen"
      @confirm="confirmMachineryConversion.mutate({ id: popupConfirmMachineryConversion.id })"
      @close="popupConfirmMachineryConversion = null"
    >
      Die Maschine wird umgerüstet.
    </TheConfirmPopup>

    <TheConfirmPopup v-if="popupConfirmDeleteOffer" @confirm="deleteOne.mutate(popupConfirmDeleteOffer)" @close="popupConfirmDeleteOffer = null">
      Alle mit dem {{ overviewTitle }} verbundenen Daten werden ebenfalls gelöscht.
    </TheConfirmPopup>
    <TheConfirmPopup
      v-if="popupConfirmCancelOffer"
      action-button-label="Stornieren"
      @confirm="cancelOffer(popupConfirmCancelOffer)"
      @close="popupConfirmCancelOffer = null"
    >
      Alle mit dem {{ overviewTitle }} verbundenen Daten werden ebenfalls storniert. Diese Aktion ist unumkehrbar.
      <div v-if="popupConfirmCancelOffer._count.invoices" class="mt-2">
        Dieser Auftrag besitzt {{ popupConfirmCancelOffer._count.invoices }} Rechnungen. Bitte stellen Sie sicher, dass auch diese storniert werden.
      </div>
    </TheConfirmPopup>

    <ThePopup v-if="commentPayload" :title="`Kommentare zu Auftrag ${commentPayload.id ?? 'N/A'}`" :show="Boolean(commentPayload)" @close="closeCommentPopup">
      <CommentList :id="commentPayload.id" type="Offer" />
    </ThePopup>

    <OfferPopupMarkAsConfirm
      v-if="confirmPayload"
      :show="Boolean(confirmPayload)"
      :offer="confirmPayload"
      @close="closeConfirmPopup"
    />
    <ThePopup
      v-if="markOfferABAsSentPayload"
      :show="Boolean(markOfferABAsSentPayload)"
      :title="`AB Versand bestätigen (${markOfferABAsSentPayload.id})`"
      @close="closeMarkOfferABAsSentPopup"
    >
      <FormKit type="form" @submit="markOfferABAsSent.mutate({ id: markOfferABAsSentPayload.id })">
        <FormKit
          type="checkbox"
          validation="required"
          label="Ich bestätige, dass die AB an den Kunden verschickt wurde."
          :validation-messages="{
            required: 'Bitte prüfen Sie dies',
          }"
        />
      </FormKit>
    </ThePopup>

    <ThePopup v-if="invoicedPayload" :show="Boolean(invoicedPayload)" :title="`Auftrag ${invoicedPayload.id}`" @close="closeInvoicedPopup">
      <FormKit type="form" submit-label="Der Auftrag wurde in Rechnung gestellt" @submit="updateToInvoiced({ ...invoicedPayload, status: 'invoiced' })" />
    </ThePopup>
    <ThePopup
      :show="!!releaseOfferToCustomerPopupPayload"
      :title="$t('offer.releaseOfferToCustomerPopup.title')"
      @close="releaseOfferToCustomerPopupPayload = null"
    >
      <p class="mb-4">
        {{ $t('offer.releaseOfferToCustomerPopup.description') }}
      </p>
      <n-button
        type="primary"
        @click="openReleaseOfferToCustomerConfirmPopup(releaseOfferToCustomerPopupPayload, true)"
      >
        {{ $t('offer.button.releaseOfferToCustomer') }}
      </n-button>
    </ThePopup>

    <OfferPopupReleaseToCustomer
      v-model="releaseOfferToCustomerConfirmPayload"
      @confirmed="releaseOfferToCustomerPopupPayload = null"
    />

    <TheDataCard v-if="allowCreation && type && createLabel && (view === 'offer' || view === 'order' || view === 'inquiry')" :title="`Anlage ${createLabel}`" :is-loading="false">
      <div class="flex flex-row gap-2">
        <FormKit type="form" :submit-label="`${createLabel} erstellen`" @submit="openOfferPage({ mode: 'create', type, status: view })" />
        <FormKit v-if="view === 'inquiry'" type="form" submit-label="Schnelle Anfragen Erfassung" @submit="openInquiryTaskPage()" />
      </div>
    </TheDataCard>

    <OfferShareWithFreelancersContainer
      v-if="shareWithFreelancersOfferId"
      :id="shareWithFreelancersOfferId"
      @close="shareWithFreelancersOfferId = undefined"
    />

    <TheDataCard :title="overviewTitle" :error="errorOffers">
      <TableFilters v-model="fulltextSearchValue" :placeholder="tableSearch.placeholder">
        <template #search>
          <div class="flex flex-col md:flex-row gap-2 flex-wrap">
            <n-checkbox v-model:checked="isSearchingOnlyFavorites" type="checkbox" class="shrink-0">
              Nur Favoriten
            </n-checkbox>
            <n-checkbox v-if="type === 'special'" v-model:checked="isSearchingOnlyStorageSpaceOffer" type="checkbox" class="shrink-0">
              Nur Lagerflächen-Angebote anzeigen
            </n-checkbox>
            <n-checkbox v-model:checked="isSortByRequestDate" type="checkbox" class="shrink-0">
              Nach Anfragedatum sortieren
            </n-checkbox>
            <n-checkbox v-model:checked="isSortByStartDate" type="checkbox" class="shrink-0">
              Nach Startdatum sortieren
            </n-checkbox>
            <n-checkbox v-model:checked="isSortBySaleProtocolUploadDate" type="checkbox" class="shrink-0">
              Nach Upload Verkaufsprotokoll sortieren
            </n-checkbox>
            <n-checkbox v-model:checked="isSearchingOnlyReleasedOffers" type="checkbox" class="shrink-0">
              Nur freigegebene Angebote anzeigen
            </n-checkbox>
          </div>

          <n-collapse v-if="showOfferFilters">
            <n-collapse-item>
              <template #header>
                <span class="text-lg">
                  Angebot-spezifische Filter ausklappen
                </span>
              </template>
              <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                  <div>
                    <p class="font-semibold">
                      {{ $t('offer.field.contactForCustomer.name') }}
                    </p>
                    <n-input v-model:value="selectedContactForCustomer" :consistent-menu-width="false" filterable clearable />
                  </div>
                  <div>
                    <p class="font-semibold">
                      {{ $t('offer.field.requestedAt.name') }}
                    </p>
                    <n-date-picker
                      v-model:value="selectedRequestedAt"
                      type="daterange"
                      filterable
                      clearable
                    />
                  </div>
                  <div v-if="type !== 'special'">
                    <p class="font-semibold">
                      {{ type === 'sale' ? $t('offer.field.requestedAt.sale.name') : $t('offer.field.obligationStartsAt.name') }}
                    </p>
                    <n-date-picker
                      v-model:value="selectedObligationStartAtRange"
                      type="daterange"
                      filterable
                      clearable
                    />
                  </div>
                  <div class="flex items-end">
                    <n-button class="!bg-white w-full md:w-auto" @click="resetFilters">
                      Alle zurücksetzen
                    </n-button>
                  </div>
                </div>
              </div>
            </n-collapse-item>
          </n-collapse>
        </template>
        <div v-if="isCardView">
          <div v-if="offers.length > 0" class="my-5">
            <OfferCard
              v-for="offer in offers"
              :key="offer.id"
              :offer="offer"
              :view="view"
            />
          </div>
          <div v-else-if="isLoadingOffers" class="border w-full h-[175px] my-5 flex items-center justify-center">
            <div class="flex flex-col gap-1 items-center text-gray-400">
              <Icon name="material-symbols:progress-activity" size="40" class="animate-spin" />
            </div>
          </div>
          <div v-else class="border w-full h-[175px] my-5 flex items-center justify-center">
            <div class="flex flex-col gap-1 items-center text-gray-400">
              <Icon name="material-symbols:inbox-outline-rounded" size="40" />
              <p>Keine Daten</p>
            </div>
          </div>

          <div class="flex justify-end">
            <n-pagination
              v-bind="serverSidePaginationTableProps.controlledPaginationSettings"
              @update:page="serverSidePaginationTableProps['onUpdate:page']"
              @update:page-size="serverSidePaginationTableProps['onUpdate:page-size']"
            />
          </div>
        </div>
        <TableView
          v-else
          :data="offers"
          :columns="currentColumns"
          :action-buttons="actionButtons"
          :is-loading="isLoadingOffers"
          :has-favourite-column="true"
          :row-props="row => ({
            style: 'cursor: pointer;',
            onClick: () => viewDetailsPage(row.id, row.isCancelled),
          })"
          v-bind="view === 'offer' || view === 'offer-freelancer-sales' ? { rowClassName, ...serverSidePaginationTableProps } : serverSidePaginationTableProps"
          :striped="view !== 'offer'"
          :initial-pagination-settings="{ pageSize: initialPageSize }"
          @comment="openCommentPopup"
          @delete="offer => popupConfirmDeleteOffer = offer"
          @update="offer => openOfferPage({
            mode: 'edit',
            id: offer.id,
            redirectUrl: ['service-order', 'offer-freelancer-sales', 'completed'].includes(view) ? route.path : undefined,
          })"
          @accept-offer="offer => openOfferPage({ mode: 'edit', id: offer.id, specialAction: 'acceptOffer' })"
          @confirm-order="openConfirmPopup"
          @invoiced="openInvoicedPopup"
          @update-favorite="updateFavorite"
          @pdf="openPrintPdf"
          @mail="sendOfferMail"
          @duplicate="offer => duplicate.mutate({ id: offer.id })"
          @cancel="offer => popupConfirmCancelOffer = offer"
          @add-costs="offer => openInternalCostPopup.open({ type: 'offer', id: offer.id })"
          @confirm-conversion="offer => popupConfirmMachineryConversion = offer"
          @mark-offer-a-b-as-sent="openMarkOfferABAsSentPopup"
          @share-with-freelancer="shareWithFreelancersOfferId = $event.id"
          @view="offer => viewDetailsPage(offer.id, offer.isCancelled)"
          @release-offer-to-customer="offer => openReleaseOfferToCustomerConfirmPopup(offer.id, true)"
          @revert-release-offer-to-customer="offer => openReleaseOfferToCustomerConfirmPopup(offer.id, false)"
        />
      </TableFilters>
    </TheDataCard>
  </div>
</template>

<style scoped>
:deep(.NotConvertableToOrder td) {
  @apply bg-yellow-100 !important;
}
:deep(.ConvertableToOrder td) {
  @apply bg-green-100 !important;
}
</style>
