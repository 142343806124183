<script setup lang="ts">
import type { ApiInputLogisticsUpdateStatus, ApiInputOfferPositionUpdateStatus, ApiLogisticsTaskGetById } from '~/types'
import { updateLogisticsTaskStatusSchema } from '~/server/schemas'
import { LogisticsTaskStatus } from '~/prisma/enums'

const { openDriverCollectionChecklistPopup: { data: popupData, close: closeChecklistPopup } } = useGlobalOpeners()

const { logisticsTask: queryLogisticsTask } = useQuery()

const logisticsTaskId = computed(() => popupData.value?.id)
const { data: logisticsTask } = queryLogisticsTask.byIdOrThrow(logisticsTaskId)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const update = useMutation({
  mutationFn: async ({ positions, task }: { positions: ApiInputOfferPositionUpdateStatus, task: ApiInputLogisticsUpdateStatus }) => {
    await $trpc.logisticsTask.updateStatus.mutate(task)
    return $trpc.offerPosition.updateStatus.mutate(positions)
  },
  onError: makeTrpcErrorToast(notification, { description: 'Die Abholungs-Checklist konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['offerPosition'] }),
    ])
    closeChecklistPopup()
    notification.success({ title: 'Die Abholungs-Checklist wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const { useremail } = useAuthorization()
function updateIsCollected(task: ApiLogisticsTaskGetById) {
  const positions = task.positionsToShip.map(p => ({ id: p.id, isCollected: p.isCollected }))
  const taskPayload = {
    id: task.id,
    type: task.type,
    status: LogisticsTaskStatus.collected,
    collectedAt: new Date(),
    collectedByEmail: useremail.value,
  }

  return update.mutate({
    positions,
    task: updateLogisticsTaskStatusSchema.parse(taskPayload),
  })
}
</script>

<template>
  <ThePopup v-if="popupData && logisticsTask" :show="Boolean(logisticsTask)" title="Abholungs-Checklist" @close="closeChecklistPopup">
    <DriverCollectionChecklistForm
      :logistics-task="logisticsTask"
      @save="updateIsCollected"
    />
  </ThePopup>
</template>
