<script setup lang="ts">
import type { DeliveryVehicleUpsert } from '~/server/trpc/routers/deliveryVehicle'
import type { ApiUsersGetDrivers } from '~/types'
import { DeliveryVehicleStatus } from '~/prisma/enums'

const props = defineProps<{
  payload: DeliveryVehicleUpsert
  drivers: ApiUsersGetDrivers[]
  onSave: (payload: DeliveryVehicleUpsert) => void
}>()

const { cloned } = useMightyCloned(props.payload)

const today = useDateAsString(new Date(), 'yyyy-MM-dd')

const driverOptions = computed(() => props.drivers.map(driver => ({
  label: `${driver.name ?? ''} (${driver.email})`,
  value: driver.email,
})))
</script>

<template>
  <FormKit
    v-slot="{ value }"
    :value="cloned"
    type="form"
    submit-label="Fahrzeug speichern"
    @submit="(data: DeliveryVehicleUpsert) => onSave(data)"
  >
    <TheDevOnlyNiceJson :form-data="value" />
    <div class="flex space-x-2">
      <FormKit
        name="licensePlateNumber"
        type="text"
        validation="required"
        label="Kfz-Kennzeichen"
        placeholder="K - LM - 9234"
      />
      <FormKit
        name="model"
        type="text"
        label="Fahrzeugmodell"
        placeholder="Scania V8"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="height"
        type="number"
        number
        min="0"
        step="0.01"
        label="Höhe (mm)"
        placeholder="3000"
        validation="required"
      />
      <FormKit
        name="breadth"
        type="number"
        number
        min="0"
        step="0.01"
        label="Breite (mm)"
        placeholder="2500"
        validation="required"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="maxPartialLoadWeight"
        type="number"
        number
        min="0"
        step="0.01"
        label="Max Gewicht Teilladung (kg)"
        placeholder="3000"
        validation="required"
      />
      <FormKit
        name="maxSingleLoadWeight"
        type="number"
        number
        min="0"
        step="0.01"
        label="Max Gewicht Einzelladung (kg)"
        placeholder="4500"
        validation="required"
      />
    </div>
    <div class="flex space-x-2">
      <FormKitDate
        name="technicalInspectionDate"
        label="TÜV"
        :placeholder="today"
        validation-visibility="live"
        validation="required"
      />
      <FormKitDate
        name="securityInspectionDate"
        label="SP"
        :placeholder="today"
        validation-visibility="live"
        validation="required"
      />
    </div>
    <div class="flex space-x-2 items-end">
      <FormKitMultiSelect
        name="driverEmail"
        label="Fahrer"
        placeholder="Bitte Fahrer auswählen"
        :multi-select-options="driverOptions"
        :is-multi-select-allowed="false"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit
        name="status"
        type="checkbox"
        :on-value="DeliveryVehicleStatus.Active"
        :off-value="DeliveryVehicleStatus.Inactive"
        label="Als aktives Fahrzeug im Dispo Planer anzeigen"
      />
      <FormKit
        name="isExternalStorageVehicle"
        type="checkbox"
        label="Als Fahrzeug für den Lagerbereich anzeigen"
      />
    </div>
    <FormKitFileDropzone
      name="photos"
      label="Fahrzeugbilder"
    />
  </FormKit>
</template>
