<script setup lang="ts">
import { z } from 'zod'
import { machineryAccessoryCategoryToGerman } from '~/translations'
import type { MachineryAccessoryCategory, MachineryAccessoryCategoryFilter, OfferPositionsToShip, OfferType } from '~/types'
import accessoryFilterConditions, { checkShoppingAccessoryFilter } from '~/components/Shopping/accessoryFilterConditions'

const { openLogisticsIssuanceEnterMachineryId: { data: popupData, close: closePopup } } = useGlobalOpeners()

const id = computed(() => {
  if (popupData.value?.mode === 'category') {
    return popupData.value.offerPositionId
  }
})

const { offerPosition: queryOfferPosition, calendar: queryCalendar, machineryAccessory: queryMachineryAccessory } = useQuery()
const { data: offerPosition } = queryOfferPosition.byId(id)

const relatedOffer = computed(() => {
  if (!offerPosition.value) {
    return
  }

  const outboundLogisticsTask = offerPosition.value?.shippedViaLogisticsTasks?.find(t => t.type === 'outbound')
  if (!outboundLogisticsTask?.offer) {
    return
  }

  return {
    where: {
      machineryAccessoryBundleId: null,
      OR: [
        { itemSets: { none: {} } },
        { itemSets: { none: { deletedAt: null } } },
      ],
    },
    obligationStartsAt: outboundLogisticsTask.offer.obligationStartsAt,
    obligationEndsAt: outboundLogisticsTask.offer.obligationEndsAt ?? undefined,
    offerType: outboundLogisticsTask.offer.type as OfferType,
    machineryTypeId: outboundLogisticsTask.positionsToShip.find(p => p.machineryId)?.machinery?.typeId ?? undefined,
    selectedMachineryAccessoryCategories: offerPosition.value.machineryAccessoryCategory ? [offerPosition.value.machineryAccessoryCategory] : [],
  }
})

const { data: machineryAccessories, isLoading: isLoadingMachineryAccessories } = queryCalendar.machineryAccessoryGroupedByCategory(relatedOffer, false, false)
const { data: incompatibleMachineryAccessories, isLoading: isLoadingIncompatibleMachineryAccessories } = queryCalendar.machineryAccessoryGroupedByCategory(relatedOffer, true, false)

const isLoading = computed(() => isLoadingMachineryAccessories.value || isLoadingIncompatibleMachineryAccessories.value)

const machineryAccessoryCategory = computed(() => popupData.value?.mode === 'category' ? popupData.value.machineryAccessoryCategory : undefined)

const enteredMachineryId = ref('')

function openLogisticsIssuance(machineryIdInput: string) {
  if (!popupData.value) {
    return
  }

  let positionType: OfferPositionsToShip | undefined
  if (machineryAccessoryCategory.value) {
    positionType = 'machineryAccessoryCategory'

    openLogisticsIssuancePopup.open({ id: popupData.value.offerPositionId, taskType: popupData.value.taskType, positionType, accessoryCategory: machineryAccessoryCategory.value, machineryId: machineryIdInput })
  } else {
    if (machineryIdInput.startsWith('a')) {
      positionType = 'machineryAccessory'
    } else if (machineryIdInput.startsWith('s')) {
      positionType = 'itemSet'
    } else if (machineryIdInput.startsWith('g') || z.coerce.number().int().safeParse(machineryIdInput).success) {
      positionType = 'machinery'
    } else {
      defaultLogger.error({
        msg: 'Unknown id type',
        machineryIdInput,
      })
      errorText.value = 'Unbekannter ID-Typ'
      return
    }

    openLogisticsIssuancePopup.open({ id: popupData.value.offerPositionId, taskType: popupData.value.taskType, positionType, machineryId: machineryIdInput })
  }

  closePopup()
}

const { data: compatibleAccessoryIdsData } = queryMachineryAccessory.compatibility.getCompatibleAccessoryIds(enteredMachineryId)
const compatibleAccessoryIds = computed(() => compatibleAccessoryIdsData.value ?? [])

const errorText = ref<string | undefined>(undefined)
function submitId(machineryIdInput: string) {
  const machineryId = machineryIdInput.split('/').at(-1) ?? machineryIdInput
  enteredMachineryId.value = machineryId
  if (!popupData.value) {
    return
  }

  if (popupData.value.mode === 'id') {
    if (machineryId === popupData.value.machineryId) {
      return openLogisticsIssuance(machineryId)
    } else {
      errorText.value = 'Maschinen-ID stimmt nicht überein'
    }
  } else {
    const machineryAccessoryValue = machineryAccessories.value ?? []
    const incompatibleMachineryAccessoriesValue = incompatibleMachineryAccessories.value ?? []
    if (machineryAccessoryValue.length === 0 && incompatibleMachineryAccessoriesValue.length === 0) {
      errorText.value = 'Es gibt keine Lagertool-Anlage'
      return
    }

    const offerPositionValue = offerPosition.value
    if (!offerPositionValue) {
      // This position should never be reached: If the offerPosition does not exist / is not available, the whole issuance flow doesnt make sense anymore
      errorText.value = 'Die notwendige Position existiert nicht (mehr)'
      return
    }

    const compatibleAccessoriesFromAccessoryCategory = machineryAccessoryValue.find(acc => acc.id === machineryAccessoryCategory.value)?.children ?? []
    const incompatibleAccessoriesFromAccessoryCategory = incompatibleMachineryAccessoriesValue.find(acc => acc.id === machineryAccessoryCategory.value)?.children ?? []
    const accessoriesFromAccessoryCategory = compatibleAccessoriesFromAccessoryCategory.concat(incompatibleAccessoriesFromAccessoryCategory)
    if (accessoriesFromAccessoryCategory.length === 0) {
      errorText.value = 'Es gibt keine verfügbaren Lagertool-Anlage für die Kategorie'
      return
    }

    const scannedMachinery = accessoriesFromAccessoryCategory.find(acc => acc.id === machineryId)
    if (!scannedMachinery) {
      errorText.value = 'Das gescannte Gerät fällt nicht unter die benötigte Kategorie, ist nicht kompatibel, ist in einem Bündel oder in einem Set'
      return
    }

    const filters = accessoryFilterConditions[popupData.value.machineryAccessoryCategory as MachineryAccessoryCategory]
    if (filters) {
      if (offerPositionValue.compatibleMachineryAccessoryId && !compatibleAccessoryIds.value.includes(offerPositionValue.compatibleMachineryAccessoryId)) {
        errorText.value = 'Das gescannte Lagertool ist nicht kompatibel'
        return
      }

      const filterKeys = Object.keys(filters) as Array<keyof MachineryAccessoryCategoryFilter>
      for (const filter of filterKeys) {
        const offerPositionFilter = offerPositionValue[filter]
        const scannedMachineryFilter = scannedMachinery[filter]

        if (offerPositionFilter === null || offerPositionFilter === '') {
          continue
        }

        if (!scannedMachineryFilter) {
          errorText.value = 'Das gescannte Gerät fällt nicht unter die erforderlichen Filter'
          return
        }

        if (!checkShoppingAccessoryFilter(filters, filter, scannedMachineryFilter, offerPositionFilter)) {
          errorText.value = 'Das gescannte Gerät fällt nicht unter die erforderlichen Filter'
          return
        }
      }
    }

    return openLogisticsIssuance(machineryId)
  }
}

watch(popupData, (newPopupData) => {
  if (!newPopupData) {
    enteredMachineryId.value = ''
    errorText.value = undefined
  }
})

const { openLogisticsIssuancePopup } = useGlobalOpeners()
const isQRScanType = computed(() => popupData.value?.type === 'scanQR')
</script>

<template>
  <ThePopup
    v-if="popupData"
    :show="Boolean(popupData)"
    :title="isQRScanType ? `Scanne ${popupData.mode === 'id' ? popupData.machineryId : machineryAccessoryCategoryToGerman[popupData.machineryAccessoryCategory as MachineryAccessoryCategory]}` : 'Geben Sie die ID an'"
    @close="closePopup"
  >
    <div v-if="errorText">
      <span class="font-bold text-red-500">{{ errorText }} (ID: {{ enteredMachineryId }})</span>
    </div>
    <TheQRCodeScanner v-if="isQRScanType" @decode-qr-code="submitId" />
    <div v-else class="flex flex-col gap-4">
      <FormKit
        type="form"
        @submit="submitId(enteredMachineryId)"
      >
        <n-input v-model:value="enteredMachineryId" :disabled="isLoading" class="mb-2" placeholder="g06000" />
        <template #actions>
          <n-button type="primary" :loading="isLoading" attr-type="submit">
            Speichern
          </n-button>
        </template>
      </FormKit>
    </div>
  </ThePopup>
</template>
