<script setup lang="ts">
import { isSameDay } from 'date-fns'
import { offerStatusToGerman } from '~/translations'
import type { ApiCalendarMachinery, ApiCustomerGetAll, OfferStatus } from '~/types'

const props = defineProps<{
  offer: ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer']
  invoices: ApiCalendarMachinery['relatedOfferPositions'][number]['invoices']
  customersById: Record<string, ApiCustomerGetAll>
  machineryId?: string | null
  machineryAccessoryId?: string | null
  dateOfColumn?: Date
}>()

const saleSpecificColumns: OneDataColumn<ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer']>[] = [{
  title: 'Verkaufsdatum',
  key: 'customerId',
  render: offer => useDateAsString(offer.obligationStartsAt),
}]
const rentalSpecificColumns: OneDataColumn<ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer']>[] = [{
  title: 'Startdatum',
  key: 'customerId',
  render: offer => useDateAsString(offer.obligationStartsAt, 'dd.MM.yyyy'),
}, {
  title: 'Enddatum',
  key: 'customerId',
  render: ({ obligationEndsAt }) => obligationEndsAt ? useDateAsString(obligationEndsAt, 'dd.MM.yyyy') : 'N/A',
}]

const columns: OneDataColumn<ApiCalendarMachinery['relatedOfferPositions'][number]['relatedOffer']>[] = [
  {
    title: 'Status',
    key: 'status',
    render: ({ status }) => offerStatusToGerman[status as OfferStatus],
  },
  {
    title: 'Firma',
    key: 'customerId',
    render: offer => props.customersById[offer.customerId].name ?? 'N/A',
  },
  {
    title: 'Telefon',
    key: 'customerId',
    render: offer => props.customersById[offer.customerId].telephone ?? 'N/A',
  },
  {
    title: 'Lieferort',
    key: 'deliveryLocation',
    render: offer => offer.deliveryLocation ?? 'N/A',
  },
  {
    title: 'ID',
    key: 'id',
    render: offer => offer.id ?? 'N/A',
  },
  ...(props.offer.type === 'sale' ? saleSpecificColumns : rentalSpecificColumns),
  {
    title: 'Abgemeldet zum',
    key: 'obligationActuallyEndedAt',
    render: ({ obligationActuallyEndedAt }) => obligationActuallyEndedAt
      ? useDateAsString(obligationActuallyEndedAt, 'dd.MM.yyyy')
      : 'N/A',
    isVisible: () => isRentalOrder.value,
  },
  {
    title: 'Ansprechpartner Name',
    key: 'claimingPartnerName',
    render: offer => offer.claimingPartnerName ?? 'N/A',
  },
  {
    title: 'Ansprechpartner Email',
    key: 'claimingPartnerEmail',
    render: offer => offer.claimingPartnerEmail ?? 'N/A',
  },
  {
    title: 'Ansprechpartner Telefon',
    key: 'claimingPartnerTelephone',
    render: offer => offer.claimingPartnerTelephone ?? 'N/A',
  },
  {
    title: 'Kommentar-Anzahl',
    key: '_count',
    render: ({ _count }) => _count.comments,
  },
]

const isTerminationPossible = computed(() => props.offer.logisticsTasks.some(t => t.status === 'delivered') && props.offer.positions.some(t => !t.terminatedDate))
const isInTerminatedState = computed(() => props.offer.positions.some(t => t.terminatedDate))
const machineryOrAccessoryPositionId = computed(() => {
  if (props.machineryId) {
    return props.offer.positions.find(p => p.machineryId === props.machineryId)?.id
  }
  if (props.machineryAccessoryId) {
    return props.offer.positions.find(p => p.machineryAccessoryId === props.machineryAccessoryId)?.id
  }
  return undefined
})

const rentalOrSaleOfferType = computed(() => ['rental', 'sale'].includes(props.offer.type)
  ? (props.offer.type as 'rental' | 'sale')
  : undefined)

const isRentalOrder = computed(() => props.offer.type === 'rental' && props.offer.status === 'order')

const isPositionPaid = computed(() => {
  return props.invoices.find((invoice) => {
    if (!props.dateOfColumn) {
      return false
    }
    return invoice.invoicedDate
      && isSameDay(invoice.invoicedDate, props.dateOfColumn)
      && invoice.invoice.status === 'paid'
  })
})

const router = useRouter()

const { openOfferPage, openOfferTerminationPopup, openInvoicingPopup } = useGlobalOpeners()
</script>

<template>
  <n-popover trigger="hover" class="w-[460px] lg:w-auto lg:min-w-[512px] lg:max-w-[650px]">
    <template #trigger>
      <slot />
    </template>
    <TableOneDataColumn :config="{ columns, data: offer }" />
    <OfferPositionStatusTimeline
      v-if="machineryOrAccessoryPositionId && rentalOrSaleOfferType"
      class="mt-4 pb-2 overflow-x-scroll"
      :offer-type="rentalOrSaleOfferType"
      :position-id="machineryOrAccessoryPositionId"
      horizontal
    />
    <n-button class="w-full mt-2" @click="openOfferPage({ mode: 'edit', id: offer.id, redirectUrl: router.currentRoute.value.fullPath })">
      <template #icon>
        <Icon name="material-symbols:edit-outline-rounded" />
      </template>{{ offer.status === 'offer' ? 'Angebot' : 'Auftrag' }} bearbeiten
    </n-button>
    <n-button
      v-if="isRentalOrder"
      class="w-full mt-2"
      :disabled="!isTerminationPossible"
      @click="openOfferTerminationPopup.open({ id: offer.id, dateOfColumn, type: 'termination' })"
    >
      <template #icon>
        <Icon name="material-symbols:cancel-outline" />
      </template>Auftrag zu diesem Tag abmelden
    </n-button>
    <n-button
      v-if="isRentalOrder && isInTerminatedState"
      class="w-full mt-2"
      @click="openOfferTerminationPopup.open({ id: offer.id, dateOfColumn, type: 'revertTermination' })"
    >
      <template #icon>
        <Icon name="material-symbols:history" />
      </template>Abmeldung rückgängig machen
    </n-button>
    <n-button
      v-if="isPositionPaid"
      class="w-full mt-2"
      @click="openInvoicingPopup.open({ offerId: offer.id })"
    >
      <template #icon>
        <Icon :name="useBrandIcons().invoice" />
      </template>Rechnungen einsehen
    </n-button>
  </n-popover>
</template>
