// A collection of Icons that we use in many places inside the application
const brandingIcons = {
  machinery: 'material-symbols:forklift-outline-rounded',
  machineryAccessory: 'material-symbols:cable-rounded',
  machineryType: 'material-symbols:type-specimen-outline-rounded',
  set: 'material-symbols:dataset-outline-rounded',
  offer: 'material-symbols:receipt-outline-rounded',
  order: 'material-symbols:order-play-outline-rounded',
  orderCompleted: 'material-symbols:order-approve-outline-rounded',
  orderCancelled: 'material-symbols:cancel-outline-rounded',
  orderPlaning: 'material-symbols:edit-calendar-outline-rounded',
  orderInquiry: 'material-symbols:unknown-document-outline-rounded',
  orderDraft: 'material-symbols:edit-document-outline',
  rental: 'material-symbols:acute-outline-rounded',
  sale: 'material-symbols:shopping-cart-checkout-rounded',
  specialOrder: 'material-symbols:award-star-outline-rounded',
  serviceProject: 'material-symbols:design-services-outline-rounded',
  customer: 'material-symbols:contacts-outline-rounded',
  logistics: 'material-symbols:package-2-outline',
  invoice: 'material-symbols:receipt-long-outline-rounded',
}
export default () => brandingIcons
