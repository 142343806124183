import { customerModuleRoles } from '~/authorization'

export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith('/auth')) {
    return
  }

  const { canAccessPage, role } = useAuthorization()

  // Redirect external service project stakeholder to the only page they have access to
  if (role.value === 'external-service-project-stakeholder' && !to.path.startsWith('/my-hos/service-project/reporting')) {
    return navigateTo('/my-hos/service-project/reporting')
  }

  const isCustomerModuleUser = customerModuleRoles.includes(role.value)
  // Redirect customer-users to the other landing page
  if (isCustomerModuleUser && to.path === '/') {
    return navigateTo('/my-hos')
  }

  if (!canAccessPage(to.path) && !to.path.startsWith('/my-hos') && isCustomerModuleUser) {
    return navigateTo('/my-hos')
  }
})
