<script setup lang="ts">
const { t: $t } = useI18n()

const {
  openInvoiceEmailRecipientPopup: { data: payload, close: closePopup },
} = useGlobalOpeners()

const { invoice: queryInvoice } = useQuery()
const { data: invoice } = queryInvoice.byId(computed(() => payload.value?.id))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const customerInvoiceEmail = ref<string | undefined>(invoice.value?.offer?.customer?.invoiceEmail || undefined)
const isSendingMail = ref(false)

watch(invoice, (newInvoice) => {
  const newOffer = newInvoice?.offer
  if (newOffer) {
    const invoiceRecipientEmail = newOffer.otherInvoiceRecipient?.email ?? newOffer.customer.invoiceEmail
    customerInvoiceEmail.value = invoiceRecipientEmail || undefined
  }
})

const sendInvoiceMail = useMutation({
  mutationFn: ({ invoiceId, email }: { invoiceId: string, email?: string }) => {
    isSendingMail.value = true
    notification.info({ title: $t('invoice.customer.email.notification.transfer'), duration: 3500 })
    return $trpc.invoice.emailInvoiceToCustomer.mutate({ id: invoiceId, email })
  },
  onError: makeTrpcErrorToast(notification, { description: $t('invoice.customer.email.notification.error') }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['invoice'] })
    notification.success({ title: $t('invoice.customer.email.notification.success'), duration: 4500 })
    closePopup()
  },
})
</script>

<template>
  <ThePopup v-if="payload && invoice" :show="Boolean(payload && invoice)" :title="$t('invoice.customer.email.confirmDelivery')" @close="closePopup">
    <div class="flex flex-col gap-2">
      <p>
        {{ $t('invoice.customer.email.confirmation.message') }}
      </p>
      <FormKit
        type="form"
        :disabled="isSendingMail"
        @submit="sendInvoiceMail.mutate({ invoiceId: invoice.id, email: customerInvoiceEmail })"
      >
        <FormKit
          v-model="customerInvoiceEmail"
          type="email"
          label="E-mail"
          validation="email|required"
          validation-visibility="live"
          :placeholder="$t('invoice.customer.email.address.placeholder')"
        />

        <template #actions>
          <div class="flex items-center gap-2 justify-end">
            <n-button
              type="primary"
              :disabled="isSendingMail"
              attr-type="submit"
            >
              {{ $t('invoice.customer.email.button.sendEmail') }}
            </n-button>
            <n-button
              :disabled="isSendingMail"
              @click="closePopup"
            >
              {{ $t('invoice.customer.email.button.cancel') }}
            </n-button>
          </div>
        </template>
      </FormKit>
    </div>
  </ThePopup>
</template>
