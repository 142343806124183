<script setup lang="ts">
const { t: $t } = useI18n()

import type { ApiMachineryDriveGetAll, ApiMachineryTypeGetAll, CalendarAttachedAccessorySearchFilter, CalendarMachinerySearchFilter, MachineryAccessoryCategory } from '~/types'
import { machineryRubrics, machineryTireColors, machineryTireTypes, offerPositionMachineryCategoryFilterMasts, offerPositionMachineryCategoryFilterSpecialEquipments, offerPositionMachineryCategoryFilterTireCounts } from '~/server/schemas'

const props = defineProps<{
  machineryTypes: ApiMachineryTypeGetAll[]
  machineryDrives: ApiMachineryDriveGetAll[]
}>()
const filters = defineModel<CalendarMachinerySearchFilter>('filters', { required: true })
const attachedAccessoriesFilters = defineModel<Map<string, Partial<Omit<CalendarAttachedAccessorySearchFilter, 'category'>>>>('attachedAccessoriesFilters', { required: true })
const showMachineryLocationRow = defineModel<boolean | undefined>('showMachineryLocationRow', { required: true })

const selectedMachineAccessoryCategories = computed(() => Array.from(attachedAccessoriesFilters.value.keys()))

// Open newly selected category collapsible
const expandedCategory = ref<string[]>([])
watch(selectedMachineAccessoryCategories, (newVal, oldVal) => {
  if (!newVal || newVal.length === 0) {
    expandedCategory.value = []
  }
  if (!oldVal || oldVal.length === 0) {
    expandedCategory.value = newVal
  } else {
    expandedCategory.value = newVal.filter(category => !oldVal.includes(category))
  }
})

/** Options */
const rubricOptions = useTranslatedSelectOptions('machinery.rubric', machineryRubrics.options)
const machineryTypeOptions = computed(() => props.machineryTypes.map(type => ({
  label: type.name,
  value: type.id,
})))
const machineryDrivesOptions = computed(() => props.machineryDrives.map(drive => ({
  label: $t(`machinery.drive.${drive.name}`),
  value: drive.id,
})))
const mastOptions = useTranslatedSelectOptions('machinery.mast', offerPositionMachineryCategoryFilterMasts.options)
const specialEquipmentOptions = useTranslatedSelectOptions('machinery.specialEquipment', offerPositionMachineryCategoryFilterSpecialEquipments.options)
const tireTypeOptions = machineryTireTypes.options.map(option => ({ value: option, label: option }))
const tireCountOptions = offerPositionMachineryCategoryFilterTireCounts.options.map(option => ({ value: option, label: option }))
const tireColorOptions = machineryTireColors.options.map(option => ({ value: option, label: option }))
const initialLiftOptions = [
  {
    label: $t('general.no'),
    value: 0,
  },
  {
    label: $t('general.yes'),
    value: 1,
  },
]

const machineryAttachedAccessoryCategories: MachineryAccessoryCategory[] = ['fork', 'sideShifter', 'forkAdjuster', 'forkCarriage', 'charger']
const machineryAttachedAccessoryCategoryOptions = useTranslatedSelectOptions('machineryAccessory.category', machineryAttachedAccessoryCategories)

const machineryAccessoryCategoriesWithProducerName: MachineryAccessoryCategory[] = ['sideShifter', 'forkAdjuster', 'forkCarriage']

/** Functions for updating filters */
function resetMachineAccessoryFilters() {
  attachedAccessoriesFilters.value.clear()
}

function resetFilters() {
  Object.keys(filters.value).forEach(key => filters.value[key as keyof CalendarMachinerySearchFilter] = null)
  showMachineryLocationRow.value = false
  resetMachineAccessoryFilters()
}

function updateAttachedAccessories(categories: string[]) {
  // Delete all categories that are not selected anymore
  for (const category of attachedAccessoriesFilters.value.keys()) {
    if (!categories.includes(category)) {
      attachedAccessoriesFilters.value.delete(category)
    }
  }

  // Add all selected categories that are not in the map yet
  for (const category of categories) {
    if (!attachedAccessoriesFilters.value.has(category)) {
      attachedAccessoriesFilters.value.set(category, {})
    }
  }
}

/** Helpers */
function integerValidator(value: number) {
  return Number.isInteger(value)
}
</script>

<template>
  <n-collapse>
    <n-collapse-item>
      <template #header>
        <span class="text-lg">
          Geräte-spezifische Filter ausklappen
        </span>
      </template>
      <div class="flex flex-col gap-3 bg-gray-200 p-3 pb-0 rounded-sm">
        <div class="grid grid-cols-2 xl:grid-cols-3 gap-3">
          <div>
            <p class="font-semibold">
              Rubrik
            </p>
            <n-select
              v-model:value="filters.machineryRubric"
              :options="rubricOptions"
              :consistent-menu-width="false"
              filterable
              clearable
            />
          </div>
          <div>
            <p class="font-semibold">
              Gerätetyp
            </p>
            <n-select
              v-model:value="filters.typeId"
              :options="machineryTypeOptions"
              :consistent-menu-width="false"
              filterable
              clearable
            />
          </div>
          <div>
            <p class="font-semibold">
              Antrieb
            </p>
            <n-select
              v-model:value="filters.driveId"
              :options="machineryDrivesOptions"
              :consistent-menu-width="false"
              filterable
              clearable
            />
          </div>

          <TheMinMaxInput
            v-model:min="filters.minLiftingWeight"
            v-model:max="filters.maxLiftingWeight"
            label="Tragkraft (kg)"
          />

          <TheMinMaxInput
            v-model:min="filters.minLiftingHeightInMillimeters"
            v-model:max="filters.maxLiftingHeightInMillimeters"
            label="Hubhöhe (mm)"
          />

          <TheMinMaxInput
            v-model:min="filters.minOverallWidthInMillimeters"
            v-model:max="filters.maxOverallWidthInMillimeters"
            label="Baubreite (mm)"
          />

          <TheMinMaxInput
            v-model:min="filters.minWorkHeightInMillimeters"
            v-model:max="filters.maxWorkHeightInMillimeters"
            label="Arbeitshöhe (mm)"
          />

          <TheMinMaxInput
            v-model:min="filters.minHeightInMillimeters"
            v-model:max="filters.maxHeightInMillimeters"
            label="Bauhöhe (mm)"
          />

          <TheMinMaxInput
            v-model:min="filters.minPlatformLengthInMillimeters"
            v-model:max="filters.maxPlatformLengthInMillimeters"
            :label="$t('receptionMachinery.field.platformLength.name')"
          />

          <TheMinMaxInput
            v-model:min="filters.minPlatformWidthInMillimeters"
            v-model:max="filters.maxPlatformWidthInMillimeters"
            :label="$t('receptionMachinery.field.platformWidth.name')"
          />

          <TheMinMaxInput
            v-model:min="filters.minTotalLengthInMillimeters"
            v-model:max="filters.maxTotalLengthInMillimeters"
            :label="$t('receptionMachinery.field.totalLength.name')"
          />

          <TheMinMaxInput
            v-model:min="filters.minSideReachInMillimeters"
            v-model:max="filters.maxSideReachInMillimeters"
            :label="$t('receptionMachinery.field.sideReach.name')"
          />

          <TheMinMaxInput
            v-model:min="filters.minWeight"
            v-model:max="filters.maxWeight"
            :label="$t('machinery.weight.label')"
          />

          <TheMinMaxInput
            v-model:min="filters.minTractionForce"
            v-model:max="filters.maxTractionForce"
            :label="$t('receptionMachinery.field.tractionForce.name')"
          />

          <TheMinMaxInput
            v-model:min="filters.minHookHeight"
            v-model:max="filters.maxHookHeight"
            :label="$t('receptionMachinery.field.hookHeight.name')"
          />

          <TheMinMaxInput
            v-model:min="filters.minBasketLoad"
            v-model:max="filters.maxBasketLoad"
            :label="$t('receptionMachinery.field.basketLoad.name')"
          />

          <TheMinMaxInput
            v-model:min="filters.minDimensionHeightInMillimeters"
            v-model:max="filters.maxDimensionHeightInMillimeters"
            :label="$t('common.field.height.name')"
          />

          <div>
            <p class="font-semibold">
              {{ $t('receptionMachinery.field.mast.name') }}
            </p>
            <n-select
              v-model:value="filters.mast"
              :options="mastOptions"
              filterable
              clearable
            />
          </div>

          <div>
            <p class="font-semibold">
              {{ $t('receptionMachinery.field.seats.name') }}
            </p>
            <n-input-number
              v-model:value="filters.seats"
              :min="0"
              :validator="integerValidator"
              step="1"
              placeholder="2"
            />
          </div>

          <div>
            <p class="font-semibold">
              {{ $t('machinery.field.specialEquipment.name') }}
            </p>
            <n-select
              v-model:value="filters.specialEquipment"
              :options="specialEquipmentOptions"
              filterable
              clearable
            />
          </div>

          <div>
            <p class="font-semibold">
              {{ $t('machinery.tireCount.label') }}
            </p>
            <n-select
              v-model:value="filters.tireCount"
              :options="tireCountOptions"
              clearable
            />
          </div>

          <div>
            <p class="font-semibold">
              {{ $t('machinery.tireType.label') }}
            </p>
            <n-select
              v-model:value="filters.tireType"
              :options="tireTypeOptions"
              clearable
            />
          </div>

          <div>
            <p class="font-semibold">
              {{ $t('machinery.tireColor.label') }}
            </p>
            <n-select
              v-model:value="filters.tireColor"
              :options="tireColorOptions"
              clearable
            />
          </div>

          <div>
            <p class="font-semibold">
              {{ $t('receptionMachinery.field.initialLift.name') }}
            </p>
            <n-select
              :options="initialLiftOptions"
              clearable
              @update:value="filters.initialLift = $event === null ? undefined : Boolean($event)"
            />
          </div>
        </div>

        <div class="flex flex-col">
          <div class="grid grid-cols-3 gap-2">
            <TheMinMaxInput
              v-model:min="filters.minOperatingHours"
              v-model:max="filters.maxOperatingHours"
              :label="$t('receptionMachinery.field.operatingHours.name')"
            />
            <div class="flex items-end">
              <n-checkbox v-model:checked="showMachineryLocationRow" type="checkbox" class="shrink-0">
                <span class="font-semibold">
                  Geräte-Standort anzeigen
                </span>
              </n-checkbox>
            </div>
          </div>
          <div class="my-4">
            Eine 10%-Toleranz wird auf die Filter für Tragkraft und Hubhöhe angewendet
          </div>
        </div>

        <div class="flex flex-col gap-2 bg-gray-200 p-3 rounded-sm">
          <div class="flex flex-col">
            <span class="text-lg mt-0">
              {{ $t('calendar.filters.attachedMachineryAccessory.title') }}
            </span>
            {{ $t('calendar.filters.attachedMachineryAccessory.description') }}
          </div>
          <div class="grid grid-cols-3 gap-2">
            <div>
              <p class="font-semibold">
                {{ $t('machineryAccessory.category.title') }}
              </p>
              <n-select
                :value="selectedMachineAccessoryCategories"
                :options="machineryAttachedAccessoryCategoryOptions"
                :consistent-menu-width="false"
                multiple
                filterable
                clearable
                @update:value="updateAttachedAccessories($event as string[])"
              />
            </div>
          </div>

          <n-collapse v-model:expanded-names="expandedCategory" class="mt-2">
            <n-collapse-item
              v-for="[category, accessoryFilters] in attachedAccessoriesFilters.entries()"
              :key="category"
              :title="$t(`machineryAccessory.category.${category}`)"
              :name="category"
            >
              <div v-if="category === 'fork' satisfies MachineryAccessoryCategory" class="grid grid-cols-3 gap-2">
                <TheMinMaxInput
                  v-model:min="accessoryFilters.minLengthInMillimeters"
                  v-model:max="accessoryFilters.maxLengthInMillimeters"
                  :label="$t('general.length_mm')"
                />

                <TheMinMaxInput
                  v-model:min="accessoryFilters.minWidthInMillimeters"
                  v-model:max="accessoryFilters.maxWidthInMillimeters"
                  :label="$t('general.width_mm')"
                />

                <TheMinMaxInput
                  v-model:min="accessoryFilters.minHeightInMillimeters"
                  v-model:max="accessoryFilters.maxHeightInMillimeters"
                  :label="$t('general.height_mm')"
                />
              </div>
              <div v-if="machineryAccessoryCategoriesWithProducerName.includes(category as MachineryAccessoryCategory)" class="grid grid-cols-3 gap-2">
                <div>
                  <p class="font-semibold">
                    {{ $t('machineryAccessory.producerCompanyName') }}
                  </p>
                  <n-input
                    v-model:value="accessoryFilters.producerCompanyName"
                    filterable
                    clearable
                  />
                </div>

                <div>
                  <p class="font-semibold">
                    {{ $t('machineryAccessory.liftingWeight') }}
                  </p>
                  <n-input-number
                    v-model:value="accessoryFilters.minLiftingWeight"
                    type="text"
                    :min="0"
                    placeholder="0"
                  >
                    <template #prefix>
                      {{ $t('general.min') }}
                    </template>
                  </n-input-number>
                </div>
              </div>
              <div v-if="category === 'charger' satisfies MachineryAccessoryCategory" class="grid grid-cols-3 gap-2">
                <div>
                  <p class="font-semibold">
                    {{ $t('machineryAccessory.volt') }}
                  </p>
                  <n-input
                    v-model:value="accessoryFilters.volt"
                    filterable
                    clearable
                  />
                </div>
              </div>
            </n-collapse-item>
          </n-collapse>
          <div class="flex items-end w-full justify-end my-4">
            <n-button class="!bg-white" @click="resetFilters">
              Alle zurücksetzen
            </n-button>
          </div>
        </div>
      </div>
    </n-collapse-item>
  </n-collapse>
</template>
