<script lang="ts" setup>
withDefaults(defineProps<{
  src?: string | null
  width?: string
  height?: string
  placeholder?: {
    iconSize?: string
    withText?: boolean
  }
}>(), { width: '10rem', height: '10rem' })

const isLoaded = ref(false)

// onError event for UnLazyImage does not fire correctly.
// Workaround for showing ImagePlacerholder if the main image did not load in 5 seconds
const hasWaitedForLoad = ref(false)
const hasError = computed(() => hasWaitedForLoad.value && !isLoaded.value)
onMounted(() => setTimeout(() => hasWaitedForLoad.value = true, 5000))

// Generated via https://blurha.sh/ with an image of a forklift provided by HOS
const FALLBACK_BLURHASH = 'LkHCG[D%x]WV?wRjs:WB-;xuV@of'
</script>

<template>
  <UnLazyImage
    v-if="src && !hasError"
    :src="src"
    :blurhash="FALLBACK_BLURHASH"
    class="object-cover object-center rounded"
    :style="{ width, height }"
    @loaded="() => isLoaded = true"
  />
  <CustomerModuleImagePlaceholder v-else :width :height :placeholder="placeholder" class="rounded" />
</template>
