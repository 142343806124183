<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'

const props = defineProps<{ payload: ApiMachineryGetById }>()
const emit = defineEmits<{ (e: 'save', payload: ApiMachineryGetById): void }>()

const { cloned } = useCloned(props.payload)

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit v-model="cloned" type="form" :plugins="[formkitPlugins]" submit-label="Dokumente speichern" @submit="() => emit('save', cloned)">
    <FormKitFileDropzone
      id="documentCEFiles"
      label="CE"
    />
    <FormKitFileDropzone
      id="documentSparePartsCatalogFiles"
      label="Ersatzteilkatalog"
    />
    <FormKitFileDropzone
      id="documentCircuitDiagramsFiles"
      label="Schaltpläne"
    />
    <FormKitFileDropzone
      id="documentWeightTest"
      label="Gewichtstest"
    />
  </FormKit>
</template>
