<script setup lang="ts">
import { z } from 'zod'

const props = defineProps<{
  context: { value: string[], node: { input: (v: Date | null) => unknown } } & Record<string, unknown>
}>()

const formKitDateTimeValueSchema = z.union([z.string(), z.date(), z.number()])

function handleInput(e: Event) {
  const target = e.target
  if (target) {
    // @ts-expect-error Value does exist on the target
    const valueToUpdate = z.coerce.date().safeParse(target.value).success ? new Date(target.value) : null
    props.context.node.input(valueToUpdate)
  }
}
const value = computed(() => {
  try {
    const value = formKitDateTimeValueSchema.parse(props.context._value)
    return useDateAsString(value, 'yyyy-MM-dd\'T\'HH:mm')
  } catch (error) {
    return null
  }
})
</script>

<template>
  <!-- Styles here are copied over from mounted instances of genesis-tailwind-themed formkit inputs -->
  <div
    class="formkit-inner formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none bg-white flex items-center max-w-md ring-1 ring-gray-400 focus-within:ring-blue-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded mb-1"
  >
    <input
      class="formkit-input appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none w-full px-3 py-2 border-none text-base text-gray-700 placeholder-gray-400"
      type="datetime-local"
      :value="value"
      @input="handleInput"
    >
  </div>
</template>
