import type { File, FileFolder } from '@prisma/client'
import type { FileFolderAction } from './actions'
import type { FileFolderEntity } from './entities'
import { type UserRole, internalRoles } from '~/authorization'

interface User {
  role: UserRole
  email: string
}

export interface PermissionConfigParams {
  currFolder?: Pick<FileFolder, 'isCreatedBySystem'> | null
  currFile?: Pick<File, 'createdByEmail'> | null
}
type PermissionConfig = Record<FileFolderAction, Readonly<UserRole[]> | ((user: User, param: PermissionConfigParams) => boolean)>

export const FILEFOLDER_ADMIN_ROLES: UserRole[] = ['admin', 'department-lead']
const customerRoles: UserRole[] = ['admin', 'project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'service-project-employee', 'site-manager', 'service-project-fitter', 'special-role-benni']

/** Permission Config */
const permissionConfigs: Readonly<Record<FileFolderEntity, PermissionConfig>> = {
  offer: {
    View: internalRoles,
    MoveFile: internalRoles,
    UpdateFiles: internalRoles,
    Create: FILEFOLDER_ADMIN_ROLES,
    UpdateFolder: FILEFOLDER_ADMIN_ROLES,
    Delete: FILEFOLDER_ADMIN_ROLES,
    MoveFolder: moveFolder,
  },

  customer: {
    View: customerRoles,
    MoveFile(user: User, { currFile }: PermissionConfigParams) {
      if (
        !currFile // No current file
        || !isAuthedUser(user.role, customerRoles) // User isn't a customer role
        || (!FILEFOLDER_ADMIN_ROLES.includes(user.role) && currFile.createdByEmail !== user.email) // User isn't an admin and the file isn't created by the user
      ) {
        return false
      }
      return true
    },
    UpdateFiles: customerRoles,
    Create: FILEFOLDER_ADMIN_ROLES,
    UpdateFolder: FILEFOLDER_ADMIN_ROLES,
    Delete: FILEFOLDER_ADMIN_ROLES,
    MoveFolder: moveFolder,
  },
}

export function canAccessFileFolder(action: FileFolderAction, user: User, entity: FileFolderEntity, configParams?: PermissionConfigParams): boolean {
  const allowedRoles = permissionConfigs[entity][action]
  if (typeof allowedRoles === 'function') {
    if (!configParams) {
      return false
    }
    return allowedRoles(user, configParams)
  }
  return isAuthedUser(user.role, allowedRoles)
}

/** Utility functions */
function moveFolder(user: User, { currFolder }: PermissionConfigParams) {
  if (!currFolder) {
    return false
  }
  if (!currFolder.isCreatedBySystem && isAuthedUser(user.role)) {
    return true
  }
  return false
}

function isAuthedUser(userRole: UserRole, allowedRoles: Readonly<UserRole[]> = FILEFOLDER_ADMIN_ROLES): boolean {
  return allowedRoles.includes(userRole)
}
