<script setup lang="ts">
import type { ApiMachineryAccessoryGetById, FilePath } from '~/types'

interface UVVData {
  id: string
  lastUVVDate: Date | null
  documentUVVFiles: FilePath[]
}

defineProps<{ payload: ApiMachineryAccessoryGetById }>()
const emit = defineEmits<{ (e: 'save', data: UVVData): void }>()

function submitUVVForm(data: UVVData) {
  emit('save', data)
}
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    :submit-label="$t('button.save')"
    @submit="submitUVVForm"
  >
    <FormKitDate
      name="lastUVVDate"
      :label="$t('machinery.field.lastUVVDate.name')"
    />
    <FormKitFileDropzone name="documentUVVFiles" :label="$t('common.field.documents.name')" />
  </FormKit>
</template>
