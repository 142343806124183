/**
 * Download and open a document (PDF/XLSX) in a new tab. Optionally, data can be passed along.
 */
export default (pathToDownloadFrom: MaybeRef<string>, makeBodyForRequest?: () => Record<string, unknown> | undefined, doDownload: boolean = false) => {
  const isLoading = ref(false)

  const notification = useNotification()

  const fetch = async () => {
    isLoading.value = true

    let filename

    // 1. Download file
    const urlToFetch = unref(pathToDownloadFrom)
    const { data, error } = await useFetch<string>(urlToFetch, {
      method: 'post',
      responseType: 'blob',
      body: makeBodyForRequest ? makeBodyForRequest() : undefined,
      onResponse(context) {
        filename = context.response.headers.get('Content-Disposition')?.split('filename=')[1]
      },
    })

    if (error.value) {
      // Get error data from h3
      const errorData = await (error.value.data && error.value.data instanceof Blob
        ? error.value.data.text().then(t => JSON.parse(t)).catch(() => undefined)
        : undefined)

      const description = errorData && errorData.message
        ? `${errorData.message}\nFehler: ${error.value.message ?? 'Unbekannter Fehler'}`
        : `Bitte probieren Sie es später erneut\nFehler: ${error.value.message ?? 'Unbekannter Fehler'}`

      notification.error({ title: 'Dateien konnten nicht geladen werden', description, duration: 5000 })
      isLoading.value = false
      return
    }

    // @ts-expect-error ts doesn't know fetch return type
    const url = useObjectUrl(data.value)
    if (!url.value) {
      notification.error({ title: 'Dateien konnten nicht geladen werden', description: 'Download-URL invalide', duration: 5000 })
      isLoading.value = false
      return
    }

    // 2. Construct hidden element to trigger click + open for end-user
    const a = document.createElement('a')
    document.body.append(a)
    a.href = url.value
    a.target = '_blank'

    // Directly download file without opening in new tab
    // Use set filename (from 'Content-Disposition' header) if provided
    if (filename && doDownload) {
      a.download = filename
    }

    // 3. Trigger open for end-user
    a.click()

    // 4. Cleanup: remove the hidden element
    // URL will be automatically revoked when data changes or component is unmounted: https://vueuse.org/core/useObjectUrl
    a.remove()

    isLoading.value = false
  }

  return { fetch, isLoading }
}
