<script setup lang="ts">
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { openDefectRepairPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { defect: queryDefect } = useQuery()
const { data: defect, isLoading: isLoadingDefect } = queryDefect.byId(computed(() => popupData.value?.id))

const repair = useMutation({
  mutationFn: $trpc.defect.repair.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Defekt konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['defect'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
    ])
    closePopup()
    notification.success({ title: 'Der Defekt wurde erfolgreich aktualisiert', duration: 4500 })
  },
})
const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <ThePopup v-if="defect && !isLoadingDefect" :show="Boolean(defect)" :title="`Reparatur ${defect[`${defect.type}Id`]}`" @close="closePopup">
    <CommentList :id="defect.id" type="Defect" class="mb-5" />
    <FormKit type="form" :plugins="[formkitPlugins]" submit-label="Speichern und als &quot;Repariert&quot; markieren" @submit="repair.mutate({ id: defect.id })" />
  </ThePopup>
</template>
