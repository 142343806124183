<script setup lang="ts">
import type { InvoiceToOfferPositionRelation } from '~/server/trpc/routers/invoice'
import type { CreateInvoiceExtraDaysPosition } from '~/types'
import { createInvoiceExtraDaysPositionSchema } from '~/server/schemas'

const props = defineProps<{ isShown: boolean, invoiceOfferPosition: InvoiceToOfferPositionRelation }>()
const emits = defineEmits<{ (e: 'close'): void, (e: 'submit', payload: CreateInvoiceExtraDaysPosition): void }>()

const quantity = ref(0)

function submit() {
  const extraDaysPosition = {
    ...props.invoiceOfferPosition,
    title: `Zusätzliche Mietdauer (${props.invoiceOfferPosition.title})`,
    type: 'extraDays' as const,
    quantity: quantity.value,
    offerPositionToExtendId: props.invoiceOfferPosition.id,
    groupType: 'InvoiceRelated',
  }

  emits('submit', createInvoiceExtraDaysPositionSchema.parse(extraDaysPosition))
}
</script>

<template>
  <ThePopup
    v-if="isShown"
    :show="Boolean(isShown)"
    :title="`Zusätzliche Miettage für ${invoiceOfferPosition.title} hinzufügen`"
    @close="emits('close')"
  >
    <FormKit
      type="form"
      submit-label="Speichern"
      @submit="submit"
    >
      <FormKit
        v-model="quantity"
        label="Anzahl zusätzlicher Miettage"
        type="number"
        number="integer"
        validation="required|min:0"
      />

      <p class="py-3">
        Das Datum der Abmeldung bleibt erhalten. Die abzurechnende Mietdauer verlängert sich aber um die oben angegebene Anzahl an Tagen nach Abmeldung.
      </p>
    </FormKit>
  </ThePopup>
</template>
