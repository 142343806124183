<script setup lang="ts">
import type { ApiItemSetGetByIdWithOngoingOfferPositions, UpdateOrCreateItemSet } from '~/types'

const props = defineProps<{
  payload: UpdateOrCreateItemSet
  isCurrentlyInOffer: boolean
  ongoingOfferPositions?: ApiItemSetGetByIdWithOngoingOfferPositions['relatedOfferPositions']
  itemSetStorageLocation?: string
  isIssuanceMode?: boolean
}>()
const emit = defineEmits<{ (e: 'submit', payload: UpdateOrCreateItemSet): void }>()

const { cloned } = useMightyCloned(props.payload)

const hasOngoingOffer = computed(() => Boolean(props.ongoingOfferPositions?.length))
const isAccessoryEditable = ref(!hasOngoingOffer.value)

const popupConfirmUpdateSetThatIsPartOfActiveOffer = ref(false)
const offerIds = computed(() => props.ongoingOfferPositions?.map(position => position.offerId).join(', ') ?? 'N/A')

const showOnlySelectedItems = ref(false)
const isCollapseOpen = ref(props.payload.mode === 'create' || props.isIssuanceMode)

const formkitPlugins = useFormkitPlugins()

function allowMachineryAccessoryUpdate() {
  isAccessoryEditable.value = true
  isCollapseOpen.value = true
}
</script>

<template>
  <TheConfirmPopup
    v-if="popupConfirmUpdateSetThatIsPartOfActiveOffer"
    positive-text="Ja"
    negative-text="Nein"
    @confirm="allowMachineryAccessoryUpdate"
    @close="popupConfirmUpdateSetThatIsPartOfActiveOffer = false"
  />
  <div>
    <n-alert v-if="isCurrentlyInOffer" class="mb-4" type="warning" :show-icon="false">
      <div class="flex items-center justify-between">
        <p>
          Das Set kann nicht aktualisiert werden, da es sich beim Kunden befindet.
        </p>
      </div>
    </n-alert>
    <n-alert v-else-if="hasOngoingOffer" class="mb-4" type="warning" :show-icon="false">
      <div class="flex items-center justify-between">
        <p>
          Das Set befindet sich in den folgenden aktiven Aufträgen: {{ offerIds }}.
        </p>
        <n-button v-if="!isAccessoryEditable" type="warning" @click="popupConfirmUpdateSetThatIsPartOfActiveOffer = true">
          Die Lagertools im Set trotzdem ändern
        </n-button>
      </div>
    </n-alert>
    <FormKit
      v-model="cloned.data"
      type="form"
      :disabled="isCurrentlyInOffer"
      :plugins="[formkitPlugins]"
      :submit-label="`${payload.mode === 'create' ? 'Set erstellen' : 'Set speichern'}`"
      @submit="emit('submit', cloned)"
    >
      <div class="flex flex-col gap-y-2">
        <div v-if="!isIssuanceMode" class="flex flex-col gap-y-2">
          <div class="flex gap-x-2">
            <FormKit
              id="title"
              type="text"
              validation="required"
              label="Titel"
              placeholder="Das Super-Heber Set"
            />
            <FormKit
              id="priceInEuro"
              class="w-full"
              type="number"
              step="0.01"
              label="Preis"
              placeholder="100"
              validation="required"
            />
            <FormKit
              id="producerCompanyName"
              type="text"
              label="Hersteller"
              placeholder="Hersteller"
            />
          </div>
          <div class="flex space-x-2">
            <FormKit
              id="typeName"
              type="text"
              label="Typ"
              placeholder="Sonstiges"
            />
            <FormKit
              id="liftingWeightInKilograms"
              class="w-full"
              type="number"
              step="0.01"
              label="Tragkraft (kg)"
              placeholder="100"
            />
            <FormKit
              id="weightInKilograms"
              class="w-full"
              type="number"
              step="0.01"
              label="Gewicht (kg)"
              placeholder="66"
            />
          </div>
          <div class="flex space-x-2">
            <FormKit
              id="transportLengthInMillimeters"
              type="number"
              step="0.01"
              label="Transport-Länge (mm)"
              placeholder="80"
            />
            <FormKit
              id="transportWidthInKilograms"
              type="number"
              step="0.01"
              label="Transport-Breite (mm)"
              placeholder="70"
            />
            <FormKit
              id="transportHeightInKilograms"
              type="number"
              step="0.01"
              label="Transport-Höhe (mm)"
              placeholder="90"
            />
          </div>
          <div class="flex space-x-2">
            <FormKit
              id="storageLocation"
              type="text"
              label="Lagerplatz"
              placeholder="Crailsheim"
            />
            <FormKit
              id="description"
              type="text"
              label="Beschreibung"
              placeholder="Scania V8"
            />
          </div>
          <div class="flex space-x-2">
            <FormKitFileDropzone
              id="photos"
              label="Fotos"
            />
            <FormKitFileDropzone
              id="documents"
              label="Dokumente"
            />
          </div>
        </div>

        <div v-if="isAccessoryEditable" class="my-2 mb-4 max-w-[90vw] lg:max-w-full">
          <n-collapse
            display-directive="show"
            :default-expanded-names="isCollapseOpen ? 'machineryAccessories' : undefined"
          >
            <n-collapse-item name="machineryAccessories">
              <template #header>
                <span class="font-semibold">
                  Lagertools verwalten
                </span>
              </template>

              <div class="flex flex-col items-end gap-2">
                <p class="font-semibold">
                  Nur ausgewählte Lagertools anzeigen
                </p>
                <n-switch v-model:value="showOnlySelectedItems" class="mb-2" />
              </div>
              <MachineryAccessoryOverviewPage
                class="mb-4"
                :show-status="['approval']"
                :additional-filters="{ id: showOnlySelectedItems ? { in: cloned.data.machineryAccessoryIds } : undefined }"
                :select-options="{ selectedKeys: cloned.data.machineryAccessoryIds, setId: payload.mode === 'update' ? payload.data.id : undefined, disableWhenMachineryAccessoryIsInOngoingOffer: true }"
                :page-size="5"
                :show-scan-button="true"
                :should-exclude-bundled-accessories="true"
                @update-checked-row-keys="keys => cloned.data.machineryAccessoryIds = keys as string[]"
              />
            </n-collapse-item>
          </n-collapse>
        </div>
      </div>
    </FormKit>
  </div>
</template>

<style scoped>
:deep(.formkit-inner) {
  max-width: 100%;
}
</style>
