<script setup lang="ts">
import type { FormKitNode } from '@formkit/core'

const props = defineProps<{ projectId: string }>()
const emit = defineEmits<{ close: [] }>()

const addressPlaceholder = 'Spitalseestraße 21, 74565 Crailsheim'

const { storageProjectAddress } = useQuery()
const useGetAllByProjectId = storageProjectAddress.allByProjectId
const { data: addressData } = useGetAllByProjectId(props.projectId)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const deleteOne = useMutation({
  mutationFn: $trpc.externalStorage.storageProjectAddress.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Lieferadressen' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['externalStorageProjectAddress'] }),
      queryClient.invalidateQueries({ queryKey: ['externalStorageProject'] }),
    ])
    notification.success({ title: 'Die Lieferadresse wurde gelöscht', duration: 4500 })
  },
})

const upsert = useMutation({
  mutationFn: $trpc.externalStorage.storageProjectAddress.upsert.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Lieferadressen' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['externalStorageProjectAddress'] }),
      queryClient.invalidateQueries({ queryKey: ['externalStorageProject'] }),
    ])
    emit('close')
    notification.success({ title: 'Die Lieferadressen wurden aktualisiert', duration: 4500 })
  },
})

function deleteAddress(node: FormKitNode, value: unknown[], index: number) {
  if (!addressData.value) {
    return
  }

  const target = addressData.value.alternativeAddresses[index]
  if (target?.id) {
    deleteOne.mutate({ id: target.id })
  }

  node.input(value.filter((_, i) => i !== index))
}
</script>

<template>
  <ThePopup
    v-if="addressData"
    title="Lieferadressen verwalten"
    show
    @close="emit('close')"
  >
    <FormKit
      v-slot="{ value }"
      type="form"
      :value="addressData"
      submit-label="Bestätigen"
      @submit="upsert.mutate({ ...$event, projectId })"
    >
      <TheDevOnlyNiceJson :data="value" />

      <FormKit
        name="standardAddress"
        type="group"
      >
        <FormKit
          name="address"
          label="Standard Lieferadresse"
          type="text"
          :placeholder="addressPlaceholder"
          validation="required"
        />
      </FormKit>

      <h3 class="font-semibold mb-2">
        Abweichende Lieferadressen
      </h3>
      <FormKit
        v-slot="{ items, node, value: nodeValue }"
        name="alternativeAddresses"
        type="list"
        dynamic
      >
        <FormKit
          v-for="(item, index) in items"
          :key="item"
          type="group"
          :index="index"
          class="flex flex-col gap-y-2"
        >
          <div class="flex gap-x-4">
            <FormKit
              name="address"
              type="text"
              :placeholder="addressPlaceholder"
              validation="required"
              :validation-messages="{
                required: 'Abweichende Lieferadresse darf nicht leer sein.',
              }"
            />

            <FormKit
              type="button"
              @click="deleteAddress(node, nodeValue, index)"
            >
              <Icon name="material-symbols:delete-outline-sharp" />
            </FormKit>
          </div>
        </FormKit>

        <n-button
          class="mb-4"
          @click="() => node.input(nodeValue.concat([{ address: '' }]))"
        >
          <template #icon>
            <Icon name="material-symbols:add" />
          </template>
          Lieferadressen hinzufügen
        </n-button>
      </FormKit>
    </FormKit>
  </ThePopup>
</template>
