<script setup lang="ts">
const { t: $t } = useI18n()

import type { ApiInputOfferUpdateReleaseToCustomer } from '~/types'

const emit = defineEmits<{
  (e: 'confirmed'): void
}>()
const payload = defineModel< ApiInputOfferUpdateReleaseToCustomer | null>({ required: true })
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const updateReleaseOfferToCustomer = useMutation({
  mutationFn: $trpc.offer.updateReleaseOfferToCustomer.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('offer.notification.updateReleaseOfferToCustomer.error') }),
  onSuccess: async (updatedOffer) => {
    await queryClient.invalidateQueries({ queryKey: ['offer'] })
    if (updatedOffer.offerReleasedToCustomerAt) {
      notification.success({ title: $t('offer.notification.releaseOfferToCustomer.success'), duration: 4500 })
    } else {
      notification.success({ title: $t('offer.notification.revertReleaseOfferToCustomer.success'), duration: 4500 })
    }
    emit('confirmed')
  },
})
</script>

<template>
  <TheConfirmPopup
    :title="$t('offer.popup.releaseToCustomer.title', { offerId: payload?.id })"
    :show="payload !== null"
    :action-button-label="$t('button.label.proceed')"
    width="600px"
    @confirm="payload && updateReleaseOfferToCustomer.mutate(payload)"
    @close="payload = null"
  >
    {{
      payload?.release
        ? $t('offer.popup.confirmReleaseToCustomer')
        : $t('offer.popup.confirmRevertReleaseToCustomer')
    }}
  </TheConfirmPopup>
</template>
