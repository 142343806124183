import { z } from 'zod'
import { useRouteQuery } from '@vueuse/router'
import { CommentType, DefectType, FileVisibility, InternalCostPositionType, StoragePositionStatus } from '~/prisma/enums'
import type { Payload as PayloadPageOffer } from '~/components/Offer/CreateOrEdit/PageContainer.vue'
import type { Payload as TaskCreateOrEditPagePayload } from '~/components/Task/CreateOrEdit/PageContainer.vue'
import type { Payload as InboundLogisticsTaskProps } from '~/components/ExternalStorage/InboundLogisticsTask/CreateOrEdit/PageContainer.vue'
import type { Payload as OutboundLogisticsTaskProps } from '~/components/ExternalStorage/OutboundLogisticsTask/CreateOrEdit/PageContainer.vue'

import type { MachineryCategories } from '~/types'
import { emailSchema, groupedPhotoGroupSchema, idObjectSchema, idSchema, invoiceType, logisticsDeliveryCommentTypeSchema, logisticsTourDirectionsSchema, machineryAccessoryCategorySchema, machineryCategories, machineryDrives, machineryRubrics, manuallyCreatableLogisticsTypes, offerStatusSchema, offerTypeSchema, userOfferMembershipCreateOrUpdateSchema } from '~/server/schemas'
import { zDiscriminatedUnion, zObject } from '~/server/schemas/zObject'

const logger = defaultLogger.withTag('GLOBAL-OPENERS')

const openPopupSchema = zObject({ open: z.literal(true) })
const offerIdSchema = zObject({ offerId: idSchema })
const offerTerminationSchema = zObject({ id: idSchema, dateOfColumn: z.coerce.date().optional(), type: z.enum(['termination', 'revertTermination']) })

const commentBaseSchema = {
  id: idSchema,
  type: z.nativeEnum(CommentType),
  isClosable: z.coerce.boolean().default(true).optional(),
  isVisibilityAdjustable: z.coerce.boolean().default(false).optional(),
  originalCommentId: idSchema.optional(),
  refreshQueries: z.array(z.nativeEnum(CommentType)).default([]).optional(),
}

const commentCreateOrUpdateSchema = zDiscriminatedUnion('mode', [
  zObject({ mode: z.literal('create') }).extend(commentBaseSchema),
  zObject({ mode: z.literal('update'), commentId: idSchema }).extend(commentBaseSchema),
])

const positionTypesWithDetailsPage = z.enum(['machinery', 'machineryAccessory', 'itemSet'])
const logisticsIssuanceBaseSchema = {
  id: idSchema,
  taskType: logisticsTourDirectionsSchema,
}
const logisticsIssuanceSchema = zDiscriminatedUnion('positionType', [
  zObject({
    positionType: positionTypesWithDetailsPage,
    machineryId: idSchema,
  }).extend(logisticsIssuanceBaseSchema),
  zObject({
    positionType: z.literal('machineryAccessoryCategory'),
    accessoryCategory: z.string(),
    machineryId: idSchema,
  }).extend(logisticsIssuanceBaseSchema),
])

const customerCreateOrEditSchema = zDiscriminatedUnion('mode', [
  zObject({ mode: z.literal('create'), routerNameToSaveCreatedId: z.string().optional() }),
  zObject({ mode: z.literal('update'), id: idSchema }),
])

const deliveryVehicleCreateOrEditSchema = zDiscriminatedUnion('mode', [
  zObject({ mode: z.literal('create') }),
  zObject({ mode: z.literal('update'), id: idSchema }),
])

const offerPrintSchema = zObject({
  offerId: idSchema,
  offerCuid: z.string(),
  type: offerTypeSchema,
})

const machineryCreateOrEditSchema = zDiscriminatedUnion('mode', [
  zObject({ mode: z.literal('create'), category: machineryCategories.optional(), typeId: idSchema.optional(), isForeignMachinery: z.boolean().optional() }),
  zObject({ mode: z.literal('update'), id: idSchema, typeId: idSchema.optional(), isForeignMachinery: z.boolean().optional() }),
])

const issuanceEnterMachineryIdSchema = zDiscriminatedUnion('mode', [zObject({
  mode: z.literal('id'),
  machineryId: idSchema,
  offerPositionId: idSchema,
  taskType: logisticsTourDirectionsSchema,
  type: z.enum(['scanQR', 'typeId']),
}), zObject({
  mode: z.literal('category'),
  machineryAccessoryCategory: z.string().min(1),
  offerPositionId: idSchema,
  taskType: logisticsTourDirectionsSchema,
  type: z.enum(['scanQR', 'typeId']),
})])

const internalCostCreateOrEditBaseSchema = zObject({
  type: z.nativeEnum(InternalCostPositionType),
  id: idSchema,
})
const internalCostCreateOrEditSchema = zDiscriminatedUnion('mode', [
  internalCostCreateOrEditBaseSchema.extend({
    mode: z.literal('create'),
  }),
  internalCostCreateOrEditBaseSchema.extend({
    mode: z.literal('update'),
    internalCostPositionId: idSchema,
  }),
])

const logisticsTaskCreateOrEditBaseSchema = zObject({ offerId: idSchema, type: manuallyCreatableLogisticsTypes })
const logisticsTaskCreateOrEditSchema = zDiscriminatedUnion('mode', [
  logisticsTaskCreateOrEditBaseSchema.extend({ mode: z.literal('create') }),
  logisticsTaskCreateOrEditBaseSchema.extend({ mode: z.literal('update'), id: idSchema }),
])

function propsToStringRecord(props: Record<string, any>) {
  const propsToRecord: Record<string, string> = {}

  for (const [key, value] of Object.entries(props)) {
    if (value) {
      propsToRecord[key] = String(value)
    }
  }
  return propsToRecord
}

export function parsePopupPayload<T extends z.ZodTypeAny>(schema: T, data: unknown): undefined | z.infer<T> {
  if (data === undefined) {
    return
  }

  try {
    return schema.parse(data)
  } catch (error) {
    if (!import.meta.dev || !(error instanceof z.ZodError)) {
      return
    }

    const isNameMismatch = error.issues.some(issue => issue.code === 'invalid_literal' && issue.path[0] === 'name')
    if (isNameMismatch) {
      logger.error({ msg: 'Popup: Not opening as names do not match' })
    } else {
      logger.error({ msg: 'Popup: Not opening as payload is corrupted', error, data })
    }
  }
}

// MachineryType
interface MachineryTypePopupCreateProps {
  editMachineryTypeMode: 'create'
  machineryCategory: MachineryCategories
  isCreatedMachineryTypeIdRequired?: boolean
}

interface MachineryTypePopupUpdateProps {
  editMachineryTypeMode: 'update'
  machineryTypeId: string
}

function makeOpener<T extends z.ZodTypeAny>(queryKey: string, schema: T) {
  const query = useRouteQuery(queryKey, undefined, {
    transform: val => parsePopupPayload(schema, val),
    mode: 'push',
  })

  return {
    open: (data: z.TypeOf<T>) => {
      query.value = data
    },
    close: () => {
      query.value = undefined
    },
    data: query,
  }
}

/**
 * Utilities to open globally available pages and popups.
 *
 * Can be called from anywhere to open the respective page or popup. Opening:
 * - a page will push a navigation onto the stack,
 * - a popup will stay on the current navigation stack entry and append to the existing query parameters to trigger the opening
 */
export default () => {
  const router = useRouter()

  const openOfferPage = (props: PayloadPageOffer) => {
    const selectedTab = props.mode === 'edit' ? props.selectedTab : undefined
    if (props.mode === 'edit') {
      delete props.selectedTab
    }
    // @ts-expect-error TS doesnt know that we can pass objects into the query. We overwrite all query-params for this case because it is a page-navigation and not a real popup
    return router.push({ path: `/offer/${props.mode}`, query: { popupOffer: props, selectedTab } })
  }

  const openTaskPage = (props: TaskCreateOrEditPagePayload) => {
    // @ts-expect-error TS doesnt know that we can pass objects into the query. We overwrite all query-params for this case because it is a page-navigation and not a real popup
    return router.push({ path: `/tasks/${props.mode}`, query: { popupTask: props } })
  }

  const openInquiryTaskPage = () => {
    // @ts-expect-error TS doesnt know that we can pass objects into the query. We overwrite all query-params for this case because it is a page-navigation and not a real popup
    return router.push({ path: '/tasks/inquiry', query: { popupTask: {} } })
  }

  const openMachineryTypePopup = (props: MachineryTypePopupCreateProps | MachineryTypePopupUpdateProps) => {
    return router.push({ query: { ...router.currentRoute.value.query, ...(propsToStringRecord(props)) } })
  }

  const openInboundLogisticsTaskPage = (props: InboundLogisticsTaskProps) => {
    // @ts-expect-error TS doesnt know that we can pass objects into the query. We overwrite all query-params for this case because it is a page-navigation and not a real popup
    return router.push({ path: `/inbound-logistics-task/${props.mode}`, query: { payloadInboundLogisticsTask: props } })
  }

  function openOutboundLogisticsTaskPage(props: OutboundLogisticsTaskProps) {
    // @ts-expect-error TS doesnt know that we can pass objects into the query. We overwrite all query-params for this case because it is a page-navigation and not a real popup
    return router.push({ path: `/external-storage-outbound/task/${props.mode}`, query: { outboundLogisticsTask: props } })
  }

  // TODO: We could refactor this to be generated form a `Record<queryKey, schema>` object to be shorter & more configuration driven
  const openCommentCreateOrUpdatePopup = makeOpener('showCommentCreateOrUpdatePopup', commentCreateOrUpdateSchema)

  const openOfferNextStepsPopup = makeOpener('popupOfferNextSteps', zObject({ status: offerStatusSchema.nullish() }).merge(offerIdSchema))
  const openOfferTerminationPopup = makeOpener('offerTerminationPopup', offerTerminationSchema)
  const openOfferPrintPopup = makeOpener('offerPrintPopup', offerPrintSchema)
  const openOfferCompleteProjectPopup = makeOpener('offerCompleteProjectPopup', offerIdSchema)

  const openCustomerPopup = makeOpener('customerCreateOrEditPopup', customerCreateOrEditSchema)

  const openDeliveryVehiclePopup = makeOpener('deliveryVehiclePopup', deliveryVehicleCreateOrEditSchema)

  const openDriverCollectionChecklistPopup = makeOpener('driverCollectionChecklistPopup', idObjectSchema)
  const openDriverLoadingChecklistPopup = makeOpener('driverLoadingChecklistPopup', idObjectSchema)

  const openLogisticsIssuanceEnterMachineryId = makeOpener('logisticsIssuanceEnterMachineryIdPopup', issuanceEnterMachineryIdSchema)
  const openLogisticsIssuanceMachineryList = makeOpener('logisticsIssuanceMachineryListPopup', zObject(logisticsIssuanceBaseSchema))
  const openLogisticsReturnScanMachinery = makeOpener('logisticsReturnScanPopup', openPopupSchema)
  const openLogisticsDateUpdateComment = makeOpener('logisticsDateUpdateComment', idObjectSchema.extend({
    type: logisticsDeliveryCommentTypeSchema,
  }))
  const openLogisticsTaskDocumentUploadPopup = makeOpener('driverDocumentUploadPopup', idObjectSchema)
  const openLogisticsTaskInformationPopup = makeOpener('logisticsTaskInformationPopup', idObjectSchema)
  const openLogisticsIssuancePopup = makeOpener('logisticsIssuancePopup', logisticsIssuanceSchema)

  const openReceptionProtocol = makeOpener('machineryReceptionPopup', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), machineryId: idSchema, typeId: idSchema, category: machineryCategories, machineryRubric: machineryRubrics, drive: machineryDrives, accessoryId: idSchema.optional() }),
    zObject({ mode: z.literal('update'), machineryId: idSchema, isModification: z.boolean().optional() }),
  ]))
  const openReceptionProtocolHistory = makeOpener('machineryReceptionHistoryPopup', idObjectSchema)
  const openPositionsDetailsPopup = makeOpener('positionsDetailsPopup', idObjectSchema.extend({ type: positionTypesWithDetailsPage }))
  const openMachineryCreateOrEditPopup = makeOpener('machineryCreateOrEditPopup', machineryCreateOrEditSchema)

  const openAccessoryCreateOrEditPopup = makeOpener('machineryAccessoryPopup', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), category: machineryAccessoryCategorySchema, isCreatedIdRequired: z.boolean().optional() }),
    zObject({ mode: z.literal('update'), id: idSchema, isUpdatingFromReception: z.boolean().optional() }),
  ]))

  const openLogisticsStorageLocationPopup = makeOpener('logisticsStorageLocationPopup', idObjectSchema.extend({
    model: z.enum(['machinery', 'machineryAccessory', 'itemSet']),
    storageLocation: z.string().nullish(),
  }))
  const openLogisticsStoreMachineryScanPopup = makeOpener('logisticsStoreAccessoryScanPopup', idObjectSchema.extend({ type: z.enum(['scanQR', 'typeId']), model: z.enum(['machineryAccessory', 'itemSet']) }))

  const openInvoicingPopup = makeOpener('popupInvoicing', zObject({ offerId: idSchema, type: invoiceType.nullish() }))
  const openInvoiceCommentPopup = makeOpener('popupInvoiceComments', idObjectSchema.extend({ invoiceId: idSchema.nullish() }))
  const openInvoiceConfirmPaymentPopup = makeOpener('popupConfirmInvoicePayment', idObjectSchema)
  const openInvoicePositionsPopup = makeOpener('popupInvoicePositions', zObject({ invoiceId: idSchema }))
  const openInvoicePartialPaymentPopup = makeOpener('popupInvoicePartialPayment', idObjectSchema)
  const openInvoiceNextStepsPopup = makeOpener('popupInvoiceNextSteps', zObject({ invoiceId: idSchema }))
  const openCreateCreditNotePopup = makeOpener('popupCreateCreditNote', idObjectSchema)
  const openInvoiceEmailRecipientPopup = makeOpener('popupInvoiceEmailRecipient', idObjectSchema)

  const openInternalCostCreateOrEditPopup = makeOpener('popupInternalCostCreateOrEdit', internalCostCreateOrEditSchema)
  const openInternalCostPopup = makeOpener('popupInternalCost', internalCostCreateOrEditBaseSchema)

  const openDefectCreateOrUpdatePopup = makeOpener('defectCreateOrEditPopup', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), type: z.nativeEnum(DefectType), itemId: idSchema, offerId: idSchema.optional() }),
    zObject({ mode: z.literal('update'), type: z.nativeEnum(DefectType), id: idSchema, itemId: idSchema }),
  ]))

  const openDefectRepairPopup = makeOpener('defectRepairPopup', zObject({ id: idSchema }))

  const openSetCreateOrUpdatePopup = makeOpener('popupSetCreateOrEdit', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create') }),
    idObjectSchema.extend({ mode: z.literal('update') }),
  ]))

  const baseSetCreateOrUpdatePopupFromIssuanceSchema = zObject({
    offerId: idSchema,
    logisticsTaskId: idSchema,
    setIndex: z.number(),
  })

  const openSetCreateOrUpdateFromIssuancePopup = makeOpener('popupSetCreateOrEditFromIssuance', zDiscriminatedUnion('mode', [
    baseSetCreateOrUpdatePopupFromIssuanceSchema.extend({ mode: z.literal('create') }),
    baseSetCreateOrUpdatePopupFromIssuanceSchema.merge(idObjectSchema.extend({ mode: z.literal('update') })),
  ]))

  const openSpecialPositionsCreateOrEditPopup = makeOpener('popupSpecialPositionCreateOrEdit', zObject({ mode: z.enum(['create', 'update']), offerId: idSchema, logisticsTaskId: idSchema }))

  const openIssuanceUpdateNotReturnablePopup = makeOpener('updateNotReturnablePopup', zObject({ id: idSchema, shouldOpenReturnPopup: z.boolean().optional() }))

  const openLogisticsTaskCreateOrEditPopup = makeOpener('logisticsTaskCreateOrEditPopup', logisticsTaskCreateOrEditSchema)

  const openEditTaskAToBStatusPopup = makeOpener('logisticsTaskeditAToBStatus', idObjectSchema)

  const openMachineryOperatingHoursEditPopup = makeOpener('openMachineryOperatingHoursEditPopup', zObject({ itemId: idSchema }))

  /**
   * TODO: remove this file when we don't need it anymore
   * @temporary
   * This is for allow user to edit the termination day of the offers
   * that are terminated before the partial termination implemented
   * see https://github.com/sidestream-tech/hanselmann-os/issues/1526 for more information
   */
  const openOfferRevertTerminationPopup = makeOpener('openOfferRevertTerminationPopup', idObjectSchema)

  const openUserOfferMembershipCreateOrEditPopup = makeOpener('showUserOfferMembershipCreateOrEditPopup', zObject({
    mode: z.enum(['create', 'edit']),
    data: userOfferMembershipCreateOrUpdateSchema,
  }))

  const openTemplatePositionPopupQuery = makeOpener('templatePositionGroupInOffer', zObject({ groupInOffer: z.number().gte(0) }))

  const openPositionTemplateBundlePopupQuery = makeOpener('positionTemplateBundleGroupInOffer', zObject({ groupInOffer: z.number().gte(0) }))

  const openReleaseMachineryForFreelancerSalesPopup = makeOpener('popupReleaseMachineryForFreelancer', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), machineryIds: idSchema.array() }),
    zObject({ mode: z.literal('update'), machineryIds: idSchema.array(), id: idSchema }),
  ]))
  const openInviteFreelancerSales = makeOpener('popupInviteFreelancerSales', z.coerce.boolean().transform(v => v === true))

  const openReleaseMachineryForPartnerSalesPopup = makeOpener('popupReleaseMachineryForPartnerSales', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), machineryIds: idSchema.array() }),
    zObject({ mode: z.literal('update'), machineryIds: idSchema.array(), id: idSchema }),
  ]))
  const openInvitePartnerSales = makeOpener('popupInvitePartnerSales', z.coerce.boolean().transform(v => v === true))

  const openReleaseMachineryForOnlineSalesPopup = makeOpener('popupReleaseMachineryForOnlineSales', zObject({ machineryIds: idSchema.array() }))

  const openInternalReservationCreateOrUpdatePopup = makeOpener('popupInternalReservationCreateOrUpdate', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), machineryId: idSchema }),
    zObject({ mode: z.literal('update'), id: idSchema }),
  ]))

  const openStorageLoadCarrierCreateOrUpdatePopup = makeOpener('popupStorageLoadCarrierCreateOrUpdate', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), routerNameToSaveCreatedId: z.string().optional(), customerId: z.string().optional() }),
    zObject({ mode: z.literal('update'), id: idSchema }),
  ]))

  const openStorageProductCreateOrUpdatePopup = makeOpener('popupStorageProductCreateOrUpdate', zObject({
    id: idSchema.optional(),
    customerId: idSchema,
    projectId: idSchema.optional(),
    isUpsertedIdRequired: z.boolean().optional(),
  }))

  const openStorageUserCreateOrUpdatePopup = makeOpener('popupStorageUserCreateOrUpdate', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create') }),
    zObject({ mode: z.literal('update'), email: emailSchema }),
  ]))

  const openPositionTemplateBundleCreateOrUpdatePopup = makeOpener('popupPositionTemplateBundleCreateOrUpdate', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), offerType: offerTypeSchema }),
    zObject({ mode: z.literal('update'), id: idSchema }),
  ]))

  const openStoragePositionApprovePopup = makeOpener('popupStoragePositionApprove', idObjectSchema.extend({
    status: z.enum([StoragePositionStatus.Stored, StoragePositionStatus.NotDelivered]),
  }))

  const openStoragePartialPositionCreateOrEditPopup = makeOpener('popupStoragePartialPositionCreateOrEdit', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), storagePositionId: idSchema }),
    zObject({ mode: z.literal('update'), storagePositionId: idSchema, id: idSchema }),
  ]))

  const openInboundLogisticsTaskTransportationDocumentPopup = makeOpener('inboundLogisticsTaskTransportationDocumentPopup', zObject({
    id: idSchema,
    isReadOnly: z.coerce.boolean().default(false).optional(),
  }))

  const openInboundLogisticsTaskPrintDeliverySlipPopup = makeOpener('inboundLogisticsTaskPrintDeliverySlipPopup', idObjectSchema)

  const openStoragePositionPdfPopup = makeOpener('popupStoragePositionPdf', idObjectSchema)

  const openOtherInvoiceRecipientCreateOrEditPopup = makeOpener('popupOtherInvoiceRecipientCreateOrEdit', zDiscriminatedUnion('mode', [
    zObject({ mode: z.literal('create'), customerId: idSchema, routerNameToSaveCreatedId: z.string().optional() }),
    zObject({ mode: z.literal('update'), customerId: idSchema, id: idSchema }),
  ]))

  const openRentalPausePopup = makeOpener('rentalPausePopup', zObject({ id: idSchema.optional(), offerId: idSchema }))

  const openGroupedPhotoPopup = makeOpener('groupedPhotoPopup', zObject({ group: groupedPhotoGroupSchema, key: z.string(), visibility: z.nativeEnum(FileVisibility).optional() }))

  return {
    openOfferPage,
    openTaskPage,
    openInquiryTaskPage,
    openOfferNextStepsPopup,

    openCustomerPopup,
    openMachineryTypePopup,
    openDeliveryVehiclePopup,
    openLogisticsTaskInformationPopup,
    openOfferTerminationPopup,
    openOfferPrintPopup,
    openOfferCompleteProjectPopup,
    openDriverCollectionChecklistPopup,
    openDriverLoadingChecklistPopup,
    openLogisticsIssuancePopup,
    openCommentCreateOrUpdatePopup,
    openLogisticsTaskDocumentUploadPopup,
    openMachineryCreateOrEditPopup,
    openLogisticsStorageLocationPopup,
    openLogisticsStoreMachineryScanPopup,

    openLogisticsIssuanceEnterMachineryId,
    openLogisticsIssuanceMachineryList,
    openLogisticsReturnScanMachinery,
    openLogisticsDateUpdateComment,
    openLogisticsTaskCreateOrEditPopup,
    openEditTaskAToBStatusPopup,

    openReceptionProtocol,
    openReceptionProtocolHistory,
    openPositionsDetailsPopup,
    openAccessoryCreateOrEditPopup,

    openInvoicingPopup,
    openInvoiceCommentPopup,
    openInvoiceConfirmPaymentPopup,
    openInvoicePositionsPopup,
    openCreateCreditNotePopup,
    openInvoicePartialPaymentPopup,
    openInvoiceNextStepsPopup,
    openInvoiceEmailRecipientPopup,

    openInternalCostCreateOrEditPopup,
    openInternalCostPopup,

    openDefectCreateOrUpdatePopup,
    openDefectRepairPopup,

    openSetCreateOrUpdatePopup,
    openSetCreateOrUpdateFromIssuancePopup,

    openSpecialPositionsCreateOrEditPopup,

    openIssuanceUpdateNotReturnablePopup,
    openOfferRevertTerminationPopup,

    openUserOfferMembershipCreateOrEditPopup,

    openMachineryOperatingHoursEditPopup,
    openTemplatePositionPopupQuery,

    openReleaseMachineryForFreelancerSalesPopup,
    openInviteFreelancerSales,

    openReleaseMachineryForPartnerSalesPopup,
    openInvitePartnerSales,

    openReleaseMachineryForOnlineSalesPopup,

    openInternalReservationCreateOrUpdatePopup,

    openStorageLoadCarrierCreateOrUpdatePopup,
    openStorageProductCreateOrUpdatePopup,

    openInboundLogisticsTaskPage,
    openOutboundLogisticsTaskPage,

    openStorageUserCreateOrUpdatePopup,

    openPositionTemplateBundleCreateOrUpdatePopup,
    openPositionTemplateBundlePopupQuery,

    openStoragePositionApprovePopup,
    openStoragePartialPositionCreateOrEditPopup,

    openInboundLogisticsTaskTransportationDocumentPopup,

    openStoragePositionPdfPopup,

    openInboundLogisticsTaskPrintDeliverySlipPopup,

    openOtherInvoiceRecipientCreateOrEditPopup,

    openRentalPausePopup,

    openGroupedPhotoPopup,
  }
}
