<script setup lang="ts">
import type { ExternalSalesMarketplaceId } from '~/prisma/enums'

const i18n = useI18n()

const { openReleaseMachineryForOnlineSalesPopup: { data, close } } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const updateMachinery = useMutation({
  mutationFn: $trpc.machinery.updatePublicMachineryPrices.mutate,
  onError: makeTrpcErrorToast(notification, { description: i18n.t('onlineSales.updateMachinery.onError.description') }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['machines'] })
    close()
    notification.success({ title: i18n.t('onlineSales.updateMachinery.onSuccess.title'), duration: 4500 })
  },
})

const { externalSalesMarketplace: queryExternalSalesMarketplace } = useQuery()
const { data: externalSalesMarketplaces } = queryExternalSalesMarketplace.getAll()
const displayedMarketplaceOptions = computed(() => {
  if (!externalSalesMarketplaces.value) {
    return {}
  }

  return Object.fromEntries(
    externalSalesMarketplaces.value.map(marketplace => ([
      marketplace.marketplaceId,
      {
        remainingMachinery: null,
      },
    ]),
    ),
  )
})

interface SubmitFormData {
  endCustomerSellingPrice?: number
  dealerSellingPrice?: number
  priceRecommendedToSellFor?: number
  machineryIds: string[]
  isReleasedForOnlineSale: boolean
  releasedForSaleOnExternalMarketplaces: ExternalSalesMarketplaceId[]
}

function onSubmitForm(value: SubmitFormData) {
  const popupData = data.value
  if (!popupData) {
    return
  }

  updateMachinery.mutate({
    ...value,
    endCustomerSellingPrice: value.endCustomerSellingPrice ?? null,
    dealerSellingPrice: value.dealerSellingPrice ?? null,
    priceRecommendedToSellFor: value.priceRecommendedToSellFor ?? null,
    machineryIds: popupData.machineryIds,
  })
}
</script>

<template>
  <ThePopup
    v-if="data"
    :show="true"
    :title="$t('machinery.releaseMachineryPopup.title')"
    @close="close"
  >
    <TheDataCard>
      <n-collapse class="mb-4">
        <n-collapse-item :title="$t('machinery.releaseMachineryPopup.dataCard.title')">
          <ul class="ml-8 list-disc grid grid-rows-1 grid-cols-5 grid-flow-auto">
            <li v-for="machineryId in data.machineryIds" :key="machineryId">
              {{ machineryId }}
            </li>
          </ul>
        </n-collapse-item>
      </n-collapse>
      <FormKit
        v-slot="{ value }"
        type="form"
        :submit-label="$t('onlineSales.submit.label')"
        @submit="onSubmitForm"
      >
        <OnlineSalesReleaseMachineryFormInput :value :marketplace-options="displayedMarketplaceOptions" />
      </FormKit>
    </TheDataCard>
  </ThePopup>
</template>
