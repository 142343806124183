import type { Address } from '~/types'

export default (address: Address) => {
  const lines = [
    address.addition,
    address.street && (address.streetNumber ? `${address.street} ${address.streetNumber}` : address.street),
    address.postalCode || address.city ? `${address.postalCode ?? ''} ${address.city ?? ''}` : null,
    address.country && address.country !== 'Deutschland' ? address.country : null,
  ].filter(Boolean)

  return lines.length > 0 ? lines.join(', ') : address.label
}
