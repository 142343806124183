<script setup lang="ts">
import type { ApiInternalCostPositionGetAll, UpdateOrCreateInternalCostPosition } from '~/types'

const props = defineProps<{ payload: UpdateOrCreateInternalCostPosition, internalCostPosition?: ApiInternalCostPositionGetAll | null }>()

const emit = defineEmits<{ (e: 'submit', value: UpdateOrCreateInternalCostPosition): void }>()

const { cloned } = useMightyCloned(props.payload)

const formkitPlugins = useFormkitPlugins()

const { internalCostPosition: internalCostPositionQuery } = useQuery()

const categoriesFetch = internalCostPositionQuery.getCategoryOptions()

const categories = computed(() => categoriesFetch.data.value)

const categoryOptions = computed(() => categories.value?.map(category => ({ label: category, value: category })))
</script>

<template>
  <div class="flex flex-col mb-4 gap-2">
    <FormKit
      v-model="cloned.data"
      type="form"
      :plugins="[formkitPlugins]"
      submit-label="Speichern"
      @submit="emit('submit', cloned)"
    >
      <div class="flex flex-col sm:flex-row items-center justify-between w-full gap-x-6">
        <FormKit
          id="title"
          type="text"
          label="Bezeichnung"
          validation="required"
        />
        <FormKit
          id="price"
          type="number"
          step="0.01"
          label="EUR"
          validation="required|min:0"
        />
        <FormKitAutocomplete
          id="category"
          name="category"
          :label="$t('common.field.category.name')"
          :is-option-creation-allowed="true"
          :options="categoryOptions"
          :placeholder="$t('internalCost.popup.category.placeholder')"
        />
        <div v-if="cloned.mode === 'update' && internalCostPosition" class="flex gap-x-4">
          <div class="w-[100px] flex flex-col">
            <span class="font-semibold">Erstellt am:</span> {{ useDateAsString(internalCostPosition.createdAt) }}
          </div>
          <div class="w-[100px] flex flex-col">
            <span class="font-semibold">Erstellt von:</span> {{ internalCostPosition.createdBy.name }}
          </div>
        </div>
      </div>
      <CommentList v-if="cloned.mode === 'update'" :id="cloned.data.id" type="InternalCostPosition" class="mb-4" />
    </FormKit>
  </div>
</template>
