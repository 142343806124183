import { refDebounced } from '@vueuse/core'
import type { SelectOption } from 'naive-ui'

export default (query: Ref<string>) => {
  const debouncedQuery = refDebounced(query, 500, { maxWait: 1000 })

  const { address } = useQuery()
  const { data, isLoading, error: suggestionsError } = address.byQuery(debouncedQuery)

  const suggestions = computed(() => {
    if (!data.value) {
      return {}
    }
    return data.value
  })

  const areSuggestionsLoading = computed(() => Boolean(isLoading.value || query.value !== debouncedQuery.value))

  // Logic taken from https://github.com/samhess/vue3-address-input/blob/main/src/AddressInput.vue#L37
  // to ensure that labels are properly formatted to reflect the search.
  function formatLabel(label: string, part: 'start' | 'middle' | 'end') {
    const index = label.toLowerCase().indexOf(query.value.toLowerCase())
    if (index >= 0) {
      let text = ''
      switch (part) {
        case 'start':
          text = label.slice(0, index)
          break
        case 'middle':
          text = label.slice(index, index + query.value.length)
          break
        case 'end':
          text = label.slice(index + query.value.length)
          break
      }
      return text
    } else if (part === 'start') {
      return label
    } else {
      return ''
    }
  }

  function formatOptionLabel(option: SelectOption) {
    return h('div', { class: 'flex items-center gap-1.5' }, [
      h('div', [
        h('span', formatLabel(option.label as string, 'start')),
        h('span', { class: 'text-primary font-medium' }, formatLabel(option.label as string, 'middle')),
        h('span', formatLabel(option.label as string, 'end')),
      ]),
    ])
  }

  function findAddressByLabel(label: string) {
    return Object.values(suggestions.value).find(address => address.label === label)
  }

  function findAddressById(id: string) {
    return Object.values(suggestions.value).find(address => address.id === id)
  }

  return { suggestions, areSuggestionsLoading, suggestionsError, formatOptionLabel, findAddressByLabel, findAddressById }
}
