import type { OfferPositionType } from '~/prisma/enums'

interface OfferPositionWithIndex {
  indexInOffer: number
  groupInOffer: number
  groupType?: string
  type: OfferPositionType
}

/**
 * Compare two offer-positions in the following order:
 * 1. by `groupType === 'InvoiceRelated'` (positions of `InvoiceRelated` group type will be placed last)
 * 2. by `groupInOffer`
 * 3. by `indexInOffer`
 * 4. by `type === 'extraDays'` (`extraDays` type will be sorted after the original position)
 */
export default function (a: OfferPositionWithIndex, b: OfferPositionWithIndex) {
  return Number(a.groupType === 'InvoiceRelated') - Number(b.groupType === 'InvoiceRelated')
    || a.groupInOffer - b.groupInOffer
    || a.indexInOffer - b.indexInOffer
    || Number(a.type === 'extraDays') - Number(b.type === 'extraDays')
}
