import type { Comment } from '@prisma/client'
import type { CommentType } from '~/prisma/enums'

/**
 * Configuration for loading, storing and caching comments for different entities.
 *
 * Multiple `CommentType`s can relate to the same entity.
 *
 * The `entity` usually closely related to an existing database table.
 *
 * The `commentTableRelationFieldName` is used to:
 * 1. map the `CommentType` (e.g.: `logisticsTask`)
 * 2. to the field of the `Comment`-table (e.g.: `logisticsTaskId`)
 * 3. that references the database table the comment belong to (e.g.: `LogisticsTask`)
 */
const commentConfigs: Record<CommentType, { entity: string, commentTableRelationFieldName: keyof Comment }> = {
  LogisticsTask: { entity: 'logisticsTask', commentTableRelationFieldName: 'logisticsTaskId' },
  Offer: { entity: 'offer', commentTableRelationFieldName: 'offerId' },
  ServiceProject: { entity: 'serviceProject', commentTableRelationFieldName: 'serviceProjectId' },
  ServiceProjectReporting: { entity: 'serviceProjectForReporting', commentTableRelationFieldName: 'serviceProjectReportingId' },
  OfferNote: { entity: 'offer', commentTableRelationFieldName: 'offerNoteId' },
  OfferFeedback: { entity: 'offer', commentTableRelationFieldName: 'offerFeedbackId' },
  Customer: { entity: 'customer', commentTableRelationFieldName: 'customerId' },
  ContactPerson: { entity: 'contactPerson', commentTableRelationFieldName: 'contactPersonId' },
  Termination: { entity: 'offer', commentTableRelationFieldName: 'offerId' },
  DeliveryDate: { entity: 'logisticsTask', commentTableRelationFieldName: 'deliveryDateLogisticsId' },
  DeliveryTime: { entity: 'logisticsTask', commentTableRelationFieldName: 'deliveryTimeLogisticsId' },
  Invoice: { entity: 'invoice', commentTableRelationFieldName: 'invoiceId' },
  InternalCostPosition: { entity: 'internalCostPosition', commentTableRelationFieldName: 'internalCostPositionId' },
  Defect: { entity: 'defect', commentTableRelationFieldName: 'defectId' },
  UserTask: { entity: 'task', commentTableRelationFieldName: 'userTaskId' },
  ItemSet: { entity: 'itemSet', commentTableRelationFieldName: 'itemSetId' },
  Machinery: { entity: 'machinery', commentTableRelationFieldName: 'machineryId' },
  SpecialPermission: { entity: 'specialPermission', commentTableRelationFieldName: 'specialPermissionId' },
  MachineryAccessory: { entity: 'machineryAccessory', commentTableRelationFieldName: 'machineryAccessoryId' },
  Temporary: { entity: 'temporary', commentTableRelationFieldName: 'temporaryId' },
  StoragePosition: { entity: 'externalStoragePosition', commentTableRelationFieldName: 'storagePositionId' },
  CancelledStoragePosition: { entity: 'externalStoragePosition', commentTableRelationFieldName: 'cancelledStoragePositionId' },
  VerifiedStoragePosition: { entity: 'externalStoragePosition', commentTableRelationFieldName: 'verifiedStoragePositionId' },
  VerifiedStoragePositionSet: { entity: 'externalStoragePositionSet', commentTableRelationFieldName: 'verifiedStoragePositionSetId' },
  OffloadedStoragePositionSet: { entity: 'externalStoragePositionSet', commentTableRelationFieldName: 'offloadedStoragePositionSetId' },
  ExternalStorageInvoicingEvent: { entity: 'externalStorageInvoicingEvent', commentTableRelationFieldName: 'externalStorageInvoicingEventId' },
  OfferCustomer: { entity: 'offer', commentTableRelationFieldName: 'offerCustomerId' },
}

export default commentConfigs
