<script lang="ts" setup>
import { type Size, isImageFilePath } from './common'
import type { FilePath, UploadedFile } from '~/types'

interface FileMeta extends FilePath {
  canDelete?: boolean
}

const props = withDefaults(defineProps<{
  files: FileMeta[]
  label?: string
  labelFont?: string
  size?: Size
  collapsedByDefault?: boolean | 'if-empty'
  allowDeleteAll?: boolean
}>(), {
  label: undefined,
  labelFont: 'font-semibold',
  size: 'Normal',
  collapsedByDefault: false,
  allowDeleteAll: false,
})

defineEmits<{
  (e: 'delete', path: string): void
}>()

const uploadedFiles = computed((): UploadedFile[] => props.files.map(getUploadedFileForView))

const defaultExpandedNames = computed(() => {
  if (props.collapsedByDefault === true) {
    return []
  }

  if (props.collapsedByDefault === false) {
    return ['photos']
  }

  // The following must be true by reason of type-narrowing at this point: `collapsedByDefault === 'if-empty'`
  const isFilesEmpty = props.files.length === 0
  if (isFilesEmpty) {
    return []
  }

  return ['photos']
})

function getUploadedFileForView(file: FileMeta): UploadedFile {
  return {
    filekey: file.path,
    status: 'success',
    hasPreview: isImageFilePath(file.path),
    canDelete: file.canDelete,
  }
}
</script>

<template>
  <div>
    <n-collapse :default-expanded-names>
      <n-collapse-item name="photos">
        <template #header>
          <h3 :class="`flex ${labelFont}`">
            {{ props.label ?? 'Dateien' }} ({{ props.files.length }})
          </h3>
        </template>

        <div v-if="uploadedFiles.length === 0">
          <n-empty class="py-5" description="Keine Dateien hochgeladen" />
        </div>
        <FileListWithPreviews
          v-else
          :files="uploadedFiles"
          :size="size"
          :allow-delete="allowDeleteAll"
          @delete="$emit('delete', $event)"
        />
      </n-collapse-item>
    </n-collapse>
  </div>
</template>
