<script lang="ts" setup>
const { t: $t } = useI18n()

import { createId } from '@paralleldrive/cuid2'
import type { UpdateOrCreateCustomer } from '~/types'

export type ContactPersons = UpdateOrCreateCustomer['data']['contactPersons']

const props = defineProps<{
  alreadyInvitedContactPersons: Record<string, boolean>
  canUpdateCustomerUser: boolean
  inviteErrors: Record<string, string>
  isExternalStakeholderFlow?: boolean
}>()
const contactPersons = defineModel<ContactPersons>({ required: true })

const expandedNames = ref<string[]>([])
const areAllExpanded = ref(false)

watch(() => props.inviteErrors, (newInviteErrors) => {
  const keys = Object.keys(newInviteErrors)
  if (keys.length > 0) {
    expandedNames.value = keys
  }
})

// watch expandedNames to determine is areAllExpanded is true.
watch(expandedNames, (names) => {
  if (names.length === contactPersons.value.length) {
    areAllExpanded.value = true
  } else {
    areAllExpanded.value = false
  }
})

function toggleCollapseOrExpandAll() {
  if (areAllExpanded.value) {
    expandedNames.value = []
  } else {
    expandedNames.value = contactPersons.value.map(({ id }) => id) ?? []
  }

  areAllExpanded.value = !areAllExpanded.value
}

function addContactPerson() {
  if (!contactPersons.value) {
    contactPersons.value = []
  }

  const id = createId()
  contactPersons.value.push({
    id,
    name: '',
    phoneNumberOffice: '',
    phoneNumberRadio: '',
    email: '',
    function: null,
    hasHOSAccess: false,
  })
  expandedNames.value.push(id)
}

const contactPersonIndexToDelete = ref<number | undefined>(undefined)
function triggerContactPersonDelete(contactPersonIndex: number) {
  if (props.isExternalStakeholderFlow) {
    return deleteContactPerson(contactPersonIndex)
  }
  contactPersonIndexToDelete.value = contactPersonIndex
}
function deleteContactPerson(contactPersonIndex: number) {
  if (!contactPersons.value || contactPersons.value.length === 0) {
    return
  }
  contactPersons.value.splice(contactPersonIndex, 1)
}

const isContactPersonDeleteButtonDisabled = computed(() => props.isExternalStakeholderFlow ? false : contactPersons.value.length === 1)

const labels = computed(() => {
  const namespace = props.isExternalStakeholderFlow ? 'contactPerson.form.externalStakeholder' : 'contactPerson.form.internal'

  return {
    title: $t(`${namespace}.title`),
    newButton: $t(`${namespace}.new.button`),
    newItemPlaceholder: $t(`${namespace}.new.placeholder`),
  }
})
</script>

<template>
  <div class="mb-4">
    <div class="flex flex-col md:flex-row md:items-center justify-between gap-2 py-4">
      <h2 class="text-xl font-bold">
        {{ labels.title }}
      </h2>

      <div class="flex items-center gap-2 md:flex-row-reverse">
        <n-button type="primary" @click="addContactPerson">
          <template #icon>
            <Icon name="material-symbols:person-add-rounded" />
          </template>
          {{ labels.newButton }}
        </n-button>
        <n-button class="size-9" @click="toggleCollapseOrExpandAll">
          <template #icon>
            <Icon :name="areAllExpanded ? 'material-symbols:collapse-all' : 'material-symbols:expand-all'" />
          </template>
        </n-button>
      </div>
    </div>
    <FormKit
      v-model="contactPersons"
      type="list"
      dynamic
    >
      <n-collapse v-model:expanded-names="expandedNames" display-directive="show" :trigger-areas="['main', 'arrow']">
        <div class="space-y-2">
          <FormKit
            v-for="(item, index) in contactPersons"
            :key="item.id"
            type="group"
            :index="index"
          >
            <div class="bg-gray-50 p-2 border rounded">
              <n-collapse-item :name="item.id" class="outer">
                <template #header>
                  <div>
                    <span> {{ item.name || labels.newItemPlaceholder }}</span>
                    <span v-if="item.function" class="hidden md:inline"> ({{ item.function }}) </span>
                    <span v-if="item.email" class="hidden md:inline"> - {{ item.email }} </span>
                    <span v-if="inviteErrors[item.id]" class="text-red-500"><Icon name="material-symbols:error" /></span>
                  </div>
                </template>

                <template #header-extra>
                  <div class="flex items-center gap-1">
                    <div class="flex flex-col gap-1 items-end">
                      <n-button
                        v-if="canUpdateCustomerUser && !isExternalStakeholderFlow"
                        class="grow-0 w-fit"
                        :type="item.hasHOSAccess ? 'error' : 'tertiary' "
                        @click="item.hasHOSAccess = !item.hasHOSAccess"
                      >
                        <template v-if="item.hasHOSAccess">
                          Zugriff auf HOS entfernen
                        </template>
                        <template v-else>
                          Zugriff auf HOS freischalten
                        </template>
                      </n-button>
                      <p v-if="inviteErrors[item.id]" class="text-red-500">
                        {{ inviteErrors[item.id] }}
                      </p>
                    </div>

                    <div>
                      <n-tooltip class="sm:w-full w-[265px]" :disabled="!isContactPersonDeleteButtonDisabled">
                        <template #trigger>
                          <n-button
                            v-if="!alreadyInvitedContactPersons[item.id] || canUpdateCustomerUser"
                            type="error"
                            secondary
                            class="size-9"
                            :disabled="isContactPersonDeleteButtonDisabled"
                            @click="triggerContactPersonDelete(index)"
                          >
                            <template #icon>
                              <Icon name="material-symbols:delete-outline-rounded" class="cursor-pointer" />
                            </template>
                          </n-button>
                        </template>
                        {{ $t('customer.contactPerson.delete.info') }}
                      </n-tooltip>
                    </div>
                  </div>
                </template>

                <n-divider class="!mt-0 !mb-3" />

                <div class="px-2">
                  <div class="grid md:grid-cols-3 md:gap-4 w-full">
                    <FormKit
                      name="name"
                      type="text"
                      :label="$t('common.field.name.name')"
                      :placeholder="$t('common.field.name.placeholder')"
                      required
                    />
                    <FormKit
                      name="email"
                      type="email"
                      :label="$t('common.field.email.name')"
                      :placeholder="$t('common.field.email.placeholder')"
                      :disabled="alreadyInvitedContactPersons[item.id]"
                      :required="item.hasHOSAccess || !!isExternalStakeholderFlow"
                    />
                    <FormKit
                      name="function"
                      type="text"
                      :label="$t('contactPerson.field.function.name')"
                    />
                  </div>

                  <div v-if="!isExternalStakeholderFlow" class="grid md:grid-cols-3 md:gap-4">
                    <FormKit
                      name="phoneNumberOffice"
                      type="tel"
                      :label="$t('contactPerson.field.phoneNumberOffice.name')"
                      :placeholder="$t('common.field.telephone.placeholder')"
                    />
                    <FormKit
                      name="phoneNumberRadio"
                      type="tel"
                      :label="$t('contactPerson.field.phoneNumberRadio.name')"
                      :placeholder="$t('common.field.telephone.placeholder')"
                    />
                  </div>
                </div>
              </n-collapse-item>
            </div>
          </FormKit>
        </div>
      </n-collapse>
    </FormKit>

    <TheConfirmPopup
      v-if="contactPersonIndexToDelete !== undefined"
      @confirm="deleteContactPerson(contactPersonIndexToDelete)"
      @close="contactPersonIndexToDelete = undefined"
    />
  </div>
</template>
