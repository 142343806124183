import { z } from 'zod'
import { machineryFemsWithFiveAndNoneAndAdditional, machineryTireColors, specialPermissionsSchema } from './schemas/machineryAndMachineryAccessoryCommon'
import { emailSchema, idObjectSchema, idSchema, pathInObjectFilesSchema, pathInObjectSingleFileSchema } from './schemas/common'
import { zDiscriminatedUnion, zObject } from './schemas/zObject'
import { machineryAccessoryCategorySchema } from './schemas/machineryAccessory'
import { StandardWorkingDays } from '~/types'
import { CartPositionType, CommentType, CommentVisibility, CustomerLanguage, CustomerStatus, CustomerType, ExternalSalesMarketplaceId, FileVisibility, LogisticsTaskType, MerloExtensions, OfferPositionGroupType, OfferPositionType, PositionTemplateType, StoragePositionLoadedWith, StoragePositionStatus } from '~/prisma/enums'
import { roles as userRoleSchema } from '~/authorization'

/* Common re-exports */
export {
  idSchema,
  idObjectSchema,
  idObjectDuplicateSchema,
  emailSchema,
  emailObjectSchema,
} from './schemas/common'

export const datesForOverlapCheck = z.object({ start: z.coerce.date(), end: z.coerce.date().nullable() })

/* Machinery re-exports */
export { machineryFems, machineryTireColors, machineryColors } from './schemas/machineryAndMachineryAccessoryCommon'

export const machineryCategories = z.enum(['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'trailer', 'tractor'])
// machineryStatusWithSold is status that are used for search & show in frontend
export const machineryStatusWithSold = z.enum(['creation', 'delivery', 'reception', 'marking', 'approval', 'sold'])
// machineryStatusSchema is status that are saved in database
export const machineryStatusSchema = z.enum(['creation', 'delivery', 'reception', 'marking', 'approval'])
export const machineryMasts = z.enum(['standard', 'duplex', 'triplex', 'quattro', 'telescopic', 'articulated'])
export const machineryMastsForBuehnen = z.enum(['telescopic', 'articulated', 'scissor', 'verticalMastPlatform'])
export const machineryHals = z.enum(['A', 'B', 'Keine Angabe'])
export const machinerySpecialEquipments = z.enum(['STVO', 'Arbeitsscheinwerfer', 'Bluespot', 'Rundumleuchte', 'Radio', '3. Ventil', '4. Ventil', 'Kabine', 'Halbkabine', 'Heizung', 'Klima', 'Waage', 'Anhängerkupplung', 'Spiegel', 'Verbindungskabel'])
export const machineryInsuranceDeductible = z.enum(['2000', '4000', '5000'])

const machinerySpecialEquipmentsToCategoriesSchema = z.record(machinerySpecialEquipments, z.array(machineryCategories))

export const machinerySpecialEquipmentsToCategories: z.infer<typeof machinerySpecialEquipmentsToCategoriesSchema> = {
  'STVO': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Arbeitsscheinwerfer': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Bluespot': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Rundumleuchte': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Spiegel': ['forklift', 'platform', 'crane', 'constructionMachinery', 'tugger', 'tractor', 'trailer'],
  'Radio': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  '3. Ventil': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  '4. Ventil': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Kabine': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Halbkabine': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Heizung': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Klima': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Waage': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Anhängerkupplung': ['forklift', 'crane', 'constructionMachinery', 'tugger', 'tractor'],
  'Verbindungskabel': ['trailer'],
}

export const equipmentType = z.enum(['Kabine', 'Beleuchtung', 'Zusatzhydraulik', 'Sonstiges'])
const machinerySpecialEquipmentsToEquimentTypeSchema = z.record(machinerySpecialEquipments, equipmentType)

export const machinerySpecialEquipmentsToEquimentType: z.infer<typeof machinerySpecialEquipmentsToEquimentTypeSchema> = {
  'STVO': 'Beleuchtung',
  'Arbeitsscheinwerfer': 'Beleuchtung',
  'Bluespot': 'Beleuchtung',
  'Rundumleuchte': 'Beleuchtung',
  'Spiegel': 'Sonstiges',
  'Radio': 'Sonstiges',
  '3. Ventil': 'Zusatzhydraulik',
  '4. Ventil': 'Zusatzhydraulik',
  'Kabine': 'Kabine',
  'Halbkabine': 'Kabine',
  'Heizung': 'Kabine',
  'Klima': 'Kabine',
  'Waage': 'Sonstiges',
  'Anhängerkupplung': 'Sonstiges',
  'Verbindungskabel': 'Sonstiges',
}

export const machineryTireTypes = z.enum(['Vollgummi', 'Luft', 'Waben', 'SE'])
export const allTerainTireTypes: z.infer<typeof machineryTireTypes>[] = ['Luft', 'Waben']
export const machineryDrives = z.enum(['electric', 'diesel', 'gas', 'noDrive'])

export const machineryRubrics = z.enum([
  'electricForklift',
  'dieselForklift',
  'gasForklift',
  'allTerrainForklift',
  'telescopicForklift',
  'rotatingForklift',
  'sideLoader',
  'fourWayForklift',
  'scissorWorkPlatform',
  'articulatedTelescopicWorkPlatform',
  'telescopicWorkPlatform',
  'verticalMastPlatform',
  'reachTruck',
  'constructionMachinery',
  'crane',
  'tugger',
  'warehouseTechnology',
  'trailer',
  'tractor',
  'selfPropelledVerticalWorkPlatform',
  'truckWorkPlatform',
  'railPlatform',
])
export const machineryPurchasing = z.enum(['Lieferung', 'Abholung'])
export const machineryConditionSchema = z.enum(['new', 'used'])

export const machinerySteeringType = z.enum(['1-Achsenlenkung', '2-Achsenlenkung'])
export const machineryFieldsToCompare = z.enum(['yearBuilt', 'liftingHeightInMillimeters', 'workHeightInMillimeters', 'serialNumber'])

export const machineryPaymentConditions = z.enum([
  'Zahlung bei Abholung wenn Fahrer vor Ort',
  'Zahlung vor Abholung',
  'Zahlung vor Lieferung',
  'Zahlung wenn Gerät bei uns',
  'Sonstiges',
])

export const externalSalesMarketplaceIdSchema = z.nativeEnum(ExternalSalesMarketplaceId)

export const machineryByIdIncludeSchema = zObject({
  include: zObject({
    receptionMachinery: z.boolean().optional(),
    defects: zObject({
      where: z.any(),
    }).optional(),
    freelancerConditionsForFreelancersThatCanSellMe: z.boolean().optional(),
    partnerConditionsForPartnersThatCanSellMe: z.boolean().optional(),
    storageLocationUpdatedBy: z.boolean().optional(),
    storageLocationCreatedBy: z.boolean().optional(),
    releasedStateUpdatedBy: z.boolean().optional(),
    lastUVVDateUpdatedBy: z.boolean().optional(),
    inspectionDateUpdatedBy: z.boolean().optional(),
  }).optional(),
})

export const createMachinery = zObject({
  id: z.number().min(1).max(5999).optional().nullable()
    .or(z.string().optional())
    .transform(val => val ? val.toString() : undefined),
  category: machineryCategories,
  deliveryCompanyName: z.string().min(1),
  producerCompanyName: z.string().min(1),
  machineryRubric: machineryRubrics,
  driveId: idSchema,
  yearBuilt: z.number().int().min(1900).max(2030),
  pricePurchaseEuros: z.number().min(0),
  priceRecommendedToSellFor: z.number().min(0).nullish(),
  endCustomerSellingPrice: z.number().min(1).nullish(),
  dealerSellingPrice: z.number().min(1).nullish(),
  paymentCondition: z.string().min(1),
  liftingHeightInMillimeters: z.number().min(0).nullable(),
  liftingWeight: z.number().min(0),
  forkLengthInMillimeters: z.number().min(0).nullable(),
  workHeightInMillimeters: z.number().min(0).nullable(),
  storageLocation: z.string().min(1),
  typeId: z.string().min(1),
  typeAddition: z.string().nullish(),
  comment: z.string().nullish(),
  isReleasedForRent: z.boolean().default(false),
  isReleasedForSale: z.boolean().default(false),
  isReleasedForOnlineSale: z.boolean().default(false),
  isForeignMachinery: z.boolean().default(false),
  purchasing: machineryPurchasing.nullish(),
  additionalDeliveryCost: z.number().min(0).nullable(),
  serialNumber: z.string().min(1).nullish(),
  deliveryDate: z.coerce.date().nullish(),
  lastUVVDate: z.coerce.date().nullish(),
  technicalInspectionDate: z.coerce.date().nullish(),
  securityInspectionDate: z.coerce.date().nullish(),
  speedometerInspectionDate: z.coerce.date().nullish(),
  specialPermissions: specialPermissionsSchema,
  documentFiles: pathInObjectFilesSchema,
  documentUVVFiles: pathInObjectFilesSchema,
  documentCEFiles: pathInObjectFilesSchema,
  documentCircuitDiagramsFiles: pathInObjectFilesSchema,
  documentSparePartsCatalogFiles: pathInObjectFilesSchema,
  documentWeightTest: pathInObjectFilesSchema,
  transportFiles: pathInObjectFilesSchema,
  marketingPhotos: pathInObjectFilesSchema,
  marketingVideos: pathInObjectFilesSchema,
  externalMarketingVideos: z.string().array(),
  previousStatus: machineryStatusSchema.nullish(),
  insuranceDeductible: machineryInsuranceDeductible.nullish(),
  foreignMachineryCustomerId: idSchema.nullish(),
  licensePlateNumber: z.string().min(1).nullish(),
})

export const updateMachinery = createMachinery.merge(zObject({
  id: idSchema,
  status: machineryStatusSchema,
  releasedStateUpdatedAt: z.coerce.date().nullish(),
  releasedStateUpdatedByEmail: z.string().nullish(),
  lastUVVDateUpdatedAt: z.coerce.date().nullish(),
  lastUVVDateUpdatedByEmail: z.string().nullish(),
  inspectionDateUpdatedAt: z.coerce.date().nullish(),
  inspectionDateUpdatedByEmail: z.string().nullish(),
  marketingMachineryAccessoryDescription: z.string().nullish(),
  marketingSpecialEquipmentDescription: z.string().nullish(),
  releasedForSaleOnExternalMarketplaces: z.array(z.object({ marketplaceId: externalSalesMarketplaceIdSchema })).nullish(),
}))

export const receptionMachineryTireCountSchema = z.enum(['3-Rad', '4-Rad', 'Keine Auswahl'])

export const createReceptionMachineryTire = zObject({
  producerCompanyName: z.string().nullish(),
  typeName: z.string().nullish(),
  color: machineryTireColors.nullish(),
  tireCount: z.string().nullish(),
  rearCondition: z.string().nullish(),
  frontSize: z.string().nullish(),
  frontCondition: z.string().nullish(),
  rearSize: z.string().nullish(),
  rearCount: z.number().min(0).nullish(),
  frontCount: z.number().min(0).nullish(),
  photos: pathInObjectFilesSchema.optional(),
})

export const createReceptionMachineryBattery = zObject({
  producerCompanyName: z.string().nullish(),
  typeName: z.string().nullish(),
  weight: z.number().min(0).nullish(),
  volt: z.string().nullish(),
  yearBuilt: z.number().int().min(1900).max(2030).nullish(),
  serialNumber: z.string().nullish(),
  ampere: z.number().nullish(),
  photos: pathInObjectFilesSchema.optional(),
})

export const machineryGears = z.enum(['Hydrostat', 'Converter', 'Keine Angabe'])

export const createReceptionMachineryMotor = zObject({
  producerCompanyName: z.string().nullish(),
  typeName: z.string().nullish(),
  power: z.number().min(0).nullish(),
  gear: z.string().nullish(),
  gearProducer: z.string().nullish(),
  serialNumber: z.string().nullish(),
  hasAdBlue: z.boolean().nullish(),
  photos: pathInObjectFilesSchema.optional(),
})

export const receptionMachinerySchema = zObject({
  machineryId: idSchema.nullish(),
  offerPositionId: idSchema.nullish(),
  yearBuilt: z.number().int().min(1900).max(2030).nullish(),
  seats: z.number().int().min(0).nullish(),
  outreach: z.number().min(0).nullish(),
  hookHeight: z.number().min(0).nullish(),
  basketLoad: z.number().min(0).nullish(),
  tractionForce: z.number().min(0).nullish(),
  serialNumber: z.string().nullish(),
  typeId: z.string().nullish(),
  typeAddition: z.string().nullish(),
  driveId: idSchema.nullish(),
  chassisNumber: z.string().nullish(),
  product: z.string().nullish(),
  key: z.string().nullish(),
  initialOperatingHours: z.number().min(0).nullish(),
  weight: z.number().min(0).nullish(),
  liftingHeightInMillimeters: z.number().min(0).nullable().optional(),
  liftingWeight: z.number().min(0).nullable().optional(),
  freeLiftInMillimeters: z.number().min(0).nullish(),
  mastOverallHeightInMillimeters: z.number().min(0).nullish(),
  overallHeightInMillimeters: z.number().min(0).nullish(),
  overallWidthInMillimeters: z.number().min(0).nullish(),
  forkCarriageWidthInMillimeters: z.number().min(0).nullish(),
  cabinHeightInMillimeters: z.number().min(0).nullish(),
  lengthToForkfaceInMillimeters: z.number().min(0).nullish(),
  loadCenter: z.string().nullish(),
  wheelbaseInMillimeters: z.number().min(0).nullish(),
  mast: z.string(machineryMasts || machineryMastsForBuehnen).nullish(),
  workHeightInMillimeters: z.number().min(0).nullable().optional(),
  fem: z.string(machineryFemsWithFiveAndNoneAndAdditional).nullish(),
  merloExtension: z.nativeEnum(MerloExtensions).nullish(),
  miscRubricAdditionalInfo: z.string().nullish(),
  hals: z.string(machineryHals).nullish(),
  terminalWest: z.boolean().nullish(),
  isNew: z.boolean().nullish(),
  addOns: z.string().nullish(),
  shaft: z.boolean().nullish(),
  initialLift: z.boolean().nullish(),
  groundClearanceCenter: z.string().nullish(),
  opticalCondition: z.string().nullish(),
  technicalCondition: z.string().nullish(),
  steeringType: z.string().nullish(),
  platformLengthInMillimeters: z.number().min(0).nullish(),
  platformWidthInMillimeters: z.number().min(0).nullish(),
  platformHeightInMillimeters: z.number().min(0).nullish(),
  sideReachInMillimeters: z.number().min(0).nullish(),
  totalLengthInMillimeters: z.number().min(0).nullish(),
  dimensionHeightInMillimeters: z.number().min(0).nullish(),
  generalPhotos: pathInObjectFilesSchema.optional(),
  cabinInsidePhotos: pathInObjectFilesSchema.optional(),
  cabinOutsidePhotos: pathInObjectFilesSchema.optional(),
  basicDataPhotos: pathInObjectFilesSchema.optional(),
  videos: pathInObjectFilesSchema.optional(),
  category: machineryCategories,
  extraAddOns: z.string().nullish(),
  extraAccessories: z.string().nullish(),
  customSpecialEquipment: z.string().nullish(),
})

export const receptionMachineryHistorySchema = receptionMachinerySchema.omit({ offerPositionId: true }).extend({ receptionMachineryId: idSchema })

const receptionMachineryRelationsSchema = zObject({
  machinerySpecialEquipments: z.array(idSchema),
  machineryTire: createReceptionMachineryTire,
  machineryTireTypes: z.array(idSchema).nullish(),
  machineryBattery: createReceptionMachineryBattery,
  machineryStatus: machineryStatusSchema.nullish(),
  machineryMotor: createReceptionMachineryMotor,
})

export const innerReceptionMachinery = receptionMachinerySchema.omit({
  machineryId: true,
  generalPhotos: true,
  cabinInsidePhotos: true,
  cabinOutsidePhotos: true,
  videos: true,
  basicDataPhotos: true,
})

const receptionMachineryBattery = createReceptionMachineryBattery.omit({ photos: true }).nullish()
const receptionMachineryMotor = createReceptionMachineryMotor.omit({ photos: true }).nullish()

export const receptionMachineryWithoutImageAndMachineryId = zObject({
  receptionMachinery: innerReceptionMachinery,
  machinerySpecialEquipments: z.array(idSchema),
  machineryTire: createReceptionMachineryTire.omit({ photos: true }).nullish(),
  machineryTireTypes: z.array(idSchema).nullish(),
  machineryBattery: receptionMachineryBattery,
  machineryMotor: receptionMachineryMotor,
})

export const receptionMachineryCompareSchema = innerReceptionMachinery.extend({
  tire: createReceptionMachineryTire.omit({ photos: true }).extend({
    receptionMachineryTireTireTypeRelation: zObject({
      receptionMachineryTireTypes: z.array(idObjectSchema).nullish(),
    }),
  }).nullish(),
  battery: receptionMachineryBattery,
  motor: receptionMachineryMotor,
  operatingHours: z.number().min(0).nullish(),
})

export const receptionMachineryRelationsPrefilledData = receptionMachineryWithoutImageAndMachineryId.extend({
  receptionMachinery: innerReceptionMachinery.omit({
    typeId: true,
    category: true,
    serialNumber: true,
    chassisNumber: true,
  }),
})

export const createReceptionMachinery = zObject({ receptionMachinery: receptionMachinerySchema, accessoryId: idSchema.optional() }).merge(receptionMachineryRelationsSchema)

export const createReceptionMachineryWithProps = createReceptionMachinery.merge(zObject({
  drive: machineryDrives,
  machineryRubric: machineryRubrics,
}))
export const updateMachineryOperationHours = zObject({ id: idSchema, operatingHours: z.number(), initialOperatingHours: z.number().min(0).nullish() })
export const updateReceptionMachinery = zObject({ receptionMachinery: receptionMachinerySchema.merge(idObjectSchema) })
  .merge(receptionMachineryRelationsSchema)
  .extend({ isModification: z.boolean().optional() })
export const updateReceptionMachineryWithProps = updateReceptionMachinery.merge(zObject({
  drive: machineryDrives,
  machineryRubric: machineryRubrics,
}))

/**
 * Machinery Type
 */
export const createMachineryTypeSchema = zObject({
  name: z.string().min(1),
  category: machineryCategories,
  documentDataSheetFiles: pathInObjectFilesSchema,
  documentManualFiles: pathInObjectFilesSchema,
  documentVideoFiles: pathInObjectFilesSchema,
  homepageImages: pathInObjectFilesSchema,
  documentExternalVideoFiles: z.string().array(),
})

export const updateMachineryTypeSchema = createMachineryTypeSchema.merge(idObjectSchema)

/*
 * Machinery Accessories re-exports
 */
export {
  machineryAccessoryTrailerTypes,
  machineryAccessoryConditions,
  machineryAccessoryCategorySchema,
  individualCreateInnerMachineAccessorySchemas,
  createInnerMachineryAccessory,
  machineryAccessoryStatusWithSold,
  updateOrCreateMachineryAccessorySchema,
} from './schemas/machineryAccessory'

export const machineryMenuSchema = z.enum(['all', 'price'])

export const taxRates = z.enum(['0', '0.07', '0.19'])
export const paymentConditions = z.enum(['instantNet', 'instant', 'beforeShipment', 'beforeCollection', 'thirtyDaysNet', 'fourteenDaysNet', 'discount', 'twentyOneDaysDiscount', 'twentyOneDaysThreePercentDiscount'])

export const addressSchema = zObject({
  label: z.string(),
  street: z.string().nullish(),
  streetNumber: z.string().nullish(),
  postalCode: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  country: z.string().nullish(),
  addition: z.string().nullish(),
  lat: z.string().nullish(),
  lng: z.string().nullish(),
})

export const contactPersonSchema = zObject({
  id: idSchema,
  name: z.string(),
  phoneNumberOffice: z.string().nullish(),
  phoneNumberRadio: z.string().nullish(),
  email: z.string().nullish(),
  function: z.string().nullish(),
  hasHOSAccess: z.boolean(),
}).refine((data) => {
  if (data.hasHOSAccess === true && data.email === null) {
    return false
  }
  return true
})

export const customerSchema = zObject({
  type: z.nativeEnum(CustomerType),
  name: z.string(),
  nameTwo: z.string().nullish(),
  nameThree: z.string().nullish(),
  location: z.string().transform(val => val.trim() === '' ? null : val).nullish(),
  address: addressSchema.nullish(),
  invoiceAddress: z.string().transform(val => val.trim() === '' ? null : val).nullish(),
  invoiceFullAddress: addressSchema.nullish(),
  invoiceEmail: z.string().nullish(),
  email: z.string().nullish(),
  telephone: z.string().nullish(),
  telefax: z.string().nullish(),
  iban: z.string().nullish(),
  taxId: z.string().nullish(),
  vatID: z.string().nullish(),
  taxRate: taxRates.nullish(),
  paymentCondition: paymentConditions,
  language: z.nativeEnum(CustomerLanguage),
  country: z.string(),
  isBlocked: z.boolean().optional(),
  isExternalStorageCustomer: z.boolean().optional(),
  isExternalStorageSupplier: z.boolean().optional(),
  isPartnerLiftCustomer: z.boolean().default(false),
  isSystemLiftCustomer: z.boolean().default(false),
  isExcludedFromMarketingCommunication: z.boolean().default(false),
})

export const customerSchemaToCheckOrderAvailability = zObject({
  location: z.string().min(1),
  invoiceAddress: z.string().nullish(),
  invoiceEmail: z.string().nullish(),
  email: z.string().min(1),
  telephone: z.string().min(1),
  iban: z.string().nullish(),
  taxId: z.string().min(1),
  vatID: z.string().min(1),
})

const customerRelationsSchema = zObject({
  contactPersons: z.array(contactPersonSchema).min(1, 'Mindestens ein Ansprechpartner ist erforderlich'),
})

export const createCustomer = zObject({ customer: customerSchema })
  .merge(customerRelationsSchema)
  .refine(data => !data.customer.isPartnerLiftCustomer || !data.customer.isSystemLiftCustomer, {
    // DB constraint: Customer_isPartnerLift_or_isSystemLift_check
    message: 'isPartnerLiftCustomer and isSystemLiftCustomer can not both be true at the same time',
    path: ['isPartnerLiftCustomer', 'isSystemLiftCustomer'],
  })
export const updateCustomer = zObject({ customer: customerSchema
  .merge(idObjectSchema)
  .merge(zObject({ status: z.nativeEnum(CustomerStatus) })) })
  .merge(customerRelationsSchema)
  .refine(data => !data.customer.isPartnerLiftCustomer || !data.customer.isSystemLiftCustomer, {
    // DB constraint: Customer_isPartnerLift_or_isSystemLift_check
    message: 'isPartnerLiftCustomer and isSystemLiftCustomer can not both be true at the same time',
    path: ['isPartnerLiftCustomer', 'isSystemLiftCustomer'],
  })

export const offerViewSchema = z.enum(['offer', 'order', 'completed', 'cancelled', 'all', 'costs', 'inquiry', 'draft', 'service-order', 'offer-freelancer-sales', 'order-freelancer-sales'])
export const offerStatusSchema = z.enum(['draft', 'inquiry', 'offer', 'order', 'invoiced'])
export const specialOfferViewSchema = z.enum(['all', 'cancelled', 'offer', 'order', 'completed'])

export const marketingViewSchema = z.enum(['preparation', 'edit', 'foreign'])

export const positionUnits = z.enum(['liter', 'day', 'flatRate', 'purchasePrice', 'operatingHours', 'hours', 'night', 'pieces'])
export const positionItemSet = z.enum(['description', 'producerCompanyName', 'typeName', 'liftingWeightInKilograms', 'weightInKilograms', 'transportLengthInMillimeters', 'transportWidthInKilograms', 'transportHeightInKilograms'])

const positionTemplateTitleSchema = zObject({
  id: idSchema.optional(),
  title: z.string().min(1),
  locale: supportedLocaleSchema,
})

export const positionTemplateSchema = zObject({
  type: z.nativeEnum(PositionTemplateType),
  titles: z.array(positionTemplateTitleSchema),
  pricePerUnit: z.number(),
  quantity: z.number().default(1),
  unit: positionUnits,
  canUseRental: z.boolean(),
  canUseSale: z.boolean(),
  canUseServiceProject: z.boolean(),
  isApplicableToAllOffers: z.boolean().default(false),
})

export const updateOfferIsCancelledSchema = idObjectSchema.extend({ isCancelled: z.boolean() })
export const updateOfferIsFavoriteSchema = idObjectSchema.extend({ isFavorite: z.boolean() })

const positionExtraIdsSchema = zObject({
  machineryTypeIds: z.array(idSchema),
  customerIds: z.array(idSchema),
  machineryDriveIds: z.array(idSchema),
})

export const createPositionTemplateSchema = zObject({ position: positionTemplateSchema }).merge(positionExtraIdsSchema)
export const updatePositionTemplateSchema = zObject({ position: positionTemplateSchema.merge(idObjectSchema) }).merge(positionExtraIdsSchema)

export const offerPositionsToShipSchema = z.enum([
  OfferPositionType.machinery,
  OfferPositionType.machineryAccessory,
  OfferPositionType.machineryAccessoryCategory,
  OfferPositionType.itemSet,
  OfferPositionType.special,
])
export const offerPositionsToShipInvoiceSchema = z.enum([
  OfferPositionType.machinery,
  OfferPositionType.machineryAccessory,
  OfferPositionType.machineryAccessoryCategory,
  OfferPositionType.itemSet,
  OfferPositionType.comment,
])

export const generatedByAutomationSchema = z.enum(['tankFilling', 'adblueFilling', 'emptyGasTanks', 'gasTanks', 'pollution'])

export const logisticsTourDirectionsSchema = z.enum(['outbound', 'inbound'])
const createBaseOfferPositionSchema = zObject({
  id: idSchema,
  title: z.string().min(1),
  quantity: z.number().transform(val => Number(val.toFixed(2))),
  pricePerUnit: z.number(),
  unit: z.string().min(1),
  discountRate: z.number(),
  type: z.nativeEnum(OfferPositionType),
  generatedByAutomation: generatedByAutomationSchema.nullish(),
  templateId: idSchema.nullish(),
  indexInOffer: z.number(),
  groupInOffer: z.number(),
  groupType: z.nativeEnum(OfferPositionGroupType),
  isHidden: z.boolean().optional(),
  showPositionInOfferPdf: z.boolean(),
  includePositionToInvoice: z.boolean(),
  doInvoice: z.boolean().optional(),
  isCreatedFromIssuance: z.boolean().optional(),
  disableTitleTranslation: z.boolean().nullish(),
})

export const offerPositionMachineryAccessoryCategoryFilterSchema = zObject({
  producerCompanyName: z.string().nullish(),
  typeName: z.string().nullish(),
  storageLocation: z.string().nullish(),
  drive: z.string().nullish(),
  wheelSize: z.string().nullish(),
  fem: z.string().nullish(),
  liftingWeight: z.number().nullish(),
  lengthInMillimeters: z.number().nullish(),
  widthInMillimeters: z.number().nullish(),
  heightInMillimeters: z.number().nullish(),
  retractionLugsWidth: z.number().nullish(),
  retractionLugsHeight: z.number().nullish(),
  retractionLugsDistanceInnerEdgeToInnerEdge: z.number().nullish(),
  retractionLugsDistanceOuterEdgeToOuterEdge: z.number().nullish(),
  weightInKilograms: z.number().nullish(),
  cubicMeters: z.number().nullish(),
  distanceInnerEdgeToInnerEdge: z.number().nullish(),
  distanceOuterEdgeToOuterEdge: z.number().nullish(),
  comment: z.string().nullish(),
  description: z.string().nullish(),
  productCode: z.string().nullish(),
  volt: z.string().nullish(),
})

export const offerPositionMachineryCategoryFilterSpecialEquipments = machinerySpecialEquipments.extract(['Kabine', 'Halbkabine'])
export const offerPositionMachineryCategoryFilterTireCounts = receptionMachineryTireCountSchema.extract(['3-Rad', '4-Rad'])
export const offerPositionMachineryCategoryFilterMasts = machineryMasts.extract(['standard', 'duplex', 'triplex', 'quattro'])

export const offerPositionMachineryCategoryFilterSchema = zObject({
  typeId: idSchema,
  driveId: idSchema,
  machineryRubric: z.string(),
  producerCompanyName: z.string().nullish(),

  previewImage: z.string().nullish(),

  minLiftingWeight: z.number().nullish().transform(x => x ?? undefined),
  maxLiftingWeight: z.number().nullish().transform(x => x ?? undefined),
  minLiftingHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxLiftingHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minMastOverallHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxMastOverallHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minOverallWidthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxOverallWidthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minOverallHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxOverallHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minPlatformLengthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxPlatformLengthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minPlatformWidthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxPlatformWidthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minTotalLengthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxTotalLengthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minSideReachInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxSideReachInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minWeight: z.number().nullish().transform(x => x ?? undefined),
  maxWeight: z.number().nullish().transform(x => x ?? undefined),
  minTractionForce: z.number().nullish().transform(x => x ?? undefined),
  maxTractionForce: z.number().nullish().transform(x => x ?? undefined),
  minHookHeight: z.number().nullish().transform(x => x ?? undefined),
  maxHookHeight: z.number().nullish().transform(x => x ?? undefined),
  minBasketLoad: z.number().nullish().transform(x => x ?? undefined),
  maxBasketLoad: z.number().nullish().transform(x => x ?? undefined),
  minWorkHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxWorkHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  minDimensionHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  maxDimensionHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),

  attachedAccessoryCategories: z.array(z.object({
    category: machineryAccessoryCategorySchema,
    minLengthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
    maxWidthInMillimeters: z.number().nullish().transform(x => x ?? undefined),
    maxHeightInMillimeters: z.number().nullish().transform(x => x ?? undefined),
  })).default([]),

  mast: offerPositionMachineryCategoryFilterMasts.nullish().transform(x => x ?? undefined),
  specialEquipment: offerPositionMachineryCategoryFilterSpecialEquipments.nullish().transform(x => x ?? undefined),
  tireCount: offerPositionMachineryCategoryFilterTireCounts.nullish().transform(x => x ?? undefined),
  tireType: machineryTireTypes.nullish().transform(x => x ?? undefined),
  tireColor: machineryTireColors.nullish().transform(x => x ?? undefined),
  seats: z.number().nullish().transform(x => x ?? undefined),
  initialLift: z.boolean().nullish().transform(x => x === null ? undefined : x),
})
const transformNumberSchema = z.number().nullish().transform(x => x ?? undefined)
const transformIdSchema = idSchema.nullish().transform(x => x || undefined)
const transformStringSchema = z.string().nullish().transform(x => x || undefined)
const transformBooleanSchema = z.boolean().nullish().transform(x => x === null ? undefined : x)

export const calendarMachineryFiltersSchema = offerPositionMachineryCategoryFilterSchema.omit({
  typeId: true,
  driveId: true,
  machineryRubric: true,

  previewImage: true,
  attachedAccessoryCategories: true,
  minOverallHeightInMillimeters: true,
  maxOverallHeightInMillimeters: true,
  minMastOverallHeightInMillimeters: true,
  maxMastOverallHeightInMillimeters: true,
}).extend({
  typeId: transformIdSchema,
  driveId: transformIdSchema,
  machineryRubric: transformStringSchema,

  maxHeightInMillimeters: transformNumberSchema,
  minHeightInMillimeters: transformNumberSchema,

  showMachineryLocationRow: transformBooleanSchema,
  minOperatingHours: transformNumberSchema,
  maxOperatingHours: transformNumberSchema,
})

export const calendarAttachedAccessoryFiltersSchema = zObject({
  category: machineryAccessoryCategorySchema,
  producerCompanyName: transformStringSchema,
  minHeightInMillimeters: transformNumberSchema,
  maxHeightInMillimeters: transformNumberSchema,
  minLengthInMillimeters: transformNumberSchema,
  maxLengthInMillimeters: transformNumberSchema,
  minWidthInMillimeters: transformNumberSchema,
  maxWidthInMillimeters: transformNumberSchema,
  volt: transformStringSchema,
  minLiftingWeight: transformNumberSchema,
})

export const createOrUpdateMachineryOfferPositionSchema = createBaseOfferPositionSchema.extend({
  type: z.literal(OfferPositionType.machinery),
  machineryId: idSchema,
  operatingHours: z.number(),
  receptionMachinery: idObjectSchema.nullish(),
  repurchasePrice: z.number().min(0).nullish(),
  repurchasedAt: z.coerce.date().nullish(),
  basePriceUsedId: idSchema.nullish(),
  priceModifiersUsed: idSchema.array().optional(),
  machineryCategoryPositionId: idSchema.nullish(),
  wasPriceProvidedFromShop: z.boolean().nullish(),
})

export const createOrUpdateOfferPositionSchema = zDiscriminatedUnion('type', [
  createOrUpdateMachineryOfferPositionSchema,
  createBaseOfferPositionSchema.extend({ type: z.literal(OfferPositionType.machineryCategory), machineryCategoryFiltersId: idSchema, basePriceUsedId: idSchema.nullish(), priceModifiersUsed: idSchema.array().optional(), insuranceDeductible: z.string().nullish() }),
  createBaseOfferPositionSchema.extend({ type: z.literal(OfferPositionType.machineryAccessory), machineryAccessoryId: idSchema, isForkReplaceRequired: z.boolean().nullish(), showSerialNumberInPdf: z.boolean().nullish(), isAttachedAccessoryHidden: z.boolean().default(false), compatibleMachineryAccessoryId: idSchema.nullish() }),
  createBaseOfferPositionSchema.extend({ type: z.literal(OfferPositionType.extraPosition), templateId: idSchema }),
  createBaseOfferPositionSchema.extend({ type: z.literal(OfferPositionType.logisticsTask), logisticsTaskId: idSchema.nullish(), disableTitleTranslation: z.boolean() }),
  createBaseOfferPositionSchema.extend({ type: z.literal(OfferPositionType.machineryAccessoryCategory), machineryAccessoryCategory: z.string().min(1), isForkReplaceRequired: z.boolean().nullish(), compatibleMachineryAccessoryId: idSchema.nullish() }).merge(offerPositionMachineryAccessoryCategoryFilterSchema),
  createBaseOfferPositionSchema.extend({ type: z.literal(OfferPositionType.insurance), templateId: idSchema }),
  createBaseOfferPositionSchema.extend({ type: z.literal(OfferPositionType.generatedByAutomation) }),
  createBaseOfferPositionSchema.extend({ type: z.literal(OfferPositionType.itemSet), itemSetId: idSchema }),
  // All other types, except `itemSetAccessory`
  createBaseOfferPositionSchema.extend({
    type: z.enum([
      OfferPositionType.special,
      OfferPositionType.manualPosition,
      OfferPositionType.comment,
      OfferPositionType.creditNote,
      OfferPositionType.invoice,
      OfferPositionType.extraDays,
    ]),
  }),
])

export const rentalDaySchema = zObject({
  id: idSchema,
  date: z.coerce.date(),
  discountRate: z.number(),
})

export const breakDaySchema = zObject({
  id: idSchema,
  date: z.coerce.date(),
})

export const offerTypeSchema = z.enum(['sale', 'rental', 'special', 'service-project'])
export const offerCreateSchema = zObject({
  type: offerTypeSchema,
  isFavorite: z.boolean(),
  status: offerStatusSchema,
  obligationStartsAt: z.coerce.date(),

  deliveryAt: z.coerce.date().nullish(),

  paymentCondition: paymentConditions,

  customerId: idSchema,
  contactPersonId: idSchema.nullish(),
  deliveryLocation: z.string().nullish(),
  deliveryAddress: addressSchema.nullish(),

  otherInvoiceRecipientId: idSchema.nullish(),

  doesCustomerDoTask: z.boolean().default(false),
  allowRentalPauseCreation: z.boolean().default(false),

  customerOrderNumber: z.string().nullish(),

  claimingPartnerName: z.string().nullish(),
  claimingPartnerEmail: z.string().nullish(),
  claimingPartnerTelephone: z.string().nullish(),

  secondClaimingPartnerName: z.string().nullish(),
  secondClaimingPartnerEmail: z.string().nullish(),
  secondClaimingPartnerTelephone: z.string().nullish(),

  thirdClaimingPartnerName: z.string().nullish(),
  thirdClaimingPartnerEmail: z.string().nullish(),
  thirdClaimingPartnerTelephone: z.string().nullish(),

  isAccessoryOnlyOffer: z.boolean().default(false),
  isConfirmed: z.boolean().nullish(),
  isCompleted: z.boolean().nullish(),

  positions: createOrUpdateOfferPositionSchema.array(),
  customerOrderDocuments: pathInObjectFilesSchema,

  // rental specific
  rentalDays: rentalDaySchema.array().default([]),
  obligationEndsAt: z.coerce.date().nullable(),
  obligationActuallyEndedAt: z.coerce.date().nullish(),

  // service project specific
  description: z.string().nullish(),
  externalDescription: z.string().nullish(),
  title: z.string().nullish(),
  requiresDailyReports: z.boolean().nullish(),

  // special specific
  isStorageSpaceOffer: z.boolean().optional(),
  projectCode: z.string().nullish(),

  wasFullyCompleted: z.boolean().nullish(),
  wasFullyCompletedComment: z.string().nullish(),
  hadExtraExpenses: z.boolean().nullish(),
  hadExtraExpensesComment: z.string().nullish(),
  hadCustomerDelays: z.boolean().nullish(),
  hadCustomerDelaysComment: z.string().nullish(),
  hadExternalDelays: z.boolean().nullish(),
  hadExternalDelaysComment: z.string().nullish(),
  hadExtraMaterials: z.boolean().nullish(),
  hadExtraMaterialsComment: z.string().nullish(),
  hadExtraWorkDone: z.boolean().nullish(),
  hadExtraWorkDoneComment: z.string().nullish(),
  hadDefects: z.boolean().nullish(),
  hadDefectsComment: z.string().nullish(),
  completionComment: z.string().nullish(),
  projectLeadName: z.string().nullish(),
  projectLeadSignature: z.string().nullish(),
  customerName: z.string().nullish(),
  customerSignature: z.string().nullish(),
  completionPhotos: pathInObjectFilesSchema,
  breakDays: breakDaySchema.array().default([]),
  isInternalProject: z.boolean().optional(),
  requestedAt: z.coerce.date(),

  contactForCustomerEmail: z.string().nullish(),

  // Data to help transfer Comments
  temporaryCommentIdToConvert: idSchema.nullish(),

  isCreatedFromShopCart: z.boolean().optional(),

  // Data for customer self collection
  customerLicensePlate: z.string().nullish(),
  carrierName: z.string().nullish(),

  // Comment created during shop checkout
  orderComment: z.string().optional(),
  orderCommentByEmail: z.string().optional(),
})

// it is possible to create an offer without a `Contact Person` or `Delivery Location`. See https://github.com/sidestream-tech/hanselmann-os/issues/441 for details
const mandatoryFieldsForRentalOfferToOrder = {
  type: z.literal('rental'),
  contactPersonId: idSchema,
  customerId: idSchema,
}

const mandatoryFieldsForSaleOfferToOrder = {
  type: z.literal('sale'),
  contactPersonId: idSchema,
  customerId: idSchema,
}

const mandatoryFieldsForServiceProjectInquiry = zObject({
  type: z.literal('service-project'),
  status: z.literal('inquiry'),
})

const mandatoryFieldsForServiceProjectInquiryToOffer = zObject({
  type: z.literal('service-project'),
  status: z.literal('offer'),

  obligationEndsAt: z.date(),
})

const mandatoryFieldsForServiceProjectOfferToOrder = zObject({
  type: z.literal('service-project'),
  status: z.literal('order'),

  contactPersonId: idSchema,
  claimingPartnerName: z.string(),
  claimingPartnerTelephone: z.string(),
})

export const mandatoryFieldsForServiceProject = zDiscriminatedUnion('status', [
  mandatoryFieldsForServiceProjectInquiry,
  mandatoryFieldsForServiceProjectInquiryToOffer,
  mandatoryFieldsForServiceProjectOfferToOrder,
])

const mandatoryFieldsForSpecialOfferToOrder = {
  type: z.literal('special'),
  contactPersonId: idSchema,
}

/**
 * Schema to determine if an offer technically has enough data to be converted to an order. Limitations:
 * - we do not check the positions exactly
 * - `customerModule.offer.getCanOfferUpdatedToOrder` depends on this limitation
 */
const offerCreateWithoutTypeAndPositions = offerCreateSchema.omit({ type: true, positions: true }).extend({ positions: z.array(z.any()) })
const offerCreateTypeDiscriminate = zDiscriminatedUnion('type', [
  offerCreateWithoutTypeAndPositions.extend(mandatoryFieldsForRentalOfferToOrder),
  offerCreateWithoutTypeAndPositions.extend(mandatoryFieldsForSaleOfferToOrder),
  offerCreateWithoutTypeAndPositions.extend(mandatoryFieldsForSpecialOfferToOrder),
])

// todo: reduce repetition of refine
export const doesOfferHaveRequiredDataToBecomeOrderSchema = offerCreateTypeDiscriminate
  .refine(({ status }) => status !== 'invoiced')
  .refine(({ type, obligationEndsAt, doesCustomerDoTask, deliveryLocation }) => {
    if (type === 'rental') {
      if (!obligationEndsAt) {
        return false
      }
      if (!doesCustomerDoTask && !deliveryLocation) {
        return false
      }
    }

    return true
  }, { message: 'Offer does not have required data to become order' })

export const offerUpdateSchema = offerCreateSchema.extend({ id: idSchema }).refine(({ type, obligationEndsAt, isStorageSpaceOffer, positions }) => {
  if (type === 'rental' && !obligationEndsAt) {
    return false
  }

  if (type === 'special') {
    const hasMachineryAccessoryPosition = positions.some(position => ['machineryAccessoryCategory', 'machineryAccessory'].includes(position.type))
    if (!obligationEndsAt && isStorageSpaceOffer) {
      return false
    } else if (!isStorageSpaceOffer && hasMachineryAccessoryPosition) {
      return false
    }
  }

  return true
}, { message: 'Offer failed update schema refinement' })

export const offerModelChangeSchema = offerCreateSchema
  .omit({ isCreatedFromShopCart: true, orderCommentByEmail: true, deliveryAddress: true })
  .merge(idObjectSchema)
  .extend({
    signedAb: z.string().nullish(),
    saleProtocolPdf: z.string().nullish(),
    machineryConvertedAt: z.coerce.date().nullish(),
    paidAmountWithoutInvoice: z.number().nullish(),
    offerReleasedToCustomerAt: z.coerce.date().nullish(),
  })

export const CRUDModeSchema = z.enum(['update', 'create'])
export const zodStringAsBoolean = z.string().transform(val => val === 'true')

export const logisticsViewSchema = z.enum(['issuance', 'issued', 'store', 'terminations', 'collections', 'collected', 'cancelled'])

// Oneway type logisticsTask status to be saved in Database
const logisticsTaskOnewaySchema = z.enum(['created', 'loaded', 'delivered'])
// Oneway type logisticsTask status to be shown on browser
export const logisticsTaskOnewayFullStatusSchema = z.enum(['created', 'planned', 'issued', 'loaded', 'delivered'])
// Roundtrip type logisticsTask status to be saved in Database
export const logisticsTaskRoundtripStatusSchema = z.enum(['created', 'loaded', 'delivered', 'terminated', 'collected', 'returned'])
// Roundtrip type logisticsTask status to be shown on browser
export const logisticsTaskRoundtripFullStatusSchema = z.enum(['created', 'planned', 'issued', 'loaded', 'delivered', 'terminated', 'collected', 'returned'])
// `aToB` type logisticsTask status to be saved in Database
export const logisticsTaskAToBStatusSchema = z.enum(['created', 'delivered', 'completed'])

export const manuallyCreatableLogisticsTypes = z.enum(['outbound', 'aToB'])

const logisticsTaskCreateBaseSchema = zObject({
  offerId: idSchema,
  plannedDeliveryAt: z.coerce.date(),
  doAllowPositionAdditionDuringIssuance: z.boolean().optional(),
  positionAdditionDuringIssuanceComment: z.string().optional(),
})

const createLogisticsTaskTypeAToBSchema = logisticsTaskCreateBaseSchema.extend({
  type: z.literal('aToB'),
  status: logisticsTaskAToBStatusSchema,
  deliveryFrom: z.string().min(1),
  deliveryTo: z.string().min(1),
})

const createLogisticsTaskTypeOutboundSchema = logisticsTaskCreateBaseSchema.extend({
  type: z.literal('outbound'),
  status: logisticsTaskOnewaySchema,
  doesFitterDoTask: z.boolean().default(false),
  positionsToShip: z.array(z.string()).optional(),
  trailerPosition: zDiscriminatedUnion('type', [
    zObject({
      type: z.literal(OfferPositionType.machineryAccessory),
      machineryAccessoryId: idSchema,
    }),
    zObject({
      type: z.literal(OfferPositionType.machineryAccessoryCategory),
      machineryAccessoryCategory: z.string(),
    }),
  ]).optional(),
})

export const createLogisticsTask = zDiscriminatedUnion('type', [
  createLogisticsTaskTypeAToBSchema,
  createLogisticsTaskTypeOutboundSchema,
])

export const updateLogisticsTask = zDiscriminatedUnion('type', [
  createLogisticsTaskTypeAToBSchema.merge(idObjectSchema),
  createLogisticsTaskTypeOutboundSchema.merge(idObjectSchema),
])

const updateLogisticsTaskStatusBaseSchema = idObjectSchema.extend({ disableAutomaticEmail: z.boolean().optional() })
export const updateLogisticsTaskStatusSchema = zDiscriminatedUnion('type', [
  updateLogisticsTaskStatusBaseSchema.extend({ type: z.literal('outbound'), status: logisticsTaskOnewaySchema }),
  updateLogisticsTaskStatusBaseSchema.extend({ type: z.literal('inbound'), status: logisticsTaskRoundtripStatusSchema }),
  updateLogisticsTaskStatusBaseSchema.extend({ type: z.literal('aToB'), status: logisticsTaskAToBStatusSchema }),
])

export const logisticsMachineryDetailsSchema = zObject({
  tankFilling: z.number().int().min(0).nullish(),
  adblueFilling: z.number().int().min(0).nullish(),
  isTankFull: z.boolean().nullish(),
  uvvValiditedAt: z.coerce.date().nullish(),
  ignitionKeys: z.number().nullish(),
  gasTanks: z.number().int().min(0).nullish(),
  emptyGasTanks: z.number().int().min(0).nullish(),
  tuvValiditedAt: z.coerce.date().nullish(),
  securityCheckValiditedAt: z.coerce.date().nullish(),
})

export const logisticsFunctionalDetailsSchema = zObject({
  areEngineAndGearboxWorking: z.boolean().nullish(),
  isBreakWorking: z.boolean().nullish(),
  isSteerwheelWorking: z.boolean().nullish(),
  areMastAndChainAndBearingWorking: z.boolean().nullish(),
  isElectricalSystemWorking: z.boolean().nullish(),
  isMachineryAccessory: z.boolean().nullish(),
  isSecurityEquipmentWorking: z.boolean().nullish(),
  isLightingWorking: z.boolean().nullish(),
  isBatteryWorking: z.boolean().nullish(),
  areWarningLightAndHornWorking: z.boolean().nullish(),
  isSafetyShutdownWorking: z.boolean().nullish(),
  isHydraulicSystemWorking: z.boolean().nullish(),
  isGpsWorking: z.boolean().nullish(),
  isBatteriesAvailable: z.boolean().nullish(),
  note: z.string().nullish(),
})

export const logisticsVisualDetailsPollutionsSchema = z.enum(['weak', 'strong', 'none'])

export const logisticsVisualDetailsSchema = zObject({
  isMachineryComplete: z.boolean().nullish(),
  hasExternalDamage: z.boolean().nullish(),
  isLeaking: z.boolean().nullish(),
  isOilLevelOk: z.boolean().nullish(),
  isCoolantLevelOk: z.boolean().nullish(),
  isMachineryCleaned: z.boolean().nullish(),
  pollution: logisticsVisualDetailsPollutionsSchema.nullish(),
  isPollutionDiscounted: z.boolean().nullish(),
  isTapeMeasureAvailable: z.boolean().nullish(),
  note: z.string().nullish(),
})

export const logisticsConditionRatingSchema = z.enum(['new', 'good', 'middle', 'damaged'])
export const logisticsConditionDetailsSchema = zObject({
  roofAndCabin: logisticsConditionRatingSchema.nullish(),
  tiresAirAndSE: logisticsConditionRatingSchema.nullish(),
  forkesAndShovels: logisticsConditionRatingSchema.nullish(),
  painting: logisticsConditionRatingSchema.nullish(),
  driverSeat: logisticsConditionRatingSchema.nullish(),
  note: z.string().nullish(),
})

export const logisticsAccessoryDetailsSchema = zObject({
  id: idSchema.optional(),
  isMachineryAccessoryComplete: z.boolean().nullish(),
  hasExternalDamage: z.boolean().nullish(),
  isFunctioning: z.boolean().nullish(),
  note: z.string().nullish(),
  photos: pathInObjectFilesSchema.optional(),
})

export const logisticsAccessoryDetailsSchemaWithCategory = logisticsAccessoryDetailsSchema.merge(zObject({
  machineryAccessoryCategory: z.string().nullish(),
  machineryAccessoryId: idSchema.nullish(),
  itemSetId: idSchema.nullish(),
}))

export const upsertLogisticsDetailsSchema = zObject({
  id: idSchema,
  taskType: z.enum([LogisticsTaskType.outbound, LogisticsTaskType.inbound, LogisticsTaskType.aToB]),
  comment: z.string().nullish(),
  generalPhotos: pathInObjectFilesSchema,
  innerCabinPhotos: pathInObjectFilesSchema,
  outerCabinPhotos: pathInObjectFilesSchema,
  tiresPhotos: pathInObjectFilesSchema,
  enginePhotos: pathInObjectFilesSchema,
  logisticsMachineryDetails: logisticsMachineryDetailsSchema.optional(),
  logisticsFunctionalDetails: logisticsFunctionalDetailsSchema.optional(),
  logisticsVisualDetails: logisticsVisualDetailsSchema.optional(),
  logisticsConditionDetails: logisticsConditionDetailsSchema.optional(),
  logisticsAccessoryDetails: logisticsAccessoryDetailsSchemaWithCategory.optional(),
  isIssued: z.boolean(),
  issuedAt: z.coerce.date(),
  issuedByEmail: z.string().min(1),
  isReturned: z.boolean(),
  returnedAt: z.coerce.date().nullish(),
  returnedByEmail: z.string().nullish(),
  storageLocation: z.string().nullish(),
  receptionMachinery: idObjectSchema.extend({ operatingHours: z.number().nullish(), operatingHoursUpdatedAt: z.date().nullish(), operatingHoursUpdatedByEmail: z.string().nullish() }).nullish(),
})

export const logisticsTasksSortSchema = zObject({
  deliveryAt: z.enum(['asc', 'desc']).default('asc').optional(),
  offer: zObject({
    deliveryAt: z.enum(['asc', 'desc']).optional(),
  }).optional(),
}).optional()

export const createComment = zObject({
  type: z.nativeEnum(CommentType),
  visibility: z.nativeEnum(CommentVisibility),
  text: z.string().optional(),
  files: pathInObjectFilesSchema,
  customerId: idSchema.nullish(),
  contactPersonId: idSchema.nullish(),
  offerId: idSchema.nullish(),
  serviceProjectId: idSchema.nullish(),
  serviceProjectReportingId: idSchema.nullish(),
  offerFeedbackId: idSchema.nullish(),
  logisticsTaskId: idSchema.nullish(),
  deliveryDateLogisticsId: idSchema.nullish(),
  deliveryTimeLogisticsId: idSchema.nullish(),
  invoiceId: idSchema.nullish(),
  internalCostPositionId: idSchema.nullish(),
  defectId: idSchema.nullish(),
  userTaskId: idSchema.nullish(),
  itemSetId: idSchema.nullish(),
  machineryId: idSchema.nullish(),
  specialPermissionId: idSchema.nullish(),
  machineryAccessoryId: idSchema.nullish(),
  temporaryId: idSchema.nullish(),
  storagePositionId: idSchema.nullish(),
  cancelledStoragePositionId: idSchema.nullish(),
  verifiedStoragePositionId: idSchema.nullish(),
  verifiedStoragePositionSetId: idSchema.nullish(),
  offloadedStoragePositionSetId: idSchema.nullish(),
  externalStorageInvoicingEventId: idSchema.nullish(),
  offerCustomerId: idSchema.nullish(),
  offerNoteId: idSchema.nullish(),

  // Reply comment specific fields
  originalCommentId: idSchema.nullish(),
})

export const updateComment = createComment
  // originalComment cannot be updated from edit
  .omit({ originalCommentId: true })
  .merge(idObjectSchema)

export const calendarPageOfferCreationModeEmitSchema = zObject({
  machinery: zObject({
    id: idSchema,
    producerCompanyName: z.string().min(1),
    type: zObject({
      name: z.string().min(1),
    }),
    operatingHours: z.number(),
    machineryCategoryPositionId: idSchema.nullish(),
  }).nullish(),
  cartPositions: z.array(z.discriminatedUnion('type', [
    z.object({
      type: z.literal('machineryAccessory'),
      machineryAccessoryId: idSchema,
      machineryAccessoryCategory: z.string(),
      isForkReplaceRequired: z.boolean().nullish(),
      compatibleMachineryAccessoryId: idSchema.nullish(),
    }),
    z.object({
      type: z.literal('machineryAccessoryCategory'),
      id: idSchema,
      machineryAccessoryCategory: z.string(),
      isForkReplaceRequired: z.boolean().nullish(),
      compatibleMachineryAccessoryId: idSchema.nullish(),
      filters: offerPositionMachineryAccessoryCategoryFilterSchema,
    }),
  ])),
  itemSets: z.array(idObjectSchema.extend({ title: z.string().min(1) })),
  selectedStartTime: z.coerce.date(),
  rentalDuration: z.number(),
  isAccessoryOnlyOffer: z.boolean(),
})

export const createFromOtherOfferSchema = zObject({
  offerId: idSchema,
  calendarPositions: calendarPageOfferCreationModeEmitSchema,
  deliveryAt: z.coerce.date(),
})

export const logisticsDeliveryCommentTypeSchema = z.enum([CommentType.DeliveryTime, CommentType.DeliveryDate])

export const invoiceViewSchema = z.enum(['archive', 'payment', 'preparation'])

export const invoiceType = z.enum(['downpayment', 'partialpayment', 'fullpayment', 'creditNote', 'proformapayment'])
// invoice types selected from invoice creation popup
export const invoiceTypeToSelect = z.enum(['downpayment', 'partialpayment', 'fullpayment', 'proformapayment'])

export const invoiceStatus = z.enum(['created', 'pending', 'partiallyPaid', 'paid', 'cancelled', 'notInvoiceable'])

export const createInvoiceBaseSchema = zObject({
  status: z.string(invoiceStatus).default('created'),
  offerId: idSchema,
  totalAmount: z.number(),
  includeVAT: z.boolean().default(true),
  invoicedAt: z.coerce.date(),
  startDate: z.coerce.date().optional(),
  endDate: z.coerce.date().optional(),
  createElectronicInvoice: z.boolean().default(false),
})

export const createInvoiceToOfferPositionRelationSchema = zObject({
  quantity: z.number().min(0),
  pricePerUnit: z.number().min(0),
  discountRate: z.number().min(0),
  offerPositionId: idSchema,
  invoicedDate: z.coerce.date().optional(),
  discountRateForDay: z.number().min(0).optional(),
})

export const createInvoiceCustomPositionSchema = createBaseOfferPositionSchema.extend({
  type: z.enum([OfferPositionType.invoice, OfferPositionType.comment]),
})
export const createCreditNoteCustomPositionSchema = createBaseOfferPositionSchema.extend({
  type: z.enum([OfferPositionType.creditNote, OfferPositionType.comment]),
  machineryId: idSchema.nullish(),
  machineryAccessoryId: idSchema.nullish(),
  machineryAccessoryCategory: z.string().nullish(),
  itemSetId: idSchema.nullish(),
})

export const createInvoiceCreditNoteSchema = createInvoiceBaseSchema.extend({
  type: z.literal('creditNote'),
  creditNoteForInvoiceId: idSchema,
  offerPositions: idObjectSchema.array(),
  customPositions: createCreditNoteCustomPositionSchema.array(),
})

export const createInvoiceExtraDaysPositionSchema = createBaseOfferPositionSchema.omit({ id: true }).extend({
  type: z.literal(OfferPositionType.extraDays),
  machineryId: idSchema.nullish(),
  machineryAccessoryId: idSchema.nullish(),
  offerPositionToExtendId: idSchema,
})

export const createProformaInvoiceSchema = createInvoiceBaseSchema.extend({
  type: z.literal('proformapayment'),
  offerPositions: createInvoiceToOfferPositionRelationSchema.array(),
  customPositions: createInvoiceCustomPositionSchema.array(),
})

export const createInvoiceSchema = zDiscriminatedUnion('type', [
  createInvoiceBaseSchema.extend({ type: z.literal('downpayment') }),
  createInvoiceBaseSchema.extend({
    type: z.literal('partialpayment'),
    offerPositions: createInvoiceToOfferPositionRelationSchema.array(),
    customPositions: createInvoiceCustomPositionSchema.array(),
    downpaymentInvoices: idObjectSchema.array(),
  }),
  createInvoiceBaseSchema.extend({
    type: z.literal('fullpayment'),
    offerPositions: createInvoiceToOfferPositionRelationSchema.array(),
    customPositions: createInvoiceCustomPositionSchema.array(),
    downpaymentInvoices: idObjectSchema.array(),
    extraDaysPositions: createInvoiceExtraDaysPositionSchema.array(),
  }),
  createInvoiceCreditNoteSchema,
]).refine(data => !data.startDate || !data.endDate || data.endDate >= data.startDate, {
  message: 'End date must be greater than or equal to start date',
  path: ['endDate', 'startDate'],
})

export const defectPriority = z.number().int().min(0).max(1)

export const paginationSchema = zObject({
  skip: z.number().nonnegative(),
  take: z.number().nonnegative(),
}).optional()

const userTaskBaseSchema = zObject({
  title: z.string().min(1),
  description: z.string().default(''),
  startAt: z.coerce.date().nullish(),
  dueAt: z.coerce.date(),
  assignedToRole: userRoleSchema.nullable(),
  assignedToUsers: emailSchema.array().default([]),
  relatedOfferId: idSchema.nullable().default(null),
  temporaryCommentIdToConvert: idSchema.nullish(),
  relatedStoragePositionId: idSchema.nullish(),
})

export const userTaskCreateSchema = userTaskBaseSchema.refine(value => value.assignedToRole || value.assignedToUsers.length > 0, { message: 'Either role or email must be assigned to task' })
export const userTaskUpdateSchema = userTaskBaseSchema.merge(idObjectSchema).refine(value => value.assignedToRole || value.assignedToUsers.length > 0, { message: 'Either role or email must be assigned to task' })

export const userOfferMembershipRoleSchema = z.enum(['commercial-project-manager', 'on-site-manager', 'project-member'])

export const userOfferMembershipCreateOrUpdateSchema = zObject({
  offerId: idSchema,
  userEmail: z.string().email().transform(v => v.toLocaleLowerCase()),
  role: userOfferMembershipRoleSchema,
  standardWorkingDays: z.array(z.nativeEnum(StandardWorkingDays)),
})

export const createInquirySchema = zObject({
  user: zObject({
    name: z.string(),
    email: z.string().email(),
  }),
  task: userTaskCreateSchema,
  documents: pathInObjectFilesSchema,
})

export const createQuickSaleSchema = zObject({
  machineryId: idSchema,
  price: z.number().nullish(),
  description: z.string(),
  additionalInformation: z.string().nullish(),
  includeVAT: z.boolean().default(true),
  templateFile: pathInObjectSingleFileSchema.optional(),
})

export const storageUserCreateOrUpdateSchema = zObject({
  name: z.string().min(1).nullable(),
  email: emailSchema,
  storageCustomerId: idSchema,
})

export const marketingMediaMachineryUpdateSchema = zObject({
  id: idSchema,
  marketingPhotos: pathInObjectFilesSchema,
  marketingVideos: pathInObjectFilesSchema,
  externalMarketingVideos: z.string().array(),
})

export const marketingMediaAccessoryUpdateSchema = zObject({
  id: idSchema,
  marketingPhotos: pathInObjectFilesSchema,
})

export const positionTemplateBundleCreateSchema = zObject({
  title: z.string(),
  templateToBundleRelations: zObject({
    template: zObject({ id: idSchema, title: z.string() }),
    order: z.number(),
  }).array(),
  offerType: offerTypeSchema,
})

export const positionTemplateBundleUpdateSchema = positionTemplateBundleCreateSchema.merge(idObjectSchema)

export const storagePositionStatusSchema = z.nativeEnum(StoragePositionStatus)

export const partialStoragePositionLoadSchema = z.object({
  id: idSchema,
  loadedWith: z.nativeEnum(StoragePositionLoadedWith),
  loadedWithOther: z.string().min(1).nullish().transform(value => value ?? null),
  loadingPhotos: pathInObjectFilesSchema,
  deliveryVehicleId: idSchema.nullable(),
  deliveryTrailerLicensePlate: z.string().min(1),
  carrierNameFromLoading: z.string().min(1).nullish().transform(value => value ?? null),
  carrierLicensePlateFromLoading: z.string().min(1).nullish().transform(value => value ?? null),
})

export const rentalPauseUpsertSchema = z.object({
  id: idSchema.optional(),
  offerId: idSchema,
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
})

export const groupedPhotoGroupSchema = z.enum(['machineryAccessoryCategory'])
export const upsertGroupedPhotoSchema = zObject({
  group: groupedPhotoGroupSchema,
  key: z.string().min(1),
  file: pathInObjectSingleFileSchema.array().length(1),
  visibility: z.nativeEnum(FileVisibility).optional(),
})

export const externalSalesMarketplaceSchema = zObject({
  marketplaceId: externalSalesMarketplaceIdSchema,
  maxExportedMachineryAmount: z.number().nullable(),
  isAutomaticExportsEnabled: z.boolean(),
})
export const upsertExternalSalesMarketplaceSchema = externalSalesMarketplaceSchema.partial({
  maxExportedMachineryAmount: true,
  isAutomaticExportsEnabled: true,
})

const createBaseShopCartSchema = zObject({
  type: z.nativeEnum(CartPositionType),
})

export const createShopCartSchema = zDiscriminatedUnion('type', [
  createBaseShopCartSchema.extend({
    type: z.literal(CartPositionType.machineryCategory),
    machineryCategoryFilters: offerPositionMachineryCategoryFilterSchema,
    weight: z.number().min(0).nullish(),
    canDeliveryPriceVary: z.boolean(),
  }),
  createBaseShopCartSchema.extend({
    type: z.literal(CartPositionType.machineryAccessoryCategory),
    machineryAccessoryCategoryFilters: offerPositionMachineryAccessoryCategoryFilterSchema.extend({ machineryAccessoryCategory: z.string().min(1) }),
  }),
])

export const languageSchema = z.enum(['en', 'de'])

export const customerDataToMergeSchema = zObject({
  name: z.string(),
  location: z.string().nullish(),
  address: addressSchema.nullish(),
  invoiceEmail: z.string().nullish(),
  taxId: z.string().nullish(),
  vatID: z.string().nullish(),
  country: z.string(),
})

export const createForeignMachineryAccessory = zObject({
  category: machineryAccessoryCategorySchema,
  distanceInnerEdgeToInnerEdge: z.number().min(0).nullish(),
  distanceOuterEdgeToOuterEdge: z.number().min(0).nullish(),
  lengthInMillimeters: z.number().min(0).nullish(),
  widthInMillimeters: z.number().min(0).nullish(),
  heightInMillimeters: z.number().min(0).nullish(),
})

export const createForeignMachinery = zObject({
  producerCompanyName: z.string(),
  category: machineryCategories,
  rubric: machineryRubrics,

  foreignMachineryID: idSchema,
  driveId: idSchema.nullish(),
  typeId: idSchema,
  yearBuilt: z.number().int().min(1900).max(2030),

  fem: z.string(machineryFemsWithFiveAndNoneAndAdditional).nullish(),
  mast: z.string(machineryMasts || machineryMastsForBuehnen).nullish(),

  liftingWeight: z.number().min(0).nullish(),
  liftingHeightInMillimeters: z.number().min(0).nullish(),
  mastOverallHeightInMillimeters: z.number().min(0).nullish(),
  tractionForce: z.number().min(0).nullish(),
  workHeightInMillimeters: z.number().min(0).nullish(),
  basketLoad: z.number().min(0).nullish(),

  tireType: machineryTireTypes.nullish(),
  tireColor: machineryTireColors.nullish(),

  generalPhotos: pathInObjectFilesSchema,
  accessories: createForeignMachineryAccessory.array(),
})
