<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import { startOfToday } from 'date-fns'
import type { ApiOfferGetById, OfferType, TerminateRentalPositions } from '~/types'

const { openOfferTerminationPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { offer: queryOffer } = useQuery()
const { data: offer, isLoading: isLoadingOffer, isFetching: isFetchingOffer } = queryOffer.byId(computed(() => popupData.value?.id))

const terminatedCheckOfferId = ref<string | null>(null)
const { openOfferPage } = useGlobalOpeners()
const router = useRouter()

const terminate = useMutation({
  mutationFn: $trpc.offerPosition.logisticsTour.terminateRentalPositions.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Abmeldung kann nicht durchgeführt werden' }),
  onSuccess: async (_, input) => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
      queryClient.invalidateQueries({ queryKey: ['shopping'] }),
      queryClient.invalidateQueries({ queryKey: ['modelChanges'] }),
    ])

    closePopup()
    notification.success({ title: 'Die Abmeldung wurde erfolgreich durchgeführt', duration: 4500 })
    if (input.doesCustomerDoTask) {
      terminatedCheckOfferId.value = input.offerId
    }
  },
})

const revertTermination = useMutation({
  mutationFn: $trpc.offerPosition.logisticsTour.undoTerminateRentalPosition.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Rückgängigmachung kann nicht durchgeführt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['shopping'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
      queryClient.invalidateQueries({ queryKey: ['modelChanges'] }),
    ])

    closePopup()
    notification.success({ title: 'Die Rückgängigmachung wurde erfolgreich durchgeführt', duration: 4500 })
  },
})

const terminationPayload = ref<TerminateRentalPositions | undefined>(undefined)

watch(offer, (newOffer) => {
  if (!popupData.value || !newOffer || isFetchingOffer.value || popupData.value.type !== 'termination' || terminationPayload.value) {
    return
  }

  terminationPayload.value = {
    offerId: newOffer.id,
    positionIds: newOffer.positions.filter(doesOfferPositionNeedDelivery).filter(p => !p.terminatedDate && p.deliveredAt).map(position => position.id),
    terminatedDate: newOffer.obligationActuallyEndedAt ?? popupData.value.dateOfColumn ?? startOfToday(),
    claimingPartnerName: newOffer.claimingPartnerName,
    claimingPartnerEmail: newOffer.claimingPartnerEmail,
    claimingPartnerTelephone: newOffer.claimingPartnerTelephone,
    differentPickupLocation: newOffer.differentPickupLocation ?? '',
    doesCustomerDoTask: newOffer.doesCustomerDoTask ?? false,
    pickupAt: newOffer.pickupAt ?? null,
    deliveryLocation: newOffer.deliveryLocation ?? '',
    obligationStartsAt: newOffer.obligationStartsAt,
    doesFitterDoTask: false,
  }
})

const checkIfOfferPageAlreadyOpen = useRouteQuery('popupOffer')
function closeTerminatedCheckOfferPopup(offerId?: string | null) {
  terminatedCheckOfferId.value = null
  if (offerId && !checkIfOfferPageAlreadyOpen.value) {
    openOfferPage({ mode: 'edit', id: offerId, redirectUrl: router.currentRoute.value.fullPath })
  }
}

const positionsToDeliver = computed(() => {
  if (!offer.value) {
    return []
  }
  return offer.value.positions.filter(doesOfferPositionNeedDelivery)
})

const getCategory = (position: ApiOfferGetById['positions'][number]) => position.machineryAccessory?.category ?? position.machineryAccessoryCategory ?? ''

const positionsOptions = computed(() => positionsToDeliver.value.toSorted((a, b) => {
  if (a.type.startsWith('machineryAccessory') && a.type === b.type) {
    return getCategory(a).localeCompare(getCategory(b))
  }

  return a.type.localeCompare(b.type)
}))

const deliveredPositions = computed(() => positionsToDeliver.value.filter(p => p.deliveredAt) ?? [])
const terminatedPositions = computed(() => positionsToDeliver.value.filter(p => p.terminatedDate) ?? [])

const offerPositionsToRevert = computed(() => {
  if (!popupData.value || !offer.value || isLoadingOffer.value || popupData.value.type !== 'revertTermination') {
    return undefined
  }

  return offer.value.positions.filter(p => p.terminatedDate)
})
</script>

<template>
  <ThePopup v-if="terminationPayload && offer" :show="Boolean(terminationPayload)" :title="`Auftrag ${terminationPayload.offerId ?? 'N/A'} abmelden`" @close="closePopup">
    <div class="mb-4 flex flex-wrap gap-x-4 gap-y-2">
      <div class="flex flex-wrap gap-x-4 gap-y-2">
        <n-collapse display-directive="show">
          <n-collapse-item>
            <template #header>
              <b>Gelieferte Positionen:</b>&nbsp;{{ deliveredPositions.length }} / {{ positionsToDeliver.length }}
            </template>
            <ul v-if="deliveredPositions.length" class="list-disc ml-5">
              <li v-for="deliveredPosition of deliveredPositions" :key="deliveredPosition.id">
                {{ deliveredPosition.title }}
              </li>
            </ul>
            <p v-else>
              Es wurden noch keine Positionen geliefert.
            </p>
          </n-collapse-item>
        </n-collapse>
        <n-collapse display-directive="show">
          <n-collapse-item>
            <template #header>
              <b>Abgemeldete Positionen:</b>&nbsp;{{ terminatedPositions.length }} / {{ positionsToDeliver.length }}
            </template>
            <ul v-if="terminatedPositions.length" class="list-disc  ml-5">
              <li v-for="terminatedPosition of terminatedPositions" :key="terminatedPosition.id">
                {{ terminatedPosition.title }}
              </li>
            </ul>
            <p v-else>
              Es wurde noch keine Position abgemeldet. Erstellen Sie unten die erste Abmeldung.
            </p>
          </n-collapse-item>
        </n-collapse>
      </div>
    </div>
    <n-divider />
    <LogisticsTerminationForm
      :payload="terminationPayload"
      :offer-type="offer.type as OfferType"
      :positions-options="positionsOptions"
      :is-pending="terminate.isPending.value"
      @save="terminate.mutate"
    />
  </ThePopup>
  <ThePopup v-if="offer && offerPositionsToRevert !== undefined" :show="offerPositionsToRevert !== undefined" :title="`Abmeldung von dem Auftrag ${offer.id ?? 'N/A'} rückgängig machen`" width="900px" @close="closePopup">
    <LogisticsTerminationRevertForm
      :offer-positions="offerPositionsToRevert"
      :offer-id="offer.id"
      @revert-termination="id => revertTermination.mutate({ id })"
      @close="closePopup"
    />
  </ThePopup>
  <ThePopup v-if="terminatedCheckOfferId" :show="Boolean(terminatedCheckOfferId)" title="Hinweis aufgrund der Selbst-Rückbringung" @close="closeTerminatedCheckOfferPopup">
    <p class="mb-4">
      Bitte vergewissern Sie sich, dass keine “Abholungs”-Position im Auftrag existiert, da der Kunde die Geräte
      selbst zurückbringt. Falls eine solche Position existiert bitte aus dem Auftrag löschen.
    </p>
    <n-button type="primary" @click="() => closeTerminatedCheckOfferPopup(terminatedCheckOfferId)">
      Den Auftrag überprüfen
    </n-button>
  </ThePopup>
</template>
