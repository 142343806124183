<script setup lang="ts" generic="T extends Record<string, any>">
const props = defineProps<{
  // Volar generics do not support two top-level props referencing the same generic (apparently). So the solution is to add a level of nesting
  config: {
    data?: T | null
    columns: {
      title: string
      key: keyof T
      minWidth?: number
      dataMinWidth?: number
      isHeading?: boolean
      rowProps?: Record<string, unknown>
      render?: (arg: T) => unknown
      isVisible?: (arg: T) => boolean
      renderComponent?: (arg: T) => unknown
      hideIfNullish?: boolean
    }[]
  }
}>()

const filteredColumns = computed(() => props.config.columns.filter((column) => {
  if (!props.config.data) {
    return true
  }

  if (column.hideIfNullish) {
    const value = column.render ? column.render(props.config.data) : props.config.data[column.key]
    const isNotNullish = value !== undefined && value !== null && value !== ''
    return column.isVisible ? column.isVisible(props.config.data) && isNotNullish : isNotNullish
  }

  if (column.isVisible) {
    return column.isVisible(props.config.data)
  }

  return true
}))

const getRandomId = () => Math.random().toString(36).slice(2, 4)
</script>

<template>
  <table class="block max-w-fit overflow-x-auto">
    <tbody>
      <tr v-for="column in filteredColumns" :key="`${String(column.key)}-${getRandomId()}`" v-bind="column.rowProps">
        <th class="text-left pr-2" :style="{ 'min-width': `${column.minWidth ?? 100}px` }">
          <h3 v-if="column.isHeading" class="text-base py-2">
            {{ column.title }}
          </h3>
          <p v-else>
            {{ column.title }}
          </p>
        </th>
        <td v-if="!column.isHeading" :style="{ 'min-width': `${column.dataMinWidth ?? 50}px` }">
          <div v-if="!config.data">
            N/A
          </div>
          <div v-else-if="column.render">
            {{ column.render(config.data) ?? 'N/A' }}
          </div>
          <div v-else-if="column.renderComponent">
            <component :is="column.renderComponent(config.data)" />
          </div>
          <span v-else>
            {{ config.data[column.key] ?? 'N/A' }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
