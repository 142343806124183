<script setup lang="ts">
import { FormKitMessages } from '@formkit/vue'
import { CommentVisibility } from '~/prisma/enums'
import type { UpdateOrCreateComment } from '~/types'
import { internalRoles } from '~/authorization'

const props = defineProps<{
  payload: UpdateOrCreateComment
  editorHeight?: string
  isVisibilityAdjustable?: boolean
  isCloseDisabled?: boolean
}>()
defineEmits<{ (e: 'save', payload: UpdateOrCreateComment): void, (e: 'close'): void }>()

const { cloned } = useCloned(props.payload.data)

const formIsEmptyTextNode = ref()
const isFormEmpty = computed(() => !cloned.value.text?.length && !cloned.value.files?.length)

const isCommentPublic = computed(() => cloned.value.visibility === CommentVisibility.Public)
function updateCommentVisibility(isPublic: boolean) {
  if (isPublic) {
    cloned.value.visibility = CommentVisibility.Public
  } else {
    cloned.value.visibility = CommentVisibility.Private
  }
}

const { isRole } = useAuthorization()
const canUseMention = computed(() => isRole(internalRoles))
</script>

<template>
  <!-- The form can be used inside of other forms -->
  <!-- To be compatible with `formKitPlugin` we need to add `id` to inputs  -->
  <FormKit
    v-model="cloned"
    type="form"
    @submit="(data: UpdateOrCreateComment['data']) => $emit('save', ({ mode: payload.mode, data } as UpdateOrCreateComment))"
  >
    <FormKitTiptapEditor
      id="text"
      name="text"
      :label="$t('comment.name', { count: 1 })"
      :placeholder="$t('comment.placeholder')"
      :is-mention-enabled="canUseMention"
      :height="editorHeight"
    />

    <FormKitFileDropzone
      id="files"
      name="files"
      :label="$t('comment.files.label')"
    />

    <label v-if="isVisibilityAdjustable" class="flex items-center gap-2 my-4">
      <n-switch
        :value="isCommentPublic"
        size="medium"
        @update:value="(v: boolean) => updateCommentVisibility(v)"
      />
      <p class="font-semibold">
        {{ $t('comment.visibility.label') }}
      </p>
    </label>
    <FormKitMessages :node="formIsEmptyTextNode?.node" />
    <FormKit
      ref="formIsEmptyTextNode"
      type="hidden"
      :validation="isFormEmpty ? 'required' : ''"
      :validation-messages="{
        required: $t('comment.validation.empty.message'),
      }"
    />
    <template #actions>
      <div class="flex gap-4">
        <n-button type="primary" attr-type="submit">
          {{ $t('comment.button.save') }}
        </n-button>
        <n-button v-if="!isCloseDisabled" @click="$emit('close')">
          {{ $t('button.cancel') }}
        </n-button>
      </div>
    </template>

    <TheDevOnlyNiceJson :comment="cloned" />
  </FormKit>
</template>
