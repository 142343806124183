<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import { joinURL } from 'ufo'
import type { DefectType } from '~/prisma/enums'
import type { ApiDefectGetAll } from '~/types'

const props = defineProps<{
  additionalFilters?: Record<string, unknown>
  createModeOptions?: { type: DefectType, itemId: string }
  overviewTitle?: string
}>()

const { isRole } = useAuthorization()

const isFreelancer = computed(() => isRole('freelancer-sales', false))

const { defect: { columns, fulltextSearch, rowProps, rowClassName }, defectColumnWithRepairInformation, freelancerDefect } = useTableColumnConfigs()
const { fulltextSearchValue, where } = useFilterData(fulltextSearch)

const { defect: queryDefect, machineryType: queryMachineryType } = useQuery()

// Machinery filters
const selectedMachineryTypeId = useRouteQuery<string | null>('selectedMachineryTypeId')
const producerCompanyName = useRouteQuery<string | null>('producerCompanyName')

function resetFilters() {
  producerCompanyName.value = null
  selectedMachineryTypeId.value = null
}

const { data: machineryTypes } = queryMachineryType.all()
const machineryTypeOptions = computed(() => machineryTypes.value?.map(type => ({
  label: `${type.name}`,
  value: type.id,
})))

const includesRepaired = useRouteQueryAsBoolean('includesRepaired')
const onlyShowDefectFromOffer = useRouteQueryAsBoolean('onlyShowDefectFromOffer')
const onlyShowCreatedFromCustomer = useRouteQueryAsBoolean('onlyShowCreatedFromCustomer')

const defectWhere = computed(() => {
  const allWhere: Record<string, unknown> = props.additionalFilters ?? {}
  if (props.createModeOptions) {
    allWhere.type = props.createModeOptions.type
    allWhere[`${props.createModeOptions.type}Id`] = props.createModeOptions.itemId
  }

  const machineryWhere = {
    typeId: selectedMachineryTypeId.value ?? undefined,
    producerCompanyName: producerCompanyName.value ? { contains: producerCompanyName.value, ...postgreSQLCaseInsensitiveSearch() } : undefined,
  }

  return {
    ...where?.value,
    ...allWhere,
    ...includesRepaired.value ? {} : { repairedAt: null },
    ...onlyShowDefectFromOffer.value ? { offerId: { not: null } } : {},
    ...onlyShowCreatedFromCustomer.value ? { createdBy: { role: 'customer-user' } } : {},
    machinery: machineryWhere,
  }
})

const { data: internalDefects, isLoading: internalLoading, error: internalError } = queryDefect.all(defectWhere, computed(() => !isFreelancer.value))
const { data: freelancerDefects, isLoading: freelancerLoading, error: freelancerError } = queryDefect.listByTypeId(defectWhere, isFreelancer)

const error = computed(() => internalError.value || freelancerError.value)

const { openDefectCreateOrUpdatePopup, openDefectRepairPopup } = useGlobalOpeners()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const updatePriority = useMutation({
  mutationFn: $trpc.defect.updatePriority.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Defekt konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['defect'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
    ])
    notification.success({ title: 'Der Defekt wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const defectType = computed(() => {
  if (props.createModeOptions) {
    return props.createModeOptions.type === 'machinery' ? 'Geräte' : 'Lagertool'
  }
  return ''
})

const tableTitle = computed(() => {
  if (props.overviewTitle !== undefined) {
    return props.overviewTitle
  }

  if (props.createModeOptions) {
    return `${defectType.value}zustand-Übersicht für ${props.createModeOptions.itemId}`
  } else {
    return 'Reparatur-Übersicht'
  }
})

function showServiceJob(defect: ApiDefectGetAll) {
  const baseUrl = defect.type === 'machinery' ? '/service/repair-machinery' : '/service/repair-storage-tool'
  const entityId = defect[`${defect.type}Id`]
  if (!entityId) {
    throw new Error('Defect entity ID must be defined at this point')
  }

  const targetURL = joinURL(baseUrl, entityId, defect.id)
  return navigateTo(targetURL)
}

const canRepair = computed(() => isRole(['admin', 'project-manager', 'department-lead', 'workshop-employee', 'storage-lead', 'special-role-benni']))

function buttonsToShow(defect: ApiDefectGetAll): Action[] {
  if (defect.repairedAt) {
    return []
  }

  const buttons: Action[] = ['showServiceJob', 'update']

  if (!canRepair.value) {
    return buttons
  }
  buttons.push('repaired')

  if (defect.priority === 0) {
    buttons.push('updateToLowerPriority')
  } else {
    buttons.push('updateToHigherPriority')
  }

  return buttons
}

const currentColumn = computed(() => includesRepaired.value ? defectColumnWithRepairInformation : columns)
</script>

<template>
  <div class="space-y-4 w-full">
    <TheDataCard
      v-if="createModeOptions !== undefined"
      :title="$t('defect.create.title', { defectType })"
      :is-loading="false"
    >
      <FormKit type="form" :submit-label="$t('defect.create.submitLabel', { defectType })" @submit="openDefectCreateOrUpdatePopup.open({ ...createModeOptions, mode: 'create' })" />
    </TheDataCard>
    <TheDataCard :title="tableTitle" :error="error">
      <TableFilters v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
        <n-collapse v-if="additionalFilters?.type === 'machinery'" class="mb-2">
          <n-collapse-item>
            <template #header>
              <span class="text-lg">
                Geräte-spezifische Filter ausklappen
              </span>
            </template>

            <div class="flex flex-col gap-x-2 gap-y-5 bg-gray-200 p-3 rounded-sm">
              <div class="grid grid-cols-3 gap-2">
                <div>
                  <p class="font-semibold">
                    Hersteller
                  </p>
                  <n-input
                    v-model:value="producerCompanyName"
                    :consistent-menu-width="false"
                    filterable
                    clearable
                  />
                </div>
                <div>
                  <p class="font-semibold">
                    Gerätetyp
                  </p>
                  <n-select
                    v-model:value="selectedMachineryTypeId"
                    :options="machineryTypeOptions"
                    :consistent-menu-width="false"
                    filterable
                    clearable
                  />
                </div>

                <div class="flex grow justify-start">
                  <n-button class="!bg-white" @click="resetFilters">
                    Alle zurücksetzen
                  </n-button>
                </div>
              </div>
            </div>
          </n-collapse-item>
        </n-collapse>
        <template #search>
          <div class="flex flex-col gap-2">
            <n-checkbox v-model:checked="includesRepaired" type="checkbox" class="shrink-0">
              Inklusive repariert
            </n-checkbox>
            <n-checkbox v-model:checked="onlyShowDefectFromOffer" type="checkbox" class="shrink-0">
              Nur Defekte aus Aufträgen anzeigen
            </n-checkbox>
            <n-checkbox v-model:checked="onlyShowCreatedFromCustomer" type="checkbox" class="shrink-0">
              Nur von Kunden gemeldete Defekte anzeigen
            </n-checkbox>
          </div>
        </template>
        <TableView
          v-if="isFreelancer"
          v-bind="freelancerDefect"
          :key="`${String(includesRepaired)}-${isFreelancer}`"
          :striped="false"
          :data="freelancerDefects ?? []"
          :is-loading="freelancerLoading"
        />
        <TableView
          v-else
          v-bind="{ rowClassName }"
          :key="`${String(includesRepaired)}-${!isFreelancer}`"
          :striped="false"
          :data="internalDefects"
          :columns="currentColumn"
          :row-props="rowProps"
          :is-loading="internalLoading"
          :action-buttons="buttonsToShow"
          @show-service-job="showServiceJob"
          @update="data => openDefectCreateOrUpdatePopup.open({ mode: 'update', id: data.id, type: data.type, itemId: data[`${data.type}Id`] as string })"
          @repaired="(data: ApiDefectGetAll) => openDefectRepairPopup.open({ id: data.id })"
          @update-to-lower-priority="(data: ApiDefectGetAll) => updatePriority.mutate({ id: data.id, priority: 1 })"
          @update-to-higher-priority="(data: ApiDefectGetAll) => updatePriority.mutate({ id: data.id, priority: 0 })"
        />
      </TableFilters>
    </TheDataCard>
  </div>
</template>

<style scoped>
:deep(.HighPriority td) {
  @apply bg-yellow-100 !important;
}

:deep(.Repaired td) {
  @apply bg-gray-200 !important;
}

:deep(.CustomerDefect td) {
  @apply bg-blue-100 !important;
}
</style>
