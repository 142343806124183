import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as logoutCHyXH6UWwkMeta } from "/app/pages/auth/logout.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as verify_45requestevDC4PTXAqMeta } from "/app/pages/auth/verify-request.vue?macro=true";
import { default as calendarsHG7ZbpD8zMeta } from "/app/pages/calendar.vue?macro=true";
import { default as customer_45userAlsE6q2amrMeta } from "/app/pages/customer-overview/customer-user.vue?macro=true";
import { default as customersPGEhYrua4PMeta } from "/app/pages/customer-overview/customers.vue?macro=true";
import { default as other_45invoice_45recipientsAV24UNZePlMeta } from "/app/pages/customer-overview/other-invoice-recipients.vue?macro=true";
import { default as completedrSwvwxqli9Meta } from "/app/pages/customer-registration/completed.vue?macro=true";
import { default as openwsjTPAoBx1Meta } from "/app/pages/customer-registration/open.vue?macro=true";
import { default as index8j34Jyoa1xMeta } from "/app/pages/delivery-vehicle/index.vue?macro=true";
import { default as _91id_93Q56mqgg4taMeta } from "/app/pages/driver/external-storage/inbound-task/[id].vue?macro=true";
import { default as _91id_93x26BbLvULhMeta } from "/app/pages/driver/external-storage/outbound-task/[id].vue?macro=true";
import { default as overviewYVhM5wnPdqMeta } from "/app/pages/driver/overview.vue?macro=true";
import { default as _91id_93iqtnfCupc4Meta } from "/app/pages/driver/task/[id].vue?macro=true";
import { default as logistics_45task_45overviewzkqolIXIOoMeta } from "/app/pages/external-storage-administration/logistics-task-overview.vue?macro=true";
import { default as storage_45load_45carrierrsny8Eb2XBMeta } from "/app/pages/external-storage-administration/storage-load-carrier.vue?macro=true";
import { default as storage_45planningWxKNj6MtzvMeta } from "/app/pages/external-storage-administration/storage-planning.vue?macro=true";
import { default as storage_45projectrVMvtC04hWMeta } from "/app/pages/external-storage-administration/storage-project.vue?macro=true";
import { default as storage_45reportingdrOh4smCzdMeta } from "/app/pages/external-storage-administration/storage-reporting.vue?macro=true";
import { default as storage_45rideM7LC08r58cMeta } from "/app/pages/external-storage-administration/storage-ride.vue?macro=true";
import { default as storage_45userN6jr9kJUkGMeta } from "/app/pages/external-storage-administration/storage-user.vue?macro=true";
import { default as approveM27cvMNmhAMeta } from "/app/pages/external-storage-inbound/approve.vue?macro=true";
import { default as requestt4eNtqhOJCMeta } from "/app/pages/external-storage-inbound/request.vue?macro=true";
import { default as storeQ5FXnpY2J7Meta } from "/app/pages/external-storage-inbound/store.vue?macro=true";
import { default as load7gtzRjvRSbMeta } from "/app/pages/external-storage-outbound/load.vue?macro=true";
import { default as requestRXULKsvFkhMeta } from "/app/pages/external-storage-outbound/request.vue?macro=true";
import { default as _91mode_93XmwwvSIzT7Meta } from "/app/pages/external-storage-outbound/task/[mode].vue?macro=true";
import { default as _91id_93ywXkjYiLobMeta } from "/app/pages/external-storage-position/[id].vue?macro=true";
import { default as _91storagePositionId_93GHDyr7xQg1Meta } from "/app/pages/external-storage/invoicing/[storagePositionId].vue?macro=true";
import { default as positions_45in_45storageJLKAphLFCjMeta } from "/app/pages/external-storage/positions-in-storage.vue?macro=true";
import { default as retrieved_45positionslh9xnuoh6wMeta } from "/app/pages/external-storage/retrieved-positions.vue?macro=true";
import { default as storage_45productvThHOX8rgIMeta } from "/app/pages/external-storage/storage-product.vue?macro=true";
import { default as used_45storage_45space_45checkXYRvTZDU7uMeta } from "/app/pages/external-storage/used-storage-space-check.vue?macro=true";
import { default as released_45machineryshcwJlpuCnMeta } from "/app/pages/freelancer-sales/released-machinery.vue?macro=true";
import { default as all3U9dD9cj8ZMeta } from "/app/pages/freelancer-sales/sale/all.vue?macro=true";
import { default as _91view_938vAQ5J4c6KMeta } from "/app/pages/freelancer-sales/sale/current/[view].vue?macro=true";
import { default as _91mode_93MS0nxxrUpeMeta } from "/app/pages/inbound-logistics-task/[mode].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as machinery_45accessoryk0cs7K9CUpMeta } from "/app/pages/internal-costs/machinery-accessory.vue?macro=true";
import { default as machinery3GwfBlOCLgMeta } from "/app/pages/internal-costs/machinery.vue?macro=true";
import { default as orderTH7JkXGKC3Meta } from "/app/pages/internal-costs/order.vue?macro=true";
import { default as _91view_93plSuihiq6fMeta } from "/app/pages/invoice/[view].vue?macro=true";
import { default as preparationaIiUfrhiAbMeta } from "/app/pages/invoice/preparation.vue?macro=true";
import { default as _91cuid_93kRcgTSY9RwMeta } from "/app/pages/logistics-details/collected/[cuid].vue?macro=true";
import { default as _91cuid_93df8ay8VltEMeta } from "/app/pages/logistics-details/issued/[cuid].vue?macro=true";
import { default as logistics_45planningruEtdob08aMeta } from "/app/pages/logistics-planning.vue?macro=true";
import { default as _91view_93z0WwIVwh1PMeta } from "/app/pages/logistics/[view].vue?macro=true";
import { default as _91id_937ll1MXElmMMeta } from "/app/pages/machinery-accessory/[id].vue?macro=true";
import { default as _91view_93QjrWNEwTKaMeta } from "/app/pages/machinery-creation/[view].vue?macro=true";
import { default as maintainBalg0YPcWaMeta } from "/app/pages/machinery-type/maintain.vue?macro=true";
import { default as _91id_93amDb0mRFLQMeta } from "/app/pages/machinery/[id].vue?macro=true";
import { default as _91view_93ri4ALeeBaIMeta } from "/app/pages/marketing/[view].vue?macro=true";
import { default as storage_45tool_45categoriesTDhzugimooMeta } from "/app/pages/marketing/storage-tool-categories.vue?macro=true";
import { default as _91id_93xO4Z2yy8QLMeta } from "/app/layers/customer-module/pages/my-hos/foreign-machinery/[id].vue?macro=true";
import { default as indexqqTC7qe3CIMeta } from "/app/layers/customer-module/pages/my-hos/foreign-machinery/index.vue?macro=true";
import { default as indexYk2XtvWUqCMeta } from "/app/layers/customer-module/pages/my-hos/index.vue?macro=true";
import { default as _91id_936f5yDzZDzkMeta } from "/app/layers/customer-module/pages/my-hos/machinery/[id].vue?macro=true";
import { default as indexvzLYVOGgakMeta } from "/app/layers/customer-module/pages/my-hos/machinery/index.vue?macro=true";
import { default as profile8Rkplb43x8Meta } from "/app/layers/customer-module/pages/my-hos/profile.vue?macro=true";
import { default as _91id_93vZd527RxS9Meta } from "/app/layers/customer-module/pages/my-hos/rental/[id].vue?macro=true";
import { default as indexq8Z4xEu4cuMeta } from "/app/layers/customer-module/pages/my-hos/rental/index.vue?macro=true";
import { default as _91accessCode_93uvZVOmrusSMeta } from "/app/layers/customer-module/pages/my-hos/rental/manage/[accessCode].vue?macro=true";
import { default as indexrUlDwuimOeMeta } from "/app/layers/customer-module/pages/my-hos/rental/manage/index.vue?macro=true";
import { default as _91id_93DM2kOJBlSfMeta } from "/app/layers/customer-module/pages/my-hos/sale/[id].vue?macro=true";
import { default as indexRuYQmEfDxCMeta } from "/app/layers/customer-module/pages/my-hos/sale/index.vue?macro=true";
import { default as _91id_93w8kpIYYDwYMeta } from "/app/layers/customer-module/pages/my-hos/service-project/[id].vue?macro=true";
import { default as indexuSwtg49uaqMeta } from "/app/layers/customer-module/pages/my-hos/service-project/index.vue?macro=true";
import { default as _91id_93bRKJ7oud2nMeta } from "/app/layers/customer-module/pages/my-hos/service-project/reporting/[id].vue?macro=true";
import { default as indexwjvui71QtcMeta } from "/app/layers/customer-module/pages/my-hos/service-project/reporting/index.vue?macro=true";
import { default as _91id_93Czdv3TCg0pMeta } from "/app/layers/customer-module/pages/my-hos/special/[id].vue?macro=true";
import { default as index9Tj7ZHkwykMeta } from "/app/layers/customer-module/pages/my-hos/special/index.vue?macro=true";
import { default as _91cuid_93yj6izUF5PSMeta } from "/app/pages/offer-reporting/[cuid].vue?macro=true";
import { default as position_45template_45bundlesiPVpiGiqIQMeta } from "/app/pages/offer-templates/position-template-bundles.vue?macro=true";
import { default as position_45templatesFfKIDDWTo5Meta } from "/app/pages/offer-templates/position-templates.vue?macro=true";
import { default as _91mode_93Y7FR9mFZjhMeta } from "/app/pages/offer/[mode].vue?macro=true";
import { default as marketplacesdnZyz0NISaMeta } from "/app/pages/online-sales/marketplaces.vue?macro=true";
import { default as foreign_45machineryQzbv07jGBWMeta } from "/app/pages/overview/foreign-machinery.vue?macro=true";
import { default as machineryi3hdKkQi2wMeta } from "/app/pages/overview/machinery.vue?macro=true";
import { default as storage_45tooli8AGXVH8GTMeta } from "/app/pages/overview/storage-tool.vue?macro=true";
import { default as quick_45sale1c5Hy9E1v3Meta } from "/app/pages/partner-sales/quick-sale.vue?macro=true";
import { default as released_45machineryNQlebJcL1vMeta } from "/app/pages/partner-sales/released-machinery.vue?macro=true";
import { default as _91view_93kFqgECTrqVMeta } from "/app/pages/rental/[view].vue?macro=true";
import { default as index9xWdRDAWRJMeta } from "/app/pages/rental/pricing/index.vue?macro=true";
import { default as _91machineryTypeId_93MOaW2MKU12Meta } from "/app/pages/rental/pricing/machinery-type/[machineryTypeId].vue?macro=true";
import { default as _91machineryId_934XBQxMjt07Meta } from "/app/pages/rental/pricing/machinery/[machineryId].vue?macro=true";
import { default as reportingox84ohMv4kMeta } from "/app/pages/reporting.vue?macro=true";
import { default as _91view_93BxEW4YZVBJMeta } from "/app/pages/sale/[view].vue?macro=true";
import { default as _91view_93CPVyvIhariMeta } from "/app/pages/service-project/[view].vue?macro=true";
import { default as _91id_93cO2s61mRHFMeta } from "/app/pages/service-project/external/[id].vue?macro=true";
import { default as _91view_938dch9VuaeUMeta } from "/app/pages/service-project/external/overview/[view].vue?macro=true";
import { default as fitter_45issuanceJkoeOrafWHMeta } from "/app/pages/service-project/fitter-issuance.vue?macro=true";
import { default as planning1jxShuQTCOMeta } from "/app/pages/service-project/planning.vue?macro=true";
import { default as machinery_45accessoryBFjy3HMHTyMeta } from "/app/pages/service/machinery-accessory.vue?macro=true";
import { default as machineryv9BHbxkvSwMeta } from "/app/pages/service/machinery.vue?macro=true";
import { default as _91_91defectId_93_933JewNjhsmdMeta } from "/app/pages/service/repair-[type]/[id]/[[defectId]].vue?macro=true";
import { default as indexf9w2rAbAsXMeta } from "/app/pages/service/repair-[type]/index.vue?macro=true";
import { default as sale_45issuanceVoeYejb1N0Meta } from "/app/pages/service/sale-issuance.vue?macro=true";
import { default as sale_45orderOolgOInucYMeta } from "/app/pages/service/sale-order.vue?macro=true";
import { default as set_45overviewsAS1JgXod0Meta } from "/app/pages/set-overview.vue?macro=true";
import { default as _91id_93ff4Iwhbgl8Meta } from "/app/pages/set/[id].vue?macro=true";
import { default as indexXcRSRur08RMeta } from "/app/layers/customer-module/pages/shop/buy/index.vue?macro=true";
import { default as machinery_45accessoryDX9zxNbDR2Meta } from "/app/layers/customer-module/pages/shop/buy/machinery-accessory.vue?macro=true";
import { default as _91id_93C5bsf6Bq9zMeta } from "/app/layers/customer-module/pages/shop/checkout/[id].vue?macro=true";
import { default as indexlmNCj0UwZ9Meta } from "/app/layers/customer-module/pages/shop/checkout/index.vue?macro=true";
import { default as indexjqYtGSBRSHMeta } from "/app/layers/customer-module/pages/shop/index.vue?macro=true";
import { default as bookingd4iDXTActsMeta } from "/app/layers/customer-module/pages/shop/rent/booking.vue?macro=true";
import { default as indexjAwQcohAlMMeta } from "/app/layers/customer-module/pages/shop/rent/index.vue?macro=true";
import { default as _91view_93YoVso1pgBzMeta } from "/app/pages/special-order/[view].vue?macro=true";
import { default as storage_45tool_45bundleur2EfqAFt9Meta } from "/app/pages/storage-tool-bundle.vue?macro=true";
import { default as _91view_93BCqcRJyAUXMeta } from "/app/pages/storage-tool-creation/[view].vue?macro=true";
import { default as _91mode_93X9Ckgdtx5wMeta } from "/app/pages/tasks/[mode].vue?macro=true";
import { default as indexG8Y0JnMIO5Meta } from "/app/pages/tasks/index.vue?macro=true";
import { default as inquiryJDoiWEoVl6Meta } from "/app/pages/tasks/inquiry.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutCHyXH6UWwkMeta || {},
    component: () => import("/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerTCvAsPw3TEMeta || {},
    component: () => import("/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-request",
    path: "/auth/verify-request",
    meta: verify_45requestevDC4PTXAqMeta || {},
    component: () => import("/app/pages/auth/verify-request.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/app/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "customer-overview-customer-user",
    path: "/customer-overview/customer-user",
    component: () => import("/app/pages/customer-overview/customer-user.vue").then(m => m.default || m)
  },
  {
    name: "customer-overview-customers",
    path: "/customer-overview/customers",
    component: () => import("/app/pages/customer-overview/customers.vue").then(m => m.default || m)
  },
  {
    name: "customer-overview-other-invoice-recipients",
    path: "/customer-overview/other-invoice-recipients",
    component: () => import("/app/pages/customer-overview/other-invoice-recipients.vue").then(m => m.default || m)
  },
  {
    name: "customer-registration-completed",
    path: "/customer-registration/completed",
    component: () => import("/app/pages/customer-registration/completed.vue").then(m => m.default || m)
  },
  {
    name: "customer-registration-open",
    path: "/customer-registration/open",
    component: () => import("/app/pages/customer-registration/open.vue").then(m => m.default || m)
  },
  {
    name: "delivery-vehicle",
    path: "/delivery-vehicle",
    component: () => import("/app/pages/delivery-vehicle/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-external-storage-inbound-task-id",
    path: "/driver/external-storage/inbound-task/:id()",
    component: () => import("/app/pages/driver/external-storage/inbound-task/[id].vue").then(m => m.default || m)
  },
  {
    name: "driver-external-storage-outbound-task-id",
    path: "/driver/external-storage/outbound-task/:id()",
    component: () => import("/app/pages/driver/external-storage/outbound-task/[id].vue").then(m => m.default || m)
  },
  {
    name: "driver-overview",
    path: "/driver/overview",
    component: () => import("/app/pages/driver/overview.vue").then(m => m.default || m)
  },
  {
    name: "driver-task-id",
    path: "/driver/task/:id()",
    component: () => import("/app/pages/driver/task/[id].vue").then(m => m.default || m)
  },
  {
    name: "external-storage-administration-logistics-task-overview",
    path: "/external-storage-administration/logistics-task-overview",
    component: () => import("/app/pages/external-storage-administration/logistics-task-overview.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-administration-storage-load-carrier",
    path: "/external-storage-administration/storage-load-carrier",
    component: () => import("/app/pages/external-storage-administration/storage-load-carrier.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-administration-storage-planning",
    path: "/external-storage-administration/storage-planning",
    component: () => import("/app/pages/external-storage-administration/storage-planning.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-administration-storage-project",
    path: "/external-storage-administration/storage-project",
    component: () => import("/app/pages/external-storage-administration/storage-project.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-administration-storage-reporting",
    path: "/external-storage-administration/storage-reporting",
    component: () => import("/app/pages/external-storage-administration/storage-reporting.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-administration-storage-ride",
    path: "/external-storage-administration/storage-ride",
    component: () => import("/app/pages/external-storage-administration/storage-ride.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-administration-storage-user",
    path: "/external-storage-administration/storage-user",
    component: () => import("/app/pages/external-storage-administration/storage-user.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-inbound-approve",
    path: "/external-storage-inbound/approve",
    component: () => import("/app/pages/external-storage-inbound/approve.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-inbound-request",
    path: "/external-storage-inbound/request",
    component: () => import("/app/pages/external-storage-inbound/request.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-inbound-store",
    path: "/external-storage-inbound/store",
    component: () => import("/app/pages/external-storage-inbound/store.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-outbound-load",
    path: "/external-storage-outbound/load",
    component: () => import("/app/pages/external-storage-outbound/load.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-outbound-request",
    path: "/external-storage-outbound/request",
    component: () => import("/app/pages/external-storage-outbound/request.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-outbound-task-mode",
    path: "/external-storage-outbound/task/:mode()",
    component: () => import("/app/pages/external-storage-outbound/task/[mode].vue").then(m => m.default || m)
  },
  {
    name: "external-storage-position-id",
    path: "/external-storage-position/:id()",
    component: () => import("/app/pages/external-storage-position/[id].vue").then(m => m.default || m)
  },
  {
    name: "external-storage-invoicing-storagePositionId",
    path: "/external-storage/invoicing/:storagePositionId()",
    component: () => import("/app/pages/external-storage/invoicing/[storagePositionId].vue").then(m => m.default || m)
  },
  {
    name: "external-storage-positions-in-storage",
    path: "/external-storage/positions-in-storage",
    component: () => import("/app/pages/external-storage/positions-in-storage.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-retrieved-positions",
    path: "/external-storage/retrieved-positions",
    component: () => import("/app/pages/external-storage/retrieved-positions.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-storage-product",
    path: "/external-storage/storage-product",
    component: () => import("/app/pages/external-storage/storage-product.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-used-storage-space-check",
    path: "/external-storage/used-storage-space-check",
    component: () => import("/app/pages/external-storage/used-storage-space-check.vue").then(m => m.default || m)
  },
  {
    name: "freelancer-sales-released-machinery",
    path: "/freelancer-sales/released-machinery",
    component: () => import("/app/pages/freelancer-sales/released-machinery.vue").then(m => m.default || m)
  },
  {
    name: "freelancer-sales-sale-all",
    path: "/freelancer-sales/sale/all",
    component: () => import("/app/pages/freelancer-sales/sale/all.vue").then(m => m.default || m)
  },
  {
    name: "freelancer-sales-sale-current-view",
    path: "/freelancer-sales/sale/current/:view()",
    meta: _91view_938vAQ5J4c6KMeta || {},
    component: () => import("/app/pages/freelancer-sales/sale/current/[view].vue").then(m => m.default || m)
  },
  {
    name: "inbound-logistics-task-mode",
    path: "/inbound-logistics-task/:mode()",
    component: () => import("/app/pages/inbound-logistics-task/[mode].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "internal-costs-machinery-accessory",
    path: "/internal-costs/machinery-accessory",
    component: () => import("/app/pages/internal-costs/machinery-accessory.vue").then(m => m.default || m)
  },
  {
    name: "internal-costs-machinery",
    path: "/internal-costs/machinery",
    component: () => import("/app/pages/internal-costs/machinery.vue").then(m => m.default || m)
  },
  {
    name: "internal-costs-order",
    path: "/internal-costs/order",
    component: () => import("/app/pages/internal-costs/order.vue").then(m => m.default || m)
  },
  {
    name: "invoice-view",
    path: "/invoice/:view()",
    component: () => import("/app/pages/invoice/[view].vue").then(m => m.default || m)
  },
  {
    name: "invoice-preparation",
    path: "/invoice/preparation",
    component: () => import("/app/pages/invoice/preparation.vue").then(m => m.default || m)
  },
  {
    name: "logistics-details-collected-cuid",
    path: "/logistics-details/collected/:cuid()",
    meta: _91cuid_93kRcgTSY9RwMeta || {},
    component: () => import("/app/pages/logistics-details/collected/[cuid].vue").then(m => m.default || m)
  },
  {
    name: "logistics-details-issued-cuid",
    path: "/logistics-details/issued/:cuid()",
    meta: _91cuid_93df8ay8VltEMeta || {},
    component: () => import("/app/pages/logistics-details/issued/[cuid].vue").then(m => m.default || m)
  },
  {
    name: "logistics-planning",
    path: "/logistics-planning",
    component: () => import("/app/pages/logistics-planning.vue").then(m => m.default || m)
  },
  {
    name: "logistics-view",
    path: "/logistics/:view()",
    component: () => import("/app/pages/logistics/[view].vue").then(m => m.default || m)
  },
  {
    name: "machinery-accessory-id",
    path: "/machinery-accessory/:id()",
    meta: _91id_937ll1MXElmMMeta || {},
    component: () => import("/app/pages/machinery-accessory/[id].vue").then(m => m.default || m)
  },
  {
    name: "machinery-creation-view",
    path: "/machinery-creation/:view()",
    component: () => import("/app/pages/machinery-creation/[view].vue").then(m => m.default || m)
  },
  {
    name: "machinery-type-maintain",
    path: "/machinery-type/maintain",
    component: () => import("/app/pages/machinery-type/maintain.vue").then(m => m.default || m)
  },
  {
    name: "machinery-id",
    path: "/machinery/:id()",
    meta: _91id_93amDb0mRFLQMeta || {},
    component: () => import("/app/pages/machinery/[id].vue").then(m => m.default || m)
  },
  {
    name: "marketing-view",
    path: "/marketing/:view()",
    component: () => import("/app/pages/marketing/[view].vue").then(m => m.default || m)
  },
  {
    name: "marketing-storage-tool-categories",
    path: "/marketing/storage-tool-categories",
    component: () => import("/app/pages/marketing/storage-tool-categories.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-foreign-machinery-id",
    path: "/my-hos/foreign-machinery/:id()",
    meta: _91id_93xO4Z2yy8QLMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/foreign-machinery/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-hos-foreign-machinery",
    path: "/my-hos/foreign-machinery",
    meta: indexqqTC7qe3CIMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/foreign-machinery/index.vue").then(m => m.default || m)
  },
  {
    name: "my-hos",
    path: "/my-hos",
    meta: indexYk2XtvWUqCMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/index.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-machinery-id",
    path: "/my-hos/machinery/:id()",
    meta: _91id_936f5yDzZDzkMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/machinery/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-hos-machinery",
    path: "/my-hos/machinery",
    meta: indexvzLYVOGgakMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/machinery/index.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-profile",
    path: "/my-hos/profile",
    meta: profile8Rkplb43x8Meta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/profile.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-rental-id",
    path: "/my-hos/rental/:id()",
    meta: _91id_93vZd527RxS9Meta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/rental/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-hos-rental",
    path: "/my-hos/rental",
    meta: indexq8Z4xEu4cuMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/rental/index.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-rental-manage-accessCode",
    path: "/my-hos/rental/manage/:accessCode()",
    meta: _91accessCode_93uvZVOmrusSMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/rental/manage/[accessCode].vue").then(m => m.default || m)
  },
  {
    name: "my-hos-rental-manage",
    path: "/my-hos/rental/manage",
    meta: indexrUlDwuimOeMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/rental/manage/index.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-sale-id",
    path: "/my-hos/sale/:id()",
    meta: _91id_93DM2kOJBlSfMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/sale/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-hos-sale",
    path: "/my-hos/sale",
    meta: indexRuYQmEfDxCMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/sale/index.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-service-project-id",
    path: "/my-hos/service-project/:id()",
    meta: _91id_93w8kpIYYDwYMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/service-project/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-hos-service-project",
    path: "/my-hos/service-project",
    meta: indexuSwtg49uaqMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/service-project/index.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-service-project-reporting-id",
    path: "/my-hos/service-project/reporting/:id()",
    meta: _91id_93bRKJ7oud2nMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/service-project/reporting/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-hos-service-project-reporting",
    path: "/my-hos/service-project/reporting",
    meta: indexwjvui71QtcMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/service-project/reporting/index.vue").then(m => m.default || m)
  },
  {
    name: "my-hos-special-id",
    path: "/my-hos/special/:id()",
    meta: _91id_93Czdv3TCg0pMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/special/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-hos-special",
    path: "/my-hos/special",
    meta: index9Tj7ZHkwykMeta || {},
    component: () => import("/app/layers/customer-module/pages/my-hos/special/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-reporting-cuid",
    path: "/offer-reporting/:cuid()",
    meta: _91cuid_93yj6izUF5PSMeta || {},
    component: () => import("/app/pages/offer-reporting/[cuid].vue").then(m => m.default || m)
  },
  {
    name: "offer-templates-position-template-bundles",
    path: "/offer-templates/position-template-bundles",
    component: () => import("/app/pages/offer-templates/position-template-bundles.vue").then(m => m.default || m)
  },
  {
    name: "offer-templates-position-templates",
    path: "/offer-templates/position-templates",
    component: () => import("/app/pages/offer-templates/position-templates.vue").then(m => m.default || m)
  },
  {
    name: "offer-mode",
    path: "/offer/:mode()",
    component: () => import("/app/pages/offer/[mode].vue").then(m => m.default || m)
  },
  {
    name: "online-sales-marketplaces",
    path: "/online-sales/marketplaces",
    component: () => import("/app/pages/online-sales/marketplaces.vue").then(m => m.default || m)
  },
  {
    name: "overview-foreign-machinery",
    path: "/overview/foreign-machinery",
    component: () => import("/app/pages/overview/foreign-machinery.vue").then(m => m.default || m)
  },
  {
    name: "overview-machinery",
    path: "/overview/machinery",
    component: () => import("/app/pages/overview/machinery.vue").then(m => m.default || m)
  },
  {
    name: "overview-storage-tool",
    path: "/overview/storage-tool",
    component: () => import("/app/pages/overview/storage-tool.vue").then(m => m.default || m)
  },
  {
    name: "partner-sales-quick-sale",
    path: "/partner-sales/quick-sale",
    component: () => import("/app/pages/partner-sales/quick-sale.vue").then(m => m.default || m)
  },
  {
    name: "partner-sales-released-machinery",
    path: "/partner-sales/released-machinery",
    component: () => import("/app/pages/partner-sales/released-machinery.vue").then(m => m.default || m)
  },
  {
    name: "rental-view",
    path: "/rental/:view()",
    component: () => import("/app/pages/rental/[view].vue").then(m => m.default || m)
  },
  {
    name: "rental-pricing",
    path: "/rental/pricing",
    component: () => import("/app/pages/rental/pricing/index.vue").then(m => m.default || m)
  },
  {
    name: "rental-pricing-machinery-type-machineryTypeId",
    path: "/rental/pricing/machinery-type/:machineryTypeId()",
    component: () => import("/app/pages/rental/pricing/machinery-type/[machineryTypeId].vue").then(m => m.default || m)
  },
  {
    name: "rental-pricing-machinery-machineryId",
    path: "/rental/pricing/machinery/:machineryId()",
    component: () => import("/app/pages/rental/pricing/machinery/[machineryId].vue").then(m => m.default || m)
  },
  {
    name: "reporting",
    path: "/reporting",
    component: () => import("/app/pages/reporting.vue").then(m => m.default || m)
  },
  {
    name: "sale-view",
    path: "/sale/:view()",
    component: () => import("/app/pages/sale/[view].vue").then(m => m.default || m)
  },
  {
    name: "service-project-view",
    path: "/service-project/:view()",
    component: () => import("/app/pages/service-project/[view].vue").then(m => m.default || m)
  },
  {
    name: "service-project-external-id",
    path: "/service-project/external/:id()",
    component: () => import("/app/pages/service-project/external/[id].vue").then(m => m.default || m)
  },
  {
    name: "service-project-external-overview-view",
    path: "/service-project/external/overview/:view()",
    component: () => import("/app/pages/service-project/external/overview/[view].vue").then(m => m.default || m)
  },
  {
    name: "service-project-fitter-issuance",
    path: "/service-project/fitter-issuance",
    component: () => import("/app/pages/service-project/fitter-issuance.vue").then(m => m.default || m)
  },
  {
    name: "service-project-planning",
    path: "/service-project/planning",
    component: () => import("/app/pages/service-project/planning.vue").then(m => m.default || m)
  },
  {
    name: "service-machinery-accessory",
    path: "/service/machinery-accessory",
    component: () => import("/app/pages/service/machinery-accessory.vue").then(m => m.default || m)
  },
  {
    name: "service-machinery",
    path: "/service/machinery",
    component: () => import("/app/pages/service/machinery.vue").then(m => m.default || m)
  },
  {
    name: "service-repair-type-id-defectId",
    path: "/service/repair-:type()/:id()/:defectId?",
    component: () => import("/app/pages/service/repair-[type]/[id]/[[defectId]].vue").then(m => m.default || m)
  },
  {
    name: "service-repair-type",
    path: "/service/repair-:type()",
    component: () => import("/app/pages/service/repair-[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "service-sale-issuance",
    path: "/service/sale-issuance",
    component: () => import("/app/pages/service/sale-issuance.vue").then(m => m.default || m)
  },
  {
    name: "service-sale-order",
    path: "/service/sale-order",
    component: () => import("/app/pages/service/sale-order.vue").then(m => m.default || m)
  },
  {
    name: "set-overview",
    path: "/set-overview",
    component: () => import("/app/pages/set-overview.vue").then(m => m.default || m)
  },
  {
    name: "set-id",
    path: "/set/:id()",
    meta: _91id_93ff4Iwhbgl8Meta || {},
    component: () => import("/app/pages/set/[id].vue").then(m => m.default || m)
  },
  {
    name: "shop-buy",
    path: "/shop/buy",
    meta: indexXcRSRur08RMeta || {},
    component: () => import("/app/layers/customer-module/pages/shop/buy/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-buy-machinery-accessory",
    path: "/shop/buy/machinery-accessory",
    meta: machinery_45accessoryDX9zxNbDR2Meta || {},
    component: () => import("/app/layers/customer-module/pages/shop/buy/machinery-accessory.vue").then(m => m.default || m)
  },
  {
    name: "shop-checkout-id",
    path: "/shop/checkout/:id()",
    meta: _91id_93C5bsf6Bq9zMeta || {},
    component: () => import("/app/layers/customer-module/pages/shop/checkout/[id].vue").then(m => m.default || m)
  },
  {
    name: "shop-checkout",
    path: "/shop/checkout",
    meta: indexlmNCj0UwZ9Meta || {},
    component: () => import("/app/layers/customer-module/pages/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "shop",
    path: "/shop",
    meta: indexjqYtGSBRSHMeta || {},
    component: () => import("/app/layers/customer-module/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-rent-booking",
    path: "/shop/rent/booking",
    meta: bookingd4iDXTActsMeta || {},
    component: () => import("/app/layers/customer-module/pages/shop/rent/booking.vue").then(m => m.default || m)
  },
  {
    name: "shop-rent",
    path: "/shop/rent",
    meta: indexjAwQcohAlMMeta || {},
    component: () => import("/app/layers/customer-module/pages/shop/rent/index.vue").then(m => m.default || m)
  },
  {
    name: "special-order-view",
    path: "/special-order/:view()",
    component: () => import("/app/pages/special-order/[view].vue").then(m => m.default || m)
  },
  {
    name: "storage-tool-bundle",
    path: "/storage-tool-bundle",
    component: () => import("/app/pages/storage-tool-bundle.vue").then(m => m.default || m)
  },
  {
    name: "storage-tool-creation-view",
    path: "/storage-tool-creation/:view()",
    component: () => import("/app/pages/storage-tool-creation/[view].vue").then(m => m.default || m)
  },
  {
    name: "tasks-mode",
    path: "/tasks/:mode()",
    component: () => import("/app/pages/tasks/[mode].vue").then(m => m.default || m)
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/app/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "tasks-inquiry",
    path: "/tasks/inquiry",
    meta: inquiryJDoiWEoVl6Meta || {},
    component: () => import("/app/pages/tasks/inquiry.vue").then(m => m.default || m)
  }
]