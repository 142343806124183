<script setup lang="ts">
const { t: $t } = useI18n()

const language = defineModel<string>()

const languageOptions = [
  { value: 'de', label: $t('customer.language.DE') },
  { value: 'en', label: $t('customer.language.EN') },
]
</script>

<template>
  <label>
    <p class="font-bold mb-1">{{ $t('common.field.language.name') }}</p>
    <n-select
      v-model:value="language"
      :options="languageOptions"
      class="w-full max-w-[450px] mb-5"
    />
  </label>
</template>
