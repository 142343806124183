<script setup lang="ts">
const { openLogisticsDateUpdateComment: { data: popupData, close: closePopup } } = useGlobalOpeners()
</script>

<template>
  <ThePopup
    v-if="popupData"
    :show="Boolean(popupData)"
    :title="`${popupData.type === 'DeliveryTime' ? 'Anlieferzeit' : 'Anliefertag'} anpassen`"
  >
    <p class="mb-2">
      Sie können den Vorgang nicht abbrechen. Ein Kommentar ist verpflichtend.
    </p>

    <CommentList :id="popupData.id" :type="popupData.type" :is-closable="false" hide-list-title @after-open-create-popup="closePopup" />
  </ThePopup>
</template>
