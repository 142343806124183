import { joinURL } from 'ufo'
import type { ApiOfferGetAll, ApiOfferGetById, OfferType } from '~/types'

const prefixWithIhr = {
  Angebot: 'Ihr Angebot',
  Auftrag: 'Ihren Auftrag',
}

const offerTypeToGerman = {
  'rental': 'Mietvertrag',
  'sale': 'Kauf',
  'service-project': 'Dienstleistungs-Projekt',
  'special': 'Spezialauftrag',
}

const offerTypeToContact = {
  'rental': 'Falls Sie Fragen haben, erreichen Sie uns persönlich unter miete@hanselmann.de oder 07951/29706-470.',
  'sale': 'Falls Sie Fragen haben, erreichen Sie uns persönlich unter sales@hanselmann.de oder 07951 29706-11',
  'service-project': 'Falls Sie Fragen haben, erreichen Sie uns persönlich unter miete@hanselmann.de oder 07951/29706-470.',
  'special': 'Falls Sie Fragen haben, erreichen Sie uns persönlich unter miete@hanselmann.de oder 07951/29706-470.',
}

function generateOfferEmailBody(
  offer: ApiOfferGetAll | ApiOfferGetById,
  name: string,
  urlWithLocale: (url: string, locale?: string) => string,
) {
  const currentURLOrigin = useFullCurrentUrl('onlyOrigin')
  const locale = getLocale(offer.customer?.language)

  const offerPDFLink = urlWithLocale(joinURL(currentURLOrigin, '/api/pdf/offer/details/', offer.cuid), locale)
  const offerDetailsLink = urlWithLocale(joinURL(currentURLOrigin, 'my-hos', offer.type, offer.id), locale)

  const offerOrOrder = offer.status === 'offer' ? 'Angebot' : 'Auftrag'

  const lines = [
    `Liebe/er ${offer.contactPerson?.name},`,
    `Vielen Dank für Ihr Interesse. Sie können ${prefixWithIhr[offerOrOrder]} über nachfolgenden Link einsehen:`,
    offerPDFLink,
    '',
    `Um weitere Informationen zu Ihrem ${offerTypeToGerman[offer.type as OfferType]} einzusehen oder den aktuellen Stand Ihrer Bestellung zu verfolgen, nutzen Sie gerne unser Kundenmodul unter folgendem Link:`,
    offerDetailsLink,
    '',
    offerTypeToContact[offer.type as OfferType],
    '',
    'Liebe Grüße,',
    name,
  ]

  // Join all the lines and add a new line at the end of each
  return lines.join('%0D%0A')
}

/**
 * Generate a `mailto` for a offer to send to customer.
 *
 * Mailto opens a pre-filled mail inside the default mail-app. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#linking_to_an_email_address
 */
export default function (
  offer: ApiOfferGetAll | ApiOfferGetById,
  name: string,
  urlWithLocale: (url: string, locale?: string) => string,
) {
  const offerOrOrder = offer.status === 'offer' ? 'Angebot' : 'Auftrag'

  const subject = `Ihr ${offerOrOrder} bei Hanselmann`

  const body = generateOfferEmailBody(offer, name, urlWithLocale)

  const params = [
    `subject=${subject}`,
    `body=${body}`,
  ]

  if (offer.type === 'rental') {
    params.push(`bcc=miete@hanselmann.de`)
  }

  return `mailto:${offer.contactPerson?.email}?${params.join('&')}`
}
