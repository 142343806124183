import { NButton, NPopover, NTooltip } from 'naive-ui'
import type { DataTableColumn, DataTableColumns } from 'naive-ui'
import { isBefore } from 'date-fns'
import currency from 'currency.js'
import type { ApiCalendarItemSet, ApiCustomerGetAll, ApiDefectGetAll, ApiDefectGetListByTypeId, ApiExternalSalesMarketplaceGetAll, ApiInputInvoiceGetAllOfferForInvoiceById, ApiInternalCostPositionGetAll, ApiInternalReservationGetAll, ApiInvoiceGetAll, ApiInvoiceGetAllForOverview, ApiInvoiceGetAllOffersForPreparation, ApiInvoiceGetById, ApiItemSetGetAll, ApiItemSetGetAllWithOngoingOfferPositions, ApiItemSetPublicGetById, ApiLogisticsTaskGetAll, ApiMachineryAccessoryGetAllForOverview, ApiMachineryAccessoryGetById, ApiMachineryAccessoryGetCompatibleAccessoriesForBundle, ApiMachineryGetAll, ApiMachineryGetAllForOverview, ApiMachineryGetById, ApiMachineryTypeGetAll, ApiOfferGetAll, ApiOfferGetById, ApiOfferPositionGetToCreateInvoice, ApiPositionTemplateBundleGetAllForOffer, ApiPositionTemplateBundleGetAllForOverview, ApiPositionTemplateGetAll, ApiPublicMachineryGetById, ApiReceptionMachineryHistoryGetAllForOverview, ApiShoppingGetAllAccessories, ApiShoppingGetCompatibleAccessories, ApiSoldMachineryAccessoryGetByCustomerId, ApiSoldMachineryGetByCustomerId, ApiStorageLoadCarrierGetAll, ApiStoragePositionGetAll, ApiStorageUserGetAll, ApiUsersGetAll, InvoiceOfferPositionRow, InvoiceStatus, InvoiceType, MachineryAccessoryCategory, MachineryAccessoryOverviewSelectOptions, OfferStatus, OfferType, OfferView, PositionUnit, RouterOutput, ShoppingMachineryAccessory } from '../types'
import { invoiceStatusToGerman, invoiceTypeToGerman, offerTypesToGerman, unitsToGerman } from '~/translations'
import { idObjectSchema, offerStatusSchema } from '~/server/schemas'
import { machineryAccessoryCategories } from '~/server/schemas/machineryAccessory'
import { calculateTotals } from '~/server/pdf-generation/sections/offer/total'
import { calculateTotals as calculateInvoiceTotals } from '~/server/pdf-generation/sections/invoice/total'
import { formatPercentage } from '~/server/pdf-generation/helpers'
import OfferStatusTimelinePopover from '~/components/Offer/StatusTimelinePopover.vue'
import ThePositionImagePopover from '~/components/ThePositionImagePopover.vue'
import { computeOfferPositionStatuses, computeOfferTimeline } from '~/components/Logistics/computeStatus'
import { ExternalSalesMarketplaceIcon, NuxtLink } from '#components'
import { CustomerStatus, type LogisticsTaskStatus, type OfferPositionType, PositionTemplateType } from '~/prisma/enums'

/**
 * A fulltext search filter is either:
 * - just a field in JSON-Pointer notation (Eg: `/customer/name`) -> indicating a partial matching search on that field
 * - a make filter value function to generate more complex filters, e.g., to cast to number or to do an exact match
 */
export type FulltextSearchFieldFilter = string | {
  field: string
  makeFilterValue: (valueToFilterFor: string) => string | number | undefined | Record<string, unknown>
}

export interface TableConfig<ColumnData> {
  /**
   * Columns of the table.
   */
  columns: DataTableColumns<ColumnData>
  /**
   * Row properties that should be assigned to each row in order to customize row attributes. E.g., can be used to style rows or to add an on-click action.
   *
   * @param row Data of row that is being rendered
   */
  rowProps?: (row: ColumnData) => ({ style: string, onClick: (() => any) | ((e: Event) => any) })
  /**
   * Row class name that should be assigned to each row. E.g., to set background color, etc.
   * Row coloring will only take effect if either:
   *  - `stripes` on the `TableView` component is set to `false`
   *  - the css rule is `!important` AND you're also adding `no-stripe` to the row
   *
   * @param row Data of row that is being rendered
   */
  rowClassName?: (row: ColumnData) => string
  /**
   * Configuration of fulltext-search on the table.
   */
  fulltextSearch: {
    /**
     * `fields` are JSON-Pointers to the fields that should be searched:
     * - By default, they will be searched in an "ILIKE" manner, so a partial search will happen. E.g., "ide" will match "sidestream".
     * - Another option is to specify an object that contains the field name (again in JSON-Pointer notation) and a `makeFilterValue` function. This function can be used to generate different filters, e.g., to convert the text to a number and do a number search.
     */
    filters: FulltextSearchFieldFilter[]
    /**
     * Placeholder that the search input should show when no search input was yet entered.
     */
    placeholder: string
    /**
     * QueryID, that is set into the browser URL, to save the search-input value. If not set, the search value will not be saved to the URL.
     */
    queryId?: string
  }
}

/**
 * check if the target element is a checkbox to block triggering a rowProps click event
 * taken from https://github.com/tusen-ai/naive-ui/issues/4008#issuecomment-1324747076
 */
const isCheckboxClick = (e: Event) => (e.target && (e.target as Element).classList?.[0] === 'n-checkbox-box__border') ?? false
const isRadioClick = (e: Event) => (e.target && (e.target as Element).classList?.[0].includes('n-radio')) ?? false

/**
 * if rental has passed the obligation date, and not terminated,
 * we want to extend the rental until today
 * Calculate and return the `rentalDays` for a offer that:
 * 1. is of `type === 'rental'`,
 * 2. has passed the obligation data,
 * 3. has not been terminated
 */
function overwriteOfferRentalDaysWithExtendedRentalDays(offer: ApiInvoiceGetAllOffersForPreparation | ApiInputInvoiceGetAllOfferForInvoiceById) {
  if (offer.type !== 'rental') {
    return offer
  }

  const isRentalExtendedTillToday = offer.obligationEndsAt
    && offer.obligationEndsAt.getTime() < startOfDayInTz(new Date()).getTime()
    && !offer.obligationActuallyEndedAt
  const isRentalExtendedTillObligationActuallyEndedAt = offer.obligationEndsAt
    && offer.obligationActuallyEndedAt
    && offer.obligationEndsAt.getTime() < offer.obligationActuallyEndedAt.getTime()

  if ((isRentalExtendedTillToday || isRentalExtendedTillObligationActuallyEndedAt) && offer.obligationEndsAt) {
    let extededRentalDays = offer.rentalDays

    if (isRentalExtendedTillToday) {
      extededRentalDays = offer.rentalDays.concat(getExtendedOfferRentalDays(offer.rentalDays))
    } else if (isRentalExtendedTillObligationActuallyEndedAt && offer.obligationActuallyEndedAt) {
      extededRentalDays = offer.rentalDays.concat(getExtendedOfferRentalDays(offer.rentalDays, offer.obligationActuallyEndedAt))
    }

    return {
      ...offer,
      rentalDays: extededRentalDays,
      positions: offer.positions.map((position) => {
        if (position.unit !== 'day') {
          return position
        }

        if (position.terminatedDate) {
          const rentalDays = extededRentalDays
            .concat(getExtendedOfferRentalDays(offer.rentalDays, position.terminatedDate))
            .filter(rentalDay => position.terminatedDate && isBefore(rentalDay.date, position.terminatedDate))

          return {
            ...position,
            quantity: rentalDays.length,
          }
        }

        return {
          ...position,
          quantity: extededRentalDays.length,
        }
      }),
    }
  }

  return offer
}

function getSoldMachineryNetRealisedSellingPrice(machinery: ApiMachineryGetAllForOverview) {
  if (!machinery.soldAt || !machinery.relatedOfferPositions) {
    return
  }

  const filteredPositions = machinery.relatedOfferPositions.filter(
    (position) => {
      if ('relatedOffer' in position && machinery.soldAt) {
        const obligationDate = new Date(position.relatedOffer.obligationStartsAt)
        const soldDate = new Date(machinery.soldAt)

        return obligationDate.getTime() === soldDate.getTime()
      }
      return false
    },
  )

  if (filteredPositions.length === 0) {
    return
  }

  const offerPosition = filteredPositions[0]
  let offerPositionInvoice
  if ('invoices' in offerPosition && offerPosition.invoices.length > 0) {
    offerPositionInvoice = offerPosition.invoices[0]
  }

  return offerPositionInvoice ? offerPositionInvoice.pricePerUnit : undefined
}

export default () => {
  const i18n = useI18n()
  const { openOfferPage, openCustomerPopup, openInvoicingPopup, openInvoicePositionsPopup, openPositionsDetailsPopup, openInternalCostCreateOrEditPopup, openDefectCreateOrUpdatePopup } = useGlobalOpeners()
  const { isRole, useremail } = useAuthorization()

  const machineryColumns = (columnShowConfig?: {
    pricePurchaseEuros: boolean
    deliveryCompanyName: boolean
    serialNumber: boolean
    comment: boolean
    priceRecommendedToSellFor: boolean
    endCustomerSellingPrice: boolean
    dealerSellingPrice: boolean
    status: boolean
    deliveryDate: boolean
    createdAt: boolean
    storageLocation: boolean
    releasedForSaleOnExternalMarketplaces: boolean
  }, isMachinerySelectable?: Ref<boolean>) => computed((): TableConfig<ApiMachineryGetAllForOverview>['columns'] => {
    const {
      pricePurchaseEuros = false,
      deliveryCompanyName = true,
      serialNumber = true,
      comment = true,
      priceRecommendedToSellFor = false,
      endCustomerSellingPrice = false,
      dealerSellingPrice = false,
      status = true,
      deliveryDate = true,
      storageLocation = true,
      releasedForSaleOnExternalMarketplaces = false,
    } = columnShowConfig ?? {}

    const isAdmin = isRole('admin')

    const columns: DataTableColumns<ApiMachineryGetAllForOverview> = []

    if (isMachinerySelectable?.value) {
      columns.push({
        type: 'selection',
        multiple: true,
      })
    }

    if (status) {
      columns.push({
        title: i18n.t('common.field.status.name'),
        key: 'status',
        width: 120,
        className: 'min-w-[120px]',
        render: ({ status, soldAt, isDefective }) => {
          const currentStatus = soldAt && isDateBeforeOrOnToday(soldAt) ? 'sold' : status
          const defectiveLabel = isDefective ? i18n.t('machinery.label.defective') : ''
          return `${i18n.t(`machinery.status.${currentStatus}`)}${defectiveLabel}`
        },
      })
    }

    columns.push({
      title: 'ID',
      key: 'id',
      minWidth: 80,
      className: 'min-w-[80px]',
      render: ({ id, marketingPhotos }) => h(ThePositionImagePopover, { id, photos: marketingPhotos, type: 'machinery' }, () => h('span', { class: 'underline text-blue-600 decoration-dotted' }, id.slice(0, 8))),
    }, {
      title: i18n.t('machinery.field.producerCompanyName.name'),
      key: 'producerCompanyName',
      minWidth: 100,
      className: 'min-w-[100px]',
    }, {
      title: i18n.t('common.field.type.name'),
      key: 'typeName',
      minWidth: 100,
      className: 'min-w-[100px]',
      render: ({ type }) => type.name,
    })

    if (serialNumber) {
      columns.push({
        title: i18n.t('machinery.field.serialNumber.name'),
        key: 'serialNumber',
        minWidth: 130,
        className: 'min-w-[130px]',
      })
    }

    if (comment) {
      columns.push({
        title: i18n.t('machinery.field.comment.name'),
        key: 'comment',
        width: 140,
        className: 'min-w-[140px]',
        render: ({ comment }) => truncateColumnValueIntoPopover(comment, 140),
      })
    }

    columns.push({
      title: i18n.t('machinery.field.liftingHeight.name'),
      key: 'liftingHeightInMillimeters',
      width: 100,
      className: 'min-w-[100px]',
      render: ({ liftingHeightInMillimeters, receptionMachinery }) => {
        if (receptionMachinery?.liftingHeightInMillimeters) {
          return `${receptionMachinery.liftingHeightInMillimeters}mm`
        }

        if (liftingHeightInMillimeters) {
          return `${liftingHeightInMillimeters}mm`
        }

        return '-'
      },
    }, {
      title: i18n.t('receptionMachinery.field.operatingHours.name'),
      key: 'operatingHours',
      minWidth: 130,
      className: 'min-w-[130px]',
      render: ({ receptionMachinery }) => {
        return receptionMachinery?.operatingHours ? receptionMachinery.operatingHours : 'N/A'
      },
    })

    if (deliveryDate) {
      columns.push({
        title: i18n.t('machinery.field.deliveryDate.name'),
        key: 'deliveryDate',
        render: ({ deliveryDate }) => deliveryDate ? useDateAsString(deliveryDate, 'dd.MM.yy') : 'N/A',
        minWidth: 130,
        className: 'min-w-[130px]',
      })
    }

    if (deliveryCompanyName) {
      columns.push({
        title: i18n.t('machinery.field.deliveryCompanyName.name'),
        key: 'deliveryCompanyName',
        minWidth: 100,
        className: 'min-w-[100px]',
      })
    }

    columns.push({
      title: i18n.t('machinery.field.yearBuilt.name'),
      key: 'yearBuilt',
      render: ({ yearBuilt }) => yearBuilt ?? 'N/A',
      minWidth: 100,
      className: 'min-w-[100px]',
    })

    if (storageLocation) {
      columns.push({
        title: i18n.t('machinery.field.storageLocation.name'),
        key: 'storageLocation',
        render: ({ storageLocation }) => storageLocation ?? 'N/A',
        minWidth: 100,
        className: 'min-w-[100px]',
      })
    }

    if (pricePurchaseEuros) {
      columns.push({
        title: i18n.t('machinery.field.pricePurchaseEuros.name'),
        key: 'pricePurchaseEuros',
        render: ({ pricePurchaseEuros }) => pricePurchaseEuros ? `${formatNumberToString(pricePurchaseEuros, 'de', 0)}€` : '',
        minWidth: 100,
        className: 'min-w-[100px]',
      })
    }

    if (priceRecommendedToSellFor) {
      columns.push({
        title: i18n.t('machinery.field.priceRecommendedToSellFor.name'),
        key: 'priceRecommendedToSellFor',
        render: ({ priceRecommendedToSellFor }) => priceRecommendedToSellFor ? `${priceRecommendedToSellFor.toLocaleString('de-DE')}€` : '',
        minWidth: 100,
        className: 'min-w-[100px]',
      })
    }

    if (endCustomerSellingPrice) {
      columns.push({
        title: i18n.t('machinery.field.endCustomerSellingPrice.name', { currency: 'EUR' }),
        key: 'endCustomerSellingPrice',
        render: ({ endCustomerSellingPrice }) => endCustomerSellingPrice ? `${endCustomerSellingPrice.toLocaleString('de-DE')}€` : '',
        minWidth: 175,
        className: 'min-w-[175px]',
      })
    }

    if (dealerSellingPrice) {
      columns.push({
        title: i18n.t('machinery.field.dealerSellingPrice.name', { currency: 'EUR' }),
        key: 'dealerSellingPrice',
        render: ({ dealerSellingPrice }) => dealerSellingPrice ? `${dealerSellingPrice.toLocaleString('de-DE')}€` : '',
        minWidth: 175,
        className: 'min-w-[175px]',
      })
    }

    if (releasedForSaleOnExternalMarketplaces) {
      columns.push({
        title: i18n.t('machinery.releasedForSaleOnExternalMarketplaces.label'),
        key: 'releasedForSaleOnExternalMarketplaces',
        minWidth: 150,
        className: 'min-w-[150px]',
        render: ({ releasedForSaleOnExternalMarketplaces }) => {
          const renderedIcons = releasedForSaleOnExternalMarketplaces.map(({ marketplaceId }) => {
            return h(NTooltip, { trigger: 'hover' }, {
              default: () => i18n.t(`externalSalesMarketplace.id.${marketplaceId}`),
              trigger: () => h(ExternalSalesMarketplaceIcon, { marketplaceId, class: 'h-[20px]' }),
            })
          })
          return h('div', { class: 'flex gap-2 items-center' }, renderedIcons.length > 0 ? renderedIcons : i18n.t('common.no'))
        },
      })
    }

    if (isAdmin) {
      columns.push({
        title: i18n.t('machinery.field.partnerConditionsForPartnersThatCanSellMe.releasedForPartner.name'),
        key: 'partnerConditionsForPartnersThatCanSellMe',
        render(row) {
          const partners = row.partnerConditionsForPartnersThatCanSellMe
          if (partners.length === 0) {
            return 'Nein'
          }

          return h(NPopover, null, {
            default: () => partners.map(it => h('p', it.partnerUser.email)),
            trigger: () => 'Ja',
          })
        },
        minWidth: 150,
        className: 'min-w-[150px]',
      }, {
        title: i18n.t('machinery.field.netRealisedSellingPrice.name'),
        key: 'soldAt',
        minWidth: 150,
        render: (row) => {
          const netRealisedSellingPrice = getSoldMachineryNetRealisedSellingPrice(row)
          return netRealisedSellingPrice ? useDisplayNumberAsCurrency(netRealisedSellingPrice) : ''
        },
      })
    }

    return columns
  })

  const machineryWithoutColumns: Omit<TableConfig<ApiMachineryGetAllForOverview>, 'columns'> = {
    fulltextSearch: {
      filters: ['/id', '/foreignMachineryID', '/deliveryCompanyName', '/producerCompanyName', '/comment', '/serialNumber', '/type/name', { field: '/liftingHeightInMillimeters', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) }, { field: '/yearBuilt', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) }],
      placeholder: 'Suchbegriff eingeben (ID, Lieferant, Hersteller, Kommentar, Seriennummer, Hubhöhe)',
      queryId: 'machinery',
    },
    rowProps: (row: ApiMachineryGetAllForOverview) => ({
      style: 'cursor: pointer;',
      onClick: (e) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }

        if (idObjectSchema.safeParse(row).success) {
          return navigateTo({ path: `/machinery/${row.id}`, query: row.isForeignMachinery ? { isForeignMachinery: 'true' } : undefined })
        }
      },
    }),
    rowClassName: (row: ApiMachineryGetAllForOverview) => {
      const freelancerSpecialOffers = row.freelancerConditionsForFreelancersThatCanSellMe?.some(condition => condition.isSpecialOffer === true && condition.freelancerUser?.email === useremail.value)
      const partnerSpecialOffers = row.partnerConditionsForPartnersThatCanSellMe?.some(condition => condition.isSpecialOffer === true && condition.partnerUser?.email === useremail.value)
      return freelancerSpecialOffers || partnerSpecialOffers
        ? 'SpecialOffer'
        : ''
    },
  }

  const machinerySingleSelect: TableConfig<ApiMachineryGetAll> = {
    fulltextSearch: {
      filters: ['/id', '/deliveryCompanyName', '/producerCompanyName', '/comment', '/serialNumber', '/type/name', { field: '/liftingHeightInMillimeters', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) }],
      placeholder: 'Suchbegriff eingeben (ID, Lieferant, Hersteller, Kommentar, Seriennummer, Hubhöhe)',
    },
    columns: [
      {
        type: 'selection',
        multiple: false,
        disabled: ({ isPermanentlyNotAvailable }) => isPermanentlyNotAvailable,
      },
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 80,
        render: ({ id, isPermanentlyNotAvailable }) => isPermanentlyNotAvailable ? `${id.slice(0, 8)} (dauerhaft nicht verfügbar)` : id.slice(0, 8),
      },
      {
        title: i18n.t('common.field.type.name'),
        key: 'typeName',
        minWidth: 100,
        render: ({ type }) => type.name,
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'producerCompanyName',
        minWidth: 100,
      },
      {
        title: i18n.t('machinery.field.serialNumber.name'),
        key: 'serialNumber',
        minWidth: 130,
      },
      {
        title: i18n.t('machinery.field.comment.name'),
        key: 'comment',
        width: 140,
        render: ({ comment }) => truncateColumnValueIntoPopover(comment, 140),
      },
      {
        title: i18n.t('machinery.field.liftingHeight.name'),
        key: 'liftingHeightInMillimeters',
        width: 100,
        render: ({ liftingHeightInMillimeters }) => liftingHeightInMillimeters ? `${liftingHeightInMillimeters}mm` : '-',
      },
      {
        title: i18n.t('machinery.field.deliveryCompanyName.name'),
        key: 'deliveryCompanyName',
        minWidth: 100,
      },
    ],
  }

  const soldMachinery: TableConfig<ApiSoldMachineryGetByCustomerId> = {
    fulltextSearch: {
      filters: ['/id', '/producerCompanyName', '/serialNumber', '/type/name', { field: '/yearBuilt', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) }],
      placeholder: 'Suchbegriff eingeben (ID, Hersteller, Seriennummer, Typ, Baujahr)',
      queryId: 'machinery',
    },
    rowProps: (row: ApiSoldMachineryGetByCustomerId) => ({
      style: 'cursor: pointer;',
      onClick: (e) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }

        if (idObjectSchema.safeParse(row).success) {
          return navigateTo(`/machinery/${row.id}`)
        }
      },
    }),
    columns: [
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 80,
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 100,
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'producerCompanyName',
        minWidth: 100,
      },
      {
        title: i18n.t('common.field.type.name'),
        key: 'typeName',
        minWidth: 100,
        render: ({ type }) => type.name,
      },
      {
        title: i18n.t('machinery.field.yearBuilt.name'),
        key: 'yearBuilt',
        render: ({ yearBuilt }) => yearBuilt ?? 'N/A',
        minWidth: 100,
      },
      {
        title: i18n.t('machinery.field.serialNumber.name'),
        key: 'serialNumber',
        minWidth: 130,
      },
      {
        title: i18n.t('machinery.field.soldAt.name'),
        key: 'soldAt',
        render: ({ soldAt }) => soldAt ? useDateAsString(soldAt, 'dd.MM.yyyy') : 'N/A',
      },
    ],
  }

  const soldMachineryAccessory: TableConfig<ApiSoldMachineryAccessoryGetByCustomerId> = {
    fulltextSearch: {
      filters: ['/id', '/producerCompanyName', '/serialNumber', '/typeName', { field: '/yearBuilt', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) }],
      placeholder: 'Suchbegriff eingeben (ID, Hersteller, Seriennummer, Typ, Baujahr)',
      queryId: 'accessory',
    },
    rowProps: (row: ApiSoldMachineryAccessoryGetByCustomerId) => ({
      style: 'cursor: pointer;',
      onClick: (e) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }

        if (idObjectSchema.safeParse(row).success) {
          return navigateTo(`/machinery-accessory/${row.id}`)
        }
      },
    }),
    columns: [
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 80,
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 115,
        render: ({ category }) => i18n.t(`machineryAccessory.category.${category}`),
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'name',
        minWidth: 100,
        render: row => row.producerCompanyName || i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('common.field.type.name'),
        key: 'typeName',
        minWidth: 100,
      },
      {
        title: i18n.t('machinery.field.yearBuilt.name'),
        key: 'yearBuilt',
        minWidth: 100,
        render: row => row.yearBuilt ?? i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('machinery.field.serialNumber.name'),
        key: 'serialNumber',
        render: row => row.serialNumber ?? i18n.t('common.notAvailable'),
        minWidth: 130,
      },
      {
        title: i18n.t('machinery.field.soldAt.name'),
        key: 'soldAt',
        render: ({ soldAt }) => soldAt ? useDateAsString(soldAt, 'dd.MM.yyyy') : i18n.t('common.notAvailable'),
      },
    ],
  }

  const machineryAccessoryColumns: TableConfig<ApiMachineryAccessoryGetAllForOverview | ApiMachineryAccessoryGetCompatibleAccessoriesForBundle>['columns'] = [
    {
      title: i18n.t('common.field.status.name'),
      key: 'status',
      minWidth: 100,
      render: ({ status, soldAt, isDefective }) => {
        const shownStatus = soldAt && isDateBeforeOrOnToday(soldAt) ? 'sold' : status
        const defectiveLabel = isDefective ? i18n.t('machinery.label.defective') : ''

        return `${i18n.t(`machineryAccessory.status.${shownStatus}`)}${defectiveLabel}`
      },
    },
    {
      title: i18n.t('common.field.id.name'),
      key: 'id',
      minWidth: 100,
      render: ({ id, photos }) => h(ThePositionImagePopover, { id, photos, type: 'machineryAccessory' }, () => h('span', { class: 'underline text-blue-600 decoration-dotted' }, id.slice(0, 8))),
    },
    {
      title: i18n.t('common.field.category.name'),
      key: 'category',
      minWidth: 140,
      render: ({ category }) => i18n.t(`machineryAccessory.category.${category}`),
    },
    {
      title: i18n.t('common.field.type.name'),
      key: 'typeName',
      minWidth: 140,
    },
    {
      title: i18n.t('machineryAccessory.field.productCode.name'),
      key: 'productCode',
      render: row => row.productCode ?? i18n.t('common.notAvailable'),
      minWidth: 140,
    },
    {
      title: i18n.t('machinery.field.serialNumber.name'),
      key: 'serialNumber',
      render: row => row.serialNumber ?? i18n.t('common.notAvailable'),
      minWidth: 140,
    },
    {
      title: i18n.t('machinery.field.producerCompanyName.name'),
      key: 'name',
      minWidth: 140,
      render: row => row.producerCompanyName || i18n.t('common.notAvailable'),
    },
    {
      title: i18n.t('machinery.field.yearBuilt.name'),
      key: 'yearBuilt',
      minWidth: 80,
      render: row => row.yearBuilt ?? i18n.t('common.notAvailable'),
    },
    {
      title: i18n.t('comment.name', { count: 1 }),
      key: 'comment',
      width: 120,
      render: ({ comment }) => truncateColumnValueIntoPopover(comment, 120),
    },
    {
      title: i18n.t('common.field.length.name'),
      key: 'lengthInMillimeters',
      minWidth: 120,
    },
    {
      title: i18n.t('machinery.field.liftingWeight.name'),
      key: 'liftingWeight',
      minWidth: 120,
    },
    {
      title: i18n.t('machinery.field.storageLocation.name'),
      key: 'storageLocation',
      minWidth: 140,
    },
    {
      title: i18n.t('machineryAccessory.field.createdAt.name'),
      key: 'createdAt',
      render: ({ createdAt }) => createdAt ? useDateAsString(createdAt) : i18n.t('common.notAvailable'),
      minWidth: 120,
    },
    {
      title: i18n.t('machineryAccessory.field.createdBy.name'),
      key: 'createdBy',
      render: ({ createdBy }) => createdBy?.name ?? i18n.t('common.notAvailable'),
      minWidth: 130,
    },
    {
      title: i18n.t('machinery.field.soldAt.name'),
      key: 'soldAt',
      minWidth: 140,
      render: row => row.soldAt ? useDateAsString(row.soldAt) : '',
      sorter: (row1, row2) => sortNullishDates(row1.soldAt, row2.soldAt),
    },
  ]

  const machineryAccessory: TableConfig<ApiMachineryAccessoryGetAllForOverview> = {
    fulltextSearch: {
      filters: ['/id', '/producerCompanyName', '/comment', '/storageLocation', '/productCode', '/createdBy/name', '/typeName', '/serialNumber', '/licensePlateNumber'],
      placeholder: 'Suchbegriff eingeben (ID, Hersteller, Typ, Seriennummer, Kommentar, Lagerplatz, Artikelnummer, Registriert von, Kennzeichen)',
      queryId: 'accessory',
    },
    rowProps: (row: ApiMachineryAccessoryGetAllForOverview) => ({
      style: 'cursor: pointer;',
      onClick: (e) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }

        if (idObjectSchema.safeParse(row).success) {
          return navigateTo(`/machinery-accessory/${row.id}`)
        }
      },
    }),
    columns: machineryAccessoryColumns,
  }

  const machineryAccessorySelectForSet = (selectOptions?: MachineryAccessoryOverviewSelectOptions): TableConfig<ApiMachineryAccessoryGetAllForOverview> => ({
    fulltextSearch: {
      filters: ['/id', '/producerCompanyName', '/comment', '/storageLocation', '/productCode', '/createdBy/name', '/typeName'],
      placeholder: 'Suchbegriff eingeben (ID, Hersteller, Typ, Kommentar, Lagerplatz, Artikelnummer, Registriert von)',
      queryId: 'accessory',
    },
    rowProps: (row: ApiMachineryAccessoryGetAllForOverview) => ({
      style: 'cursor: pointer;',
      onClick: (e) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }

        if (idObjectSchema.safeParse(row).success) {
          openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: row.id })
        }
      },
    }),
    columns: [
      {
        type: 'selection',
        multiple: true,
        fixed: 'left',
        disabled: (row) => {
          if (row.isPermanentlyNotAvailable) {
            return true
          }

          const itemSet = row.itemSets[0]
          if (row.attachedMachineryId || (itemSet && itemSet.id !== selectOptions?.setId)) {
            return true
          }

          if (selectOptions?.disableWhenMachineryAccessoryIsInOngoingOffer && row.relatedOfferPositions?.length > 0) {
            return true
          }

          return false
        },
      },
      {
        title: i18n.t('common.field.status.name'),
        key: 'status',
        minWidth: 130,
        render: ({ status, attachedMachineryId, itemSets, isPermanentlyNotAvailable }) => {
          const statusText = i18n.t(`machineryAccessory.status.${status}`)
          if (isPermanentlyNotAvailable) {
            return `${statusText}${i18n.t('machineryAccessory.label.permanentlyNotAvailable')}`
          }
          if (attachedMachineryId) {
            return `${statusText}${i18n.t('machineryAccessory.label.attachedMachinery', { attachedMachineryId })}`
          }
          const itemSet = itemSets[0]
          if (itemSet) {
            return i18n.t('machineryAccessory.label.inItemSet', { itemSetId: itemSet.id })
          }
          return statusText
        },
      },
      {
        title: i18n.t('machineryAccessory.field.limitation.name'),
        key: 'id',
        minWidth: 140,
        render: ({ relatedOfferPositions }) => {
          const overlappedOffer = relatedOfferPositions[0]?.relatedOffer
          if (overlappedOffer) {
            if (overlappedOffer.type === 'rental') {
              return i18n.t('machineryAccessory.label.rented', { offerId: overlappedOffer.id })
            }

            if (overlappedOffer.type === 'sale') {
              return i18n.t('machineryAccessory.label.sold', { offerId: overlappedOffer.id })
            }
          }

          return ''
        },
      },
      ...machineryAccessory.columns.slice(1),
    ],
  })

  const machineryAccessorySelect = ({ machineryId, selectType, isSetSelectable }: { machineryId?: string, selectType?: 'multiple' | 'single', isSetSelectable?: boolean }) => computed((): TableConfig<ApiShoppingGetCompatibleAccessories> => ({
    fulltextSearch: {
      filters: ['/id', '/producerCompanyName', '/comment', '/storageLocation'],
      placeholder: 'Suchbegriff eingeben (ID, Hersteller, Kommentar, Lagerplatz)',
    },
    rowProps: (row: ApiShoppingGetCompatibleAccessories) => ({
      style: 'cursor: pointer;',
      onClick: (e: Event) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }
        openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: row.id })
      },
    }),
    columns: [
      {
        type: 'selection',
        multiple: selectType === 'multiple',
        disabled: (row) => {
          if (row.isPermanentlyNotAvailable) {
            return true
          }

          if (!row.attachedMachineryId || !machineryId) {
            return false
          }

          // check if itSet is set here
          if (!isSetSelectable) {
            return false
          }

          if (row.attachedMachineryId !== machineryId) {
            return true
          }

          return false
        },
      },
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 100,
        render: ({ id, attachedMachineryId, isPermanentlyNotAvailable }) => {
          if (isPermanentlyNotAvailable) {
            return `${id.slice(0, 8)}${i18n.t('machineryAccessory.label.permanentlyNotAvailable')}`
          }
          const connectedStatus = attachedMachineryId && attachedMachineryId !== machineryId
            ? i18n.t('machineryAccessory.label.attachedMachinery', { attachedMachineryId })
            : ''
          return `${id.slice(0, 8)}${connectedStatus}`
        },
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 100,
        render: ({ category }) => i18n.t(`machineryAccessory.category.${category}`),
        filter: (value, { category }) => value === category,
        filterOptions: machineryAccessoryCategories.map(value => ({ value, label: i18n.t(`machineryAccessory.category.${value}`) })),
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'name',
        minWidth: 100,
        render: row => row.producerCompanyName || i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('comment.name', { count: 1 }),
        key: 'comment',
        width: 120,
        render: ({ comment }) => truncateColumnValueIntoPopover(comment, 120),
      },
      {
        title: i18n.t('machinery.field.storageLocation.name'),
        key: 'storageLocation',
        minWidth: 100,
      },
    ],
  }))

  const compatibleMachineryAccessoriesForBundleMultiSelect: TableConfig<ApiMachineryAccessoryGetCompatibleAccessoriesForBundle> = {
    fulltextSearch: machineryAccessory.fulltextSearch,
    columns: [
      { type: 'selection' },
      ...machineryAccessoryColumns,
    ],
  }

  const connectedMachineryAccessories: TableConfig<ApiMachineryGetById['attachedMachineryAccessories'][number]> = {
    fulltextSearch: {
      filters: [{ field: '/id', makeFilterValue: (fulltextSearchValue: string) => ({ attachedMachineryAccessories: { some: { id: fulltextSearchValue } } }) }],
      placeholder: 'Suchbegriff eingeben (ID)',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => {
        if (idObjectSchema.safeParse(row).success) {
          return navigateTo(`/machinery-accessory/${row.id}`)
        }
      },
    }),
    columns: [
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 100,
        render: ({ id }) => `${id.slice(0, 8)}`,
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 100,
        render: ({ category }) => i18n.t(`machineryAccessory.category.${category}`),
        filter: (value, { category }) => value === category,
        filterOptions: machineryAccessoryCategories.map(value => ({ value, label: i18n.t(`machineryAccessory.category.${value}`) })),
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'name',
        minWidth: 100,
        render: row => row.producerCompanyName ?? i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('comment.name'),
        key: 'comment',
        width: 120,
        render: ({ comment }) => truncateColumnValueIntoPopover(comment, 120),
      },
      {
        title: i18n.t('machinery.field.storageLocation.name'),
        key: 'storageLocation',
        minWidth: 100,
      },
      {
        title: i18n.t('common.field.length.name'),
        key: 'lengthInMillimeters',
        minWidth: 120,
        render: ({ lengthInMillimeters }) => lengthInMillimeters ? lengthInMillimeters.toLocaleString('de-de') : i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('common.field.width.name'),
        key: 'widthInMillimeters',
        minWidth: 120,
        render: ({ widthInMillimeters }) => widthInMillimeters ? widthInMillimeters.toLocaleString('de-de') : i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('common.field.height.name'),
        key: 'heightInMillimeters',
        minWidth: 120,
        render: ({ heightInMillimeters }) => heightInMillimeters ? heightInMillimeters.toLocaleString('de-de') : i18n.t('common.notAvailable'),
      },
    ],
  }

  const connectedMachineryAccessoriesPublic: TableConfig<ApiPublicMachineryGetById['attachedMachineryAccessories'][number]> = {
    fulltextSearch: connectedMachineryAccessories.fulltextSearch,
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => {
        if (idObjectSchema.safeParse(row).success) {
          return navigateTo(`/machinery-accessory/${row.id}`)
        }
      },
    }),
    columns: [
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 100,
        render: ({ id }) => `${id.slice(0, 8)}`,
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 100,
        render: ({ category }) => i18n.t(`machineryAccessory.category.${category}`),
        filter: (value, { category }) => value === category,
        filterOptions: machineryAccessoryCategories.map(value => ({ value, label: i18n.t(`machineryAccessory.category.${value}`) })),
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'name',
        minWidth: 100,
        render: row => row.producerCompanyName ?? i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('common.field.length.name'),
        key: 'lengthInMillimeters',
        minWidth: 120,
        render: ({ lengthInMillimeters }) => lengthInMillimeters ? lengthInMillimeters.toLocaleString('de-de') : i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('common.field.width.name'),
        key: 'widthInMillimeters',
        minWidth: 120,
        render: ({ widthInMillimeters }) => widthInMillimeters ? widthInMillimeters.toLocaleString('de-de') : i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('common.field.height.name'),
        key: 'heightInMillimeters',
        minWidth: 120,
        render: ({ heightInMillimeters }) => heightInMillimeters ? heightInMillimeters.toLocaleString('de-de') : i18n.t('common.notAvailable'),
      },
    ],
  }

  const attachedMachinery: TableConfig<NonNullable<ApiMachineryAccessoryGetById['attachedMachinery']>> = {
    fulltextSearch: {
      filters: ['/attachedMachinery/id'],
      placeholder: 'Suchbegriff eingeben (ID)',
    },
    rowProps: (row: NonNullable<ApiMachineryAccessoryGetById['attachedMachinery']>) => ({
      style: 'cursor: pointer;',
      onClick: () => {
        if (idObjectSchema.safeParse(row).success) {
          return navigateTo(`/machinery/${row.id}`)
        }
      },
    }),
    columns: [
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 100,
        render: ({ id }) => `${id.slice(0, 8)}`,
      },
      {
        title: i18n.t('common.field.type.name'),
        key: 'type',
        minWidth: 100,
        render: ({ type }) => type.name,
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'producerCompanyName',
        minWidth: 100,
      },
    ],
  }

  const positionTemplatesMultiSelect: TableConfig<ApiPositionTemplateGetAll> = {
    fulltextSearch: {
      filters: ['/id', '/titles/some/title', { field: '/pricePerUnit', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) }],
      placeholder: 'Suchbegriff eingeben (ID, Title, Kosten pro Einheit)',
    },
    columns: [
      {
        type: 'selection',
      },
      {
        title: 'ID',
        key: 'id',
        minWidth: 80,
        render: ({ id }) => id.slice(0, 8),
      },
      {
        title: 'Typ',
        key: 'type',
        minWidth: 120,
        render: ({ type }) => i18n.t(`offerPosition.type.${type}`),
        filter: (value, { type }) => value === type,
        filterOptions: useTranslatedSelectOptions('offerPosition.type', Object.values(PositionTemplateType)),
      },
      {
        title: 'Titel',
        key: 'titles',
        minWidth: 120,
        render: ({ titles }) => {
          const germanTitle = titles.find(title => title.locale === 'de')
          return truncateColumnValueIntoPopover(germanTitle?.title ?? '-', 120)
        },
      },
      {
        title: 'Sprachen',
        key: 'titles',
        minWidth: 120,
        render: ({ titles }) => {
          const supportedLocales = titles.map(title => title.locale.toUpperCase()).join(', ')
          return truncateColumnValueIntoPopover(supportedLocales, 120)
        },
      },
      {
        title: 'Kosten pro Einheit',
        key: 'pricePerUnit',
        minWidth: 150,
        render: ({ pricePerUnit }) => pricePerUnit ? `${pricePerUnit} EUR` : '-',
      },
      {
        title: 'Erstellt',
        key: 'createdAt',
        render: ({ createdAt }) => createdAt ? useDateAsString(createdAt) : 'N/A',
        sorter: (row1, row2) => sortNullishDates(row1.createdAt, row2.createdAt),
        minWidth: 130,
      },
    ],
  }

  const positionTemplateBundleMultiSelect: TableConfig<ApiPositionTemplateBundleGetAllForOffer> = {
    fulltextSearch: {
      filters: ['/id', '/title'],
      placeholder: 'Suchbegriff eingeben (ID, Titel)',
    },
    columns: [
      {
        type: 'selection',
      },
      {
        title: 'ID',
        key: 'id',
        minWidth: 80,
        render: ({ id }) => id.slice(0, 8),
      },
      {
        title: 'Titel',
        key: 'title',
        minWidth: 130,
        render: ({ title }) => truncateColumnValueIntoPopover(title, 130),
      },
    ],
  }

  const machineryTypes: TableConfig<ApiMachineryTypeGetAll> = {
    fulltextSearch: {
      filters: ['/name', '/category', '/documentDataSheetFiles/some/path', '/documentManualFiles/some/path', '/documentVideoFiles/some/path'],
      placeholder: 'Suchbegriff eingeben (Typ, Kategorie)',
      queryId: 'type',
    },
    columns: [
      {
        title: 'Typ',
        key: 'name',
        minWidth: 130,
      },
      {
        title: 'Kategorie',
        key: 'category',
        render: ({ category }) => i18n.t(`machinery.category.${category}`),
        minWidth: 115,
      },
      {
        title: 'Dokumente',
        key: 'name',
        minWidth: 100,
        render: row => row._count.documentDataSheetFiles + row._count.documentManualFiles + row._count.documentVideoFiles + row.documentExternalVideoFiles.length,
      },
    ],
  }

  const position: TableConfig<ApiPositionTemplateGetAll> = {
    fulltextSearch: {
      filters: [
        '/id',
        '/titles/some/title',
        { field: '/pricePerUnit', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) },
        // machineryType.name
        '/isDefaultForMachineryTypes/some/name',
      ],
      placeholder: 'Suchbegriff eingeben (ID, Title, Kosten pro Einheit, Maschinen-Typ)',
      queryId: 'position',
    },
    columns: [
      {
        title: 'ID',
        key: 'id',
        minWidth: 80,
        render: ({ id }) => id.slice(0, 8),
      },
      {
        title: 'Typ',
        key: 'type',
        minWidth: 120,
        render: ({ type }) => i18n.t(`offerPosition.type.${type}`),
        filter: (value, { type }) => value === type,
        filterOptions: useTranslatedSelectOptions('offerPosition.type', Object.values(PositionTemplateType)),
      },
      {
        title: 'Titel',
        key: 'titles',
        minWidth: 120,
        render: ({ titles }) => {
          const germanTitle = titles.find(title => title.locale === 'de')
          return truncateColumnValueIntoPopover(germanTitle?.title ?? '-', 120)
        },
      },
      {
        title: 'Sprachen',
        key: 'titles',
        minWidth: 120,
        render: ({ titles }) => {
          const supportedLocales = titles.map(title => title.locale.toUpperCase()).join(', ')
          return truncateColumnValueIntoPopover(supportedLocales, 120)
        },
      },
      {
        title: 'Einheit',
        key: 'unit',
        minWidth: 100,
        render: ({ unit }) => unitsToGerman[unit as PositionUnit] || '-',
        filter: (value, { unit }) => value === unit,
        filterOptions: Object.entries(unitsToGerman).map(([value, label]) => ({ value, label })),
      },
      {
        title: 'Kosten pro Einheit',
        key: 'pricePerUnit',
        minWidth: 150,
        render: ({ pricePerUnit }) => pricePerUnit ? `${pricePerUnit} EUR` : '-',
      },
      {
        title: 'Verknüpfung',
        key: 'isDefaultForMachineryTypes',
        minWidth: 120,
        render: ({ isDefaultForMachineryTypes }) => Object.values(isDefaultForMachineryTypes).length,
      },
      {
        title: 'Erstellt',
        key: 'createdAt',
        render: ({ createdAt }) => createdAt ? useDateAsString(createdAt) : 'N/A',
        sorter: (row1, row2) => sortNullishDates(row1.createdAt, row2.createdAt),
        minWidth: 130,
      },
    ],
  }

  const customer: TableConfig<ApiCustomerGetAll> = {
    fulltextSearch: {
      filters: [
        '/id',
        '/name',
        '/location',
        '/relatedOffers/some/id',
        '/createdBy/name',
      ],
      placeholder: 'Suchbegriff eingeben (Id, Name, Ort, Angebote-Id, Aufträge-Id, Erstellt von)',
      queryId: 'customer',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => {
        if (idObjectSchema.safeParse(row).success) {
          openCustomerPopup.open({ mode: 'update', id: row.id })
        }
      },
    }),
    columns: [
      {
        title: i18n.t('common.field.status.name'),
        key: 'status',
        minWidth: 100,
        render: ({ status }) => i18n.t(`customer.status.${status}`),
        filter: (value, { type }) => value === type,
        filterOptions: useTranslatedSelectOptions('customer.status', Object.values(CustomerStatus)),
      },
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 75,
        render: ({ id }) => id.slice(0, 8),
      },
      {
        title: i18n.t('common.field.name.name'),
        key: 'name',
        minWidth: 140,
      },
      {
        title: i18n.t('customer.field.location.name'),
        key: 'location',
        minWidth: 140,
      },
      {
        title: i18n.t('common.field.type.name'),
        key: 'type',
        minWidth: 100,
        render: ({ type }) => i18n.t(`customer.type.${type}`),
      },
      {
        // TODO: add i18n
        title: 'Angebote',
        key: 'offerCount',
        minWidth: 100,
        render: ({ offerCount }) => offerCount,
      },
      {
        // TODO: add i18n
        title: 'Aufträge',
        key: 'orderCount',
        minWidth: 100,
        render: ({ orderCount }) => orderCount,
      },
      {
        title: i18n.t('common.field.createdAt.name'),
        key: 'createdAt',
        render: ({ createdAt }) => createdAt ? useDateAsString(createdAt) : 'N/A',
        sorter: (row1, row2) => sortNullishDates(row1.createdAt, row2.createdAt),
        minWidth: 140,
      },
      {
        title: i18n.t('common.field.createdBy.name'),
        key: 'createdBy',
        render: ({ createdBy }) => createdBy?.name ?? 'N/A',
        minWidth: 110,
      },
    ],
  }

  function getMachinesFromPositions<T>(row: { positions: { type: OfferPositionType, machinery: T }[] }): { type: 'machinery', machinery: NonNullable<T> }[] {
    return row.positions.filter((position): position is { type: 'machinery', machinery: NonNullable<T> } => position.type === 'machinery' && !!position.machinery)
  }

  const offer: TableConfig<ApiOfferGetAll> = {
    fulltextSearch: {
      filters: [
        '/id',
        '/customer/name',
        '/deliveryLocation',
        // machineryId
        '/positions/some/machineryId',
        // machinery.producerCompanyName
        '/positions/some/machinery/producerCompanyName',
        // machinery.type.name
        '/positions/some/machinery/type/name',
      ],
      placeholder: 'Suchbegriff eingeben (ID, Lieferort, Herstellername, Maschinen-Typ, Kunden-Name)',
      queryId: 'offer',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => {
        if (!row.isCancelled && idObjectSchema.safeParse(row).success) {
          return openOfferPage({ mode: 'edit', id: row.id })
        }
      },
    }),
    rowClassName: (row) => {
      // We want to avoid assigning the below class names to the summary-row. To check if a row is the summary row, we check if it the `id` column is not a string (expecting an object with `value` and `colSpan`, see `summary` in the `TableView` component)
      if (typeof row.id !== 'string') {
        return ''
      }

      return row.isConvertableToOrder ? 'ConvertableToOrder' : 'NotConvertableToOrder'
    },
    columns: [
      {
        title: i18n.t('common.field.status.name'),
        key: 'status',
        minWidth: 130,
        render: row => row.status === 'order' && ['sale', 'rental'].includes(row.type)
          ? h(OfferStatusTimelinePopover, { offerId: row.id, placement: 'right-end' }, i18n.t(`offer.status.${row.status}`))
          : i18n.t(`offer.status.${row.status}`),
      },
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        width: 170,
      },
      {
        title: i18n.t('offer.field.machineryId.name'),
        key: 'positions',
        minWidth: 115,
        render: (row) => {
          const machines = getMachinesFromPositions(row)
          return machines.length > 0
            ? machines.map(m => m.machinery.id.slice(0, 8)).join(', ')
            : i18n.t('common.notAvailable')
        },
      },
      {
        title: i18n.t('offer.field.machineryProducerCompanyName.name'),
        key: 'rentalMachineryRelation',
        minWidth: 150,
        render: (row) => {
          const machines = getMachinesFromPositions(row)
          return machines.length > 0
            ? machines.map(m => m.machinery.producerCompanyName).join(', ')
            : i18n.t('common.notAvailable')
        },
      },
      {
        title: i18n.t('machinery.type.name'),
        key: 'machineryType',
        minWidth: 115,
        render: (row) => {
          const machines = getMachinesFromPositions(row)
          return machines.length > 0
            ? machines.map(m => m.machinery.type.name).join(', ')
            : i18n.t('common.notAvailable')
        },
      },
      {
        title: i18n.t('offer.field.customer.name'),
        key: 'customer',
        minWidth: 125,
        render: ({ customer }) => customer.name,
      },
      {
        title: i18n.t('offer.field.deliveryLocation.rental.name'),
        key: 'deliveryLocation',
        minWidth: 100,
      },
    ],
  }

  const offerForInvoiceTab: TableConfig<ApiInvoiceGetAllOffersForPreparation | ApiInputInvoiceGetAllOfferForInvoiceById> = {
    fulltextSearch: {
      filters: [
        '/id',
        '/customer/name',
        '/deliveryLocation',
        '/title',
        // machineryId
        '/positions/some/machineryId',
        // machinery.producerCompanyName
        '/positions/some/machinery/producerCompanyName',
        // machinery.type.name
        '/positions/some/machinery/type/name',
      ],
      placeholder: i18n.t('invoice.offer.fulltextSearch.placeholder'),
      queryId: 'invoicePrepare',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => {
        if (idObjectSchema.safeParse(row).success) {
          openInvoicingPopup.open({ offerId: row.id })
        }
      },
    }),
    rowClassName: (row) => {
      // We want to avoid assigning the below class names to the summary-row. To check if a row is the summary row, we check if it the `id` column is not a string (expecting an object with `value` and `colSpan`, see `summary` in the `TableView` component)
      if (typeof row.id !== 'string') {
        return ''
      }

      if (row.isCancelled) {
        return 'CancelledOffer'
      }

      if (row.isLogisticallyCompleted) {
        return 'CompletedOffer'
      }

      return ''
    },
    columns: [
      {
        title: 'Typ',
        key: 'type',
        minWidth: 120,
        render: ({ type }) => offerTypesToGerman[type as OfferType],
      },
      {
        title: 'Auftrags-ID',
        key: 'id',
        width: 140,
        render: ({ id }) => h(NButton, {
          size: 'tiny',
          onClick: (e: Event) => {
            e.stopImmediatePropagation()
            return openOfferPage({ mode: 'edit', id })
          },
        }, () => [useRenderIcon({ name: 'material-symbols:open-in-new-rounded', class: 'mr-2' }), id]),
      },
      {
        title: 'Kundenname & ID',
        key: 'customer',
        minWidth: 150,
        render: ({ customer }) => `${customer.name} (${customer.id})`,
      },
      {
        title: 'Geräte-ID',
        key: 'positions',
        minWidth: 100,
        render: ({ positions }) => {
          const machinery = positions.find(position => position.type === 'machinery')
          return machinery?.machineryId ?? ''
        },
      },
      {
        title: i18n.t('invoice.offer.column.title'),
        key: 'title',
        minWidth: 150,
        render: ({ title }) => title ?? '',
      },
      {
        title: i18n.t('invoice.offer.column.projectCode'),
        key: 'projectCode',
        minWidth: 160,
        render: ({ projectCode }) => projectCode ?? '',
      },
      {
        title: 'Status',
        key: 'positions',
        minWidth: 150,
        render: (offer) => {
          const offerTimeline = computeOfferTimeline(offer)
          return Object.values(offerTimeline).findLast(segment => segment.statusState === 'success')?.title ?? 'Unbekannt'
        },
      },
      {
        title: 'Verkaufs-/Abmeldedatum',
        key: 'obligationEndsAt',
        minWidth: 110,
        render: (row) => {
          const date = row.type === 'sale' ? row.obligationEndsAt : row.obligationActuallyEndedAt
          return date ? useDateAsString(date) : 'N/A'
        },
      },
      {
        title: 'Gesamt-Betrag (Brutto)',
        key: 'positions',
        minWidth: 150,
        render: (row) => {
          const rowWithExtendedRentalDays = overwriteOfferRentalDaysWithExtendedRentalDays(row)

          return `${formatNumberToString(calculateTotals(rowWithExtendedRentalDays).brutto.value)} EUR`
        },
      },
    ],
  }

  const indexOfCustomerName = -2
  // Copy offer.columns
  function rentalColumns(offerView: OfferView): TableConfig<ApiOfferGetAll>['columns'] {
    const columns = [...offer.columns]

    // Add rental specific columns before CustomerName
    columns.splice(indexOfCustomerName, 0, {
      title: i18n.t('offer.field.requestedAt.name'),
      key: 'requestedAt',
      minWidth: 140,
      render: ({ requestedAt }) => useDateAsString(requestedAt, 'dd.MM.yy'),
    }, {
      title: i18n.t('offer.field.obligationStartsAt.rental.name'),
      key: 'obligationStartsAt',
      minWidth: 130,
      render: ({ obligationStartsAt }) => useDateAsString(obligationStartsAt, 'dd.MM.yy'),
    }, {
      title: i18n.t('offer.field.obligationEndsAt.rental.name'),
      key: 'obligationEndsAt',
      minWidth: 130,
      render: offer => offer.obligationEndsAt ? `${calculateRentalDuration(offer)} Tage` : i18n.t('common.notAvailable'),
    }, {
      title: i18n.t('offer.field.contactForCustomer.name'),
      key: 'contactForCustomer',
      minWidth: 210,
      render: ({ contactForCustomer }) => contactForCustomer?.name ?? i18n.t('common.notAvailable'),
    }, {
      title: i18n.t('common.field.createdAt.name'),
      key: 'createdAt',
      minWidth: 140,
      render: ({ createdAt }) => useDateAsString(createdAt, 'dd.MM.yy'),
    }, {
      title: i18n.t('common.field.createdBy.name'),
      key: 'createdBy',
      minWidth: 210,
      render: ({ createdBy }) => createdBy?.name ?? i18n.t('common.notAvailable'),
    }, {
      title: i18n.t('offer.field.updatedToOrderAt.name'),
      key: 'updatedToOrderAt',
      render: row => row.updatedToOrderAt ? useDateAsString(row.updatedToOrderAt, 'dd.MM.yy') : i18n.t('common.notAvailable'),
      minWidth: 210,
    })

    if (offerView === 'offer') {
      columns.splice(indexOfCustomerName, 0, {
        title: i18n.t('offer.field.offerReleasedToCustomerAt.title'),
        key: 'offerReleasedToCustomerAt',
        minWidth: 200,
        render: ({ offerReleasedToCustomerAt }) => offerReleasedToCustomerAt ? useDateAsString(offerReleasedToCustomerAt, 'dd.MM.yy') : i18n.t('common.notAvailable'),
      }, {
        title: i18n.t('offer.field.offerReleasedToCustomerBy.title'),
        key: 'offerReleasedToCustomerBy',
        minWidth: 200,
        render: ({ offerReleasedToCustomerBy, offerReleasedToCustomerByEmail }) => offerReleasedToCustomerBy?.name ?? offerReleasedToCustomerByEmail ?? i18n.t('common.notAvailable'),
      })
    }

    return columns
  }

  // Copy offer.columns
  const saleColumns: TableConfig<ApiOfferGetAll>['columns'] = [...offer.columns]
  // Add sale specific columns before CustomerName
  saleColumns.splice(indexOfCustomerName, 0, {
    title: i18n.t('offer.field.requestedAt.sale.name'),
    key: 'requestedAt',
    minWidth: 140,
    render: ({ requestedAt }) => useDateAsString(requestedAt, 'dd.MM.yy'),
  }, {
    title: i18n.t('offer.field.obligationStartsAt.sale.name'),
    key: 'obligationStartsAt',
    minWidth: 180,
    render: ({ obligationStartsAt }) => useDateAsString(obligationStartsAt, 'dd.MM.yy'),
  }, {
    title: i18n.t('offer.field.contactForCustomer.name'),
    key: 'contactForCustomer',
    minWidth: 210,
    render: ({ contactForCustomer }) => contactForCustomer?.name ?? i18n.t('common.notAvailable'),
  }, {
    title: i18n.t('offer.field.abSentAt.name'),
    key: 'abSentAt',
    minWidth: 180,
    render: ({ abSentAt }) => abSentAt ? useDateAsString(abSentAt, 'dd.MM.yy') : i18n.t('common.notAvailable'),
  }, {
    title: i18n.t('offer.field.abSentBy.name'),
    key: 'abSentBy',
    minWidth: 210,
    render: ({ abSentBy }) => abSentBy?.name ?? i18n.t('common.notAvailable'),
  }, {
    title: i18n.t('offer.field.saleProtocolPdfUploadedAt.name'),
    key: 'saleProtocolPdfUploadedAt',
    minWidth: 140,
    render: ({ saleProtocolPdfUploadedAt }) => saleProtocolPdfUploadedAt ? useDateAsString(saleProtocolPdfUploadedAt, 'dd.MM.yy') : i18n.t('common.notAvailable'),
  }, {
    title: i18n.t('common.field.createdAt.name'),
    key: 'createdAt',
    minWidth: 140,
    render: ({ createdAt }) => useDateAsString(createdAt, 'dd.MM.yy'),
  }, {
    title: i18n.t('common.field.createdBy.name'),
    key: 'createdBy',
    minWidth: 210,
    render: ({ createdBy }) => createdBy?.name ?? i18n.t('common.notAvailable'),
  }, {
    title: i18n.t('offer.field.updatedToOrderAt.name'),
    key: 'updatedToOrderAt',
    render: row => row.updatedToOrderAt ? useDateAsString(row.updatedToOrderAt, 'dd.MM.yy') : i18n.t('common.notAvailable'),
    minWidth: 210,
  })

  const serviceProjectOffer: TableConfig<ApiOfferGetAll> = {
    fulltextSearch: {
      filters: ['/id', '/deliveryLocation', '/customer/name', '/title'],
      placeholder: 'Suchbegriff eingeben (ID, Einsatzort, Kunden-Name, Titel)',
      queryId: 'service-project',
    },
    columns: [],
  }

  const specialOffer: TableConfig<ApiOfferGetAll> = {
    fulltextSearch: {
      filters: ['/id', '/customer/name'],
      placeholder: 'Suchbegriff eingeben (ID, Kunden-Name)',
      queryId: 'special-offer',
    },
    columns: [
      {
        title: i18n.t('common.field.status.name'),
        key: 'status',
        minWidth: 100,
        render: ({ status }) => i18n.t(`offer.status.${status}`),
        filter: (value, { status }) => value === status,
        filterOptions: offerStatusSchema.options.filter(val => val !== 'offer').map(value => ({ value, label: i18n.t(`offer.status.${value}`) })),
      },
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        width: 170,
      },
      {
        title: i18n.t('offer.field.requestedAt.name'),
        key: 'requestedAt',
        minWidth: 140,
        sorter: (row1, row2) => sortNullishDates(row1.requestedAt, row2.requestedAt),
        render: ({ requestedAt }) => useDateAsString(requestedAt, 'dd.MM.yy'),
      },
      {
        title: i18n.t('offer.field.customer.name'),
        key: 'customer',
        minWidth: 125,
        render: ({ customer }) => customer.name,
      },
      {
        title: i18n.t('offer.field.isStorageSpaceOffer.name'),
        key: 'isStorageSpaceOffer',
        minWidth: 160,
        render: ({ isStorageSpaceOffer }) => isStorageSpaceOffer ? i18n.t('common.yes') : i18n.t('common.no'),
      },
      {
        title: i18n.t('offer.field.projectCode.name'),
        key: 'projectCode',
        minWidth: 145,
        render: ({ projectCode }) => projectCode ?? i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('common.field.createdAt.name'),
        key: 'createdAt',
        minWidth: 140,
        sorter: (row1, row2) => sortNullishDates(row1.createdAt, row2.createdAt),
        render: ({ createdAt }) => useDateAsString(createdAt, 'dd.MM.yy'),
      },
      {
        title: i18n.t('common.field.createdBy.name'),
        key: 'createdBy',
        minWidth: 210,
        render: ({ createdBy }) => createdBy?.name ?? i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('offer.field.updatedToOrderAt.name'),
        key: 'updatedToOrderAt',
        render: row => row.updatedToOrderAt ? useDateAsString(row.updatedToOrderAt, 'dd.MM.yy') : i18n.t('common.notAvailable'),
        minWidth: 210,
      },
    ],
  }

  const users: TableConfig<ApiUsersGetAll> = {
    fulltextSearch: {
      filters: ['/name', '/email'],
      placeholder: 'Suchbegriff eingeben (Name, E-Mail)',
    },
    columns: [
      {
        title: 'Rolle',
        key: 'role',
        minWidth: 10,
        render: ({ role }) => i18n.t(`userRoles.${role}`),
      },
      {
        title: 'Name',
        key: 'name',
        minWidth: 30,
      },
      {
        title: 'E-Mail',
        key: 'email',
        minWidth: 30,
      },
      {
        title: 'Zuletzt eingeloggt',
        key: 'sessions',
        render: ({ sessions }) => sessions[0]?.createdAt ? useDateAsString(sessions[0]?.createdAt) : 'N/A',
      },
    ],
  }

  const logistics = (hasOfferTypeFilter: boolean): TableConfig<ApiLogisticsTaskGetAll> => ({
    fulltextSearch: {
      filters: [
        '/id',
        '/offerId',
        '/offer/customer/name',
        '/offer/deliveryLocation',
        // machineryId
        '/positionsToShip/some/machineryId',
        // machineryAccessoryId
        '/positionsToShip/some/machineryAccessoryId',
        '/positionsToShip/some/itemSetId',
      ],
      placeholder: 'Suchbegriff eingeben (Id, Geräte-ID, Lagertool-ID, Set-ID, Kundenname, Lieferort)',
      queryId: 'logistics',
    },
    columns: [
      {
        title: 'Status',
        key: 'role',
        minWidth: 130,
        render: (task) => {
          // `issued` and `planned` are view-specific statuses, and not saved in the database
          // see `logisticsTaskOnewayFullStatusSchema` in server/schemas.ts
          let status: LogisticsTaskStatus | 'issued' | 'planned' = task.status

          if (status === 'created') {
            const isPlanned = task.deliveryAt && task.assignedDeliveryVehicleId
            const isIssued = Boolean(task.positionsToShip.some(p => p.issuedAt))
            if (isPlanned) {
              status = 'issued'
            } else if (isIssued) {
              status = 'planned'
            }
          }

          return i18n.t(`logisticsTask.fullStatus.${status}`)
        },
      },
      {
        title: 'Angebots-Art',
        key: 'offer',
        minWidth: 180,
        render: ({ offer }) => offerTypesToGerman[offer?.type as OfferType],
        ...(hasOfferTypeFilter
          ? {
              filter: (value, { offer }) => value === offer?.type,
              filterOptions: Object.entries(offerTypesToGerman).map(([value, label]) => ({ value, label })),
            }
          : {}),
      },
      {
        title: 'ID',
        key: 'id',
        minWidth: 100,
        render: ({ id }) => id.slice(0, 8),
      },
      {
        title: 'Gerät',
        key: 'positionsToShip',
        minWidth: 100,
        render: ({ positionsToShip }) => positionsToShip.find(p => p.machineryId)?.machineryId ?? 'N/A',
      },

      {
        title: 'Art',
        key: 'type',
        width: 200,
        render: task => task.type === 'outbound' ? 'Anlieferung' : 'Abholung',
      },
      {
        title: 'Liefer-Datum (Auftrag)',
        key: 'offer',
        width: 200,
        render: (task) => {
          if (task.type === 'outbound') {
            return task.offer?.deliveryAt ? useDateAsString(task.offer.deliveryAt) : 'N/A'
          }
          const obligationActuallyEndedAt = task.offer?.obligationActuallyEndedAt
          return obligationActuallyEndedAt ? useDateAsString(obligationActuallyEndedAt) : 'N/A'
        },
        sorter: (row1, row2) => {
          if (row1.type === 'outbound') {
            return sortNullishDates(row1.offer?.deliveryAt, row2.offer?.deliveryAt)
          }

          return sortNullishDates(row1.offer?.obligationActuallyEndedAt, row2.offer?.obligationActuallyEndedAt)
        },
      },
      {
        title: 'Liefer-Datum (Logistik)',
        key: 'deliveryAt',
        width: 200,
        render: ({ deliveryAt }) => deliveryAt ? useDateAsString(deliveryAt) : 'N/A',
        sorter: (row1, row2) => sortNullishDates(row1.deliveryAt, row2.deliveryAt),
      },
      {
        title: 'Kundenname',
        key: 'sale',
        minWidth: 120,
        render: task => task.offer?.customer.name,
      },
      {
        title: 'Lieferort',
        key: 'sale',
        minWidth: 150,
        render: task => task.offer?.deliveryLocation,
      },
    ],
  })

  const getLogisticsIssuedOrCollectedFulltextSearch = (type: 'issued' | 'collected'): TableConfig<ApiLogisticsTaskGetAll>['fulltextSearch'] => ({
    filters: [
      '/offerId',
      '/offer/customer/name',
      '/offer/customerId',
      '/offer/deliveryLocation',
      // issued/returned by
      `/positionsToShip/some/${type === 'issued' ? 'issuedBy' : 'returnedBy'}/name`,
      // machineryId
      '/positionsToShip/some/machineryId',
      // machineryAccessoryId
      '/positionsToShip/some/machineryAccessoryId',
      // itemSetId
      '/positionsToShip/some/itemSetId',
    ],
    placeholder: `Suchbegriff eingeben (Auftrags-ID, Kundenname, Kunden-ID, Geräte-ID, ${type === 'issued' ? 'Ausgegeben' : 'Zurückgenommen'} von)`,
    queryId: 'logistics',
  })

  const logisticsIssued: TableConfig<ApiLogisticsTaskGetAll> = {
    fulltextSearch: getLogisticsIssuedOrCollectedFulltextSearch('issued'),
    columns: [
      {
        title: 'Typ',
        key: 'offer',
        minWidth: 180,
        render: ({ offer }) => offerTypesToGerman[offer?.type as OfferType],
      },
      {
        title: 'Auftrags-ID',
        key: 'offerId',
        minWidth: 150,
      },
      {
        title: 'Kundenname',
        key: 'sale',
        minWidth: 120,
        render: (task) => {
          return truncateColumnValueIntoPopover(task.offer?.customer.name, 120)
        },
      },
      {
        title: 'Kunden-ID',
        key: 'sale',
        minWidth: 120,
        render: task => task.offer?.customer.id.slice(0, 8),
      },
      {
        title: 'Geräte-ID',
        key: 'positionsToShip',
        minWidth: 150,
        render: (task) => {
          const machineryIds = task.positionsToShip.map(p => p.machineryId ?? p.machineryAccessoryId ?? p.itemSetId).join(', ')
          return truncateColumnValueIntoPopover(machineryIds, 150)
        },
      },
      {
        title: 'Ausgabedatum',
        key: 'positionsToShip',
        width: 200,
        render: (task) => {
          const machineryPosition = task.positionsToShip.find(p => p.type === 'machinery') ?? task.positionsToShip[0]
          return machineryPosition?.issuedAt ? useDateAsString(machineryPosition.issuedAt) : 'N/A'
        },
      },
      {
        title: 'Ausgegeben von',
        key: 'offer',
        width: 200,
        render: (task) => {
          const machineryPosition = task.positionsToShip.find(p => p.type === 'machinery') ?? task.positionsToShip[0]
          return machineryPosition?.issuedBy ? machineryPosition?.issuedBy?.name : 'N/A'
        },
      },
      {
        title: 'Versendet?',
        width: 200,
        key: 'sentOutProtocolBy',
        render: ({ sentOutProtocolBy, sentProtocolToCustomerAt }) => {
          if (!sentProtocolToCustomerAt) {
            return 'Nein'
          }

          return `${useDateAsString(sentProtocolToCustomerAt)} von ${sentOutProtocolBy?.name ?? 'Unbekannt'} `
        },
      },
    ],
  }

  const logisticsCollected: TableConfig<ApiLogisticsTaskGetAll> = {
    fulltextSearch: getLogisticsIssuedOrCollectedFulltextSearch('collected'),
    columns: [
      {
        title: 'Typ',
        key: 'offer',
        minWidth: 180,
        render: ({ offer }) => offerTypesToGerman[offer?.type as OfferType],
      },
      {
        title: 'Auftrags-ID',
        key: 'offerId',
        minWidth: 150,
      },
      {
        title: 'Kundenname',
        key: 'sale',
        minWidth: 120,
        render: (task) => {
          return truncateColumnValueIntoPopover(task.offer?.customer.name, 120)
        },
      },
      {
        title: 'Kunden-ID',
        key: 'sale',
        minWidth: 120,
        render: task => task.offer?.customer.id.slice(0, 8),
      },
      {
        title: 'Geräte-ID',
        key: 'positionsToShip',
        minWidth: 150,
        render: (task) => {
          const machineryIds = task.positionsToShip.map(p => p.machineryId ?? p.machineryAccessoryId).join(', ')
          return truncateColumnValueIntoPopover(machineryIds, 150)
        },
      },
      {
        title: 'Rücknahmedatum',
        key: 'positionsToShip',
        width: 200,
        render: (task) => {
          const machineryPosition = task.positionsToShip.find(p => p.type === 'machinery') ?? task.positionsToShip[0]
          return machineryPosition?.returnedAt ? useDateAsString(machineryPosition.returnedAt) : 'N/A'
        },
      },
      {
        title: 'Zurückgenommen von',
        key: 'offer',
        width: 200,
        render: (task) => {
          const machineryPosition = task.positionsToShip.find(p => p.type === 'machinery') ?? task.positionsToShip[0]
          return machineryPosition?.returnedBy ? machineryPosition?.returnedBy?.name : 'N/A'
        },
      },
      {
        title: 'Versendet?',
        width: 200,
        key: 'sentOutProtocolBy',
        render: ({ sentOutProtocolBy, sentProtocolToCustomerAt }) => {
          if (!sentProtocolToCustomerAt) {
            return 'Nein'
          }

          return `${useDateAsString(sentProtocolToCustomerAt)} von ${sentOutProtocolBy?.name ?? 'Unbekannt'} `
        },
      },
    ],
  }

  const invoice: TableConfig<ApiInvoiceGetAll | ApiInvoiceGetById | ApiInvoiceGetAllForOverview> = {
    fulltextSearch: {
      filters: ['/invoiceId', '/offer/id', '/offer/customer/name', '/offer/customer/id', '/offer/deliveryLocation', '/offer/title', '/createdBy/name', '/offer/positions/some/machineryId', '/cancellationInvoice/invoiceId'],
      placeholder: i18n.t('invoice.fulltextSearch.placeholder'),
      queryId: 'invoice',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => openInvoicePositionsPopup.open({ invoiceId: row.id }),
    }),
    rowClassName: (row) => {
      // We want to avoid assigning the below class names to the summary-row. To check if a row is the summary row, we check if it the `id` column is not a string (expecting an object with `value` and `colSpan`, see `summary` in the `TableView` component)
      if (typeof row.id !== 'string') {
        return ''
      }
      return row.status === 'paid' ? 'PaidInvoice' : ''
    },
    columns: [
      {
        title: 'Status',
        key: 'status',
        minWidth: 110,
        render: ({ status }) => invoiceStatusToGerman[status as InvoiceStatus],
      },
      {
        title: 'Rechnung #',
        key: 'invoiceId',
        minWidth: 110,
        render: ({ invoiceId }) => invoiceId ?? 'N/A',
      },
      {
        title: 'Rechnungstyp',
        key: 'type',
        minWidth: 180,
        render: ({ type }) => invoiceTypeToGerman[type as InvoiceType],
      },
      {
        title: 'Auftrags-ID',
        key: 'offerId',
        minWidth: 130,
        render: ({ offer }) => h(NButton, {
          size: 'tiny',
          onClick: (e: Event) => {
            e.stopImmediatePropagation()
            return openOfferPage({ mode: 'edit', id: offer.id })
          },
        }, () => [useRenderIcon({ name: 'material-symbols:open-in-new-rounded', class: 'mr-2' }), offer.id]),
      },
      {
        title: 'Kundenname & ID',
        key: 'customer',
        minWidth: 160,
        render: ({ offer }) => `${offer.customer.name} (${offer.customer.id})`,
      },
      {
        title: i18n.t('invoice.offer.column.title'),
        key: 'title',
        minWidth: 150,
        render: ({ offer }) => offer.title ?? '',
      },
      {
        title: i18n.t('invoice.offer.column.projectCode'),
        key: 'projectCode',
        minWidth: 160,
        render: ({ offer }) => offer.projectCode ?? '',
      },
      {
        title: 'Gesamt-Betrag (Netto)',
        key: 'totalAmount',
        minWidth: 130,
        render: (row) => {
          const totals = calculateInvoiceTotals(row)
          return `${formatNumberToString(totals.netto.value)} EUR`
        },
      },
      {
        title: 'Gesamt-Betrag (Brutto)',
        key: 'totalAmount',
        minWidth: 130,
        render: (row) => {
          const totals = calculateInvoiceTotals(row)
          return `${formatNumberToString(totals.brutto.value)} EUR`
        },
      },
      {
        title: 'Bezahlter Betrag',
        key: 'payments',
        minWidth: 130,
        render: ({ payments }) => {
          const paidAmount = payments.reduce((acc, payment) => {
            return acc.add(payment.amount)
          }, currency(0))
          return `${formatNumberToString(paidAmount.value)} EUR`
        },
      },
      {
        title: i18n.t('invoice.field.invoicedAt.name'),
        key: 'invoicedAt',
        minWidth: 140,
        render: ({ invoicedAt }) => invoicedAt ? useDateAsString(invoicedAt) : i18n.t('general.notApplicable'),
      },
      {
        title: 'Erstellt am',
        key: 'createdAt',
        minWidth: 140,
        render: row => useDateAsString(row.createdAt),
      },
      {
        title: 'Erstellt von',
        key: 'createdBy',
        minWidth: 140,
        render: row => row.createdBy.name,
      },
      {
        title: 'Versendet?',
        key: 'pendingAt',
        minWidth: 140,
        render: row => row.pendingAt ? useDateAsString(row.pendingAt) : 'Nein',
      },
      {
        title: 'Bezahlt am',
        key: 'paidAt',
        minWidth: 140,
        render: row => row.paidAt ? useDateAsString(row.paidAt) : '',
      },
      {
        title: 'Storniert von',
        key: 'cancelledBy',
        minWidth: 140,
        render: ({ cancellationInvoice }) => cancellationInvoice?.createdBy.name ?? '',
      },
      {
        title: 'Storniert am',
        key: 'cancelledAt',
        minWidth: 140,
        render: row => row.cancelledAt ? useDateAsString(row.cancelledAt) : '',
      },

    ],
  }

  const invoiceToOfferPositions = (invoiceType: Ref<InvoiceType | undefined>) => computed((): TableConfig<ApiOfferPositionGetToCreateInvoice> => ({
    fulltextSearch: {
      filters: ['/invoiceId'],
      placeholder: 'Suchbegriff eingeben (Rechnungs-Nr.)',
    },
    columns: [
      {
        type: 'selection',
        disabled: (row) => {
          if (invoiceType.value && ['fullpayment', 'proformapayment'].includes(invoiceType.value)) {
            return row.type !== 'comment'
          } else {
            return row.paymentStatus === 'Invoiced' || row.type === 'insurance'
          }
        },
      },
      {
        title: 'Status',
        key: 'status',
        render: ({ paymentStatus, invoicedIn, children }) => {
          if (paymentStatus !== 'Invoiced' || children?.length) {
            return i18n.t(`offerPosition.paymentStatus.${paymentStatus}`)
          } else {
            return invoicedIn ? `Berechnet in #${invoicedIn}` : 'Berechnet'
          }
        },
        minWidth: 170,
      },
      {
        title: 'Datum',
        key: 'relatedOffer',
        render: ({ relatedOffer, type, unit, firstDayOfWeek, invoicedDate, children }) => {
          const shouldShowDate = ['rental', 'special'].includes(relatedOffer.type)

          if (unit !== 'day' || !shouldShowDate || type === 'insurance') {
            return ''
          }

          const startDate = firstDayOfWeek ?? relatedOffer.obligationStartsAt

          if (!invoicedDate) {
            // count all the grandchildren & use this as quantity for day unit positions
            let quantity = 0
            for (const child of children ?? []) {
              if (child.children) {
                quantity += child.children.length
              } else {
                quantity += 1
              }
            }

            return `${useDateAsString(startDate, 'dd.MM.yy')} - ${useDateAsString(addDaysDstSafe(startDate, quantity - 1), 'dd.MM.yy')}`
          }

          return invoicedDate ? `${useDateAsString(invoicedDate, 'cccc')}, ${useDateAsString(invoicedDate, 'dd.MM.yy')}` : `${useDateAsString(startDate, 'cccc')}, ${useDateAsString(startDate, 'dd.MM.yy')}`
        },
        minWidth: 160,
      },
      {
        title: 'Typ',
        key: 'id',
        render: ({ type }) => i18n.t(`offerPosition.type.${type}`),
        minWidth: 140,
      },
      {
        title: 'Name',
        key: 'title',
        render: ({ title }) => {
          return truncateColumnValueIntoPopover(title, 150)
        },
        width: 150,
      },
      {
        title: 'Menge',
        key: 'quantity',
        minWidth: 140,
        render: ({ quantity, discountRateForDay }) => {
          if (discountRateForDay) {
            /**
             * sometimes floating calculation in js can return falsy data (eg. 0.3000000004)
             * so we use currency library for floating calculation here
             * find more: https://www.codemag.com/article/1811041/JavaScript-Corner-Math-and-the-Pitfalls-of-Floating-Point-Numbers
             */
            return currency(quantity).multiply(currency(1).subtract(discountRateForDay)).value.toLocaleString('de-DE')
          }
          return quantity.toLocaleString('de-DE')
        },
      },
      {
        title: 'Einheit',
        key: 'unit',
        render: ({ unit }) => unitsToGerman[unit as PositionUnit],
        minWidth: 130,
      },
      {
        title: 'E-Preis (EUR)',
        key: 'pricePerUnit',
        render: ({ pricePerUnit }) => pricePerUnit.toLocaleString('de-DE'),
        minWidth: 130,
      },
      {
        title: 'Rabatt',
        key: 'discountRate',
        render: ({ discountRateForDay, generatedByAutomation, discountRate }) => {
          if (generatedByAutomation) {
            return formatPercentage(discountRate)
          }

          if (typeof discountRateForDay === 'number') {
            return formatPercentage(discountRateForDay)
          }

          return ''
        },
        minWidth: 160,
      },
      {
        title: 'G-Preis (EUR)',
        key: 'pricePerUnit',
        minWidth: 140,
        render: (position) => {
          let total = currency(0)
          if (position.children) {
            for (const child of position.children) {
              if (child.children) {
                for (const grandChild of child.children) {
                  total = total.add(calculateInvoiceToOfferPositionPrice(grandChild))
                }
              } else {
                total = total.add(calculateInvoiceToOfferPositionPrice(child))
              }
            }
            return total.value.toLocaleString('de-DE')
          }
          return calculateInvoiceToOfferPositionPrice(position).value.toLocaleString('de-DE')
        },
      },
    ],
  }))

  const getAccessoryLimitations = ({ isSold, isDefective, overlappedOffer, attachedMachineryId, isPermanentlyNotAvailable, itemSets }: { isSold?: boolean, isDefective: boolean, overlappedOffer?: { type: string, id: string } | null, attachedMachineryId?: string | null, isPermanentlyNotAvailable: boolean, itemSets: { id: string }[] }) => {
    if (isPermanentlyNotAvailable) {
      return 'Dauerhaft nicht verfügbar'
    }

    let text = ''

    if (itemSets.length > 0) {
      text = `im Set ${itemSets[0].id}`
    }

    if (overlappedOffer) {
      if (overlappedOffer.type === 'rental') {
        text = `Vermietet ${overlappedOffer.id}`
      }

      if (overlappedOffer.type === 'sale') {
        text = `Verkauft ${overlappedOffer.id}`
      }
    }

    if (isDefective) {
      if (text.length > 0) {
        text += ' (defekt)'
      } else {
        text = 'Defekt'
      }
    }

    if (isSold) {
      if (text.length > 0) {
        text += ' (verkauft)'
      } else {
        text = 'Verkauft'
      }
    }

    if (attachedMachineryId) {
      if (text.length > 0) {
        text += ` (verbunden mit ${attachedMachineryId})`
      } else {
        text = `Verbunden mit ${attachedMachineryId}`
      }
    }

    return text
  }

  const shoppingAccessoryTable = (
    showSelectColumn: Ref<boolean>,
    checkedRowIds: Ref<string[]>,
    canAccessoriesBeAdded: Ref<boolean>,
    machineryId: Ref<string | undefined>,
    selectType: Ref<'single' | 'multi'>,
    selectedCategory: Ref<MachineryAccessoryCategory>,
    offer: Ref<{ status: OfferStatus, id?: string }>,
    disabledAccessoryIds: Ref<string[]>,
  ) => computed((): TableConfig<ShoppingMachineryAccessory> => ({
    fulltextSearch: { filters: [], placeholder: '' },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: (e: Event) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }

        openPositionsDetailsPopup.open({ id: row.id, type: 'machineryAccessory' })
      },
    }),
    columns: [
      // Do not show selection column if disabled
      ...(showSelectColumn.value
        ? [{
            type: 'selection',
            multiple: selectType.value === 'multi',
            disabled: (row) => {
              if (row.soldAt || row.isPermanentlyNotAvailable || disabledAccessoryIds.value.includes(row.id) || row.itemSets.length > 0 || row.isDefective) {
                return true
              }

              // If selection is disabled we only allow _unchecking_ of already checked rows
              if (!canAccessoriesBeAdded.value && !checkedRowIds.value.includes(row.id)) {
                return true
              }

              if (offer.value.status !== 'offer' && row.overlappedOffer) {
                if (row.overlappedOffer.id === offer.value.id) {
                  return false
                }
                return true
              }
              if (row.attachedMachineryId) {
              // If the attachedMachinery is fork, we allow to replace with other fork
                if (row.attachedMachineryId === machineryId.value && row.category === 'fork') {
                  return false
                }
                return true
              }
            },
          } as DataTableColumn<ShoppingMachineryAccessory>]
        : []),
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 90,
        render: ({ id, photos }) => {
          return h(ThePositionImagePopover, { id, photos, type: 'machineryAccessory', howToShowDetails: 'show-details-popup' }, () => h('span', { class: 'underline text-blue-600 decoration-dotted' }, id.slice(0, 8)))
        },
      },
      {
        title: i18n.t('machineryAccessory.field.limitation.name'),
        key: 'id',
        minWidth: 140,
        render: ({ soldAt, isDefective, overlappedOffer, attachedMachineryId, isPermanentlyNotAvailable, itemSets }) => {
          const isSold = !!(soldAt && isDateBeforeOrOnToday(soldAt))
          return getAccessoryLimitations({ isSold, isDefective, overlappedOffer, attachedMachineryId, isPermanentlyNotAvailable, itemSets })
        },
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 100,
        render: ({ category }) => i18n.t(`machineryAccessory.category.${category}`),
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'name',
        minWidth: 100,
        render: row => row.producerCompanyName || i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('comment.name', { count: 1 }),
        key: 'comment',
        width: 120,
        render: ({ comment }) => truncateColumnValueIntoPopover(comment, 120),
      },
      {
        title: i18n.t('machinery.field.storageLocation.name'),
        key: 'storageLocation',
        minWidth: 100,
      },
      ...(
        ['fork', 'forkExtension'].includes(selectedCategory.value)
          ? [{
              title: i18n.t('common.field.length.name'),
              key: 'lengthInMillimeters',
              minWidth: 120,
              render: ({ lengthInMillimeters }) => lengthInMillimeters ? lengthInMillimeters.toLocaleString('de-de') : '',
            }, {
              title: i18n.t('common.field.width.name'),
              key: 'widthInMillimeters',
              minWidth: 120,
              render: ({ widthInMillimeters }) => widthInMillimeters ? widthInMillimeters.toLocaleString('de-de') : '',
            }, {
              title: i18n.t('common.field.height.name'),
              key: 'heightInMillimeters',
              minWidth: 120,
              render: ({ heightInMillimeters }) => heightInMillimeters ? heightInMillimeters.toLocaleString('de-de') : '',
            }] as DataTableColumn<ShoppingMachineryAccessory>[]
          : []
      ),
    ],
  }))

  const shoppingAccessoryIdTable = (offer: { id?: string, status: OfferStatus, type: OfferType }, disabledAccessoryIds: string[]): TableConfig<ApiShoppingGetAllAccessories> => ({
    fulltextSearch: {
      filters: ['/id'],
      placeholder: 'Suchbegriff eingeben (ID)',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: (e: Event) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }

        openPositionsDetailsPopup.open({ id: row.id, type: 'machineryAccessory' })
      },
    }),
    columns: [
      {
        type: 'selection',
        multiple: true,
        disabled: (row) => {
          if (row.soldAt || row.isPermanentlyNotAvailable || disabledAccessoryIds.includes(row.id)) {
            return true
          }

          if (offer.status !== 'offer' && row.relatedOfferPositions.length > 0 && offer.type !== 'service-project') {
            const isThisOverlappedOffer = row.relatedOfferPositions.some(({ relatedOffer }) => relatedOffer.id === offer.id)
            if (isThisOverlappedOffer) {
              return false
            }
            return true
          }

          const itemSet = row.itemSets[0]
          if (row.attachedMachineryId || itemSet) {
            return true
          }

          return row.isDefective
        },
      },
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 90,
        render: ({ id, photos }) => {
          return h(ThePositionImagePopover, { id, photos, type: 'machineryAccessory', howToShowDetails: 'show-details-popup' }, () => h('span', { class: 'underline text-blue-600 decoration-dotted' }, id.slice(0, 8)))
        },
      },
      {
        title: i18n.t('machineryAccessory.field.limitation.name'),
        key: 'id',
        minWidth: 140,
        render: ({ soldAt, isDefective, relatedOfferPositions, attachedMachineryId, isPermanentlyNotAvailable, itemSets }) => {
          const overlappedOffer = relatedOfferPositions?.[0]?.relatedOffer
          const isSold = !!(soldAt && isDateBeforeOrOnToday(soldAt))
          return getAccessoryLimitations({ isSold, isDefective, overlappedOffer, attachedMachineryId, isPermanentlyNotAvailable, itemSets })
        },
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 100,
        render: ({ category }) => i18n.t(`machineryAccessory.category.${category}`),
      },
      {
        title: i18n.t('machinery.field.producerCompanyName.name'),
        key: 'name',
        minWidth: 100,
        render: row => row.producerCompanyName || i18n.t('common.notAvailable'),
      },
    ],
  })

  const invoiceOfferPositionRow: TableConfig<InvoiceOfferPositionRow> = {
    fulltextSearch: {
      filters: ['/invoiceId'],
      placeholder: 'Suchbegriff eingeben (Rechnungs-Nr.)',
    },
    columns: [
      {
        type: 'selection',
      },
      {
        title: 'Status',
        key: 'status',
        render: ({ invoice, children }) => {
          return children ? 'Berechnet' : `Berechnet in #${invoice.invoiceId}`
        },
        minWidth: 170,
      },
      {
        title: 'Datum',
        key: 'relatedOffer',
        render: ({ invoicedDate, offerPosition, quantity }) => {
          if (offerPosition.unit !== 'day' || !invoicedDate) {
            return ''
          }

          if (quantity > 1) {
            return `${useDateAsString(invoicedDate, 'dd.MM.yy')} - ${useDateAsString(addDaysDstSafe(invoicedDate, currency(quantity).subtract(1).value), 'dd.MM.yy')}`
          }

          return useDateAsString(invoicedDate, 'dd.MM.yy')
        },
        minWidth: 160,
      },
      {
        title: 'Typ',
        key: 'id',
        render: ({ offerPosition }) => i18n.t(`offerPosition.type.${offerPosition.type}`),
        minWidth: 140,
      },
      {
        title: 'Name',
        key: 'title',
        render: ({ offerPosition }) => {
          return truncateColumnValueIntoPopover(offerPosition.title, 150)
        },
        width: 150,
      },
      {
        title: 'Menge',
        key: 'quantity',
        render: ({ quantity }) => quantity.toLocaleString('de-DE'),
        minWidth: 140,
      },
      {
        title: 'Einheit',
        key: 'unit',
        render: ({ offerPosition }) => unitsToGerman[offerPosition.unit as PositionUnit],
        minWidth: 130,
      },
      {
        title: 'E-Preis (EUR)',
        key: 'pricePerUnit',
        render: ({ pricePerUnit }) => pricePerUnit.toLocaleString('de-DE'),
        minWidth: 130,
      },
      {
        title: 'Rabatt',
        key: 'discountRate',
        render: ({ discountRateForDay }) => {
          return discountRateForDay === undefined ? '' : formatPercentage(discountRateForDay)
        },
        minWidth: 160,
      },
      {
        title: 'G-Preis (EUR)',
        key: 'pricePerUnit',
        minWidth: 140,
        render: (position) => {
          let total = currency(0)
          if (position.children) {
            for (const child of position.children) {
              if (child.children) {
                for (const grandChild of child.children) {
                  total = total.add(calculateInvoiceToOfferPositionPrice({
                    title: grandChild.offerPosition.title,
                    type: grandChild.offerPosition.type,
                    ...grandChild,
                  }))
                }
              } else {
                total = total.add(calculateInvoiceToOfferPositionPrice({
                  title: child.offerPosition.title,
                  type: child.offerPosition.type,
                  ...child,
                }))
              }
            }
            return total.value.toLocaleString('de-DE')
          }

          return calculateInvoiceToOfferPositionPrice({
            title: position.offerPosition.title,
            type: position.offerPosition.type,
            ...position,
          }).value.toLocaleString('de-DE')
        },
      },
    ],
  }

  const internalCostPosition: TableConfig<ApiInternalCostPositionGetAll> = {
    fulltextSearch: {
      filters: ['/title', '/category', '/createdByEmail', '/createdBy/name', { field: '/price', makeFilterValue: (fulltextSearchValue: string) => useValueAsNumber(fulltextSearchValue) }],
      placeholder: i18n.t('internalCost.table.seach.placeholder'),
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => openInternalCostCreateOrEditPopup.open({ mode: 'update', type: row.type, id: row[`${row.type}Id`] ?? '', internalCostPositionId: row.id }),
    }),
    columns: [
      {
        title: 'Bezeichnung',
        key: 'title',
        minWidth: 200,
        render: ({ title }) => truncateColumnValueIntoPopover(title, 200),
      },
      {
        title: 'Kosten (EUR)',
        key: 'price',
        minWidth: 100,
        render: ({ price }) => price.toLocaleString('de-DE'),
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 100,
        render: ({ category }) => category,
      },
      {
        title: 'Erstellt am',
        key: 'createdAt',
        minWidth: 100,
        render: ({ createdAt }) => useDateAsString(createdAt),
      },
      {
        title: 'Erstellt von',
        key: 'createdBy',
        minWidth: 140,
        render: ({ createdBy }) => createdBy.name ?? createdBy.email,
      },
    ],
  }

  const defect: TableConfig<ApiDefectGetAll> = {
    fulltextSearch: {
      filters: ['/id', '/machineryId', '/machineryAccessoryId', '/description', '/createdBy/name', '/offerId'],
      placeholder: 'Suchbegriff eingeben (Geräte-ID, Beschreibung, Kunden-ID, Erstellt von, Auftrags-ID)',
      queryId: 'defect',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => {
        openDefectCreateOrUpdatePopup.open({ itemId: row[`${row.type}Id`] as string, type: row.type, mode: 'update', id: row.id })
      },
    }),
    rowClassName: (row) => {
      // We want to avoid assigning the below class names to the summary-row. To check if a row is the summary row, we check if it the `id` column is not a string (expecting an object with `value` and `colSpan`, see `summary` in the `TableView` component)
      if (typeof row.id !== 'string') {
        return ''
      }
      if (row.createdBy?.role === 'customer-user' || row.createdByAccessCodeSessionId) {
        return 'CustomerDefect'
      }
      if (row.repairedAt) {
        return 'Repaired'
      }
      return row.priority === 0 ? 'HighPriority' : ''
    },
    columns: [
      {
        title: 'Geräte-ID',
        key: 'type',
        minWidth: 100,
        render: defect => defect[`${defect.type}Id`],
      },
      {
        title: 'Typ',
        key: 'typ',
        minWidth: 100,
        render: ({ machinery, machineryAccessory }) => machinery ? truncateColumnValueIntoPopover(machinery.type?.name, 100) : truncateColumnValueIntoPopover(machineryAccessory?.typeName, 100),
      },
      {
        title: 'Hersteller',
        key: 'producerCompanyName',
        minWidth: 150,
        render: ({ machinery, machineryAccessory }) => {
          if (machinery) {
            return machinery.producerCompanyName
          }
          if (machineryAccessory) {
            return machineryAccessory.producerCompanyName
          }
          return ''
        },
      },
      {
        title: 'Kategorie',
        key: 'Kategorie',
        minWidth: 120,
        render: ({ machinery, machineryAccessory }) => {
          if (machinery) {
            return truncateColumnValueIntoPopover(i18n.t(`machinery.category.${machinery.category}`), 120)
          }
          if (machineryAccessory) {
            return truncateColumnValueIntoPopover(i18n.t(`machineryAccessory.category.${machineryAccessory.category}`), 120)
          }
          return ''
        },
      },
      {
        title: 'Kunde',
        key: 'customer',
        minWidth: 100,
        render: ({ offer }) => truncateColumnValueIntoPopover(offer?.customer.name ?? '', 120),
      },
      {
        title: 'Priorität',
        key: 'priority',
        minWidth: 130,
        render: ({ priority }) => {
          return priority ? 'Aufschiebbar' : 'Unaufschiebbar'
        },
        filterOptions: [{ value: 0, label: 'Unaufschiebbar' }, { value: 1, label: 'Aufschiebbar' }],
        filter: (priority, row) => row.priority === priority,
      },
      {
        title: 'Beschreibung',
        key: 'description',
        minWidth: 150,
        render: ({ description }) => truncateColumnValueIntoPopover(description, 150),
      },
      {
        title: i18n.t('defect.isCustomer.label'),
        key: 'createdBy',
        minWidth: 150,
        render: row => row.createdBy?.role === 'customer-user' || row.createdByAccessCodeSession ? i18n.t('general.yes') : i18n.t('general.no'),
      },
      {
        title: 'Erstellt am',
        key: 'createdAt',
        minWidth: 140,
        render: row => useDateAsString(row.createdAt),
        sorter: (row1, row2) => sortNullishDates(row1.createdAt, row2.createdAt),
      },
      {
        title: 'Erstellt von',
        key: 'createdBy',
        minWidth: 140,
        render: row => row.createdBy?.name ?? row.createdByAccessCodeSession?.name ?? '',
      },
      {
        title: 'Aufgeschoben von',
        key: 'delayedBy',
        minWidth: 140,
        render: row => row.delayedBy?.name ?? '',
      },
      {
        title: 'Defekt aus Auftrag',
        key: 'offerId',
        minWidth: 150,
        render: ({ offerId }) => offerId
          ? h('div', { onClick: (e) => {
            e.stopImmediatePropagation()
            return openOfferPage({ mode: 'edit', id: offerId })
          }, class: 'underline text-blue-600' }, offerId)
          : '',
      },
    ],
  }

  const defectColumnWithRepairInformation: TableConfig<ApiDefectGetAll>['columns'] = [
    ...defect.columns,
    {
      title: 'Repariert am',
      key: 'repairedAt',
      minWidth: 180,
      render: row => row.repairedAt ? useDateAsString(row.repairedAt) : ' ',
      sorter: (row1, row2) => sortNullishDates(row1.repairedAt, row2.repairedAt),
    },
    {
      title: 'Repariert von',
      key: 'repairedBy',
      minWidth: 180,
      render: row => row.repairedBy?.name ?? ' ',
    },
  ]

  const freelancerDefect: TableConfig<ApiDefectGetListByTypeId> = {
    fulltextSearch: {
      filters: ['/id', '/machineryId', '/offer/description'],
      placeholder: i18n.t('freelancerDefect.table.fulltextSearch.placeholder'),
      queryId: 'defect',
    },
    columns: [
      {
        title: i18n.t('freelancerDefect.table.column.machineryId'),
        key: 'type',
        minWidth: 100,
        render: ({ machineryId }) => machineryId || '',
      },
      {
        title: i18n.t('freelancerDefect.table.column.type'),
        key: 'type',
        minWidth: 100,
        render: ({ machinery }) => machinery ? truncateColumnValueIntoPopover(machinery.type?.name, 100) : '',
      },
      {
        title: i18n.t('freelancerDefect.table.column.producerCompanyName'),
        key: 'producerCompanyName',
        minWidth: 150,
        render: ({ machinery }) =>
          machinery
            ? machinery.producerCompanyName
            : '',
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 120,
        render: ({ machinery }) =>
          machinery
            ? truncateColumnValueIntoPopover(i18n.t(`machinery.category.${machinery.category}`), 120)
            : '',
      },
      {
        title: i18n.t('freelancerDefect.table.column.priority'),
        key: 'priority',
        minWidth: 130,
        render: ({ priority }) => {
          return priority ? 'Aufschiebbar' : 'Unaufschiebbar'
        },
        filterOptions: [{ value: 0, label: 'Unaufschiebbar' }, { value: 1, label: 'Aufschiebbar' }],
        filter: (priority, row) => row.priority === priority,
      },
      {
        title: i18n.t('freelancerDefect.table.column.description'),
        key: 'description',
        minWidth: 150,
        render: ({ offer }) => offer?.description ? truncateColumnValueIntoPopover(offer.description, 150) : '',
      },
    ],
  }

  const itemSet: TableConfig<ApiItemSetGetAll | ApiItemSetGetAllWithOngoingOfferPositions> = {
    fulltextSearch: {
      filters: ['/id', '/title', '/description', '/createdBy/name', '/machineryAccessories/some/id'],
      placeholder: 'Suchbegriff eingeben (ID, Titel, Beschreibung Lagertool-ID, Erstellt von)',
      queryId: 'set',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => {
        if (row.deletedAt) {
          return
        }
        if (idObjectSchema.safeParse(row).success) {
          return navigateTo(`/set/${row.id}`)
        }
      },
    }),
    columns: [
      {
        title: 'Set-Id',
        key: 'id',
        minWidth: 120,
      },
      {
        title: 'Titel',
        key: 'title',
        minWidth: 150,
        render: ({ title }) => truncateColumnValueIntoPopover(title, 150),
      },
      {
        title: 'Beschreibung',
        key: 'description',
        minWidth: 150,
        render: ({ description }) => truncateColumnValueIntoPopover(description, 150),
      },
      {
        title: 'Erstellt am',
        key: 'createdAt',
        minWidth: 140,
        render: row => useDateAsString(row.createdAt),
        sorter: (row1, row2) => sortNullishDates(row1.createdAt, row2.createdAt),
      },
      {
        title: 'Erstellt von',
        key: 'createdBy',
        minWidth: 140,
        render: row => row.createdBy.name,
      },
      {
        title: 'Verkauft am',
        key: 'soldAt',
        minWidth: 140,
        render: row => row.soldAt ? useDateAsString(row.soldAt) : '',
        sorter: (row1, row2) => sortNullishDates(row1.soldAt, row2.soldAt),
      },
    ],
  }

  const publicSetMachineryAccessories: TableConfig<ApiItemSetPublicGetById['machineryAccessories'][number]> = {
    fulltextSearch: {
      filters: [],
      placeholder: '',
    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: () => {
        if (idObjectSchema.safeParse(row).success) {
          return navigateTo(`/machinery-accessory/${row.id}`)
        }
      },
    }),
    columns: [
      {
        title: i18n.t('common.field.status.name'),
        key: 'status',
        minWidth: 130,
        render: ({ status }) => i18n.t(`machineryAccessory.status.${status}`),
      },
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        minWidth: 100,
        render: ({ id }) => id.slice(0, 8),
      },
      {
        title: i18n.t('common.field.category.name'),
        key: 'category',
        minWidth: 140,
        render: ({ category }) => i18n.t(`machineryAccessory.category.${category}`),
        filter: (value, { category }) => value === category,
        filterOptions: machineryAccessoryCategories.map(value => ({ value, label: i18n.t(`machineryAccessory.category.${value}`) })),
      },
    ],
  }

  const itemSetStatusForCalendar = (overlappedOffer: ApiCalendarItemSet['overlappedOffer']) => {
    if (!overlappedOffer) {
      return ''
    }
    if (overlappedOffer.type === 'rental') {
      return `Vermietet ${overlappedOffer.id}`
    }

    if (overlappedOffer.type === 'sale') {
      return `Verkauft ${overlappedOffer.id}`
    }
    return ''
  }

  const itemSetForCalendar = (disabledIds = [] as string[]): TableConfig<ApiCalendarItemSet> => ({
    fulltextSearch: {
      filters: ['/id', '/title', '/description', '/machineryAccessories/some/id', '/createdBy/name'],
      placeholder: 'Suchbegriff eingeben (ID, Title, Beschreibung, Lagertool-ID, Erstellt von)',

    },
    rowProps: row => ({
      style: 'cursor: pointer;',
      onClick: (e) => {
        if (isCheckboxClick(e) || isRadioClick(e)) {
          return
        }

        if (idObjectSchema.safeParse(row).success) {
          openPositionsDetailsPopup.open({ type: 'itemSet', id: row.id })
        }
      },
    }),
    columns: [
      {
        type: 'selection',
        multiple: true,
        disabled: ({ soldAt, overlappedOffer, isAvailable, id }) => {
          if (soldAt || overlappedOffer || !isAvailable || disabledIds.includes(id)) {
            return true
          }

          return false
        },
      },
      {
        title: 'Title',
        key: 'title',
        minWidth: 150,
        render: ({ title }) => truncateColumnValueIntoPopover(title, 150),
      },
      {
        title: 'Einschränkung',
        key: 'id',
        minWidth: 140,
        render: ({ soldAt, overlappedOffer, isAvailable }) => {
          if (soldAt) {
            return 'Verkauft'
          }

          if (!isAvailable) {
            return 'Defekt oder dauerhaft nicht verfügbar'
          }

          return itemSetStatusForCalendar(overlappedOffer)
        },
      },
      {
        title: 'Beschreibung',
        key: 'description',
        minWidth: 150,
        render: ({ description }) => truncateColumnValueIntoPopover(description, 150),
      },
      {
        title: 'Erstellt am',
        key: 'createdAt',
        minWidth: 140,
        render: row => useDateAsString(row.createdAt),
        sorter: (row1, row2) => sortNullishDates(row1.createdAt, row2.createdAt),
      },
      {
        title: 'Erstellt von',
        key: 'createdBy',
        minWidth: 140,
        render: row => row.createdBy.name,
      },
    ],
  })

  const offerPositionForTermination: TableConfig<ApiOfferGetById['positions'][number]> = {
    fulltextSearch: {
      filters: ['/title', '/machineryAccessoryId', '/machineryId'],
      placeholder: 'Suchbegriff eingeben (Titel, Geräte-ID, Lagertool-ID, Abmelden am)',
    },
    columns: [
      {
        title: 'Titel',
        key: 'title',
        minWidth: 150,
        render: ({ title }) => truncateColumnValueIntoPopover(title, 150),
      },
      {
        title: 'Abmelde-Datum',
        key: 'terminatedDate',
        minWidth: 100,
        render: ({ terminatedDate }) => terminatedDate ? useDateAsString(terminatedDate) : 'N/A',
      },
      {
        title: 'Abgemeldet am',
        key: 'terminatedAt',
        minWidth: 100,
        render: ({ terminatedAt }) => terminatedAt ? useDateAsString(terminatedAt) : 'N/A',
      },
      {
        title: 'Abgemeldet von',
        key: 'terminatedByEmail',
        minWidth: 100,
        render: ({ terminatedByEmail }) => terminatedByEmail || 'N/A',
      },
    ],
  }

  const offerPositionForOfferOverview: TableConfig<ApiOfferGetById['positions'][number]> = {
    fulltextSearch: {
      filters: [],
      placeholder: '',
    },
    columns: [
      {
        title: i18n.t('common.field.title.name'),
        key: 'title',
        minWidth: 150,
      },
      {
        title: i18n.t('offer.position.field.isIssued.name'),
        key: 'isIssued',
        render: (position) => {
          const statusList = computeOfferPositionStatuses(position)
          if (statusList && statusList.length > 0) {
            const lastStatus = statusList[statusList.length - 1].status
            return i18n.t(`logisticsTask.fullStatus.${lastStatus}`)
          }
          return i18n.t('common.notAvailable')
        },
      },
      {
        title: i18n.t('common.field.deliveredAt.name'),
        key: 'deliveredAt',
        render: ({ deliveredAt }) => deliveredAt ? useDateAsString(deliveredAt) : i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('offer.position.field.terminatedDate.name'),
        key: 'terminatedDate',
        render: ({ terminatedDate }) => terminatedDate ? useDateAsString(terminatedDate) : i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('offer.position.field.terminatedAt.name'),
        key: 'terminatedAt',
        render: ({ terminatedAt }) => terminatedAt ? useDateAsString(terminatedAt) : i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('offer.position.field.terminatedBy.name'),
        key: 'terminatedBy',
        render: ({ terminatedBy }) => terminatedBy?.name || i18n.t('common.notAvailable'),
      },
      {
        title: i18n.t('offer.position.field.createdAt.name'),
        key: 'createdAt',
        render: ({ createdAt }) => createdAt ? useDateAsString(createdAt) : i18n.t('common.notAvailable'),
      },
    ],
  }

  const machineryReleasedForFreelancerConditions: TableConfig<RouterOutput['freelancerSales']['getMachineryReleasedForFreelancerConditions'][number]> = {
    fulltextSearch: {
      filters: ['/machineryId', '/freelancerUser/name', '/machinery/type/name', '/machinery/producerCompanyName', '/machinery/machineryRubric'],
      placeholder: 'Suchbegriff eingeben (Maschinen-ID, Hersteller, Typ, Rubrik, Freelancer-Name)',
    },
    columns: [
      {
        title: 'Maschinen-ID',
        key: 'machineryId',
        render: ({ machineryId }) => h(NuxtLink, { to: `/machinery/${machineryId}`, class: 'text-blue-500 underline' }, machineryId),
        minWidth: 150,
      },
      {
        title: 'Hersteller',
        key: 'machinery',
        render: ({ machinery }) => machinery.producerCompanyName,
        minWidth: 140,
      },
      {
        title: 'Typ',
        key: 'machinery',
        render: ({ machinery }) => machinery.type.name,
        minWidth: 120,
      },
      {
        title: 'Rubrik',
        key: 'machinery',
        render: ({ machinery }) => i18n.t(`machinery.rubric.${machinery.machineryRubric}`),
        minWidth: 120,
      },
      {
        title: 'Händler',
        key: 'user',
        render: ({ freelancerUser }) => freelancerUser.name,
        minWidth: 120,
      },
      {
        title: 'Freelancer EK-Preis',
        key: 'priceFreelancerHasToPurchaseFor',
        render: ({ priceFreelancerHasToPurchaseFor }) => useDisplayNumberAsCurrency(priceFreelancerHasToPurchaseFor),
        minWidth: 150,
      },
      {
        title: 'Freischaltung durch?',
        key: 'releasedByUser',
        render: ({ releasedByUser, createdAt }) => `${releasedByUser.name} um ${useDateAsString(createdAt)}`,
        minWidth: 180,
      },
    ],
    rowClassName: row => row.isSpecialOffer ? 'SpecialOffer' : '',
  }

  const machineryReleasedForPartnerConditions: TableConfig<RouterOutput['partnerSales']['getMachineryReleasedForPartnerConditions'][number]> = {
    fulltextSearch: {
      filters: ['/machineryId', '/partnerUser/name', '/machinery/type/name', '/machinery/producerCompanyName', '/machinery/machineryRubric'],
      placeholder: 'Suchbegriff eingeben (Maschinen-ID, Hersteller, Typ, Rubrik, Partner-Name)',
    },
    columns: [
      {
        title: 'Maschinen-ID',
        key: 'machineryId',
        render: ({ machineryId }) => h(NuxtLink, { to: `/machinery/${machineryId}`, class: 'text-blue-500 underline' }, machineryId),
        minWidth: 150,
      },
      {
        title: 'Hersteller',
        key: 'machinery',
        render: ({ machinery }) => machinery.producerCompanyName,
        minWidth: 140,
      },
      {
        title: 'Typ',
        key: 'machinery',
        render: ({ machinery }) => machinery.type.name,
        minWidth: 120,
      },
      {
        title: 'Rubrik',
        key: 'machinery',
        render: ({ machinery }) => i18n.t(`machinery.rubric.${machinery.machineryRubric}`),
        minWidth: 120,
      },
      {
        title: 'Händler',
        key: 'user',
        render: ({ partnerUser }) => partnerUser.name,
        minWidth: 120,
      },
      {
        title: 'Partner EK-Preis',
        key: 'pricePartnerHasToPurchaseFor',
        render: ({ pricePartnerHasToPurchaseFor }) => useDisplayNumberAsCurrency(pricePartnerHasToPurchaseFor),
        minWidth: 150,
      },
      {
        title: 'Freischaltung durch?',
        key: 'releasedByUser',
        render: ({ releasedByUser, createdAt }) => `${releasedByUser.name} um ${useDateAsString(createdAt)}`,
        minWidth: 180,
      },
    ],
    rowClassName: row => row.isSpecialOffer ? 'SpecialOffer' : '',
  }

  const freelancerOfferOverview: TableConfig<RouterOutput['freelancerSales']['getAllOffers'][number] | RouterOutput['freelancerSales']['getMyOffers'][number]> = {
    fulltextSearch: {
      filters: [],
      placeholder: '',
    },
    columns: [
      {
        title: i18n.t('offer.field.freelancer.name'),
        key: 'createdBy',
        render: ({ createdBy }) => createdBy?.name ?? createdBy?.email ?? i18n.t('common.notAvailable'),
        minWidth: 100,
      },
      {
        title: i18n.t('common.field.status.name'),
        key: 'status',
        minWidth: 85,
        render: ({ status }) => i18n.t(`offer.status.${status}`),
      },
      {
        title: i18n.t('common.field.id.name'),
        key: 'id',
        width: 170,
      },
      {
        title: i18n.t('offer.field.machineryId.name'),
        key: 'positions',
        minWidth: 115,
        render: (row) => {
          const machines = getMachinesFromPositions(row)
          return machines.length > 0
            ? machines.map(m => m.machinery.id.slice(0, 8)).join(', ')
            : i18n.t('common.notAvailable')
        },
      },
      {
        title: i18n.t('offer.field.machineryProducerCompanyName.name'),
        key: 'rentalMachineryRelation',
        minWidth: 150,
        render: (row) => {
          const machines = getMachinesFromPositions(row)
          return machines.length > 0
            ? machines.map(m => m.machinery.producerCompanyName).join(', ')
            : i18n.t('common.notAvailable')
        },
      },
      {
        title: i18n.t('machinery.type.name'),
        key: 'machineryType',
        minWidth: 115,
        render: (row) => {
          const machines = getMachinesFromPositions(row)
          return machines.length > 0
            ? machines.map(m => m.machinery.type.name).join(', ')
            : i18n.t('common.notAvailable')
        },
      },
      {
        title: i18n.t('offer.field.customer.name'),
        key: 'customer',
        minWidth: 125,
        render: ({ customer }) => customer.name,
      },
      {
        title: i18n.t('offer.field.deliveryLocation.rental.name'),
        key: 'deliveryLocation',
        minWidth: 100,
      },
    ],
  }

  const internalReservation: TableConfig<ApiInternalReservationGetAll> = {
    fulltextSearch: {
      filters: [],
      placeholder: '',
    },
    columns: [
      {
        title: 'Geräte-ID',
        key: 'machineryId',
        minWidth: 115,
      },
      {
        title: 'Beschreibung',
        key: 'reservationDescription',
        minWidth: 140,
        render: ({ reservationDescription }) => truncateColumnValueIntoPopover(reservationDescription, 140),
      },
      {
        title: 'Startdatum der Reservierung',
        key: 'reservationStartsAt',
        minWidth: 120,
        render: ({ reservationStartsAt }) => useDateAsString(reservationStartsAt, 'dd.MM.yy'),
      },
      {
        title: 'Enddatum der Reservierung',
        key: 'reservationEndsAt',
        minWidth: 120,
        render: ({ reservationEndsAt }) => useDateAsString(reservationEndsAt, 'dd.MM.yy'),
      },
      {
        title: 'Erstellt von',
        key: 'createdBy',
        minWidth: 150,
        render: ({ createdBy, createdByEmail }) => createdBy.name ?? createdByEmail,
      },
      {
        title: 'Gelöscht von',
        key: 'deletedBy',
        minWidth: 150,
        render: ({ deletedBy, deletedByEmail }) => deletedBy?.name ?? deletedByEmail ?? '-',
      },
      {
        title: 'Gelöscht am',
        key: 'deletedAt',
        minWidth: 120,
        render: ({ deletedAt }) => deletedAt ? useDateAsString(deletedAt, 'dd.MM.yy') : '-',
      },
    ],
    rowClassName: ({ deletedAt }) => deletedAt ? 'bg-red-100' : 'bg-transparent',
  }

  const receptionMachineryHistoryForOverview: TableConfig<ApiReceptionMachineryHistoryGetAllForOverview> = {
    fulltextSearch: {
      filters: [],
      placeholder: '',
    },
    columns: [
      {
        title: i18n.t('common.field.createdAt.name'),
        key: 'createdAt',
        render: ({ createdAt }) => useDateAsString(createdAt),
      },
      {
        title: i18n.t('common.field.createdBy.name'),
        key: 'createdBy',
        render: ({ createdBy }) => createdBy?.name ?? createdBy?.email ?? i18n.t('common.unknown'),
      },
    ],
  }

  const storageLoadCarrier: TableConfig<ApiStorageLoadCarrierGetAll> = {
    fulltextSearch: {
      filters: ['/customer/name', '/name', '/createdBy/name'],
      placeholder: 'Suchbegriff eingeben (Kundenname, Bezeichnung, Erstellt von)',
    },
    columns: [
      {
        title: 'Kundenname',
        key: 'customer',
        minWidth: 130,
        render: ({ customer }) => truncateColumnValueIntoPopover(customer.name, 130),
      },
      {
        title: 'Bezeichnung',
        key: 'name',
        minWidth: 120,
      },
      {
        title: 'Gewicht (kg)',
        key: 'weight',
        minWidth: 120,
        render: ({ weight }) => formatNumberToString(weight, 'de', 3),
      },
      {
        title: 'Länge (mm)',
        key: 'lengthInMm',
        minWidth: 100,
      },
      {
        title: 'Breite (mm)',
        key: 'widthInMm',
        minWidth: 100,
      },
      {
        title: 'Höhe (mm)',
        key: 'heightInMm',
        minWidth: 100,
      },
      {
        title: 'Lademeter',
        key: 'loadingMeter',
        minWidth: 100,
      },
      {
        title: 'Max. Traglast (kg)',
        key: 'maximumLiftingWeight',
        minWidth: 140,
      },
      {
        title: 'Erstellt von',
        key: 'createdBy',
        minWidth: 150,
        render: ({ createdBy, createdByEmail }) => createdBy.name ?? createdByEmail,
      },
    ],
  }

  const storagePositionRow: TableConfig<ApiStoragePositionGetAll>['rowProps'] = (row: ApiStoragePositionGetAll) => ({
    style: 'cursor: pointer;',
    onClick: () => {
      return navigateTo(`/external-storage-position/${row.id}`)
    },
  })

  const storageUser: TableConfig<ApiStorageUserGetAll> = {
    fulltextSearch: {
      filters: ['/name', '/email', '/storageCustomer/name'],
      placeholder: 'Suchbegriff eingeben (Nutzername, Nutzer-E-Mail, Kundenname)',
    },
    columns: [
      {
        title: 'Nutzername',
        key: 'name',
        minWidth: 130,
        render: ({ name }) => truncateColumnValueIntoPopover(name ?? 'N/A', 130),
      },
      {
        title: 'Nutzer-E-Mail',
        key: 'email',
        minWidth: 130,
      },
      {
        title: 'Kundenname',
        key: 'storageCustomer',
        minWidth: 130,
        render: ({ storageCustomer }) => truncateColumnValueIntoPopover(storageCustomer?.name ?? 'N/A', 130),
      },
      {
        title: 'Erstellt am',
        key: 'createdAt',
        render: ({ createdAt }) => createdAt ? useDateAsString(createdAt) : 'N/A',
      },
      {
        title: 'Erstellt von',
        key: 'createdBy',
        minWidth: 130,
        render: ({ createdBy, createdByEmail }) => truncateColumnValueIntoPopover(createdBy?.name ?? createdByEmail ?? 'N/A', 130),
      },
    ],
  }

  const positionTemplateBundle: TableConfig<ApiPositionTemplateBundleGetAllForOverview> = {
    fulltextSearch: {
      filters: ['/id', '/title', '/createdByEmail', '/createdBy/name'],
      placeholder: 'Suchbegriff eingeben (ID, Titel, Erstellt von)',
    },
    columns: [
      {
        title: 'ID',
        key: 'id',
        minWidth: 100,
      },
      {
        title: 'Titel',
        key: 'title',
        minWidth: 130,
        render: ({ title }) => truncateColumnValueIntoPopover(title, 130),
      },
      {
        title: 'Anzahl Positionen',
        key: 'positionTemplatesCount',
      },
      {
        title: 'Erstellt am',
        key: 'createdAt',
        render: ({ createdAt }) => useDateAsString(createdAt),
      },
      {
        title: 'Erstellt von',
        key: 'createdByName',
        minWidth: 130,
        render: ({ createdByName, createdByEmail }) => truncateColumnValueIntoPopover(createdByName ?? createdByEmail, 130),
      },
      {
        title: 'Angebotstyp',
        key: 'offerType',
        minWidth: 130,
        render: ({ offerType }) => offerTypesToGerman[offerType as OfferType],
      },
    ],
  }

  const externalSalesMarketplace: TableConfig<ApiExternalSalesMarketplaceGetAll> = {
    fulltextSearch: {
      filters: [],
      placeholder: '',
    },
    columns: [
      {
        title: '',
        key: 'icon',
        render: ({ marketplaceId,
        }) => h(ExternalSalesMarketplaceIcon, { marketplaceId }),
      },
      {
        title: i18n.t('externalSalesMarketplace.id'),
        key: 'marketplaceId',
        render: ({ marketplaceId }) => i18n.t(`externalSalesMarketplace.id.${marketplaceId}`),
      },
      {
        title: i18n.t('externalSalesMarketplace.isAutomaticExportsEnabled'),
        key: 'isAutomaticExportsEnabled',
        render: ({ isAutomaticExportsEnabled }) => isAutomaticExportsEnabled ? i18n.t('general.yes') : i18n.t('general.no'),
      },
      {
        title: i18n.t('externalSalesMarketplace.maxExportedMachineryAmount'),
        key: 'maxExportedMachineryAmount',
        render: ({ maxExportedMachineryAmount }) => maxExportedMachineryAmount ?? i18n.t('externalSalesMarketplace.maxExportedMachineryAmount.noValue'),
      },
      {
        title: i18n.t('externalSalesMarketplace.machineryToExport.count'),
        key: '/machineryToExportCount',
        render: ({ _count }) => _count.machineryToExport,
      },
      {
        title: i18n.t('externalSalesMarketplace.exportHistory.latest.machinery.count'),
        key: '/latestExportMachineryCount',
        render: ({ exportHistory }) => exportHistory[0]?._count.includedMachinery ?? 0,
      },
    ],
  }

  return {
    machineryColumns,
    machineryWithoutColumns,
    soldMachinery,
    machineryAccessory,
    machineryAccessorySelectForSet,
    compatibleMachineryAccessoriesForBundleMultiSelect,
    machineryTypes,
    soldMachineryAccessory,
    offer,
    offerForInvoiceTab,
    rentalColumns,
    saleColumns,
    specialOffer,
    serviceProjectOffer,
    position,
    customer,
    users,
    logistics,
    logisticsIssued,
    logisticsCollected,
    machinerySingleSelect,
    machineryAccessorySelect,
    connectedMachineryAccessories,
    attachedMachinery,
    positionTemplatesMultiSelect,
    invoice,
    invoiceToOfferPositions,
    shoppingAccessoryTable,
    shoppingAccessoryIdTable,
    invoiceOfferPositionRow,
    internalCostPosition,
    defect,
    defectColumnWithRepairInformation,
    freelancerDefect,
    itemSet,
    itemSetForCalendar,
    publicSetMachineryAccessories,
    offerPositionForTermination,
    connectedMachineryAccessoriesPublic,
    offerPositionForOfferOverview,
    machineryReleasedForFreelancerConditions,
    machineryReleasedForPartnerConditions,
    freelancerOfferOverview,
    internalReservation,
    receptionMachineryHistoryForOverview,
    storageLoadCarrier,
    storagePositionRow,
    storageUser,
    positionTemplateBundle,
    positionTemplateBundleMultiSelect,
    externalSalesMarketplace,
  }
}
