<script lang="ts" setup>
defineProps<{
  icon: string
  label?: string
  color?: string
  isDisabled?: boolean
}>()
defineEmits<{ (e: 'update:color', color: string): void }>()

/*
 * Swatches taken from https://tailwindcss.com/docs/colors
 * Colors used: 'Red', 'Yellow', 'Green', 'Blue', 'Purple', 'Pink'
 * Shades used: '600', '500', '400', '300', '200', '100'
 */
const swatches = [
  '#E7000A',
  '#D08700',
  '#02A63E',
  '#155DFC',
  '#9810FA',
  '#E60076',

  '#FB2C36',
  '#F0B102',
  '#00C950',
  '#2C7FFF',
  '#AD46FF',
  '#F6349A',

  '#FF6466',
  '#FDC700',
  '#02DF72',
  '#51A2FF',
  '#C27AFF',
  '#FB64B6',

  '#FFA2A2',
  '#FFDF1F',
  '#7BF1A8',
  '#8EC5FF',
  '#DAB2FF',
  '#FDA5D5',

  '#FFC9C9',
  '#FFF085',
  '#B9F8CF',
  '#BFDBFF',
  '#E9D4FF',
  '#FCCEE7',
]
</script>

<template>
  <n-popover trigger="click">
    <template #trigger>
      <div>
        <FormKitCreateInputBaseTiptapMenuButton :icon :label :is-disabled />
      </div>
    </template>

    <div class="space-y-2">
      <div class="grid grid-cols-6 gap-1.5">
        <div
          v-for="swatch in swatches"
          :key="swatch"
          class="size-5 rounded-sm cursor-pointer"
          :class="{ 'ring shadow': swatch === color }"
          :style="{ backgroundColor: swatch }"
          @click="$emit('update:color', swatch)"
        />
      </div>

      <n-color-picker
        :value="color"
        size="small"
        :modes="['hex']"
        :show-alpha="false"
        @update:value="color => $emit('update:color', color)"
      />
    </div>
  </n-popover>
</template>
