import type { TimelineItemProps } from 'naive-ui'
import type { ApiInputInvoiceGetAllOfferForInvoiceById, ApiInvoiceGetAllOffersForPreparation, ApiLogisticsTaskGetByIdForStatusTimeline, ApiOfferGetById, ApiOfferGetByIdForStatusTimeline, ApiOfferPositionGetByIdForStatusTimeline, DispatcherTaskForApp, LogisticsRentalFullStatus } from '~/types'

type TimelineStatusState = TimelineItemProps['type']
export interface TimelineItem {
  title: string
  time?: string | undefined
  statusState: TimelineStatusState
  content?: string | undefined | null
}

interface Status {
  time: Date
  username: string | null | undefined
  status: LogisticsRentalFullStatus
}

type PartialPositionsByStatus = Partial<Record<LogisticsRentalFullStatus, Status[]>>

function createTimelineItemForLogisticsStatus(status: LogisticsRentalFullStatus, totalPositionCount: number, positionsByStatus: PartialPositionsByStatus, logisticsTasks: { isIssuancePaused: boolean }[]) {
  const $t = useNuxtApp().$i18n.t

  const positionsThatReachedStatusCount = positionsByStatus[status]?.length ?? 0

  let statusState: TimelineStatusState | undefined

  if (positionsThatReachedStatusCount === 0) {
    if (totalPositionCount > 0) {
      statusState = 'default'
    } else {
      statusState = 'success'
    }
  } else if (status !== 'issued' && positionsThatReachedStatusCount === totalPositionCount) {
    statusState = 'success'
  } else if (status === 'issued' && positionsThatReachedStatusCount === totalPositionCount) {
    const isLogisticsTaskPasued = logisticsTasks.some(task => task.isIssuancePaused)
    if (isLogisticsTaskPasued) {
      statusState = 'warning'
    } else {
      statusState = 'success'
    }
  } else {
    statusState = 'warning'
  }

  const examplePosition = positionsByStatus[status]?.[0]
  let time

  if (examplePosition && statusState !== 'default') {
    time = useDateAsString(examplePosition.time)
  }

  // use new Set() to exclude duplicates from array
  const users = [...new Set(positionsByStatus[status]?.map(position => position.username) || [])]

  return {
    title: `[${positionsByStatus[status]?.length ?? 0}/${totalPositionCount}] ${$t(`logisticsTask.fullStatus.${status}`)}`,
    statusState,
    time,
    content: `${users.join(', ')}`,
  }
}

const possiblePositionStatuses = ['issued', 'loaded', 'delivered', 'collected', 'returned'] as const

function aggregatePositionsByStatusTheyReached(positions: ApiOfferGetByIdForStatusTimeline['positions'] | ApiLogisticsTaskGetByIdForStatusTimeline['positionsToShip'] | DispatcherTaskForApp['positionsToShip'] | ApiOfferGetById['logisticsTasks'][number]['positionsToShip']) {
  const positionsByStatus: PartialPositionsByStatus = {}

  for (const position of positions.filter(doesOfferPositionNeedDelivery)) {
    // Get statuses of position
    const statuses = computeOfferPositionStatuses(position)

    if (statuses.length === 0) {
      continue
    }

    // Iterate over all possible statuses, adding the position to each status
    // if the position reached it
    for (const possibleStatus of possiblePositionStatuses) {
      const status = statuses.find(({ status }) => status === possibleStatus)

      if (!status) {
        continue
      }

      const existingData = positionsByStatus[possibleStatus] ?? []
      positionsByStatus[possibleStatus] = [...existingData, status]
    }
  }
  return positionsByStatus
}

function computeSpecialOfferTimeline(offer: ApiOfferGetByIdForStatusTimeline) {
  const $t = useNuxtApp().$i18n.t

  const totalLogisticsTaskCount = offer.logisticsTasks.length
  const completedLogisticsTaskCount = offer.logisticsTasks.filter(task => task.completedAt).length
  const lastTaskCompletedAt = totalLogisticsTaskCount === completedLogisticsTaskCount ? offer.logisticsTasks.sort((a, b) => sortNullishDates(a.completedAt, b.completedAt))[0]?.completedAt : null

  return {
    created: {
      title: $t('logisticsTask.fullStatus.created'),
      statusState: 'success',
      time: useDateAsString(offer.requestedAt),
    },
    completed: {
      title: `${$t('logisticsTask.fullStatus.completed')} ${completedLogisticsTaskCount}/${totalLogisticsTaskCount}`,
      statusState: totalLogisticsTaskCount === completedLogisticsTaskCount ? 'success' : 'default',
      time: lastTaskCompletedAt ? useDateAsString(lastTaskCompletedAt) : undefined,
    },
  } satisfies Record<string, TimelineItem>
}

export function computeOfferTimeline(offer: ApiOfferGetByIdForStatusTimeline | ApiInvoiceGetAllOffersForPreparation | ApiInputInvoiceGetAllOfferForInvoiceById) {
  if (offer.type === 'special') {
    return computeSpecialOfferTimeline(offer)
  }

  const $t = useNuxtApp().$i18n.t

  const positionsByStatus = aggregatePositionsByStatusTheyReached(offer.positions)

  const logisticsTasksOutbound = offer.logisticsTasks?.filter(t => t.type === 'outbound') ?? []
  const logisticsTasksOutboundPlanned = logisticsTasksOutbound.filter(t => !!t.plannedAt)
  const areAllOutboundTasksPlanned = logisticsTasksOutbound.length > 0 && logisticsTasksOutbound.length === logisticsTasksOutboundPlanned.length
  const exampleTaskPlannedAt = logisticsTasksOutboundPlanned[0]?.plannedAt

  const statusOverview: Record<string, TimelineItem> = {
    accepted: {
      title: 'Akzeptiert',
      statusState: 'success' as TimelineStatusState,
    },
    planned: {
      title: `${$t('logisticsTask.fullStatus.planned')} [${logisticsTasksOutboundPlanned.length}/${offer.positions.filter(p => p.type === 'logisticsTask').length}]`,
      statusState: areAllOutboundTasksPlanned ? 'success' : 'default' as TimelineStatusState,
      time: exampleTaskPlannedAt ? useDateAsString(exampleTaskPlannedAt) : undefined,
    },
  }

  const positionsThatNeedDelivery = offer.positions.filter(doesOfferPositionNeedDelivery)
  const statusesToCheck: LogisticsRentalFullStatus[] = ['issued', 'loaded', 'delivered']

  for (const status of statusesToCheck) {
    statusOverview[status] = createTimelineItemForLogisticsStatus(status, positionsThatNeedDelivery.length, positionsByStatus, offer.logisticsTasks)
  }

  if (offer.type === 'sale') {
    return statusOverview
  }

  const shippedPositions = offer.positions.filter(doesOfferPositionNeedDelivery)
  const offerTerminated = offer.obligationActuallyEndedAt
  const isServiceProject = offer.type === 'service-project'

  let terminatedPositionCount = 0
  if (offerTerminated && !isServiceProject) {
    terminatedPositionCount = shippedPositions.length
  } else {
    terminatedPositionCount = shippedPositions.filter(p => p.terminatedDate).length
  }

  const terminatedByUsers = [...new Set(shippedPositions.map(position => position.terminatedBy?.name).filter(p => !!p))]

  statusOverview.terminated = {
    title: `${offer.type === 'service-project' ? 'Geräte abgemeldet' : 'Miete beendet'} (${terminatedPositionCount}/${shippedPositions.length})`,
    statusState: (!offerTerminated || isServiceProject) && terminatedPositionCount === 0 ? 'default' : offerTerminated ? 'success' : 'warning',
    time: (offerTerminated && terminatedPositionCount > 0) ? useDateAsString(offerTerminated, 'dd.MM.yy') : undefined,
    content: terminatedByUsers.join(', '),
  }

  const statusesToCheckForRental: LogisticsRentalFullStatus[] = ['collected', 'returned']

  for (const status of statusesToCheckForRental) {
    if (status === 'returned' && offer.type === 'service-project') {
      if (offer.isCompleted) {
        statusOverview[status] = {
          title: $t('logisticsTask.fullStatus.returned'),
          statusState: 'success',
        }
      } else {
        statusOverview[status] = {
          title: $t('logisticsTask.fullStatus.returned'),
          statusState: 'default',
        }
      }
    } else {
      statusOverview[status] = createTimelineItemForLogisticsStatus(status, positionsThatNeedDelivery.length, positionsByStatus, offer.logisticsTasks)
    }
  }

  return statusOverview
}

export function computePositionTimeline(offerType: 'sale' | 'rental', position: ApiOfferPositionGetByIdForStatusTimeline) {
  const $t = useNuxtApp().$i18n.t

  const statusOverview: Record<string, TimelineItem> = {
    accepted: {
      title: $t('logisticsTask.fullStatus.issued'),
      statusState: position.issuedAt ? 'success' : 'default' as TimelineStatusState,
      time: position.issuedAt ? useDateAsString(position.issuedAt) : undefined,
      content: position.issuedBy?.name ? position.issuedBy?.name : undefined,
    },
    loaded: {
      title: $t('logisticsTask.fullStatus.loaded'),
      statusState: position.loadedAt ? 'success' : 'default' as TimelineStatusState,
      content: position.loadedBy?.name ? position.loadedBy?.name : undefined,
    },
    delivered: {
      title: $t('logisticsTask.fullStatus.delivered'),
      statusState: position.deliveredAt ? 'success' : 'default',
    },
  }

  if (offerType === 'sale') {
    return statusOverview
  }

  statusOverview.collected = {
    title: $t('logisticsTask.fullStatus.collected'),
    statusState: position.collectedAt ? 'success' : 'default',
    content: position.collectedBy?.name ? position.collectedBy?.name : undefined,
  }

  statusOverview.returned = {
    title: $t('logisticsTask.fullStatus.returned'),
    statusState: position.returnedAt ? 'success' : 'default',
    content: position.returnedBy?.name ? position.returnedBy?.name : undefined,
  }

  return statusOverview
}

const statusesToCheckForOutbound: LogisticsRentalFullStatus[] = ['issued', 'loaded', 'delivered']
const statusesToCheckForInbound: LogisticsRentalFullStatus[] = ['collected', 'returned']

export function computeLogisticsTaskTimeline(task: ApiLogisticsTaskGetByIdForStatusTimeline | DispatcherTaskForApp | ApiOfferGetById['logisticsTasks'][number]) {
  const $t = useNuxtApp().$i18n.t

  const isPlanned = task.deliveryAt && (task.assignedDeliveryVehicleId || task.doesCustomerDoTask || task.doesFitterDoTask || task.doesTransportationCompanyDoTask)

  const statusOverview: Record<string, TimelineItem> = {
    created: {
      title: $t('logisticsTask.fullStatus.created'),
      statusState: 'success',
      time: useDateAsString(task.createdAt),
      content: task.createdBy.name,
    },
    planned: {
      title: $t('logisticsTask.fullStatus.planned'),
      statusState: isPlanned ? 'success' : 'default',
      time: isPlanned && task.plannedAt ? useDateAsString(task.plannedAt) : undefined,
      content: task.plannedBy?.name,
    },
  }

  if (task.offer?.type === 'special') {
    statusOverview.delivered = {
      title: $t('logisticsTask.fullStatus.delivered'),
      statusState: task.deliveredAt ? 'success' : 'default',
      time: task.deliveredAt ? useDateAsString(task.deliveredAt) : undefined,
      content: task.deliveredBy?.name,
    }

    statusOverview.completed = {
      title: $t('logisticsTask.fullStatus.returned'),
      statusState: task.status === 'completed' ? 'success' : 'default',
      time: task.completedAt ? useDateAsString(task.completedAt) : undefined,
      content: task.completedBy?.name,
    }

    return statusOverview
  }

  const positionsByStatus = aggregatePositionsByStatusTheyReached(task.positionsToShip)
  const positionsThatNeedDelivery = task.positionsToShip.filter(doesOfferPositionNeedDelivery)
  const statusesToCheck = task.type === 'outbound' ? statusesToCheckForOutbound : statusesToCheckForInbound

  for (const status of statusesToCheck) {
    statusOverview[status] = createTimelineItemForLogisticsStatus(status, positionsThatNeedDelivery.length, positionsByStatus, [task])
  }

  return statusOverview
}

type PossibleOfferPositionStatus = typeof possiblePositionStatuses[number]
type PositionForStatusComputation = {
  id: string
} & {
  [K in PossibleOfferPositionStatus as `${K}At`]: Date | null
} & {
  [K in PossibleOfferPositionStatus as `${K}By`]: null | undefined | {
    name: string | null
  }
}
export function computeOfferPositionStatuses(position: PositionForStatusComputation): Status[] {
  const statuses: Status[] = []

  for (const status of possiblePositionStatuses) {
    if (position[`${status}At`]) {
      const time = position[`${status}At`]
      const username = position[`${status}By`]?.name

      if (!time || !username) {
        throw new Error(`At and by must be defined time this point for position ${position.id}, status: ${status}`)
      }

      statuses.push({ time, username, status: status as LogisticsRentalFullStatus })
    }
  }

  return statuses
}
