<script setup lang="ts">
const { t: $t } = useI18n()

import type { OtherInvoiceRecipientUpsert } from '~/types'
import { paymentConditions, taxRates } from '~/server/schemas'
import { CustomerLanguage } from '~/prisma/enums'

const props = defineProps<{ payload: OtherInvoiceRecipientUpsert }>()

defineEmits<{
  (e: 'upsert', payload: OtherInvoiceRecipientUpsert): void
}>()

const taxRateOptions = taxRates.options.map(rate => ({
  label: `${(Number(rate) * 100).toFixed(0)}%`,
  value: rate,
}))

const countryOptions = getCountryOptions()

const paymentConditionOptions = paymentConditions.options.map(condition => ({
  label: $t(`customer.paymentCondition.${condition}`),
  value: condition,
}))

const languageOptions = useTranslatedSelectOptions('customer.language', Object.values(CustomerLanguage))

const { cloned } = useCloned(props.payload)
const isValidAddress = computed(() => Boolean((cloned.value.fullAddress && cloned.value.fullAddress.city && cloned.value.fullAddress.postalCode)))
</script>

<template>
  <FormKit
    v-model="cloned"
    type="form"
    submit-label="Abweichender Rechnungsempfänger speichern"
    :submit-attrs="{ disabled: !isValidAddress }"
    @submit="$emit('upsert', cloned)"
  >
    <TheDevOnlyNiceJson :json="cloned" />
    <FormKit
      :label="$t('customer.name', { count: 1 })"
      type="text"
      name="customerId"
      disabled
    />
    <div class="flex space-x-2">
      <FormKit
        name="name"
        type="text"
        :label="$t('common.field.name.name')"
        required
      />
      <FormKit
        name="nameTwo"
        type="text"
        :label="`${$t('common.field.name.name')} 2`"
        :placeholder="$t('customer.field.nameTwo.placeholder')"
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="nameThree"
        type="text"
        :label="`${$t('common.field.name.name')} 3`"
        :placeholder="$t('customer.field.nameThree.placeholder')"
      />
      <FormKitAddressPopup
        name="fullAddress"
        :initial-value="cloned.address"
        :label="$t('common.field.address.name')"
        :placeholder="$t('common.field.address.placeholder')"
        :errors="isValidAddress ? [] : [$t('address.error.cityAndPostalCodeRequired')]"
        disable-expand
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="email"
        type="email"
        :validation="[['required'], ['email']]"
        :label="$t('common.field.email.name')"
        :placeholder="$t('common.field.email.placeholder')"
      />
      <FormKit
        name="telephone"
        type="text"
        :label="$t('common.field.telephone.name')"
        :placeholder="$t('common.field.telephone.placeholder')"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="telefax"
        type="tel"
        :label="$t('customer.field.telefax.name')"
        :placeholder="$t('customer.field.telefax.placeholder')"
      />
      <FormKit
        name="iban"
        type="text"
        :label="$t('customer.field.iban.name')"
        :placeholder="$t('customer.field.iban.placeholder')"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="taxId"
        type="text"
        :label="$t('customer.field.taxId.name')"
        :placeholder="$t('customer.field.taxId.placeholder')"
        required
      />
      <FormKit
        name="vatID"
        type="text"
        :label="$t('customer.field.vatID.name')"
        :placeholder="$t('customer.field.vatID.placeholder')"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="taxRate"
        type="select"
        :options="taxRateOptions"
        :label="$t('customer.field.taxRate.name')"
        required
      />
      <FormKit
        name="paymentCondition"
        type="select"
        :options="paymentConditionOptions"
        validation="required"
        :label="$t('common.field.paymentCondition.name')"
        required
      />
    </div>

    <div class="flex space-x-2">
      <FormKit
        name="language"
        type="select"
        :options="languageOptions"
        validation="required"
        :label="$t('common.field.language.name')"
        :placeholder="$t('common.pleaseSelect')"
        required
      />
      <FormKitMultiSelect
        name="country"
        :label="$t('customer.field.country.name')"
        :multi-select-options="countryOptions"
        :is-multi-select-allowed="false"
        :placeholder="$t('customer.field.country.placeholder')"
        required
      />
    </div>
  </FormKit>
</template>
