<script setup lang="ts">
import { machineryAccessoryCategoryToGerman } from '~/translations'
import type { MachineryAccessoryCategory } from '~/types'
import type { ApiCustomerModuleOfferGetById } from '~/layers/customer-module/types'

const props = defineProps<{ position: ApiCustomerModuleOfferGetById['positions'][number] }>()
const { groupedPhotoQuery } = useCustomerModuleQuery()
const enableMachineryAccessoryPhoto = computed(() => Boolean((!props.position.machinery && props.position.machineryAccessory?.category) || props.position.machineryAccessoryCategory))

const categoryForPhoto = computed(() => {
  if (props.position.machineryAccessory) {
    return props.position.machineryAccessory.category
  }

  if (props.position.machineryAccessoryCategory) {
    return props.position.machineryAccessoryCategory
  }

  return undefined
})

const { data: machineryAccessoryPhoto } = groupedPhotoQuery.getByGroupAndKey('machineryAccessoryCategory', categoryForPhoto.value, enableMachineryAccessoryPhoto)

const imageToUse = computed(() => {
  if (props.position.machinery?.marketingPhotos?.length) {
    return props.position.machinery.marketingPhotos[0].path
  }

  if (machineryAccessoryPhoto.value) {
    return machineryAccessoryPhoto.value.file.path
  }

  return undefined
})

const isMobile = useIsMobileScreen()
const imageDimension = computed(() => isMobile.value ? '75px' : '100px')
</script>

<template>
  <div class="rounded overflow-hidden border border-gray-300">
    <div class="relative flex items-start gap-2">
      <div :class="`sm:grid size-[${imageDimension}] place-content-center bg-white`">
        <CustomerModulePositionImage
          :src="imageToUse"
          :height="imageDimension"
          :width="imageDimension"
          :placeholder="{ iconSize: '50px', withText: false }"
          class="!text-hos-blue !bg-white"
        />
      </div>
      <div class="p-2">
        <h1 class="md:text-base font-semibold capitalize">
          {{ position.title }}
        </h1>

        <template v-if="position.machinery">
          <CustomerModuleListItem
            :label="$t('customerModule.offer.machinery.id.label')"
            :value="position.machinery.id"
            :horizontal="true"
            size="sm"
          />
          <CustomerModuleListItem
            :label="$t('customerModule.offer.machinery.producerCompanyName.label')"
            :value="position.machinery.producerCompanyName"
            :horizontal="true"
            size="sm"
          />
          <CustomerModuleListItem
            :label="$t('customerModule.offer.machinery.type.label')"
            :value="position.machinery.type.name"
            :horizontal="true"
            size="sm"
          />
        </template>
        <template v-else-if="position.machineryAccessory">
          <CustomerModuleListItem
            :label="$t('customerModule.offer.accessory.id.label')"
            :value="position.machineryAccessory.id"
            :horizontal="true"
            size="sm"
          />
          <CustomerModuleListItem
            :label="$t('customerModule.offer.accessory.producerCompanyName.label')"
            :value="position.machineryAccessory.producerCompanyName"
            :horizontal="true"
            size="sm"
          />
          <CustomerModuleListItem
            :label="$t('customerModule.offer.accessory.type.label')"
            :value="`${machineryAccessoryCategoryToGerman[position.machineryAccessory.category as MachineryAccessoryCategory]} (${position.machineryAccessory.typeName})`"
            :horizontal="true"
            size="sm"
          />
        </template>
      </div>

      <slot name="extra" />
    </div>
    <slot name="bottom" />
  </div>
</template>
