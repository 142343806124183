<script setup lang="ts">
import { createInput } from '@formkit/vue'
import PositionSelector from './BaseInputs/PositionSelector.vue'

const positionSelectorInput = createInput(PositionSelector, { props: ['options', 'title'] })
</script>

<template>
  <FormKit :type="positionSelectorInput" />
</template>
