import type { TrackEventOptions } from '@gtm-support/vue-gtm'

export default () => {
  const gtm = useGtm()

  function enable(enabled: boolean) {
    if (!gtm) {
      return
    }
    return gtm.enable(enabled)
  }

  function track(event: TrackEventOptions) {
    if (!gtm) {
      return
    }
    return gtm.trackEvent(event)
  }

  return { enable, track }
}
