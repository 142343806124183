<script setup lang="ts">
import type { FileFolderType } from '~/prisma/enums'

const props = defineProps<{
  entityId: string
  folderType: FileFolderType
}>()

/** Queries */
const { fileFolder } = useQuery()
const { data: fileFolderData, isLoading } = fileFolder.getFolders(props.entityId, props.folderType)

const entity = fileFolderEntity[props.folderType]
</script>

<template>
  <div v-if="isLoading" class="flex justify-center items-center w-full h-36">
    <n-spin size="large" />
  </div>
  <FileFolderPage
    v-else-if="fileFolderData"
    :folder-tree="fileFolderData.folderTree"
    :root-folder="fileFolderData.rootFolder"
    :entity
  />
  <div v-else>
    <p>{{ $t('fileFolder.error.load') }}</p>
  </div>
</template>
