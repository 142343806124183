<script setup lang="ts">
const { t: $t } = useI18n()

import type { TreeDropInfo, TreeOption } from 'naive-ui'
import type { ApiFileFolderRootFolder, CustomFolderTreeFolderOption, CustomFolderTreeOption, SubFolderCreateOrUpdate } from '~/types'
import TreeNodeLabel from '~/components/FileFolder/TreeNodeLabel.vue'

export type FileFolderPageMode = 'view' | 'selectFolder'
type ModeProps = {
  mode: 'view'
} | {
  mode: 'selectFolder'
  currentNode: CustomFolderTreeOption
  close: () => void
}

interface TreeDragProps extends TreeDropInfo {
  node: CustomFolderTreeOption
  dragNode: CustomFolderTreeOption
}

const props = withDefaults(
  defineProps<{
    folderTree: CustomFolderTreeOption[]
    rootFolder: ApiFileFolderRootFolder
    entity: FileFolderEntity
    modeProps?: ModeProps
  }>(),
  {
    modeProps: () => ({ mode: 'view' }),
  },
)

const SEARCH_DEBOUNCE_TIME = 300
const hasFileFolderPermission = useFileFolderPermission(props.entity)
const { isRole, useremail } = useAuthorization()
const showAddFileButton = computed(() => hasFileFolderPermission(FileFolderAction.UpdateFiles) && props.modeProps.mode === 'view')
const showCreateFolderButton = computed(() => hasFileFolderPermission(FileFolderAction.Create))
const selectedKeys = ref<string[]>([])

/** Search */
const searchQuery = ref('')
const debouncedQuery = refDebounced(searchQuery, SEARCH_DEBOUNCE_TIME)

/** Mutations */
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const moveFolder = useMutation({
  mutationFn: $trpc.fileFolder.moveFolder.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('fileFolder.moveFolder.error') }),
  onSuccess: async () => {
    await Promise.all(getFileFolderQueryInvalidations(props.entity, queryClient))
    notification.success({ title: $t('fileFolder.moveFolder.success'), duration: 4500 })
    if (props.modeProps.mode === 'selectFolder') {
      props.modeProps.close()
    }
  },
})

const moveFile = useMutation({
  mutationFn: $trpc.fileFolder.moveFile.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('fileFolder.moveFile.error') }),
  onSuccess: async () => {
    await Promise.all(getFileFolderQueryInvalidations(props.entity, queryClient))
    notification.success({ title: $t('fileFolder.moveFile.success'), duration: 4500 })
    if (props.modeProps.mode === 'selectFolder') {
      props.modeProps.close()
    }
  },
})

/** Popups */
// parentFolderName is not needed when creating subfolder for root folder or updating folder
const popupCreateOrUpdatePayload = ref<null | { payload: SubFolderCreateOrUpdate, parentFolderName?: string }>(null)
const popupUpdateFilesPayload = ref<null | CustomFolderTreeFolderOption['folder']>(null)
const popupConfirmDeletePayload = ref<null | CustomFolderTreeOption>(null)
const popupMovePayload = ref<null | CustomFolderTreeOption>(null)
function openCreatePopup(option?: CustomFolderTreeOption) {
  const parentFolderId = option?.key ?? props.rootFolder.id

  popupCreateOrUpdatePayload.value = {
    payload: {
      mode: 'create',
      data: {
        parentFolderId,
        name: '',
      },
    },
    parentFolderName: option?.label,
  }
}

function handleTreeDrop(info: TreeDropInfo) {
  // type casting due to naive-ui typings
  const { node, dragNode, dropPosition } = info as TreeDragProps
  if (node.nodeType !== 'folder') {
    return
  }

  let parentId: string | null = node.key
  if (dropPosition !== 'inside') {
    parentId = node.folder.parentFolderId
  }

  const currentParentId = dragNode.nodeType === 'folder' ? dragNode.folder.parentFolderId : dragNode.file.parentFolderId
  if (!parentId || currentParentId === parentId) {
    return
  }

  moveFolderOrFile(dragNode, parentId)
}

function moveFolderOrFile(currentNode: CustomFolderTreeOption, newParentFolderId: string) {
  if (currentNode.nodeType === 'folder') {
    if (hasFileFolderPermission(FileFolderAction.MoveFolder, { currFolder: currentNode.folder })) {
      moveFolder.mutate({
        id: currentNode.key,
        newParentFolderId,
        entity: props.entity,
      })
    }
  } else {
    if (hasFileFolderPermission(FileFolderAction.MoveFile, { currFile: currentNode.file })) {
      moveFile.mutate({
        path: currentNode.key,
        newParentFolderId,
        entity: props.entity,
      })
    }
  }
}

/** Tree Node Buttons */
type TreeNodeActionButton = 'createSubfolder' | 'updateFile' | 'delete' | 'moveToOtherFolder'
const nodeDefaultButtons: Record<FileFolderPageMode, Record<CustomFolderTreeOption['nodeType'], TreeNodeActionButton[]>> = {
  view: {
    folder: ['updateFile', 'createSubfolder', 'moveToOtherFolder', 'delete'],
    file: ['moveToOtherFolder', 'delete'],
  },
  selectFolder: {
    folder: ['createSubfolder'],
    file: [],
  },
}

const treeNodeButtonConfig: Record<TreeNodeActionButton, { icon: string, text?: string, action: (folderTree: CustomFolderTreeOption) => void }> = {
  createSubfolder: {
    icon: 'material-symbols:create-new-folder-outline-rounded',
    text: $t('fileFolder.button.create.subfolder'),
    action: folderTree => openCreatePopup(folderTree),
  },
  updateFile: {
    icon: 'material-symbols:note-add-outline-rounded',
    text: $t('fileFolder.button.add.file'),
    action: (folderTree) => {
      if (folderTree.nodeType === 'folder') {
        popupUpdateFilesPayload.value = folderTree.folder
      }
    },
  },
  delete: {
    icon: 'material-symbols:delete-outline-rounded',
    text: $t('fileFolder.button.delete'),
    action: (folderTree) => {
      if (folderTree.nodeType === 'folder') {
        popupConfirmDeletePayload.value = folderTree
      } else {
        popupConfirmDeletePayload.value = folderTree
      }
    },
  },
  moveToOtherFolder: {
    icon: 'material-symbols:move-up-rounded',
    text: $t('fileFolder.button.move'),
    action: (folderTree) => {
      popupMovePayload.value = folderTree
    },
  },
}

function renderNodeButtons(option: TreeOption) {
  // type casting due to naive-ui typings
  const fileFolderNode = option as CustomFolderTreeOption
  const buttonsWithPermission = nodeDefaultButtons[props.modeProps.mode][fileFolderNode.nodeType].filter((button) => {
    switch (button) {
      case 'delete':
        if (fileFolderNode.nodeType === 'folder') {
          return hasFileFolderPermission(FileFolderAction.Delete, { currFolder: fileFolderNode.folder })
        } else {
          return isRole('admin') || fileFolderNode.file.createdByEmail === useremail.value
        }
      case 'createSubfolder':
        return hasFileFolderPermission(FileFolderAction.Create)
      case 'updateFile':
        return hasFileFolderPermission(FileFolderAction.UpdateFiles)
      case 'moveToOtherFolder':
        if (fileFolderNode.nodeType === 'folder') {
          return hasFileFolderPermission(FileFolderAction.MoveFolder, { currFolder: fileFolderNode.folder })
        } else {
          return hasFileFolderPermission(FileFolderAction.MoveFile, { currFile: fileFolderNode.file })
        }
      default:
        return true
    }
  })

  const buttons = buttonsWithPermission.map((button) => {
    const { icon, text, action } = treeNodeButtonConfig[button]
    const onClick = (e: Event) => {
      e.stopImmediatePropagation() // Prevent re-rendering buttons
      action(fileFolderNode)
    }
    return useRenderButton({ icon, text, quaternary: true }, onClick)
  })

  return h('div', { class: 'flex items-center gap-2 TreeButtons' }, buttons)
}

/** Tree Node Label */
function renderNodeLabel(option: TreeOption) {
  // type casting due to naive-ui typings
  const fileFolderNode = option as CustomFolderTreeOption
  const treeNodeLabelProps = {
    option: fileFolderNode,
    mode: props.modeProps.mode,
    entity: props.entity,
    onEditFolder: () => {
      popupCreateOrUpdatePayload.value = {
        payload: {
          mode: 'update',
          data: { name: fileFolderNode.label, id: fileFolderNode.key },
        },
      }
    },
  }

  return h(TreeNodeLabel, treeNodeLabelProps)
}
</script>

<template>
  <div class="FileFolderContainer space-y-4">
    <div class="flex w-full items-center gap-2">
      <TableFilters
        v-model="searchQuery"
        class="w-full"
        :placeholder="$t('fileFolder.search.placeholder')"
      />
      <n-button
        v-if="showAddFileButton"
        type="primary"
        secondary
        @click="popupUpdateFilesPayload = rootFolder"
      >
        <template #icon>
          <Icon name="material-symbols:note-add-outline-rounded" />
        </template>
        {{ $t('fileFolder.button.updateFiles') }}
      </n-button>
      <n-button
        v-if="showCreateFolderButton"
        type="primary"
        secondary
        @click="openCreatePopup()"
      >
        <template #icon>
          <Icon name="material-symbols:create-new-folder-outline-rounded" />
        </template>
        {{ $t('fileFolder.button.create.folder') }}
      </n-button>
    </div>

    <n-tree
      v-model:selected-keys="selectedKeys"
      :data="folderTree"
      :render-label="({ option }) => renderNodeLabel(option)"
      :render-suffix="({ option }) => renderNodeButtons(option)"
      :show-irrelevant-nodes="false"
      :pattern="debouncedQuery"
      :draggable="modeProps.mode === 'view'"
      :selectable="modeProps.mode === 'selectFolder'"
      expand-on-dragenter
      block-line
      @drop="handleTreeDrop"
    />

    <div v-if="modeProps.mode === 'selectFolder'" class="flex gap-2 justify-end">
      <n-button
        type="default"
        @click="modeProps.close"
      >
        {{ $t('button.cancel') }}
      </n-button>
      <n-button
        type="primary"
        @click="moveFolderOrFile(modeProps.currentNode, selectedKeys[0] ?? rootFolder.id)"
      >
        {{ $t(selectedKeys.length === 0 ? 'fileFolder.button.moveToRoot' : 'fileFolder.button.moveToSelected') }}
      </n-button>
    </div>

    <LazyFileFolderPopupCreateOrUpdate
      v-if="popupCreateOrUpdatePayload"
      v-bind="popupCreateOrUpdatePayload"
      :entity
      @close="popupCreateOrUpdatePayload = null"
    />
    <LazyFileFolderPopupUpdateFiles
      v-if="popupUpdateFilesPayload"
      :folder="popupUpdateFilesPayload"
      :entity
      @close="popupUpdateFilesPayload = null"
    />
    <LazyFileFolderPopupConfirmDelete
      v-if="popupConfirmDeletePayload"
      :payload="popupConfirmDeletePayload"
      :entity
      @close="popupConfirmDeletePayload = null"
    />
    <LazyFileFolderPopupMove
      v-if="popupMovePayload"
      :payload="popupMovePayload"
      :folder-tree
      :root-folder
      :entity
      @close="popupMovePayload = null"
    />
  </div>
</template>

<style scoped>
.FileFolderContainer {
  & :deep(.n-tree-node){
    @apply flex items-center;
  }

  & :deep(.n-tree-node-content),
  & :deep(.n-tree-node-content.subfolder),
  & :deep(.n-tree-node-content.favorite) {
    @apply border-2 border-hos-gray-light p-1 !important;
  }

  & :deep(.n-tree .n-tree-node.n-tree-node--highlight .n-tree-node-content .n-tree-node-content__text){
    @apply border-0 !important;
  }

  & :deep(.n-tree.n-tree--block-line .n-tree-node:not(.n-tree-node--disabled).n-tree-node--selected) {
    @apply bg-primary-100/30 !important;
  }

  & :deep(.n-tree.n-tree--block-line .n-tree-node:not(.n-tree-node--disabled).n-tree-node--pending) {
    @apply bg-white;
  }

  & :deep(.n-tree-node:not(.n-tree-node--disabled):hover > .n-tree-node-content > .n-tree-node-content__suffix > .TreeButtons) {
    @apply opacity-100;
  }

  & :deep(.TreeButtons) {
  @apply transition-all duration-300 ease-in-out opacity-0;
}
}
</style>
