<script setup lang="ts">
import type { DataTableRowKey, SelectOption } from 'naive-ui'
import { createId } from '@paralleldrive/cuid2'
import accessoryFilterConditions, { checkShoppingAccessoryFilter } from './accessoryFilterConditions'
import { machineryAccessoryCategoryToGerman } from '~/translations'
import type { MachineryAccessoryCategory, MachineryAccessoryCategoryFilter, OfferStatus, ShoppingCartPosition, ShoppingMachineryAccessory } from '~/types'
import type { ShoppingTab } from '~/components/Calendar/Page.vue'

const props = defineProps<{
  machineryId: string | undefined
  selectedTab: ShoppingTab
  compatability: 'compatible' | 'incompatible'
  selectedMachineryAccessoryCategory: MachineryAccessoryCategory
  showSelection: boolean
  selectType: 'single' | 'multi'
  offerStartDay: Date
  offerEndDay?: Date
  attachedForkId?: string
  isForkEditMode?: boolean
  isAccessoryOnlyOffer?: boolean
  offer: { status: OfferStatus, id?: string }
  disabledAccessoryIds: string[]
  addCategoryButtonText?: string
  compatibleAccessoryId?: string
  currentCompatibleAccessoryIds?: string[]
}>()
const emits = defineEmits<{ (e: 'updateIsForkEditMode', value: boolean): void }>()

const cartPositions = defineModel<ShoppingCartPosition[]>({ default: () => [] })

const { shopping } = useQuery()

const { shoppingAccessoryTable } = useTableColumnConfigs()

const machineryId = computed(() => props.machineryId)

const { data: availabilitySpecificCategory, isLoading: isLoadingSpecificCategory } = shopping.accessoryAvailabilitiesByMachineryId(
  machineryId,
  computed(() => props.selectedMachineryAccessoryCategory as MachineryAccessoryCategory),
  computed(() => props.offerStartDay),
  computed(() => props.offerEndDay),
  false,
  computed(() => props.offer.status),
  props.compatibleAccessoryId && props.currentCompatibleAccessoryIds ? ref(props.currentCompatibleAccessoryIds) : undefined,
)

const accessoryFilter = ref<MachineryAccessoryCategoryFilter>({})
function resetAccessoryFilter() {
  accessoryFilter.value = {}
}

watch(() => props.selectedMachineryAccessoryCategory, (newCategory, oldCategory) => {
  if (!newCategory || newCategory === oldCategory) {
    return
  }
  resetAccessoryFilter()
})

function resetCartSelection() {
  cartPositions.value = []
}

// TODO: This returns false if `val` is `0` or `''` -> however, `0` or `''` could be valid values. Investigate this if an error is reported
const isValueInFilter = computed(() => Object.values(accessoryFilter.value).some(Boolean))

const filtersRenderingKey = computed(() => {
  const selectedMachaineryCategory = cartPositions.value.map(p => p.type === 'machineryAccessoryCategory' ? p.machineryAccessoryCategory : null).filter(c => c !== null)
  return `${props.selectedMachineryAccessoryCategory ?? ''}-${selectedMachaineryCategory.join('-')}`
})

const machineryAccessoriesOfSelectedCategory = computed(() => {
  if (!availabilitySpecificCategory.value) {
    return []
  }

  return availabilitySpecificCategory.value.resultsByAccessoryCategory[props.selectedMachineryAccessoryCategory]?.[props.compatability] ?? []
})

const filteredMachineryAccessoriesOfSelectedCategory = computed(() => {
  const filters = accessoryFilterConditions[props.selectedMachineryAccessoryCategory]
  if (!filters) {
    return machineryAccessoriesOfSelectedCategory.value
  }

  return machineryAccessoriesOfSelectedCategory.value.filter((accessory) => {
    const filterKeys = Object.entries(accessoryFilter.value) as Array<[keyof MachineryAccessoryCategoryFilter, string | number | undefined | null]>
    for (const [key, value] of filterKeys) {
      if (value === undefined || value === null) {
        continue
      }

      const accessoryKey = accessory[key]
      if (accessoryKey === undefined || accessoryKey === null) {
        return false
      }
      if (!checkShoppingAccessoryFilter(filters, key, accessoryKey, value)) {
        return false
      }
    }
    return true
  })
})

const accessoryOptions = computed((): Partial<Record<keyof MachineryAccessoryCategoryFilter, SelectOption[]>> => {
  const filters = accessoryFilterConditions[props.selectedMachineryAccessoryCategory]
  if (!filters) {
    return {}
  }

  const filtersToCreateOptions = Object.entries(filters).filter(([_, value]) => value.optionsFromDBDataConfigs)

  const optionsByFilter: Partial<Record<keyof MachineryAccessoryCategoryFilter, SelectOption[]>> = {}
  for (const filter of filtersToCreateOptions) {
    const accessoriesByFilters = machineryAccessoriesOfSelectedCategory.value.reduce<Record<string, string[]>>((countByValue, accessory) => {
      const filterValue = accessory[filter[0] as keyof MachineryAccessoryCategoryFilter]
      if (!filterValue) {
        return countByValue
      }

      if (countByValue[filterValue]) {
        countByValue[filterValue].push(accessory.id)
      } else {
        countByValue[filterValue] = [accessory.id]
      }

      return countByValue
    }, {} as Record<keyof string, string[]>)

    optionsByFilter[filter[0] as keyof MachineryAccessoryCategoryFilter] = Object.entries(accessoriesByFilters).map(([key, value]) => ({
      label: `${key} (${value.length})`,
      value: filter[1].optionsFromDBDataConfigs?.getValue ? filter[1].optionsFromDBDataConfigs.getValue(key) : key,
    }))
  }

  return optionsByFilter
})

const notification = useNotification()

function addBundledMachineryAccessories(rows: ShoppingMachineryAccessory[], checkedRowKeys?: DataTableRowKey[]) {
  const bundleAccessoryIds = rows.flatMap(row => row.machineryAccessoryBundle?.machineryAccessories.map(({ id }) => id) ?? [])

  const allAccessoryIdsToAdd = new Set(bundleAccessoryIds.concat(checkedRowKeys as string[] ?? []))
  if (allAccessoryIdsToAdd.size === 0) {
    return
  }

  const alreadySelectedMachineryAccessoryIds = cartPositions.value
    .map(p => p.type === 'machineryAccessory' && p.machineryAccessoryId)

  const accessoryIdsToAdd = [...allAccessoryIdsToAdd].filter(id => !alreadySelectedMachineryAccessoryIds.includes(id))
  const accessoryPositionsToAdd = accessoryIdsToAdd.map(id => ({ type: 'machineryAccessory', machineryAccessoryId: id } satisfies ShoppingCartPosition))

  if (accessoryPositionsToAdd.length > 0) {
    cartPositions.value = cartPositions.value.concat(...accessoryPositionsToAdd)
    notification.success({ title: 'Lagertool Bündel wurde erfolgreich hinzugefügt', duration: 4500 })
  }
}

function removeBundledMachineryAccessories(checkedRowKeys: DataTableRowKey[], row?: ShoppingMachineryAccessory) {
  if (!row) {
    const uncheckedAccessoryIds = selectedCartPositionsByCategory.value.filter(id => !checkedRowKeys.includes(id))

    const accessoryPositionsToRemove = filteredMachineryAccessoriesOfSelectedCategory.value
      .filter(accessory => uncheckedAccessoryIds.includes(accessory.id))
      .flatMap(accessory => accessory.machineryAccessoryBundle?.machineryAccessories.map(({ id }) => id))
      .concat(uncheckedAccessoryIds)

    cartPositions.value = cartPositions.value.filter(p => p.type === 'machineryAccessory' && !accessoryPositionsToRemove.includes(p.machineryAccessoryId))
    notification.success({ title: 'Lagertool Bündel wurde erfolgreich entfernt', duration: 4500 })
    return
  }

  if (!row.machineryAccessoryBundle) {
    return
  }

  const bundleAccessoryIds = row.machineryAccessoryBundle?.machineryAccessories.map(({ id }) => id)

  if (bundleAccessoryIds.some(id => !checkedRowKeys.includes(id))) {
    cartPositions.value = cartPositions.value.filter(p => p.type === 'machineryAccessory' && !bundleAccessoryIds.includes(p.machineryAccessoryId))
    notification.success({ title: 'Lagertool Bündel wurde erfolgreich entfernt', duration: 4500 })
  }
}

function setSelectedCartPositionsForCategory(checkedRowKeys: DataTableRowKey[], rows: ShoppingMachineryAccessory[], meta: { row?: ShoppingMachineryAccessory, action: 'check' | 'uncheck' | 'checkAll' | 'uncheckAll' }) {
  const cartPositionsOfOtherCategoryOrTypeCategory = cartPositions.value.filter((p) => {
    const isTypeCategory = p.type === 'machineryAccessoryCategory'
    const isNotInAccessoriesOfSelectedCategory = p.type === 'machineryAccessory' && !filteredMachineryAccessoriesOfSelectedCategory.value.some(accessory => accessory.id === p.machineryAccessoryId)

    return isTypeCategory || isNotInAccessoriesOfSelectedCategory
  })

  const compatiblePositions = cartPositions.value.filter(p => p.compatibleMachineryAccessoryId)

  let cartPositionsOfThisCategory = checkedRowKeys.map((key) => {
    const compatiblePosition = compatiblePositions.find(p => p.type === 'machineryAccessory' && p.machineryAccessoryId === key)
    if (compatiblePosition) {
      return compatiblePosition
    }

    const machineryAccessory = filteredMachineryAccessoriesOfSelectedCategory.value.find(accessory => accessory.id === key)
    if (!machineryAccessory) {
      throw new Error('Accessory must exist')
    }
    const isForkSelected = props.selectedMachineryAccessoryCategory === 'fork'

    const lengthInMillimetersForFork = isForkSelected ? machineryAccessory.lengthInMillimeters : undefined
    const isForkReplaceRequired = isForkSelected && props.selectType === 'single' && props.attachedForkId !== machineryAccessory.id
    return { type: 'machineryAccessory', machineryAccessoryId: machineryAccessory.id, isForkReplaceRequired, lengthInMillimetersForFork } satisfies ShoppingCartPosition
  })

  /**
   * Stop edge-case where `select-all` checkbox triggers a select of _all_ items, although we already reached the maximum.
   *
   * To mitigate this we calculate how many items can maximally be added to the cart by checking:
   * - Is selectType `single`? If yes, we can only add one item max.
   * - How many specific item instances are there in total?
   * - How many generic categories has the user already be added?
   *
   * The difference of this is the total count of specific items we still can add.
   *
   * If `cartPositionsOfThisCategory` (the collection of specific items of this category) is more than this count, we only take what still fits.
   *
   */
  const itemCountThatCanMaximallyBeAdded = filteredMachineryAccessoriesOfSelectedCategory.value.length - cartPositionsOfOtherCategoryOrTypeCategory.filter(p => p.type === 'machineryAccessoryCategory' && p.machineryAccessoryCategory === props.selectedMachineryAccessoryCategory).length

  if (itemCountThatCanMaximallyBeAdded < cartPositionsOfThisCategory.length) {
    cartPositionsOfThisCategory = cartPositionsOfThisCategory.slice(0, itemCountThatCanMaximallyBeAdded)
  }

  // If we are in fork edit mode, we unset it
  if (props.isForkEditMode) {
    emits('updateIsForkEditMode', false)
  }

  cartPositions.value = [
    ...cartPositionsOfOtherCategoryOrTypeCategory,
    ...cartPositionsOfThisCategory,
  ]

  if (meta.action === 'check') {
    addBundledMachineryAccessories(rows)
  } else if (meta.action === 'checkAll') {
    addBundledMachineryAccessories(rows, checkedRowKeys)
  } else if (meta.action === 'uncheck' && meta.row) {
    removeBundledMachineryAccessories(checkedRowKeys, meta.row)
  } else if (meta.action === 'uncheckAll') {
    removeBundledMachineryAccessories(checkedRowKeys)
  }
}

const canAccessoryOfThisCategoryBeAdded = computed(() => {
  // If no more items are available we do not allow adding
  const availableItemCount = props.offer.status === 'offer'
    ? filteredMachineryAccessoriesOfSelectedCategory.value.length
    : filteredMachineryAccessoriesOfSelectedCategory.value.filter(row => !row.overlappedOffer).length
  if (availableItemCount === 0) {
    return false
  }

  // If all items of category were already selected, we do not allow adding
  const machineryAccessoryCategoryItems: ShoppingCartPosition[] = cartPositions.value.filter(p => p.type === 'machineryAccessoryCategory' && p.machineryAccessoryCategory === props.selectedMachineryAccessoryCategory)

  let totalItemsOfSelectedCategoryCount = 0
  // If filter has values, we need to check if the selected machinery categories are matching the filter
  // to count correct number of items
  if (isValueInFilter.value) {
    const filters = accessoryFilterConditions[props.selectedMachineryAccessoryCategory]
    const filteredSelectedItems = filters
      ? cartPositions.value.filter((p) => {
        if (p.type !== 'machineryAccessoryCategory' || p.machineryAccessoryCategory !== props.selectedMachineryAccessoryCategory || !p.filters) {
          return false
        }

        const accessoryFilterEntries = Object.entries(accessoryFilter.value) as Array<[keyof MachineryAccessoryCategoryFilter, string | number | undefined | null ]>
        for (const [key, value] of accessoryFilterEntries) {
          const accessoryValue = p.filters[key]
          if (value === undefined || value === null || accessoryValue === undefined || accessoryValue === null) {
            continue
          }

          // If filter matches, we count without this item
          if (checkShoppingAccessoryFilter(filters, key, accessoryValue, value)) {
            return true
          }
        }

        return false
      })
      : machineryAccessoryCategoryItems

    totalItemsOfSelectedCategoryCount = filteredSelectedItems.length + selectedCartPositionsByCategory.value.length
  } else {
    totalItemsOfSelectedCategoryCount = machineryAccessoryCategoryItems.length + selectedCartPositionsByCategory.value.length
  }

  if (props.selectType === 'single') {
    return machineryAccessoryCategoryItems.length === 0
  }

  return availableItemCount > totalItemsOfSelectedCategoryCount
})

function addCategoryAndFiltersToCart(machineryAccessoryCategory: MachineryAccessoryCategory | null) {
  if (!machineryAccessoryCategory) {
    return
  }

  // If we are in fork edit mode, we unset it
  if (props.isForkEditMode) {
    emits('updateIsForkEditMode', false)
  }

  const isForkReplaceRequired = machineryAccessoryCategory === 'fork' && props.selectType === 'single'
  cartPositions.value = [...cartPositions.value, { id: createId(), machineryAccessoryCategory, type: 'machineryAccessoryCategory', filters: accessoryFilter.value, isForkReplaceRequired }]
  resetAccessoryFilter()
}

// When we disable canAccessoryOfThisCategoryBeAdded in 'signle' selection type
// radio select button in table wouldn't work.
// so instead of using canAccessoryOfThisCategoryBeAdded value we use this value for disabling category add button
const isAccessoryCategoryAddDisabled = computed(() => {
  if (filteredMachineryAccessoriesOfSelectedCategory.value.length === 0) {
    return true
  }

  if (props.selectType === 'single') {
    const machineryAccessoryCategoryItems: ShoppingCartPosition[] = cartPositions.value.filter((p) => {
      if (p.type === 'machineryAccessoryCategory' && p.machineryAccessoryCategory === props.selectedMachineryAccessoryCategory) {
        return true
      }
      // check if the machineryAccessory in the options is already selected
      if (p.type === 'machineryAccessory' && machineryAccessoriesOfSelectedCategory.value.some(accessory => p.machineryAccessoryId === accessory.id)) {
        return true
      }

      return false
    })

    return machineryAccessoryCategoryItems.length + selectedCartPositionsByCategory.value.length >= 1
  }

  return !canAccessoryOfThisCategoryBeAdded.value
})

const selectedCartPositionsByCategory = computed(() => {
  const machineryAccessoriesInCart = cartPositions.value.filter(p => p.type === 'machineryAccessory')

  const machineryAccessoriesInCartOfSelectedCategory = []
  for (const machineryAccessory of machineryAccessoriesInCart) {
    if (filteredMachineryAccessoriesOfSelectedCategory.value.some(accessory => accessory.id === machineryAccessory.machineryAccessoryId)) {
      machineryAccessoriesInCartOfSelectedCategory.push(machineryAccessory.machineryAccessoryId)
    }
  }
  return machineryAccessoriesInCartOfSelectedCategory
})

const tableConfig = shoppingAccessoryTable(
  computed(() => props.showSelection),
  selectedCartPositionsByCategory,
  canAccessoryOfThisCategoryBeAdded,
  machineryId,
  computed(() => props.selectType),
  computed(() => props.selectedMachineryAccessoryCategory),
  computed(() => props.offer),
  computed(() => props.disabledAccessoryIds),
)
</script>

<template>
  <div v-if="selectedMachineryAccessoryCategory">
    <ShoppingAccessoryFilters
      v-model="accessoryFilter"
      class="mt-2"
      :selected-tab="selectedTab"
      :selected-machinery-accessory-category="selectedMachineryAccessoryCategory"
      :filters-rendering-key="filtersRenderingKey"
      :accessory-filter-options="accessoryOptions"
    />

    <div v-if="machineryId && isLoadingSpecificCategory">
      Anbau-Geräte der Kategorie {{ machineryAccessoryCategoryToGerman[selectedMachineryAccessoryCategory as MachineryAccessoryCategory] }} werden geladen...
    </div>

    <div v-else>
      <div v-if="props.showSelection" class="my-4 w-full flex items-center gap-1">
        <n-button class="flex-grow" type="primary" ghost :disabled="isAccessoryCategoryAddDisabled" @click="addCategoryAndFiltersToCart(selectedMachineryAccessoryCategory)">
          {{ addCategoryButtonText ?? 'Anbau-Gerät dieser Kategorie hinzufügen' }}
        </n-button>

        <n-button ghost :disabled="!isAccessoryCategoryAddDisabled" @click="resetCartSelection">
          <Icon name="material-symbols:settings-backup-restore-rounded" />
        </n-button>
      </div>
      <p v-if="selectType === 'single' && !!cartPositions.find(p => p.type === 'machineryAccessoryCategory')" class="mb-4 italic">
        Vermerk: Gerät wird bei der Ausgabe bestimmt
      </p>
      <p v-if="isValueInFilter" class="font-bold">
        Anzeige des gefilterten Ergebnisses ( gefunden: {{ filteredMachineryAccessoriesOfSelectedCategory.length }} )
      </p>
      <TableView
        v-if="filteredMachineryAccessoriesOfSelectedCategory"
        :key="selectedMachineryAccessoryCategory"
        :data="filteredMachineryAccessoriesOfSelectedCategory"
        :columns="tableConfig.columns"
        :is-loading="Boolean(machineryId && isLoadingSpecificCategory)"
        :row-key="row => row.id"
        :checked-row-keys="selectedCartPositionsByCategory"
        :row-props="tableConfig.rowProps"
        @update:checked-row-keys="setSelectedCartPositionsForCategory"
      />
    </div>
  </div>
</template>
