// This file was generated by a custom prisma generator, do not edit manually.
/* eslint-disable ts/no-redeclare */
export const MerloExtensions = {
  ZM1: 'ZM1',
  ZM2: 'ZM2',
  ZM3: 'ZM3',
} as const

export type MerloExtensions = (typeof MerloExtensions)[keyof typeof MerloExtensions]

export const CartPositionType = {
  machineryCategory: 'machineryCategory',
  machineryAccessoryCategory: 'machineryAccessoryCategory',
} as const

export type CartPositionType = (typeof CartPositionType)[keyof typeof CartPositionType]

export const OfferPositionType = {
  comment: 'comment',
  creditNote: 'creditNote',
  extraDays: 'extraDays',
  extraPosition: 'extraPosition',
  generatedByAutomation: 'generatedByAutomation',
  insurance: 'insurance',
  invoice: 'invoice',
  itemSet: 'itemSet',
  itemSetAccessory: 'itemSetAccessory',
  logisticsTask: 'logisticsTask',
  machinery: 'machinery',
  machineryAccessory: 'machineryAccessory',
  machineryAccessoryCategory: 'machineryAccessoryCategory',
  machineryCategory: 'machineryCategory',
  manualPosition: 'manualPosition',
  special: 'special',
} as const

export type OfferPositionType = (typeof OfferPositionType)[keyof typeof OfferPositionType]

export const OfferPositionGroupType = {
  GlobalTermsAndConditions: 'GlobalTermsAndConditions',
  InvoiceRelated: 'InvoiceRelated',
  MachineryAndAccessories: 'MachineryAndAccessories',
  OfferAndCustomerDefault: 'OfferAndCustomerDefault',
  SpecialPositions: 'SpecialPositions',
} as const

export type OfferPositionGroupType = (typeof OfferPositionGroupType)[keyof typeof OfferPositionGroupType]

export const OfferPositionPaymentStatus = {
  Invoiced: 'Invoiced',
  Mixed: 'Mixed',
  Open: 'Open',
} as const

export type OfferPositionPaymentStatus = (typeof OfferPositionPaymentStatus)[keyof typeof OfferPositionPaymentStatus]

export const PositionTemplateType = {
  comment: 'comment',
  extraPosition: 'extraPosition',
  insurance: 'insurance',
  logisticsTask: 'logisticsTask',
} as const

export type PositionTemplateType = (typeof PositionTemplateType)[keyof typeof PositionTemplateType]

export const LogisticsTaskType = {
  outbound: 'outbound',
  inbound: 'inbound',
  aToB: 'aToB',
  toHanselmann: 'toHanselmann',
  toWorkshop: 'toWorkshop',
  individual: 'individual',
} as const

export type LogisticsTaskType = (typeof LogisticsTaskType)[keyof typeof LogisticsTaskType]

export const LogisticsTaskStatus = {
  collected: 'collected',
  completed: 'completed',
  created: 'created',
  delivered: 'delivered',
  loaded: 'loaded',
  returned: 'returned',
  terminated: 'terminated',
} as const

export type LogisticsTaskStatus = (typeof LogisticsTaskStatus)[keyof typeof LogisticsTaskStatus]

export const CustomerStatus = {
  Current: 'Current',
  Potential: 'Potential',
  Unconfirmed: 'Unconfirmed',
} as const

export type CustomerStatus = (typeof CustomerStatus)[keyof typeof CustomerStatus]

export const CustomerType = {
  Dealer: 'Dealer',
  EndCustomer: 'EndCustomer',
} as const

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType]

export const CustomerLanguage = {
  DE: 'DE',
  EN: 'EN',
  FR: 'FR',
  PL: 'PL',
} as const

export type CustomerLanguage = (typeof CustomerLanguage)[keyof typeof CustomerLanguage]

export const ContactPersonVerificationStatus = {
  notRequested: 'notRequested',
  confirmed: 'confirmed',
  rejected: 'rejected',
  pending: 'pending',
} as const

export type ContactPersonVerificationStatus = (typeof ContactPersonVerificationStatus)[keyof typeof ContactPersonVerificationStatus]

export const DeliveryVehicleStatus = {
  Active: 'Active',
  Inactive: 'Inactive',
} as const

export type DeliveryVehicleStatus = (typeof DeliveryVehicleStatus)[keyof typeof DeliveryVehicleStatus]

export const CommentType = {
  CancelledStoragePosition: 'CancelledStoragePosition',
  ContactPerson: 'ContactPerson',
  Customer: 'Customer',
  Defect: 'Defect',
  DeliveryDate: 'DeliveryDate',
  DeliveryTime: 'DeliveryTime',
  ExternalStorageInvoicingEvent: 'ExternalStorageInvoicingEvent',
  InternalCostPosition: 'InternalCostPosition',
  Invoice: 'Invoice',
  ItemSet: 'ItemSet',
  LogisticsTask: 'LogisticsTask',
  Machinery: 'Machinery',
  MachineryAccessory: 'MachineryAccessory',
  Offer: 'Offer',
  OfferCustomer: 'OfferCustomer',
  OfferFeedback: 'OfferFeedback',
  OfferNote: 'OfferNote',
  OffloadedStoragePositionSet: 'OffloadedStoragePositionSet',
  ServiceProject: 'ServiceProject',
  ServiceProjectReporting: 'ServiceProjectReporting',
  SpecialPermission: 'SpecialPermission',
  StoragePosition: 'StoragePosition',
  Temporary: 'Temporary',
  Termination: 'Termination',
  UserTask: 'UserTask',
  VerifiedStoragePosition: 'VerifiedStoragePosition',
  VerifiedStoragePositionSet: 'VerifiedStoragePositionSet',
} as const

export type CommentType = (typeof CommentType)[keyof typeof CommentType]

export const CommentVisibility = {
  Private: 'Private',
  Public: 'Public',
} as const

export type CommentVisibility = (typeof CommentVisibility)[keyof typeof CommentVisibility]

export const CommentReactionType = {
  ThumbsUp: 'ThumbsUp',
  ThumbsDown: 'ThumbsDown',
  RedHeart: 'RedHeart',
} as const

export type CommentReactionType = (typeof CommentReactionType)[keyof typeof CommentReactionType]

export const InternalCostPositionType = {
  machinery: 'machinery',
  machineryAccessory: 'machineryAccessory',
  offer: 'offer',
} as const

export type InternalCostPositionType = (typeof InternalCostPositionType)[keyof typeof InternalCostPositionType]

export const DefectType = {
  machinery: 'machinery',
  machineryAccessory: 'machineryAccessory',
} as const

export type DefectType = (typeof DefectType)[keyof typeof DefectType]

export const ModelChangesType = {
  Offer: 'Offer',
  OfferPosition: 'OfferPosition',
} as const

export type ModelChangesType = (typeof ModelChangesType)[keyof typeof ModelChangesType]

export const CreatedByType = {
  Automation: 'Automation',
  User: 'User',
} as const

export type CreatedByType = (typeof CreatedByType)[keyof typeof CreatedByType]

export const StoragePositionStatus = {
  Created: 'Created',
  RequestedForStorage: 'RequestedForStorage',
  Offloaded: 'Offloaded',
  Stored: 'Stored',
  NotDelivered: 'NotDelivered',
  StorageApproved: 'StorageApproved',
  NotDeliveredApproved: 'NotDeliveredApproved',
  RequestedForRetrieving: 'RequestedForRetrieving',
  FullyOrdered: 'FullyOrdered',
  Retrieved: 'Retrieved',
} as const

export type StoragePositionStatus = (typeof StoragePositionStatus)[keyof typeof StoragePositionStatus]

export const StoragePositionLoadedWith = {
  Forklift: 'Forklift',
  OverheadCrane: 'OverheadCrane',
  PickAndCarryCrane: 'PickAndCarryCrane',
  Other: 'Other',
} as const

export type StoragePositionLoadedWith = (typeof StoragePositionLoadedWith)[keyof typeof StoragePositionLoadedWith]

export const InboundLogisticsTaskStatus = {
  Created: 'Created',
  RequestedForStorage: 'RequestedForStorage',
  Storing: 'Storing',
  Done: 'Done',
} as const

export type InboundLogisticsTaskStatus = (typeof InboundLogisticsTaskStatus)[keyof typeof InboundLogisticsTaskStatus]

export const PartialStoragePositionStatus = {
  RequestedForRetrieval: 'RequestedForRetrieval',
  ReadyForRetrieval: 'ReadyForRetrieval',
  RetrievalScheduled: 'RetrievalScheduled',
  Loaded: 'Loaded',
  Delivered: 'Delivered',
} as const

export type PartialStoragePositionStatus = (typeof PartialStoragePositionStatus)[keyof typeof PartialStoragePositionStatus]

export const OutboundLogisticsTaskStatus = {
  Created: 'Created',
  RequestedForRetrieval: 'RequestedForRetrieval',
  Loaded: 'Loaded',
  Done: 'Done',
} as const

export type OutboundLogisticsTaskStatus = (typeof OutboundLogisticsTaskStatus)[keyof typeof OutboundLogisticsTaskStatus]

export const ExternalStorageInvoicingEventType = {
  Picking: 'Picking',
  Repackaging: 'Repackaging',
  Ride: 'Ride',
  StorageMovement: 'StorageMovement',
  UsedStorageSpace: 'UsedStorageSpace',
  Washing: 'Washing',
  WashingRework: 'WashingRework',
} as const

export type ExternalStorageInvoicingEventType = (typeof ExternalStorageInvoicingEventType)[keyof typeof ExternalStorageInvoicingEventType]

export const FileFolderType = {
  Offer: 'Offer',
  OfferDocument: 'OfferDocument',
  OfferCustomerDocument: 'OfferCustomerDocument',
  OfferCorrespondence: 'OfferCorrespondence',
  OfferOffer: 'OfferOffer',
  OfferPicture: 'OfferPicture',
  OfferPlanAndDrawing: 'OfferPlanAndDrawing',
  OfferContractor: 'OfferContractor',
  OfferOther: 'OfferOther',
  OfferOrder: 'OfferOrder',
  OfferTermination: 'OfferTermination',
  OfferRentalDays: 'OfferRentalDays',
  OfferDefects: 'OfferDefects',
  Customer: 'Customer',
  CustomerDocument: 'CustomerDocument',
  CustomerCredit: 'CustomerCredit',
  CustomerInsuranceConfirmation: 'CustomerInsuranceConfirmation',
  CustomerSpecialAgreement: 'CustomerSpecialAgreement',
} as const

export type FileFolderType = (typeof FileFolderType)[keyof typeof FileFolderType]

export const FileVisibility = {
  Private: 'Private',
  Public: 'Public',
} as const

export type FileVisibility = (typeof FileVisibility)[keyof typeof FileVisibility]

export const ServiceProjectTimeTrackingStatus = {
  Done: 'Done',
  Open: 'Open',
  Cancelled: 'Cancelled',
} as const

export type ServiceProjectTimeTrackingStatus = (typeof ServiceProjectTimeTrackingStatus)[keyof typeof ServiceProjectTimeTrackingStatus]

export const ServiceProjectTimeTrackingType = {
  ServiceEmployee: 'ServiceEmployee',
  OtherPersons: 'OtherPersons',
} as const

export type ServiceProjectTimeTrackingType = (typeof ServiceProjectTimeTrackingType)[keyof typeof ServiceProjectTimeTrackingType]

export const ServiceProjectTravelCancellationReason = {
  HotelStay: 'HotelStay',
  InternalProject: 'InternalProject',
} as const

export type ServiceProjectTravelCancellationReason = (typeof ServiceProjectTravelCancellationReason)[keyof typeof ServiceProjectTravelCancellationReason]

export const AccessCodeScope = {
  OfferRental: 'OfferRental',
} as const

export type AccessCodeScope = (typeof AccessCodeScope)[keyof typeof AccessCodeScope]

export const ExternalSalesMarketplaceId = {
  MachineryZone: 'MachineryZone',
  ForkliftInternational: 'ForkliftInternational',
  Supralift: 'Supralift',
  Maschinensucher: 'Maschinensucher',
  Mascus: 'Mascus',
} as const

export type ExternalSalesMarketplaceId = (typeof ExternalSalesMarketplaceId)[keyof typeof ExternalSalesMarketplaceId]

export const ExternalSalesMarketplaceExportResult = {
  Success: 'Success',
  PartialSuccess: 'PartialSuccess',
  Failure: 'Failure',
} as const

export type ExternalSalesMarketplaceExportResult = (typeof ExternalSalesMarketplaceExportResult)[keyof typeof ExternalSalesMarketplaceExportResult]

export const RentalPriceModifierUnit = {
  Absolute: 'Absolute',
  Percent: 'Percent',
} as const

export type RentalPriceModifierUnit = (typeof RentalPriceModifierUnit)[keyof typeof RentalPriceModifierUnit]

export const RentalPriceModifierConditionType = {
  Days: 'Days',
  CustomerType: 'CustomerType',
  CustomerTypeAndDays: 'CustomerTypeAndDays',
} as const

export type RentalPriceModifierConditionType = (typeof RentalPriceModifierConditionType)[keyof typeof RentalPriceModifierConditionType]

export const RentalPriceModifierCustomerType = {
  Dealer: 'Dealer',
  DealerAndPartnerLift: 'DealerAndPartnerLift',
  DealerAndSystemLift: 'DealerAndSystemLift',
  EndCustomer: 'EndCustomer',
} as const

export type RentalPriceModifierCustomerType = (typeof RentalPriceModifierCustomerType)[keyof typeof RentalPriceModifierCustomerType]
