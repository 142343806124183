<script setup lang="ts">
import type { ApiLogisticsTaskGetById, LogisticsTourDirections, MachineryCategories, MachineryDrives } from '~/types'

const props = defineProps<{ task: ApiLogisticsTaskGetById }>()

const { isRole } = useAuthorization()

const { openLogisticsIssuanceMachineryList } = useGlobalOpeners()

const type = computed(() => props.task.type)

const protocolName = computed(() => type.value === 'outbound' ? 'Ausgabeprotokoll' : 'Rücknahmeprotokoll')

const {
  logisticsIssuanceAndMachineryDetails,
  logisticsIssuanceAccessoryDetails,
  logisticsReturnAndMachineryDetails,
  logisticsReturnAccessoryDetails,
} = useOneDataColumnConfigs()
const { logisticsDetailsFieldsByMachineCategory } = useFormOptionalConditions()

const machineryPositions = computed(() => props.task.positionsToShip.filter(({ type }) => type === 'machinery'))

const accessoriesPositions = computed(() => props.task.positionsToShip.filter(({ type }) => type !== 'machinery'))
const isSpecialOffer = computed(() => props.task.offer?.type === 'special')
</script>

<template>
  <TheDataCard>
    <template #header>
      <div class="CardHeader">
        <h2>{{ protocolName }}</h2>
        <n-button
          v-if="task && isRole() && !isSpecialOffer"
          @click="openLogisticsIssuanceMachineryList.open({ id: task.id, taskType: task.type as LogisticsTourDirections })"
        >
          <template #icon>
            <Icon name="material-symbols:edit-outline-rounded" />
          </template>Ändern
        </n-button>
      </div>
    </template>
    <div v-if="!task">
      <n-empty class="py-5" description="Es wurde noch keine Aufnahme für die Logistik durchgeführt." />
    </div>
    <!-- Special offer doesn't have issuance data -->
    <div v-else-if="isSpecialOffer || !(type === 'inbound' || type === 'outbound')">
      <n-empty class="py-5" description="Für diesen Spezial-Auftrag ist keine Aufzeichnung erforderlich." />
    </div>
    <div v-else>
      <template v-for="machineryPosition in machineryPositions" :key="machineryPosition.id">
        <TableOneDataColumn
          :config="{
            columns: type === 'outbound' ? logisticsIssuanceAndMachineryDetails : logisticsReturnAndMachineryDetails,
            data: machineryPosition }"
        />
        <template v-if="machineryPosition.machinery">
          <div v-if="machineryPosition.machinery.defects.length > 0">
            <p class="Heading">
              {{ $t('defect.name.plural') }} ({{ machineryPosition.machinery.defects.length }})
            </p>
            <div v-for="(defect, index) in machineryPosition.machinery.defects" :key="index">
              <p class="font-semibold mb-0.5">
                {{ defect.description }}
              </p>
              <FileView
                v-if="defect.photos.length > 0"
                :label="$t('defect.photos.label')"
                :files="defect.photos"
              />
              <n-divider class="!my-1" />
            </div>
          </div>
          <div>
            <h2 class="Heading">
              Fotos
            </h2>
            <FileView
              v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'generalPhotos', machineryPosition.machinery.category as MachineryCategories, machineryPosition.machinery.drive.name as MachineryDrives)"
              label="Allgemeine"
              :files="machineryPosition[`${type}GeneralPhotos`]"
            />
            <FileView
              v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'innerCabinPhotos', machineryPosition.machinery.category as MachineryCategories, machineryPosition.machinery.drive.name as MachineryDrives)"
              label="Kabine innen"
              :files="machineryPosition[`${type}InnerCabinPhotos`]"
            />
            <FileView
              v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'outerCabinPhotos', machineryPosition.machinery.category as MachineryCategories, machineryPosition.machinery.drive.name as MachineryDrives)"
              label="Kabine außen"
              :files="machineryPosition[`${type}OuterCabinPhotos`]"
            />
            <FileView
              v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'tiresPhotos', machineryPosition.machinery.category as MachineryCategories, machineryPosition.machinery.drive.name as MachineryDrives)"
              label="Reifen"
              :files="machineryPosition[`${type}TiresPhotos`]"
            />
            <FileView
              v-if="useShowFieldByFilters(logisticsDetailsFieldsByMachineCategory, 'enginePhotos', machineryPosition.machinery.category as MachineryCategories, machineryPosition.machinery.drive.name as MachineryDrives)"
              label="Motor"
              :files="machineryPosition[`${type}EnginePhotos`]"
            />
          </div>
        </template>
      </template>
      <div v-if="accessoriesPositions.length" class="flex flex-col gap-y-3">
        <h2 class="Heading mt-5">
          Anbaugeräte
        </h2>

        <div v-for="accessory in accessoriesPositions" :key="accessory.id">
          <TableOneDataColumn
            :config="{
              columns: type === 'outbound' ? logisticsIssuanceAccessoryDetails : logisticsReturnAccessoryDetails,
              data: accessory }"
          />
          <div v-if="accessory.machineryAccessory && accessory.machineryAccessory.defects.length > 0">
            <p class="Heading">
              {{ $t('defect.name.plural') }} ({{ accessory.machineryAccessory.defects.length }})
            </p>
            <div v-for="(defect, index) in accessory.machineryAccessory.defects" :key="index">
              <p class="font-semibold mb-0.5">
                {{ defect.description }}
              </p>
              <FileView
                v-if="defect.photos.length > 0"
                :label="$t('defect.photos.label')"
                :files="defect.photos"
              />
              <n-divider class="!my-1" />
            </div>
          </div>
          <FileView
            label="Anbaugerät"
            :files="accessory[`${type}LogisticsAccessoryDetails`]?.photos ?? []"
          />
        </div>
      </div>
    </div>
  </TheDataCard>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
.Heading {
  @apply text-lg font-semibold pb-1;
}
</style>
