import { NButton } from 'naive-ui'

interface ButtonOptions {
  text?: string
  icon: string | null
  quaternary?: boolean
}

export default ({ text, icon, quaternary }: ButtonOptions, onClick: (e: Event) => unknown) => {
  const attributes = {
    type: 'primary',
    size: 'small',
    class: 'grow',
    ghost: !quaternary,
    quaternary,
    onClick: (e: Event) => onClick(e),
  } as const

  const content = () => {
    if (icon) {
      return [useRenderIcon({ name: icon, class: text ? 'mr-1' : '' }), text]
    }

    return text
  }

  return h(NButton, attributes, content)
}
