import type { SuggestionOptions } from '@tiptap/suggestion'
import { VueRenderer } from '@tiptap/vue-3'
import MentionList from './List.vue'

function filterByQuery(query: string) {
  const { $trpc } = useNuxtApp()
  return $trpc.mention.byQuery.query(query)
}

export function createMentionExtension(enabled?: boolean, mentionListId = 'mention-list'): Partial<SuggestionOptions> {
  return {
    char: '@',
    items: ({ query }) => filterByQuery(query),
    render() {
      let component: VueRenderer | undefined

      return {
        onStart: (props) => {
          if (!enabled) {
            return
          }
          component = new VueRenderer(MentionList, {
            props: { ...props, mentionListId },
            editor: props.editor,
          })
        },
        onUpdate: props => component?.updateProps(props),
        onKeyDown: props => component?.ref?.onKeyDown(props),
        onExit: () => component?.destroy(),
      }
    },
  }
}
