<script lang="ts" setup>
import type { ApiCommentGetAll, CreateComment } from '~/types'
import type { ApiCustomerModuleCommentGetAll } from '~/layers/customer-module/types'
import type { CommentReactionType } from '~/prisma/enums'

const props = defineProps<{
  comment: ApiCommentGetAll | ApiCustomerModuleCommentGetAll
  isReadOnly: boolean
  isDeleteDisabled: boolean
  isVisibilityAdjustable: boolean
  entityId: string
}>()
defineEmits<{
  (e: 'create', payload: CreateComment['data']): void
  (e: 'update', commentId: string): void
  (e: 'delete', commentId: string): void
  (e: 'reactionUpsert', commentId: string, type: CommentReactionType): void
  (e: 'reactionDelete', commentId: string): void
}>()
const expandedNames = ref<string[]>([])

// Open replies when new replies are added
watch(() => props.comment.totalRepliesCount, (curr, prev) => {
  if (curr > prev) {
    expandedNames.value = ['replies']
  }
})
</script>

<template>
  <n-collapse v-if="comment.totalRepliesCount > 0" v-model:expanded-names="expandedNames">
    <n-collapse-item
      name="replies"
    >
      <template #header>
        <h3 class="font-semibold">
          {{ $t('comment.replies.showReplies', { count: comment.totalRepliesCount }) }}
        </h3>
      </template>
      <div class="space-y-2 ml-8 bg-white">
        <CommentItem
          v-for="reply of comment.replies"
          v-bind="props"
          :key="reply.id"
          :comment="reply"
          @create="$emit('create', $event)"
          @update="$emit('update', $event)"
          @delete="$emit('delete', $event)"
          @reaction-upsert="(id, type) => $emit('reactionUpsert', id, type)"
          @reaction-delete="$emit('reactionDelete', $event)"
        />
      </div>
    </n-collapse-item>
  </n-collapse>
</template>
