import type { FilePath, UploadedFile } from '~/types'

export type Size = 'Normal' | 'Small'

export function isImageFilePath(filePath: string, extensionsToCheck = ['.jpg', '.jpeg', '.png', '.webp']) {
  const extension = getFileExtension(filePath)

  return extensionsToCheck.includes(extension)
}

export function getUploadedFile(file: FilePath): UploadedFile {
  return {
    filekey: file.path,
    status: 'success',
    hasPreview: isImageFilePath(file.path),
  }
}

// https://github.com/vidstack/player/blob/e0b6f303fc133ef76e794815d1712d7b7a7ee61f/packages/vidstack/src/providers/youtube/utils.ts#L1
const youtubeRegex = /(?:youtu\.be|youtube|youtube\.com|youtube-nocookie\.com)\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=)?((?:\w|-){11})/

// https://github.com/vidstack/player/blob/e0b6f303fc133ef76e794815d1712d7b7a7ee61f/packages/vidstack/src/providers/vimeo/utils.ts#L3
const vimeoRegex = /(?:https:\/\/)?(?:player\.)?vimeo(?:\.com)?\/(?:video\/)?(\d+)(?:\?hash=.*)?/

function getExternalVideoProviderAndId(src: string) {
  const youtubeId = src.match(youtubeRegex)?.[1]
  if (youtubeId) {
    return ['youtube', youtubeId] as const
  }

  const vimeoId = src.match(vimeoRegex)?.[1]
  if (vimeoId) {
    return ['vimeo', vimeoId] as const
  }

  return [undefined, undefined] as const
}

export function getVideoProvider(src: string | null) {
  if (!src) {
    return undefined
  }
  const [provider, _] = getExternalVideoProviderAndId(src)
  return provider
}

export function normalizeExternalVideoURL(src: string) {
  const [provider, id] = getExternalVideoProviderAndId(src)
  if (!provider) {
    return undefined
  }

  const prefix = provider === 'youtube' ? 'https://www.youtube.com/watch?v=' : 'https://vimeo.com/'
  return `${prefix}${id}`
}
