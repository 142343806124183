<script setup lang="ts">
const props = defineProps<{ machineryId?: string, isExpanded?: boolean }>()

const selectedDuration = useRouteQueryAsNumberRange('machineryInvoicingDateRange')

const chartContainerRef = ref<HTMLElement | null>(null)
const { isIntersecting } = useIsIntersecting(chartContainerRef)

const { reporting } = useQuery()
const { data: machineryRevenueChart, isFetching, suspense, refetch, isPending } = reporting.getChartRevenueMachinery(selectedDuration, props.machineryId, isIntersecting)

const loading = computed(() => isFetching.value || isPending.value)

onServerPrefetch(async () => {
  if (isIntersecting.value) {
    await suspense()
  }
})
</script>

<template>
  <ReportingCard name="machineryRevenue" :header="$t('reporting.machineryRevenue.title')">
    <template v-if="isExpanded" #header-extra>
      <n-button :disabled=" loading" @click="() => refetch()">
        <template #icon>
          <Icon name="material-symbols:refresh" />
        </template>
      </n-button>
    </template>

    <template #controls>
      <TheDurationPicker v-model="selectedDuration" class="mb-2" />
      <p class="mb-2">
        Der hier dargestellte Umsatz wird aus offenen und bezahlten Rechnungen berechnet, nachträglich erstellte Rechnungskorrekturen werden nicht berücksichtigt. Zur Berechnung werden ausschließlich die Maschinen-Positionen berücksichtigt. Die Berechnung basiert auf den jeweils von der Maschine abgerechneten Tagen.
      </p>
      <p class="mb-2 font-semibold">
        Gesamt-Maschinen-Umsatz im Zeitraum: {{ useDisplayNumberAsCurrency(machineryRevenueChart?.additionalData?.totalRevenue ?? 0) }}
      </p>
      <p>Umsatz pro Tag:</p>
    </template>

    <div ref="chartContainerRef" class="min-h-[400px]">
      <n-skeleton v-if="loading" class="rounded" width="100%" height="400px" :sharp="false" />
      <ReportingChart v-else-if="machineryRevenueChart" v-bind="machineryRevenueChart" />
    </div>
  </ReportingCard>
</template>
