<script setup lang="ts">
import type { ApiInputInvoiceGetAllOfferForInvoiceById } from '~/types'

const props = defineProps<{
  customerId?: string
  position: ApiInputInvoiceGetAllOfferForInvoiceById['positions'][0]
}>()

const machineryPricingData = computed(() => ({
  machineryId: props.position.machineryId ?? '',
  rentalDurationInDays: props.position.quantity,
  customerId: props.customerId || undefined,
}))

const { rentalPricing } = useQuery()
const { data: machineryPricing, error } = rentalPricing.calculateMachineryPrice(machineryPricingData, !!machineryPricingData.value.machineryId)
</script>

<template>
  <div v-if="props.position.machineryId" class="flex gap-1">
    {{ $t('rentalPricing.offerPositionPopover.actual.title') }}: {{ position.title }}
    <n-popover v-if="machineryPricing" :duration="500">
      <template #trigger>
        <span class="trigger !mb-0 formkit-label">
          <Icon name="material-symbols:help-rounded" />
        </span>
      </template>

      <p class="font-bold">
        {{ $t('rentalPricing.offerPositionPopover.actual.title') }}
      </p>

      <OfferCreateOrEditPositionPriceCalculationInformation
        :pricing="machineryPricing"
      />
    </n-popover>

    <n-popover v-else-if="error">
      <template #trigger>
        <span class="trigger formkit-label">
          <Icon name="material-symbols:warning" class="text-red-500" />
        </span>
      </template>

      <p class="font-bold">
        {{ $t('rentalPricing.offerPositionPopover.error.title') }}
      </p>

      {{ error }}
    </n-popover>
  </div>
</template>

<style lang="css" scoped>
.trigger {
  @apply flex items-center gap-1 mb-1 font-bold text-sm;
}
</style>
