/**
 * Plugin copied over from https://github.com/nuxt/nuxt/pull/28160 as we cannot upgrade to nuxt 3.14 yet.
 *
 * TODO: Remove this when upgrading to Nuxt >3.14 and instead use `emitChunkError: 'automatic-immediate'`, see https://nuxt.com/docs/guide/going-further/experimental-features#emitroutechunkerror
 *
 * See https://github.com/sidestream-tech/hanselmann-os/issues/3830 for the issue that is planned to remove this plugin.
 */

function reloadNuxtApp_(path: string) {
  reloadNuxtApp({ persistState: true, path })
}

// See https://github.com/nuxt/nuxt/issues/23612 for more context
export default defineNuxtPlugin({
  name: 'nuxt:chunk-reload-immediate',
  setup(nuxtApp) {
    // Remember `to.path` when navigating to a new path: A `chunkError` may occur during navigation, we then want to then reload at `to.path`
    let currentlyNavigationTo: null | string = null
    addRouteMiddleware((to) => {
      currentlyNavigationTo = to.path
    })

    // Reload when a `chunkError` is thrown
    nuxtApp.hook('app:chunkError', () => reloadNuxtApp_(currentlyNavigationTo ?? nuxtApp._route.path))

    // Reload when the app manifest updates
    nuxtApp.hook('app:manifest:update', () => reloadNuxtApp_(nuxtApp._route.path))
  },
})
