import type { CommentReactionType } from '~/prisma/enums'

// Emojis can be checked on: https://emojipedia.org/
export const commentReactionConfig: Record<CommentReactionType, string> = {
  ThumbsUp: '👍',
  ThumbsDown: '👎',
  RedHeart: '❤️',
}

export default function (reaction: CommentReactionType) {
  return commentReactionConfig[reaction]
}
