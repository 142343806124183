<script setup lang="ts">
import { joinURL, withQuery } from 'ufo'

const props = defineProps<{ payload: { id: string, description: string } }>()
defineEmits<{
  (e: 'close'): void
}>()

const language = ref<string>('de')

function openPDF() {
  const url = withQuery(
    joinURL('/api/pdf/quickSale/details/', props.payload.id),
    { language: language.value },
  )

  window.open(url, '_blank')
}
</script>

<template>
  <ThePopup
    :title="$t('quickSale.nameWithDescription', { description: payload.description })"
    show
    @close="$emit('close')"
  >
    <QuickSalePdfLanguageSelector v-model="language" />
    <n-button type="primary" @click="openPDF">
      {{ $t('button.openPdf') }}
    </n-button>
  </ThePopup>
</template>
