export default defineNuxtPlugin(() => {
  if (!import.meta.client) {
    return
  }

  // Watches when new cookie consent was given and refreshes the Nuxt app to ensure all components know this
  window.addEventListener('ccm19EmbeddingAccepted', () => reloadNuxtApp())

  // Watches and ensures that if no consent for Google Tags is given no data is sent
  const { isEmbeddingAccepted } = useCookieConsent()
  const isGTagsAccepted = isEmbeddingAccepted(EmbeddingType.GTM)
  useGTags().enable(isGTagsAccepted)
})
