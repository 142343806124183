interface Embedding {
  id: string
  name: string
}

export enum EmbeddingType {
  YouTube = 'YouTube',
  Vimeo = 'Vimeo',
  GTM = 'GTM',
}

interface CCM {
  ucid: string
  version: string
  consent: boolean
  consentRequired: boolean
  fullConsentGiven: boolean
  acceptedCookies: string[]
  acceptedEmbeddings: Embedding[]
  closeControlPanel: () => void
  closeDetailsWindow: () => void
  closeWidget: () => void
  openControlPanel: () => void
  openDetailsWindow: () => void
  openDetailsWindowAtEmbedding: () => void
  openDetailsWindowAtPurpose: () => void
  openWidget: () => void
}

export default () => {
  const ccm = computed(() => {
    if (!import.meta.client || !window) {
      return undefined
    }
    // @ts-expect-error CCM is not types inside the global window
    return globalThis.CCM as CCM | undefined
  })

  function isCookieAccepted(cookieName: string) {
    if (!ccm.value) {
      return false
    }
    return ccm.value.acceptedCookies.includes(cookieName)
  }

  function isEmbeddingAccepted(type: EmbeddingType) {
    function checkEmbeddings(name: string): boolean {
      if (!ccm.value) {
        return false
      }
      return ccm.value.acceptedEmbeddings.some(it => it.name === name)
    }

    switch (type) {
      case EmbeddingType.YouTube: return checkEmbeddings('YouTube Video')
      case EmbeddingType.Vimeo: return checkEmbeddings('Vimeo')
      case EmbeddingType.GTM: return checkEmbeddings('Google Tag Manager')
    }
  }

  function openWidget() {
    if (!ccm.value) {
      return false
    }
    return ccm.value.openWidget()
  }

  function openControlPanel() {
    if (!ccm.value) {
      return false
    }
    return ccm.value.openControlPanel()
  }

  return {
    ccm,
    isCookieAccepted,
    isEmbeddingAccepted,
    openWidget,
    openControlPanel,
  }
}
