import { useRouteQuery } from '@vueuse/router'
import { changeLocale } from '@formkit/vue'

export default function () {
  const i18n = useI18n()
  const routeQuery = useRouteQuery('lang')

  const availableLocales = i18n.locales.value.filter(lang => lang.code !== 'de_marketing_environment')
  const localeSelectOptions = availableLocales.map(lang => ({
    label: lang.name,
    value: lang.code,
  }))

  const currentLocaleCode = computed({
    get: () => i18n.locale.value,
    set: (newLocale) => {
      // TODO: fix the ts/no-floating-promises rule (issue link: https://github.com/sidestream-tech/hanselmann-os/issues/4369)
      // eslint-disable-next-line ts/no-floating-promises
      i18n.setLocale(newLocale)
      changeLocale(newLocale)
      routeQuery.value = newLocale
    },
  })

  const currentLocaleProperties = i18n.localeProperties

  // Set initial locale once from URL, if is present & valid locale
  if (routeQuery.value) {
    const parsedLocale = supportedLocaleSchema.safeParse(routeQuery.value)
    if (parsedLocale.success) {
      currentLocaleCode.value = parsedLocale.data
    }
  }

  return { currentLocaleCode, currentLocaleProperties, localeSelectOptions }
}
