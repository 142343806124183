<script setup lang="ts">
const { t: $t } = useI18n()

import { joinURL, withQuery } from 'ufo'

const { openOfferPrintPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()
const offerId = computed(() => popupData.value?.offerId)

const { offer: queryOffer } = useQuery()
const { data: offer, isLoading: isLoadingOffer } = queryOffer.byId(offerId)

const { urlWithLocale } = useUrlWithLocale()

const outboundLogisticsTasks = computed(() => {
  if (!offer.value) {
    return []
  }

  return offer.value.logisticsTasks.filter(t => t.type === 'outbound')
})

const inboundLogisticsTasks = computed(() => {
  if (!offer.value) {
    return []
  }

  return offer.value.logisticsTasks.filter(t => t.type === 'inbound')
})

function navigateToDeliveryNotePDF() {
  if (!popupData.value) {
    return
  }

  const customerLocale = offer.value?.customer?.language || 'de'
  window.open(`/api/pdf/offer/details/${popupData.value.offerCuid}?type=deliveryNote&locale=${customerLocale}`, '_blank')
}

function navigateToDeliveryPDF(logisticsTaskId: string) {
  window.open(`/api/pdf/logisticsTask/delivery/${logisticsTaskId}`, '_blank')
}

function navigateToProjectReport(offerCuid: string) {
  window.open(`/api/pdf/project/completion/${offerCuid}`, '_blank')
}

function navigateToIssuancePDF(logisticsTaskId: string) {
  window.open(`/api/pdf/logisticsTask/issuance/${logisticsTaskId}`, '_blank')
}

function navigateToLogisticsDetailsPage(logisticsTaskId: string, type: 'issued' | 'collected') {
  window.open(`/logistics-details/${type}/${logisticsTaskId}`, '_blank')
}

function openOfferPDFUrl(offerPDFUrl?: string | null) {
  if (!offerPDFUrl) {
    return
  }

  window.open(withQuery(offerPDFUrl, { inline: true }), '_blank')
}

function openOfferPDF() {
  if (offer.value?.type === 'service-project' && offer.value.isCompleted && offer.value.completedOrderPdf) {
    openOfferPDFUrl(offer.value.completedOrderPdf)
  } else if (popupData.value) {
    const url = urlWithLocale(joinURL('/api/pdf/offer/details/', popupData.value.offerCuid), getLocale(offer.value?.customer?.language))
    window.open(url, '_blank')
  }
}

const hasContactPerson = computed(() => {
  if (!offer.value) {
    return false
  }

  return Boolean(offer.value.contactPerson)
})

const isInquiry = computed(() => offer.value?.status === 'inquiry')

const translatedOfferStatus = computed(() => $t(`offer.status.${offer.value?.status}`))

const showDraftPdfButton = computed(() => {
  if (!offer.value || offer.value.type === 'service-project') {
    return false
  }

  return ['inquiry', 'order', 'invoiced'].includes(offer.value.status)
})
</script>

<template>
  <ThePopup v-if="popupData" :is-loading="isLoadingOffer" :show="Boolean(popupData)" :title="`Dokumenten-Übersicht (${popupData.offerId})`" @close="closePopup">
    <div v-if="offer" class="CustomTable">
      <n-alert v-if="!hasContactPerson" class="mb-3" type="warning">
        Besteller muss ausgewählt werden, um eine {{ translatedOfferStatus }} PDF zu erstellen
      </n-alert>
      <div v-if="['inquiry', 'order', 'invoiced'].includes(offer.status)" class="Row">
        <div class="Label space-x-1">
          <span>{{ $t('offer.status.offer') }}</span>
          <n-popover>
            <template #trigger>
              <div>
                <Icon name="material-symbols:info-outline-rounded" size="18" />
              </div>
            </template>
            <span>Falls vorhanden kann hier die Angebots-PDF vom Zeitpunkt der Umwandlung von Angebot zu Auftrag heruntergeladen werden.</span>
          </n-popover>
        </div>
        <div>
          <n-button
            :disabled="!offer.offerPdf"
            @click="openOfferPDFUrl(offer.offerPdf)"
          >
            Als PDF laden
          </n-button>
        </div>
      </div>
      <div v-if="showDraftPdfButton" class="Row">
        <div class="Label space-x-1">
          <span>{{ $t('offer.status.draft') }}</span>
          <n-popover>
            <template #trigger>
              <div>
                <Icon name="material-symbols:info-outline-rounded" size="18" />
              </div>
            </template>
            <span>{{ $t("offer.print.draftPdf.information") }}</span>
          </n-popover>
        </div>
        <div>
          <n-button
            :disabled="offer.draftPdf.length === 0"
            @click="openOfferPDFUrl(offer.draftPdf[0].path)"
          >
            Als PDF laden
          </n-button>
        </div>
      </div>
      <div class="Row">
        <div class="Label space-x-1">
          <span>{{ translatedOfferStatus }}</span>
          <n-popover v-if="offer.isCompleted">
            <template #trigger>
              <div>
                <Icon name="material-symbols:info-outline-rounded" size="18" />
              </div>
            </template>
            <span>Falls vorhanden kann hier die Auftrag-PDF vom Zeitpunkt der Erstellung des Abschlussberichts heruntergeladen werden.</span>
          </n-popover>
        </div>
        <div>
          <n-button
            :disabled="!hasContactPerson"
            @click="openOfferPDF"
          >
            Als PDF laden
          </n-button>
        </div>
      </div>
      <template v-if="offer.type === 'service-project'">
        <div class="Row">
          <div class="Label">
            Abschlussbericht
          </div>
          <div v-if="offer.isCompleted">
            <n-button
              @click="navigateToProjectReport(offer.cuid)"
            >
              Als PDF laden
            </n-button>
          </div>
          <div v-else>
            <span class="text-gray-500">Dieses Projekt ist noch nicht abgeschlossen</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="Row">
          <div class="Label">
            {{ $t('pdf.offer.status.deliveryNote') }}
          </div>
          <div>
            <n-button
              :disabled="!hasContactPerson || isInquiry"
              @click="navigateToDeliveryNotePDF"
            >
              {{ $t('button.loadAsPdf') }}
            </n-button>
          </div>
        </div>
        <div class="Row">
          <div class="Label">
            {{ $t('pdf.offer.status.deliveryNoteWithDeliveryProtocol') }}
          </div>
          <div v-if="outboundLogisticsTasks.length" class="flex flex-col gap-y-2">
            <div v-for="task of outboundLogisticsTasks" :key="task.id">
              <n-button
                :disabled="!task.positionsToShip.some(p => p.isDelivered)"
                @click="navigateToDeliveryPDF(task.id)"
              >
                {{ $t('button.loadAsPdfWithName', { name: task.id }) }}
              </n-button>
            </div>
          </div>
          <div v-else>
            <span class="text-gray-500">Keine Logistik gefunden</span>
          </div>
        </div>
        <div class="Row">
          <div class="Label">
            Ausgabeprotokoll
          </div>
          <div v-if="outboundLogisticsTasks.length" class="flex flex-col gap-y-2">
            <div v-for="task of outboundLogisticsTasks" :key="task.id" class="flex gap-2 flex-wrap">
              <n-button
                :disabled="!task.positionsToShip.every(p => p.isIssued)"
                @click="navigateToIssuancePDF(task.id)"
              >
                {{ task.id }} als PDF laden
              </n-button>
              <n-button
                :disabled="!task.positionsToShip.every(p => p.isIssued)"
                @click="navigateToLogisticsDetailsPage(task.cuid, 'issued')"
              >
                Seite laden
              </n-button>
            </div>
          </div>
          <div v-else>
            <span class="text-gray-500">Keine ausgabe Logistik gefunden</span>
          </div>
        </div>
        <div class="Row">
          <div class="Label">
            Rücknahmeprotkoll
          </div>
          <div v-if="inboundLogisticsTasks.length" class="flex flex-col gap-x-2">
            <div v-for="task of inboundLogisticsTasks" :key="task.id" class="flex gap-2 flex-wrap">
              <n-button
                :disabled="!task.positionsToShip.every(p => p.isReturned)"
                @click="navigateToIssuancePDF(task.id)"
              >
                {{ task.id }} als PDF laden
              </n-button>
              <n-button
                :disabled="!task.positionsToShip.every(p => p.isIssued)"
                @click="navigateToLogisticsDetailsPage(task.cuid, 'collected')"
              >
                Seite laden
              </n-button>
            </div>
          </div>
          <div v-else>
            <span class="text-gray-500">Bisher keine Rücknahme gefunden</span>
          </div>
        </div>
      </template>
    </div>
  </ThePopup>
</template>

<style scoped>
  .CustomTable {
    @apply flex flex-col gap-y-2
  }
  .CustomTable .Row {
    @apply flex flex-row
  }
  .CustomTable .Label {
    @apply flex w-[150px] font-semibold shrink-0 mr-2
  }
  .Row + .Row {
    @apply border-t border-gray-200 pt-2
  }
</style>
