<script setup lang="ts">
const { t: $t } = useI18n()

import type { ApiPublicMachineryAccessoryGetById, MachineryAccessoryCategory } from '~/types'

const props = withDefaults(
  defineProps<{
    machineryAccessoryId: string
    forPartner?: boolean
  }>(),
  {
    forPartner: false,
  },
)

const { setLocale } = useI18n()

if (props.forPartner) {
  setLocale('de')
}

const { machineryAccessory: queryMachineryAccessory } = useQuery()
const { data: machineryAccessory, isLoading: isLoadingMachineryAccessory, error: errorMachineryAccessory } = queryMachineryAccessory.public.byId(ref(props.machineryAccessoryId))

const { machineryAccessoryPublicFieldsByCategory: showMachineryAccessoryPublicFieldsByCategory } = useFormOptionalConditions()

const machineryAccessoryPublicCreationInformation: OneDataColumn<ApiPublicMachineryAccessoryGetById>[] = [
  {
    title: $t('common.field.category.name'),
    key: 'category',
    render: ({ category }) => $t(`machineryAccessory.category.${category}`),
  },
  {
    title: $t('machinery.field.producerCompanyName.name'),
    key: 'producerCompanyName',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.producerCompanyName.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('common.field.type.name'),
    key: 'typeName',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.typeName.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machinery.field.yearBuilt.name'),
    key: 'yearBuilt',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.yearBuilt.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machinery.field.liftingWeight.name'),
    key: 'liftingWeight',
    render: ({ liftingWeight }) => liftingWeight?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.liftingWeight.includes(category as MachineryAccessoryCategory),
    hideIfNullish: true,
  },
  {
    title: $t('common.field.length.name'),
    key: 'lengthInMillimeters',
    render: ({ lengthInMillimeters }) => lengthInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.lengthInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('common.field.width.name'),
    key: 'widthInMillimeters',
    render: ({ widthInMillimeters }) => widthInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.widthInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('common.field.height.name'),
    key: 'heightInMillimeters',
    render: ({ heightInMillimeters }) => heightInMillimeters?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.heightInMillimeters.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.distanceInnerEdgeToInnerEdge.name'),
    key: 'distanceInnerEdgeToInnerEdge',
    render: ({ distanceInnerEdgeToInnerEdge }) => distanceInnerEdgeToInnerEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.distanceInnerEdgeToInnerEdge.includes(category as MachineryAccessoryCategory),
    hideIfNullish: true,
  },
  {
    title: $t('machineryAccessory.field.distanceOuterEdgeToOuterEdge.name'),
    key: 'distanceOuterEdgeToOuterEdge',
    render: ({ distanceOuterEdgeToOuterEdge }) => distanceOuterEdgeToOuterEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.distanceOuterEdgeToOuterEdge.includes(category as MachineryAccessoryCategory),
    hideIfNullish: true,
  },
  {
    title: $t('machineryAccessory.field.retractionLugsWidth.name'),
    key: 'retractionLugsWidth',
    render: ({ retractionLugsWidth }) => retractionLugsWidth?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsWidth.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.retractionLugsHeight.name'),
    key: 'retractionLugsHeight',
    render: ({ retractionLugsHeight }) => retractionLugsHeight?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsHeight.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.retractionLugsDistanceInnerEdgeToInnerEdge.name'),
    key: 'retractionLugsDistanceInnerEdgeToInnerEdge',
    render: ({ retractionLugsDistanceInnerEdgeToInnerEdge }) => retractionLugsDistanceInnerEdgeToInnerEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsDistanceInnerEdgeToInnerEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.retractionLugsDistanceOuterEdgeToOuterEdge.name'),
    key: 'retractionLugsDistanceOuterEdgeToOuterEdge',
    render: ({ retractionLugsDistanceOuterEdgeToOuterEdge }) => retractionLugsDistanceOuterEdgeToOuterEdge?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.retractionLugsDistanceOuterEdgeToOuterEdge.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.lengthLeverLink.name'),
    key: 'lengthLeverLink',
    render: ({ lengthLeverLink }) => lengthLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.lengthLeverLink.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.widthLeverLink.name'),
    key: 'widthLeverLink',
    render: ({ widthLeverLink }) => widthLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.widthLeverLink.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.powerLeverLink.name'),
    key: 'powerLeverLink',
    render: ({ powerLeverLink }) => powerLeverLink?.toLocaleString('de-DE'),
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.powerLeverLink.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.description.name'),
    key: 'description',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.description.includes(category as MachineryAccessoryCategory),
  },
  {
    title: $t('machineryAccessory.field.recordingClass.name'),
    key: 'fem',
    isVisible: ({ category }) => showMachineryAccessoryPublicFieldsByCategory.fem.includes(category as MachineryAccessoryCategory),
    hideIfNullish: true,
  },
]
</script>

<template>
  <div>
    <div v-if="!forPartner" class="flex max-sm:flex-col sm:items-center sm:mb-2 relative">
      <TheDataCard class="mb-2">
        <TheLogo />
      </TheDataCard>
      <TheLanguageSelector class="sm:absolute sm:left-4 max-sm:m-2" />
    </div>
    <div v-if="!machineryAccessory && !isLoadingMachineryAccessory">
      <TheDataCard>
        Dieses Anbaugerät existiert nicht oder wurde noch nicht bestätigt.
      </TheDataCard>
    </div>
    <div v-else class="flex flex-col w-full gap-y-2">
      <TheDataCard :title="$t('machineryAccessory.creationInformation')" :is-loading="isLoadingMachineryAccessory" :error="errorMachineryAccessory">
        <TableOneDataColumn :config="{ data: machineryAccessory, columns: machineryAccessoryPublicCreationInformation }" />
        <FileView :label="$t('common.photos')" :files="machineryAccessory?.photos ?? []" collapsed-by-default="if-empty" />
      </TheDataCard>
    </div>
  </div>
</template>
