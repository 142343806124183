import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ior_a6a15bdc8c8898b397c87dda83052e05/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}