import { withQuery } from 'ufo'

/**
 * Returns the provided url with the currently detected locale attached as a query param, e.g., `?locale=de`.
 *
 * Optionally, a `locale` can be passed in that'll take precedence over the detected one.
 *
 * The `useI18nLocale` composable is called outside of the actual function as it can only be called from within the
 * setup function and not on every call of urlWithLocale, otherwise it throws an error.
 *
 * Fixed in PR: https://github.com/sidestream-tech/hanselmann-os/pull/3881
 */
export default () => {
  const { currentLocaleCode } = useI18nLocale()

  function urlWithLocale(url: string, locale?: string) {
    return withQuery(url, { locale: locale ?? currentLocaleCode.value })
  }

  return { urlWithLocale }
}
