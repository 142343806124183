<script setup lang="ts">
import type { ApiLogisticsTaskGetById } from '~/types'

type Position = ApiLogisticsTaskGetById['positionsToShip'][number]

const props = defineProps<{
  positionsToShip: Position[]
  type: 'machinery' | 'machineryAccessory'
}>()

interface LogisticsTaskPositionTextPayload {
  weight?: number | null
  length?: number | null
  width?: number | null
  height?: number | null
}

const positions = computed(() =>
  props.positionsToShip.filter(position => position.type === props.type),
)

function extractDimensions(position: Position, type: 'machinery' | 'machineryAccessory'): LogisticsTaskPositionTextPayload {
  if (type === 'machinery') {
    return {
      width: position.machinery?.receptionMachinery?.overallWidthInMillimeters,
      height: position.machinery?.receptionMachinery?.overallHeightInMillimeters ?? position.machinery?.receptionMachinery?.mastOverallHeightInMillimeters,
      weight: position.machinery?.receptionMachinery?.weight,
      length: position.machinery?.receptionMachinery?.totalLengthInMillimeters,
    }
  }
  // type = 'machineryAccessory'
  return {
    width: position.machineryAccessory?.widthInMillimeters,
    height: position.machineryAccessory?.heightInMillimeters,
    weight: position.machineryAccessory?.weightInKilograms,
    length: position.machineryAccessory?.lengthInMillimeters,
  }
}

function getPositionDimensionsText(payload: LogisticsTaskPositionTextPayload) {
  const { weight, length, width, height } = payload
  const { t } = useI18n()
  const result: string[] = []

  if (typeof weight === 'number') {
    result.push(`${weight.toLocaleString('de-DE')}${t('unit.kg')}`)
  }
  if (typeof length === 'number') {
    result.push(t('logisticsTask.position.lengthInMm', {
      length: length.toLocaleString('de-DE'),
    }))
  }
  if (typeof width === 'number') {
    result.push(t('logisticsTask.position.widthInMm', {
      width: width.toLocaleString('de-DE'),
    }))
  }
  if (typeof height === 'number') {
    result.push(t('logisticsTask.position.heightInMm', {
      height: height.toLocaleString('de-DE'),
    }))
  }

  return result.length > 0 ? `(${result.join(', ')})` : ''
}

const getLogisticsTaskPositionText = computed(() => {
  return positions.value.map((position) => {
    const dimensions = extractDimensions(position, props.type)
    return getPositionDimensionsText(dimensions)
  })
})
</script>

<template>
  <div v-if="positions.length">
    <div
      v-for="(position, index) in positions"
      :key="position.id || index"
      class="flex flex-col mt-2"
    >
      <NuxtLink
        v-if="type === 'machinery'"
        class="hover:text-primary underline"
        :href="`/machinery/${position.machineryId}`"
      >
        <Icon class="mr-1" :name="useBrandIcons().machinery" />
        <span>{{ position.title }}</span>
      </NuxtLink>

      <NuxtLink
        v-else
        class="hover:text-primary underline"
        :href="`/machinery-accessory/${position.machineryAccessoryId}`"
      >
        <Icon class="mr-1" :name="useBrandIcons().machinery" />
        <span>{{ position.machineryAccessoryId }}</span>
      </NuxtLink>

      <div>
        {{ getLogisticsTaskPositionText[index] }}
      </div>
    </div>
  </div>
  <div v-else>
    N/A
  </div>
</template>
