<script lang="ts" setup>
const { data: popupData, close } = useGlobalOpeners().openInboundLogisticsTaskTransportationDocumentPopup

const { data: inboundLogisticsTask } = useQuery().inboundLogisticsTask.byId(computed(() => popupData.value?.id))
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const updateTransportationDocuments = useMutation({
  mutationFn: $trpc.externalStorage.inboundLogisticsTask.updateTransportationDocuments.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Einlagerung konnte nicht bearbeitet werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['externalStoragePosition'] }),
      queryClient.invalidateQueries({ queryKey: ['inboundLogisticsTask'] }),
    ])
    close()
  },
})
</script>

<template>
  <ThePopup
    v-if="popupData"
    :show="true"
    :title="`Lieferung ${popupData.id} einlagern`"
    width="900px"
    @close="close"
  >
    <FormKit
      v-if="inboundLogisticsTask"
      type="form"
      :value="inboundLogisticsTask"
      submit-label="Speichern"
      @submit="updateTransportationDocuments.mutate"
    >
      <FormKitFileDropzone
        name="transportationDocuments"
        :label="`Transportpapiere${popupData.isReadOnly ? '' : ' (min 1)'}`"
        validation="required|min:1"
        :read-only="popupData.isReadOnly"
      />
    </FormKit>
  </ThePopup>
</template>
