<script setup lang="ts">
const { t: $t } = useI18n()

import { hasPositionPermission } from './CreateOrEdit/Position/positionInputPermissions'
import type { ApiInputOfferUpdateReleaseToCustomer, OfferType } from '~/types'

const { openOfferPrintPopup, openInvoicingPopup, openOfferNextStepsPopup: { data: payload, close: closePopup } } = useGlobalOpeners()
const { username, role } = useAuthorization()
const translatedOfferStatus = (payload.value?.status && $t(`offer.status.${payload.value?.status}`)) ?? $t('offer.status.offer')

const { offer: queryOffer } = useQuery()
const { data: offer, isLoading } = queryOffer.byId(computed(() => payload.value?.offerId))

const { canAccessPage } = useAuthorization()

const { urlWithLocale } = useUrlWithLocale()

const canSeePDFs = computed(() => hasPositionPermission('pdfs', role.value, offer.value?.type as OfferType))
const canGenerateProformPayments = computed(() => canAccessPage('/invoice') && offer.value?.type === 'sale' && offer.value?.status !== 'offer')
const releaseOfferToCustomerConfirmPayload = ref<ApiInputOfferUpdateReleaseToCustomer | null>(null)
</script>

<template>
  <ThePopup v-if="payload" :show="Boolean(payload)" title="Nächste Schritte einleiten" @close="closePopup">
    <div v-if="isLoading">
      Daten werden für nächste Schritte geladen...
    </div>
    <div v-else-if="offer" class="w-1/2 mb-4 flex flex-col gap-y-2">
      <div v-if="canSeePDFs" class="flex items-center justify-between">
        <span>PDF drucken</span>
        <n-button
          @click="openOfferPrintPopup.open({
            type: offer.type as OfferType,
            offerId: offer.id,
            offerCuid: offer.cuid,
          })"
        >
          <Icon
            name="material-symbols:description-outline-rounded"
            size="20"
            class="text-blue-900 cursor-pointer"
          />
        </n-button>
      </div>
      <div v-if="offer.type === 'rental' && offer.status === 'offer' && !offer.offerReleasedToCustomerAt" class="flex items-center justify-between">
        <span>{{ $t('offer.button.releaseOfferToCustomer') }}</span>
        <n-button
          @click="releaseOfferToCustomerConfirmPayload = {
            id: offer.id,
            release: true,
          }"
        >
          <Icon
            name="material-symbols:check-circle-outline"
            size="20"
            class="text-blue-900 cursor-pointer"
          />
        </n-button>
      </div>
      <div class="flex items-center justify-between">
        <span>{{ translatedOfferStatus }} per E-Mail verschicken</span>
        <n-button @click="navigateTo(getMailtoForOfferEmail(offer, username, urlWithLocale), { external: true })">
          <Icon
            name="material-symbols:mail-outline-rounded"
            size="20"
            class="text-blue-900"
          />
        </n-button>
      </div>
      <div v-if="canGenerateProformPayments" class="flex items-center justify-between">
        <span>Proforma Rechnung für Auftrag erstellen</span>
        <n-button
          @click="openInvoicingPopup.open({ offerId: offer.id, type: 'proformapayment' })"
        >
          <Icon
            :name="useBrandIcons().invoice"
            size="20"
            class="text-blue-900"
          />
        </n-button>
      </div>
      <n-button type="primary" @click="closePopup">
        Vorgang beenden
      </n-button>
    </div>
  </ThePopup>
  <OfferPopupReleaseToCustomer
    v-model="releaseOfferToCustomerConfirmPayload"
  />
</template>
