<script setup lang="ts">
import type { ApiLogisticsTaskGetById } from '~/types'

const { openLogisticsTaskDocumentUploadPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const { logisticsTask: queryLogisticsTask } = useQuery()

const logisticsTaskId = computed(() => popupData.value?.id)
const { data: logisticsTask } = queryLogisticsTask.byIdOrThrow(logisticsTaskId)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const update = useMutation({
  mutationFn: $trpc.logisticsTask.updateDocument.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Logistik konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['logisticsTask'] })
    closePopup()
    notification.success({ title: 'Der Logistik wurde erfolgreich aktualisiert', duration: 4500 })
  },
})
</script>

<template>
  <ThePopup v-if="popupData && logisticsTask" :show="Boolean(logisticsTask)" title="Logistik-Dokumente" @close="closePopup">
    <FormKit :value="logisticsTask" type="form" @submit="(payload: ApiLogisticsTaskGetById) => update.mutate(payload)">
      <FormKitFileDropzone name="documentFiles" />
    </FormKit>
  </ThePopup>
</template>
