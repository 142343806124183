<script setup lang="ts">
import { computePositionTimeline } from '~/components/Logistics/computeStatus'

const props = defineProps<{
  offerType: 'sale' | 'rental'
  positionId: string
}>()

const { offerPosition: queryOfferPosition } = useQuery()
const { data: offerPosition } = queryOfferPosition.byIdForStatusTimeline(props.positionId)

const timelineItems = computed(() => {
  if (!offerPosition.value) {
    return undefined
  }

  return Object.values(computePositionTimeline(props.offerType, offerPosition.value))
})
</script>

<template>
  <TheStatusTimeline :timeline-items="timelineItems" />
</template>
