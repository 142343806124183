<script setup lang="ts">
import { createInput } from '@formkit/vue'
import AddressPopup from './CreateInputBase/AddressPopup.vue'

const addressPopup = createInput(AddressPopup, { props: [
  'placeholder',
  'disabled',
  'initialValue',
  'showMapView',
  'disableMaxWidth',
  'label',
] })
</script>

<template>
  <FormKit :type="addressPopup" />
</template>
