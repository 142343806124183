<script lang="ts" setup>
import type { SelectOption } from 'naive-ui'
import type { Address } from '~/types'

const props = defineProps<{
  context: {
    value: Address | undefined | null
    node: { input: (v: Address | undefined | null) => unknown }
    placeholder?: string
    initialValue?: string
    disabled?: boolean
    disableMaxWidth?: boolean
    label?: string
  } & Record<string, unknown>
}>()

/* Detailed Address Editor */
const isAddressEditorPopupOpen = ref(false)

const streetInput = ref<string>('')
const addressInput = ref<Address>({ label: '' })
watch(streetInput, street => addressInput.value = { ...addressInput.value, street, lat: null, lng: null })

function updateAddressFromInput() {
  if (addressInput.value.street) {
    props.context.node.input(addressInput.value)
  }
  isAddressEditorPopupOpen.value = false
}

function resetForm() {
  if (props.context.value) {
    addressInput.value = props.context.value
  } else {
    addressInput.value = { label: '' }
  }
  streetInput.value = props.context.value?.street ?? props.context.initialValue ?? ''
}
onMounted(() => resetForm())

function resetAndClose() {
  resetForm()
  isAddressEditorPopupOpen.value = false
}

/* Suggestions */
const { suggestions, formatOptionLabel, findAddressById } = useAddressAutoComplete(streetInput)
const options = computed(() => Object.values(suggestions.value).map(({ id, label, components }) => ({
  label: components.street ?? label,
  value: id,
})))

function formatStreetWithOptionLabel(option: SelectOption) {
  let label = option.label
  if (option.value) {
    const suggestion = findAddressById(String(option.value))
    if (suggestion) {
      label = suggestion.label
    }
  }
  return formatOptionLabel({ ...option, label })
}

function handleSelect(id: string) {
  const selectedAddress = findAddressById(id)
  if (selectedAddress) {
    addressInput.value = {
      label: selectedAddress.label,
      ...selectedAddress.components,
      ...selectedAddress.location,
    }
    if (selectedAddress.components.street) {
      streetInput.value = selectedAddress.components.street
    }
  }
}

const addressLines = computed(() => {
  if (props.context.value) {
    return formatAddressForLocationLabel(props.context.value).split(', ')
  }
  if (props.context.initialValue) {
    return props.context.initialValue.split(', ')
  }
  return []
})

const previewAddressLines = computed(() => formatAddressForLocationLabel(addressInput.value).split(', '))
</script>

<template>
  <div class="space-y-2" :class="{ 'max-w-md': !context.disableMaxWidth }">
    <!-- Visible Input field -->
    <div class="flex justify-between gap-4 p-3 bg-gray-50 rounded border">
      <address v-if="addressLines.length > 0" class="not-italic">
        <template v-for="line in addressLines" :key="line">
          {{ line }} <br>
        </template>
      </address>
      <span v-else class="text-gray-400">
        {{ $t('address.placeholder') }}
      </span>

      <n-button secondary :disabled="context.disabled" @click="isAddressEditorPopupOpen = true">
        <template #icon>
          <Icon name="material-symbols:edit-outline-rounded" />
        </template>
      </n-button>
    </div>

    <ThePopup
      :show="isAddressEditorPopupOpen"
      :title="context.label ?? $t('address.modal.title')"
      @close="isAddressEditorPopupOpen = false"
    >
      <div class="grid grid-cols-2 gap-x-2 gap-y-4">
        <div class="col-span-2 grid grid-cols-4 gap-2">
          <label class="col-span-3 space-y-1">
            <span class="font-semibold">
              {{ $t('address.street.label') }}
            </span>

            <n-auto-complete
              v-model:value="streetInput"
              :input-props="{ autocomplete: 'disabled' }"
              :options="options"
              :render-label="formatStreetWithOptionLabel"
              :disabled="context.disabled"
              size="large"
              :placeholder="$t('address.street.placeholder')"
              @select="handleSelect"
            />
          </label>
          <label class="space-y-1">
            <span class="font-semibold">
              {{ $t('address.streetNumber.label') }}
            </span>
            <n-input
              v-model:value="addressInput.streetNumber"
              size="large"
              :placeholder="$t('address.streetNumber.placeholder')"
            />
          </label>
        </div>

        <label class="col-span-2 space-y-1">
          <span class="font-semibold">
            {{ $t('address.addition.label') }}
          </span>
          <n-input
            v-model:value="addressInput.addition"
            size="large"
            :placeholder="$t('address.addition.placeholder')"
          />
        </label>

        <label class="space-y-1">
          <span class="font-semibold">
            {{ $t('address.postalCode.label') }}
          </span>
          <n-input
            v-model:value="addressInput.postalCode"
            size="large"
            :placeholder="$t('address.postalCode.placeholder')"
          />
        </label>
        <label class="space-y-1">
          <span class="font-semibold">
            {{ $t('address.city.label') }}
          </span>
          <n-input
            v-model:value="addressInput.city"
            size="large"
            :placeholder="$t('address.city.placeholder')"
          />
        </label>

        <label class="space-y-1">
          <span class="font-semibold">
            {{ $t('address.country.label') }}
          </span>
          <n-input
            v-model:value="addressInput.country"
            size="large"
            :placeholder="$t('address.country.placeholder')"
          />
        </label>
      </div>

      <div v-if="previewAddressLines.length > 0" class="mt-4 space-y-1">
        <div class="flex items-center gap-1">
          <span class="font-semibold">
            {{ $t('address.preview') }}
          </span>
          <n-tooltip trigger="hover">
            <template #trigger>
              <Icon name="material-symbols:info-outline-rounded" class="text-blue-500" />
            </template>
            {{ $t('address.preview.help') }}
          </n-tooltip>
        </div>
        <div class="flex justify-between gap-4 p-3 bg-gray-50 rounded border">
          <address class="not-italic">
            <template v-for="line in previewAddressLines" :key="line">
              {{ line }} <br>
            </template>
          </address>
        </div>
      </div>

      <TheDevOnlyNiceJson :address-input />

      <div class="flex items-center gap-2 mt-4">
        <n-button type="primary" :disabled="!addressInput.street" @click="updateAddressFromInput">
          {{ $t('button.save') }}
        </n-button>
        <n-button secondary @click="resetAndClose">
          {{ $t('button.cancel') }}
        </n-button>
      </div>
    </ThePopup>
  </div>
</template>
