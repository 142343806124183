<script lang="ts" setup>
import type { TiptapEditor } from '#imports'

const props = defineProps<{ editor?: TiptapEditor }>()

const isNotEditable = computed(() => !props.editor?.isEditable)
</script>

<template>
  <div class="flex flex-col gap-1">
    <div class="Row justify-between">
      <!-- Row One: Left -->
      <div class="Row">
        <FormKitCreateInputBaseTiptapMenuButton
          icon="material-symbols:format-bold-rounded"
          :label="$t('editor.format.bold')"
          :is-active="editor?.isActive('bold')"
          :is-disabled="isNotEditable || !editor?.can().chain().focus().toggleBold().run()"
          @click="editor?.chain().focus().toggleBold().run()"
        />
        <FormKitCreateInputBaseTiptapMenuButton
          icon="material-symbols:format-italic-rounded"
          :label="$t('editor.format.italic')"
          :is-active="editor?.isActive('italic')"
          :is-disabled="isNotEditable || !editor?.can().chain().focus().toggleItalic().run()"
          @click="editor?.chain().focus().toggleItalic().run()"
        />
        <FormKitCreateInputBaseTiptapMenuButton
          icon="material-symbols:format-underlined-rounded"
          :label="$t('editor.format.underline')"
          :is-active="editor?.isActive('underline')"
          :is-disabled="isNotEditable || !editor?.can().chain().focus().toggleUnderline().run()"
          @click="editor?.chain().focus().toggleUnderline().run()"
        />
        <FormKitCreateInputBaseTiptapMenuButton
          icon="material-symbols:format-strikethrough-rounded"
          :label="$t('editor.format.strike')"
          :is-active="editor?.isActive('strike')"
          :is-disabled="isNotEditable || !editor?.can().chain().focus().toggleStrike().run()"
          @click="editor?.chain().focus().toggleStrike().run()"
        />
        <n-divider vertical class="Divider" />
        <FormKitCreateInputBaseTiptapMenuColorButton
          icon="material-symbols:format-color-text-rounded"
          :label="$t('editor.format.textColor')"
          :color="editor?.getAttributes('textStyle').color ?? '#000000'"
          :is-disabled="isNotEditable"
          @update:color="color => editor?.chain().focus().setColor(color).run()"
        />
        <FormKitCreateInputBaseTiptapMenuButton
          icon="material-symbols:format-quote-rounded"
          :label="$t('editor.format.blockquote')"
          :is-active="editor?.isActive('blockquote')"
          :is-disabled="isNotEditable || !editor?.can().chain().focus().toggleBlockquote()"
          @click="editor?.chain().focus().toggleBlockquote().run()"
        />
        <n-divider vertical class="Divider" />
        <FormKitCreateInputBaseTiptapMenuButton
          icon="material-symbols:format-clear-rounded"
          :label="$t('editor.format.reset')"
          :is-disabled="isNotEditable || !editor?.can().chain().focus().unsetAllMarks().run()"
          @click="editor?.chain().focus().unsetAllMarks().run()"
        />
      </div>

      <!-- Row One: Right -->
      <div class="Row">
        <FormKitCreateInputBaseTiptapMenuButton
          icon="material-symbols:undo-rounded"
          :is-active="true"
          :is-disabled="isNotEditable || !editor?.can().chain().focus().undo().run()"
          @click="editor?.chain().focus().undo().run()"
        />
        <FormKitCreateInputBaseTiptapMenuButton
          icon="material-symbols:redo-rounded"
          :is-active="true"
          :is-disabled="isNotEditable || !editor?.can().chain().focus().redo().run()"
          @click="editor?.chain().focus().redo().run()"
        />

        <!-- Menu: extra -->
        <template v-if="$slots.extra">
          <n-divider vertical class="Divider" />
          <slot name="extra" />
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.Row {
  @apply flex items-center flex-wrap gap-1;
}
.Divider {
  @apply !bg-gray-300;
}
</style>
