import { FileFolderType } from '~/prisma/enums'

export enum FileFolderEntity {
  Offer = 'offer',
  Customer = 'customer',
}

/**
 * Configuration for loading, storing and caching file folders for different entities.
 */
export const fileFolderEntity = Object.values(FileFolderType).reduce((acc, type) => {
  if (isOfferFolderType(type)) {
    acc[type] = FileFolderEntity.Offer
  } else if (isCustomerFolderType(type)) {
    acc[type] = FileFolderEntity.Customer
  }

  return acc
}, {} as Record<FileFolderType, FileFolderEntity>)

// Helpers
function isOfferFolderType(type: FileFolderType): type is Extract<FileFolderType, `Offer${string}`> {
  return type.startsWith('Offer')
}
function isCustomerFolderType(type: FileFolderType): type is Extract<FileFolderType, `Customer${string}`> {
  return type.startsWith('Customer')
}
