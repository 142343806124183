<script setup lang="ts">
import type { ApiMachineryGetById, ApiPublicMachineryGetById, MachineryAccessoryCategory } from '~/types'
import { machineryAccessoryCategoryToGerman } from '~/translations'

const props = withDefaults(defineProps<{
  machinery: ApiMachineryGetById | ApiPublicMachineryGetById
  doLoadCompatibleAccessories: boolean
  isUpdateDisabled: boolean
  isInPublicPage?: boolean
}>(), {
  isInPublicPage: false,
})

const { shopping: queryShopping } = useQuery()
const { data: compatibleMachineryAccessories, isLoading } = queryShopping.getCompatibleAccessories(computed(() => {
  // Do not load info if reception hasn't happened
  if (!props.doLoadCompatibleAccessories) {
    return
  }

  return props.machinery.id
}))

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const makeAccessoriesCompatible = useMutation({
  mutationFn: (data: { machineryId: string, machineryAccessoryIds: string[] }) => $trpc.machinery.makeAccessoriesCompatible.mutate({ id: data.machineryId, machineryAccessoryIds: data.machineryAccessoryIds }),
  onError: makeTrpcErrorToast(notification, { description: 'Die Anbaugeräte konnte nicht verbunden werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['accessories'] }),
      queryClient.invalidateQueries({ queryKey: ['shopping'] }),
    ])
    closeCompatibilityPopup()
    notification.success({ title: 'Die Anbaugerät wurden erfolgreich verbunden', duration: 4500 })
  },
})

const { payload: compatibilityPayload, open: openCompatibilityPopup, close: closeCompatibilityPopup } = usePopup<ApiMachineryGetById>()

// Extra machinery accessories that are deemed uncompatible due to our own filtering
// but the user still connected them.
// Discussion one why we handle it this way: https://github.com/sidestream-tech/hanselmann-os/pull/4265#issuecomment-2668910483
const uniqueFilteredOutAccessories = computed(() => {
  const selectedCompatibleMachineryAccessories = (props.machinery as ApiMachineryGetById).compatibleMachineryAccessories
  if (!compatibleMachineryAccessories.value) {
    return selectedCompatibleMachineryAccessories
  }

  return selectedCompatibleMachineryAccessories.filter(a => !compatibleMachineryAccessories.value.some(b => a.id === b.id))
})
</script>

<template>
  <div>
    <ThePopup v-if="compatibilityPayload" :show="Boolean(compatibilityPayload)" width="75%" @close="closeCompatibilityPopup">
      <ServiceMachineryAccessoryCompatibilityWithMachinery
        :already-checked-row-keys="compatibilityPayload.compatibleMachineryAccessories.map(({ id }) => id)"
        @make-compatible="(ids) => { makeAccessoriesCompatible.mutate({ machineryId: machinery.id, machineryAccessoryIds: ids }) }"
      />
    </ThePopup>
    <TheDataCard>
      <template #header>
        <div class="CardHeader">
          <h2>{{ isInPublicPage ? $t('machinery.compatibleMachineryAccessories') : 'Kompatible Anbaugeräte' }}</h2>
          <n-button
            v-if="!isUpdateDisabled && doLoadCompatibleAccessories"
            @click="openCompatibilityPopup(machinery as ApiMachineryGetById)"
          >
            <template #icon>
              <Icon name="material-symbols:edit-outline-rounded" />
            </template>Ändern
          </n-button>
        </div>
      </template>

      <div v-if="!doLoadCompatibleAccessories">
        Die Maschine ist noch nicht bereit um Anbaugerät-Kompatibilitäten zu konfigurieren. Wurde die Aufnahme bereits abgeschlossen?
      </div>
      <n-spin v-else-if="isLoading" />
      <ul v-else-if="(compatibleMachineryAccessories && compatibleMachineryAccessories.length > 0) || uniqueFilteredOutAccessories.length > 0" class="pl-4 list-disc">
        <li v-for="accessory in compatibleMachineryAccessories" :key="accessory.id">
          <NuxtLink :to="`/machinery-accessory/${accessory.id}`" class="underline">
            {{ accessory.id }}
          </NuxtLink>, {{ isInPublicPage ? $t(`machineryAccessory.category.${accessory.category}`) : machineryAccessoryCategoryToGerman[accessory.category as MachineryAccessoryCategory] }}, {{ accessory.producerCompanyName ?? $t('general.notApplicable') }}
        </li>
        <li v-for="accessory in uniqueFilteredOutAccessories" :key="accessory.id">
          <n-tooltip class="sm:w-full w-[265px]" placement="right">
            <template #trigger>
              <NuxtLink :to="`/machinery-accessory/${accessory.id}`" class="underline text-red-400">
                {{ accessory.id }}
              </NuxtLink>
            </template>
            {{ $t('machinery.compatibleMachineryAccessories.filteredOut') }}
          </n-tooltip>, {{ isInPublicPage ? $t(`machineryAccessory.category.${accessory.category}`) : machineryAccessoryCategoryToGerman[accessory.category as MachineryAccessoryCategory] }}, {{ accessory.producerCompanyName ?? $t('general.notApplicable') }}
        </li>
      </ul>
      <n-empty v-else />
    </TheDataCard>
  </div>
</template>

<style scoped>
.CardHeader {
  @apply  w-full flex justify-between mb-5
}
.CardHeader h2 {
  @apply text-lg font-semibold
}
</style>
