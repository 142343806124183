import { add, isBefore } from 'date-fns'

const convertLocalTimeToUTCTime = (localDate: Date) => new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000)

export default function (startDate: Date, endDate: Date) {
  const dates = []
  let currentDate = new Date(startDate)

  // For this check to work correctly in server
  // we need to save `obligationStartsAt`, `obligationEndsAt`, `obligationActuallyEndedAt` as startofDay in local timezone
  // we convert time to UTC time to get same result from server & frontend
  while (isBefore(currentDate, endDate) || getAbsoluteDateDifferenceInHours(convertLocalTimeToUTCTime(endDate), convertLocalTimeToUTCTime(currentDate)) <= 12) {
    dates.push(new Date(currentDate))
    currentDate = add(currentDate, { days: 1 })
  }

  return dates
}
