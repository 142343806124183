<script setup lang="ts">
import type { DataTableColumns } from 'naive-ui'
import type { ApiQuickSaleGetAllByMachineryId, ApiQuickSaleGetAllByPartnerSales } from '~/types'

const props = defineProps<{ machineryId?: string }>()

const isPartnerSalesOverview = computed(() => !props.machineryId)

const quickSaleByMachineryIdColumns: DataTableColumns<ApiQuickSaleGetAllByMachineryId> = [
  {
    title: 'Beschreibung',
    key: 'description',
    minWidth: 150,
  },
  {
    title: 'Erstellt am',
    key: 'createdAt',
    render: ({ createdAt }) => useDateAsString(createdAt),
  },
  {
    title: 'Erstellt von',
    key: 'createdBy',
    render: ({ createdBy, createdByEmail }) => createdBy.name ?? createdByEmail,
  },
]

const quickSaleByPartnerSalesColumns: DataTableColumns<ApiQuickSaleGetAllByPartnerSales> = [
  {
    title: 'Geräte-ID',
    key: 'machineryId',
  },
  ...quickSaleByMachineryIdColumns,
]

const fulltextSearch = {
  filters: ['/machineryId', '/createdBy/name'],
  placeholder: 'Suchbegriff eingeben (Geräte-ID, Erstellt von)',
  queryId: 'quickSale',
}

const { fulltextSearchValue, where } = useFilterData(fulltextSearch)

const { quickSale } = useQuery()
const { data: quickSalesByMachineryId, isLoading: isLoadingQuickSalesByMachineryId } = quickSale.allByMachineryId(props.machineryId, !isPartnerSalesOverview.value)
const { data: quickSalesByPartnerSales, isLoading: isLoadingQuickSalesByPartnerSales } = quickSale.allByPartnerSales(where, isPartnerSalesOverview.value)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const deleteOne = useMutation({
  mutationFn: $trpc.quickSale.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Das Schnellangebot konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
      queryClient.invalidateQueries({ queryKey: ['quickSales'] }),
    ])
    popupConfirmDelete.value = null
    notification.success({ title: 'Das Schnellangebot wurde erfolgreich gelöscht', duration: 4500 })
  },
})

const popupConfirmDelete = ref<null | { id: string }>(null)
const popupPdf = ref<null | { id: string, description: string }>(null)
</script>

<template>
  <TheConfirmPopup v-if="popupConfirmDelete" @confirm="deleteOne.mutate(popupConfirmDelete)" @close="popupConfirmDelete = null">
    Das Schnellangebot wird gelöscht.
  </TheConfirmPopup>
  <QuickSalePopupPdf
    v-if="popupPdf"
    :payload="popupPdf"
    @close="popupPdf = null"
  />

  <TableFilters v-if="isPartnerSalesOverview" v-model="fulltextSearchValue" :placeholder="fulltextSearch.placeholder">
    <TableView
      :data="quickSalesByPartnerSales"
      :columns="quickSaleByPartnerSalesColumns"
      :is-loading="isLoadingQuickSalesByPartnerSales"
      :action-buttons="() => ['pdf', 'delete']"
      @pdf="row => popupPdf = row"
      @delete="row => popupConfirmDelete = row"
    />
  </TableFilters>

  <TableView
    v-else
    :data="quickSalesByMachineryId"
    :columns="quickSaleByMachineryIdColumns"
    :is-loading="isLoadingQuickSalesByMachineryId"
    :action-buttons="() => ['pdf', 'delete']"
    @pdf="row => popupPdf = row"
    @delete="row => popupConfirmDelete = row"
  />
</template>
