<script setup lang="ts">
import { startOfToday } from 'date-fns'
import type { ApiInternalReservationGetAll } from '~/types'

const props = defineProps<{
  additionalFilters: Record<string, unknown>
}>()

const includeCompletedReservations = ref(false)

const where = computed(() => ({
  ...props.additionalFilters,
  reservationEndsAt: generatePrismaGreaterThanOrEqualWhere(includeCompletedReservations.value ? undefined : startOfToday()),
}))

const { internalReservation: internalReservationQuery } = useQuery()
const { data: internalReservations, isLoading: isLoadingInternalReservations } = internalReservationQuery.all(where)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const deleteOne = useMutation({
  mutationFn: $trpc.internalReservation.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die interne Reservierung konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['internalReservations'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
    ])

    popupConfirmDeleteInternalReservation.value = null
    notification.success({ title: 'Die interne Reservierung wurde gelöscht', duration: 4500 })
  },
})

const { internalReservation: { columns } } = useTableColumnConfigs()

const { openInternalReservationCreateOrUpdatePopup } = useGlobalOpeners()
const popupConfirmDeleteInternalReservation = ref<null | ApiInternalReservationGetAll>(null)
</script>

<template>
  <TheConfirmPopup v-if="popupConfirmDeleteInternalReservation" @confirm="deleteOne.mutate(popupConfirmDeleteInternalReservation)" @close="popupConfirmDeleteInternalReservation = null">
    Die interne Reservierung wird gelöscht.
  </TheConfirmPopup>

  <div class="flex flex-col gap-y-2">
    <n-checkbox v-model:checked="includeCompletedReservations">
      <span class="font-semibold">
        Abgeschlossene Reservierungen einschließen
      </span>
    </n-checkbox>

    <TableView
      :data="internalReservations"
      :columns="columns"
      :is-loading="isLoadingInternalReservations"
      :action-buttons="({ deletedBy }) => deletedBy ? [] : ['update', 'delete']"
      :row-class-name="({ deletedBy }) => deletedBy ? 'no-stripe !bg-red-100 hover:!bg-red-200' : ''"
      @update="(row: ApiInternalReservationGetAll) => openInternalReservationCreateOrUpdatePopup.open({ id: row.id, mode: 'update' })"
      @delete="reservation => popupConfirmDeleteInternalReservation = reservation"
    />
  </div>
</template>
