import options from './options'
import type { PDFDocumentType } from './type'

// FUTURE TODO: use PDFKit `doc.save()` and `doc.restore()` instead of calling
// `reset()` at the beginning and end of each section function
export function reset<TData>(doc: PDFDocumentType<TData>) {
  doc
    .fontSize(options.fontSize.base)
    .fillColor(options.fontColor.base)
    .lineWidth(1)
    .strokeColor(options.fontColor.base)
    .strokeOpacity(1)
    .lineGap(0)

  return doc
}

export function maxWidth(doc: PDFDocumentType<any>, lines: string[], options: PDFKit.Mixins.TextOptions = {}) {
  if (lines.length === 0) {
    return 0
  }
  return Math.max(...lines.map(l => doc.widthOfString(l, options)))
}

export function formatPercentage(percentageBetween0And1: number | string | null | undefined, locale = 'de') {
  const percentage = percentageBetween0And1 ? Number(percentageBetween0And1) : 0
  return `${formatNumberToString(percentage * 100, locale)}%`
}

function createUnitFormatter(unit: string) {
  return (value: string | number) => `${value} ${unit}`
}

export const kgUnitFormatter = createUnitFormatter('kg')
export const mmUnitFormatter = createUnitFormatter('mm')

interface InnerEntities {
  machineryRelations: PositionToCalculatePrice[]
  machineryCategoryRelations: PositionToCalculatePrice[]
  accessoryRelations: PositionToCalculatePrice[]
  itemSetRelations: PositionToCalculatePrice[]
  automatedPositionsRelations: PositionToCalculatePrice[]
  commentPositionRelations: PositionToCalculatePrice[]
  insurancePositionRelations: PositionToCalculatePrice[]
  extraPositionRelations: PositionToCalculatePrice[]
}

export function extractInnerEntities(positions: PositionToCalculatePrice[]) {
  const innerEntities: InnerEntities = {
    machineryRelations: [],
    machineryCategoryRelations: [],
    accessoryRelations: [],
    itemSetRelations: [],
    automatedPositionsRelations: [],
    commentPositionRelations: [],
    insurancePositionRelations: [],
    extraPositionRelations: [],
  }

  for (const position of positions) {
    if (position.type === 'machinery') {
      innerEntities.machineryRelations.push(position)
    } else if (position.type === 'machineryCategory') {
      innerEntities.machineryCategoryRelations.push(position)
    } else if (['machineryAccessory', 'machineryAccessoryCategory'].includes(position.type)) {
      innerEntities.accessoryRelations.push(position)
    } else if (position.type === 'itemSet') {
      innerEntities.itemSetRelations.push(position)
    } else if (position.type === 'generatedByAutomation') {
      innerEntities.automatedPositionsRelations.push(position)
    } else if (position.type === 'comment') {
      innerEntities.commentPositionRelations.push(position)
    } else if (position.type === 'insurance') {
      innerEntities.insurancePositionRelations.push(position)
    }
  }

  innerEntities.extraPositionRelations = positions.filter((p) => {
    if (innerEntities.automatedPositionsRelations.map(({ title }) => title).includes(p.title)) {
      return false
    }

    return ['extraPosition', 'logisticsTask', 'manualPosition'].includes(p.type)
  })

  return innerEntities
}

export function drawHorizontalLine<T>(doc: PDFDocumentType<T>, moveDown = 1) {
  doc
    .moveDown(moveDown)
    .moveTo(options.margin.left, doc.y)
    .lineTo(doc.page.width - options.margin.right, doc.y)
    .stroke()
}

export function formatDetails(i18n: I18n, value: null | undefined | number | string | boolean): string | null {
  if (value === null || value === undefined) {
    return null
  }

  if (typeof value === 'boolean') {
    return i18n.t(`pdf.common.${Boolean(value).toString()}`)
  }

  return value.toString()
}
