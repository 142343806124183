export default (entity: FileFolderEntity) => {
  const { role, useremail } = useAuthorization()

  function hasPermission(action: FileFolderAction, configParams?: PermissionConfigParams): boolean {
    const user = { role: role.value, email: useremail.value }
    return canAccessFileFolder(action, user, entity, configParams)
  }

  return hasPermission
}
