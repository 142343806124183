<script setup lang="ts">
import { Color as TiptapColor } from '@tiptap/extension-color'
import { Underline as TiptapUnderline } from '@tiptap/extension-underline'
import { TextStyle as TiptapTextStyle } from '@tiptap/extension-text-style'
import { Placeholder as TiptapPlaceholder } from '@tiptap/extension-placeholder'
import { Mention as TiptapMention } from '@tiptap/extension-mention'
import { createMentionExtension } from './Mention/config'

const props = defineProps<{
  context: {
    value: string
    node: { input: (e: string) => unknown }
    height?: string
    placeholder?: string
    readonly?: boolean
    isMentionEnabled?: boolean
  }
}>()

const height = props.context.height ?? '20vh'

const mentionListId = useId()
const editor = useEditor({
  content: props.context.value.replaceAll(/\r\n|\r|\n/g, '<br>'),
  extensions: [
    TiptapStarterKit,
    TiptapUnderline,
    TiptapTextStyle,
    TiptapColor.configure({ types: ['textStyle'] }),
    TiptapPlaceholder.configure({
      placeholder: props.context.placeholder,
    }),
    TiptapMention.configure({
      HTMLAttributes: { class: 'Mention' },
      suggestion: createMentionExtension(props.context.isMentionEnabled, mentionListId),
    }),
  ],
  editorProps: {
    attributes: {
      class: 'TipTapEditor',
      style: `min-height: ${height};`,
    },
  },
  editable: !props.context.readonly,
  onUpdate: ({ editor }) => editor.isEmpty ? props.context.node.input('') : props.context.node.input(editor.getHTML()),
})

watch(() => props.context.readonly, (readonly) => {
  if (readonly === undefined || !editor) {
    return
  }
  editor.value.setEditable(!readonly)
})

onBeforeUnmount(() => unref(editor).destroy())
</script>

<template>
  <div class="rounded border ResizableWrapper">
    <div class="bg-gray-100 py-1 px-1.5 rounded-t sticky top-0 z-10">
      <FormKitCreateInputBaseTiptapMenu :editor />
    </div>

    <n-scrollbar class="h-full">
      <TiptapEditorContent :editor />
    </n-scrollbar>
    <div :id="mentionListId" class="relative" />
  </div>
</template>

<style>
.ResizableWrapper {
  @apply box-border resize overflow-auto min-w-full max-w-full max-h-full min-h-[100px] overflow-hidden;
}

.TipTapEditor {
  @apply h-full outline-none prose prose-sm max-w-none px-3;
}
.TipTapEditor p.is-editor-empty:first-child::before {
  @apply text-gray-300 float-left h-0 pointer-events-none;
  content: attr(data-placeholder);
}
.TipTapEditor .Mention {
  @apply px-1 py-0.5 text-primary-500 bg-primary-50/75 box-decoration-clone rounded;
}
</style>
