<script setup lang="ts">
import 'viewerjs/dist/viewer.css'
import * as viewer from 'v-viewer'
import type { CustomFolderTreeOption } from '~/types'
import type { FileFolderPageMode } from '~/components/FileFolder/Page.vue'

const props = defineProps<{ option: CustomFolderTreeOption, mode: FileFolderPageMode, entity: FileFolderEntity }>()
defineEmits<{
  (e: 'editFolder'): void
}>()

const hasFileFolderPermission = useFileFolderPermission(props.entity)
const popupPdfSource = ref<null | string>(null)

const fileExtension = computed(() => props.option.nodeType === 'file'
  ? getFileExtension(props.option.file.path)
  : undefined)
const isNameEditable = computed(() => props.option.nodeType === 'folder'
  && props.mode === 'view'
  && hasFileFolderPermission(FileFolderAction.UpdateFolder, { currFolder: props.option.folder }))

function handleFileClick() {
  if (!fileExtension.value) {
    return
  }

  if (fileExtension.value === '.pdf') {
    popupPdfSource.value = props.option.key
  } else if (fileExtension.value === '.png' || fileExtension.value === '.jpg' || fileExtension.value === '.jpeg') {
    previewImg(props.option.key)
  } else {
    window.open(props.option.key, '_blank')
  }
}

/** Image preview */
function previewImg(filePath: string) {
  viewer.api({
    images: [{
      'data-source': filePath,
      'src': filePath,
    }],
    options: {
      toolbar: true,
      url: 'data-source',
    },
  })
}
</script>

<template>
  <div class="flex items-center p-2">
    <div
      v-if="fileExtension"
      class="flex items-center gap-2"
      @click="handleFileClick"
    >
      <Icon :name="getFileExtensionIcon(fileExtension)" class="shrink-0" />
      <span class="text-blue-500 underline cursor-pointer">{{ option.label }}</span>
    </div>
    <div v-else class="flex items-center gap-2">
      <Icon name="material-symbols:folder-outline-rounded" size="18" />
      <span class="text font-bold">{{ option.label }}</span>
      <n-button
        v-if="isNameEditable"
        type="primary"
        size="small"
        quaternary
        class="grow"
        @click="$emit('editFolder')"
      >
        <template #icon>
          <Icon name="material-symbols:edit-square-outline-rounded" />
        </template>
      </n-button>
    </div>

    <LazyFilePdfEmbedPopup
      v-if="popupPdfSource"
      :source="popupPdfSource"
      @close="popupPdfSource = null"
    />
  </div>
</template>
